import styled from 'styled-components';

import React, {FC} from 'react';

interface IColumnTitle {
  children?: React.ReactNode;
  title: string;
  center?: boolean;
  padding?: string;
}

const StyledColumnTitle = styled.span<{$center?: boolean; $padding?: string}>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.$center ? 'center' : 'start')};
  align-items: center;
  padding: ${props => props.$padding};
  gap: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #0f1120;
`;

const ColumnTitle: FC<IColumnTitle> = ({title, center, padding}) => {
  return (
    <StyledColumnTitle $center={center} $padding={padding}>
      {title}
    </StyledColumnTitle>
  );
};

export default ColumnTitle;
