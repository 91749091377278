import styled from 'styled-components';

import React from 'react';

import {TextSmall} from '@Common/Text';

const CheckboxLabel = styled.label`
  display: flex;
  align-items: flex-start;
  gap: 5px;
`;

const CheckboxInput = styled.input<{$isNotField?: boolean}>`
  border-radius: 5px;
  width: 20px;
  height: 20px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    border: ${({$isNotField}) => ($isNotField ? ' 2px solid red' : 'none')};
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 7px;
  width: 325px;
  & h3 {
    margin-left: 25px;
  }
`;

interface IEditProfileItem {
  title: string;
  value: boolean;
  setValue: (value: boolean) => void;
  helper: string;
}
const GroupsItemCheckboxEditProfile = ({title, value, setValue, helper}: IEditProfileItem) => {
  return (
    <CheckboxWrapper>
      <CheckboxLabel>
        <CheckboxInput checked={value} onChange={e => setValue(e.target.checked)} type="checkbox" />
        {title}
      </CheckboxLabel>
      <TextSmall>{helper}</TextSmall>
    </CheckboxWrapper>
  );
};

export default GroupsItemCheckboxEditProfile;
