import styled from 'styled-components';

import React from 'react';

import ClientCardPicture from '@components/ProfileCard/Picture';

import {Colors, TextHeaderMedium, TextParagraph} from '@Common/Text';

import {IUser} from 'types/User';

const ContentInfoCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  align-items: center;
  text-align: left;
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};

  border-radius: 10px;
`;

export const checkRole = (role: boolean, word: string) => {
  return role ? word : '';
};

export const checkRoles = (data: IUser) => (
  <>
    {checkRole(data.is_superuser, 'Админ')} {checkRole(data.is_supervisor, 'Куратор')}{' '}
    {checkRole(data.is_tracker, 'Трекер')} {checkRole(data.is_customer, 'Бизнес-заказчик')}{' '}
    {checkRole(data.is_hired, 'Исполнитель')} {checkRole(data.is_project, 'Инициатор')}{' '}
    {checkRole(data.is_observer, 'Наблюдатель')} {checkRole(data.is_expert, 'Эксперт')}{' '}
    {checkRole(data.is_internal, 'Является сотрудником атомной отрасли')}{' '}
  </>
);

const ProfileInfoCard = ({data}: {data: IUser}) => {
  return (
    <ContentInfoCardStyled>
      <ClientCardPicture src={data.avatar} />
      <TextHeaderMedium align="center">{data.fio || data.email1}</TextHeaderMedium>
      <TextParagraph align="center" color={Colors.grey}>
        Компания: {data.company_name || '-'}
      </TextParagraph>
      <TextParagraph align="center" color={Colors.grey}>
        Должность: {data.position || '-'}
      </TextParagraph>
      <TextParagraph align="center" color={Colors.grey}>
        Роли: {checkRoles(data)}
      </TextParagraph>

      <TextParagraph align="center" color={Colors.textDark}>
        Комментариев: {data.comment_count || '-'}
      </TextParagraph>
    </ContentInfoCardStyled>
  );
};

export default ProfileInfoCard;
