import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {NavigationList} from 'types/components';

interface navigationStateType {
  value: NavigationList;
}

const initialState: navigationStateType = {
  value: [{path: '/', title: 'Главная'}],
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigationList: (state, action: PayloadAction<{navigationList: NavigationList}>) => {
      state.value = action.payload.navigationList;
    },
  },
});

export const {setNavigationList} = navigationSlice.actions;
export default navigationSlice.reducer;
