import React, {useRef} from 'react';

import {ContentBoardMenu} from '@components/Menu';

import {UseNavigationList} from '@hooks/UseNavigationList';

const Dashboards = () => {
  const navigationList = useRef([
    {path: '/dashboards', title: 'Доски'},
    {path: '/projects', title: 'Список'},
  ]);

  UseNavigationList(navigationList.current);

  return (
    <>
      <ContentBoardMenu pageTitle={'Доски'} />
      {/* <PaginationMenu /> */}
    </>
  );
};

export default Dashboards;
