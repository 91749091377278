import {styled} from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  width: 50%;
`;

export const ExpertiseContainer = styled.div`
  display: flex;
  width: 600px;
  flex-direction: column;
  gap: 10px;
`;
