import React, {useState} from 'react';
import Select, {StylesConfig} from 'react-select';

import {DropdownSingleType, MyOption} from '../Single/types';

import './index.css';
import {FiltersPanelDropdownProps} from './types';

const FiltersPanelDropdown = ({
  labels,
  handleChange,
  placeholder,
  initialData,
  isClearable = true,
}: FiltersPanelDropdownProps) => {
  const styles: StylesConfig<MyOption> = {
    container: baseStyles => ({
      ...baseStyles,
      width: '300px',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: '100px',
      cursor: 'pointer',
      borderRadius: '10px',
      height: '40px',

      borderColor: state.isFocused ? '#9fb6ff' : baseStyles.borderColor,
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: '10px',
      padding: '10px 4px',
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      padding: '2px 12px',
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '0 8px',

      maxHeight: '150px',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
      scrollbarColor: '#888',
    }),
    input: baseStyles => ({
      ...baseStyles,
      margin: '0px',
      color: '#51575F',
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      margin: '0px',
    }),
    indicatorSeparator: baseStyles => ({
      ...baseStyles,
      display: 'none',
    }),
    clearIndicator: baseStyles => ({
      ...baseStyles,
      color: '#025EA1',
      padding: '0',
      svg: {
        width: '25px',
        height: '25px',
      },
    }),
    dropdownIndicator: baseStyles => ({
      ...baseStyles,
      color: '#025EA1',
      padding: '0 8px',
      svg: {
        width: '25px',
        height: '25px',
      },
    }),
    option: (baseStyles, {isFocused, isSelected}) => ({
      ...baseStyles,
      cursor: 'pointer',
      fontSize: '16px',
      padding: '4px 16px',
      borderRadius: '10px',
      backgroundColor: isSelected ? '#003274' : isFocused ? '#E0E1E8' : baseStyles.backgroundColor,
      wordBreak: 'break-word',

      '@media (max-width: 1300px)': {
        padding: '7px 7px',
      },
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      color: '#51575F',
      margin: '0',
    }),
  };

  const [activeItem, setActiveItem] = useState<DropdownSingleType>(initialData || null);

  const customNoOptionsMessage = ({inputValue}: {inputValue: string}) => {
    return inputValue ? 'Нет результатов' : 'Нет доступных опций';
  };

  const handleChangeItem = (item: DropdownSingleType) => {
    setActiveItem(item);
    handleChange(item?.value || null);
  };

  return (
    <Select
      menuPlacement="auto"
      isClearable={isClearable}
      isSearchable={true}
      className="custom-select-control"
      classNamePrefix="custom-select"
      placeholder={placeholder || 'Выберите'}
      options={labels}
      onChange={selectedOption => handleChangeItem(selectedOption as DropdownSingleType)}
      value={activeItem}
      styles={styles}
      noOptionsMessage={customNoOptionsMessage}
    />
  );
};

export default FiltersPanelDropdown;
