import {Link} from 'react-router-dom';

import React from 'react';

import {EditIcon} from '@Common/Images';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import TagsTableRow from './Row';

type RecordProps = {
  id: number;
  name: string;
};

const Record = ({id, name}: RecordProps) => {
  return (
    <TagsTableRow $templateColumns="88% 10%" key={id} $isGray={id % 2 !== 0}>
      <TextParagraph color={Colors.textDark}>{name}</TextParagraph>
      <Link to={`${id}` + '/edit'}>
        <EditIcon />
      </Link>
    </TagsTableRow>
  );
};

export default Record;
