import styled from 'styled-components';

import React from 'react';

import {FlexButton} from '@Common/Utils/FlexButton';

import UseAppDispatch from '@hooks/UseAppDispatch';

import {AlertButtonProps} from 'types/Alert';

const StyledAlertButton = styled(FlexButton)`
  padding: 10px;
  justify-content: center;
  align-items: center;

  margin: 2px 0;

  width: 405px;

  font-size: ${({theme}) => theme.fontSize.medium};
  font-weight: ${({theme}) => theme.fontWeight.normal};

  border-radius: ${({theme}) => theme.borderRadius.full};
  border: ${({theme}) => theme.border};

  background: ${({theme}) => theme.colors.white};
  color: ${({theme}) => theme.colors.darkBlue};

  cursor: pointer;
`;

const AlertButton = ({title, action}: AlertButtonProps) => {
  const dispatch = UseAppDispatch();
  return <StyledAlertButton onClick={() => dispatch(action())}>{title}</StyledAlertButton>;
};

export default AlertButton;
