import styled from 'styled-components';

import {NavLink} from 'react-router-dom';

const Container = styled(NavLink)<{$isMinimized?: boolean}>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 4px;
  gap: 10px;
  background: #ffffff;
  border-bottom: 1px solid #868897;
  box-shadow: 0 0 32px rgba(172, 172, 172, 0.25);
  box-sizing: border-box;
  justify-content: ${props => (props.$isMinimized ? 'center' : 'left')};

  cursor: pointer;

  & svg {
    width: ${({$isMinimized}) => ($isMinimized ? '50px' : '250px')};
    height: ${({$isMinimized}) => ($isMinimized ? '62px' : '100px')};
  }
`;

export default Container;
