import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import initialState from 'store/support/state';
import {sortArray} from 'utils';

import {ISupportItem} from 'types/state/Support';

const supportSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    sort: (
      state,
      action: PayloadAction<{sortableField: keyof ISupportItem; sortDirection: string}>,
    ) => {
      sortArray(state.value, action.payload);
    },
    sortToggle: state => {
      state.sortDirection = state.sortDirection === 'up' ? 'down' : 'up';
    },
  },
});

export const {sort, sortToggle} = supportSlice.actions;

export default supportSlice.reducer;
