import styled from 'styled-components';

import React, {FC, MouseEvent} from 'react';

import {CloseFilterIcon, DownIcon, RightIcon} from '@Common/Images';
import {FormItem} from '@Common/Utils';

const StyledButton = styled(FormItem)<{$width?: number}>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  width: ${({$width}) => ($width ? $width : 340)}px;
`;
const StyledIcons = styled.div`
  display: flex;
  gap: 10px;
`;

const TitleStyled = styled.div`
  /* width: 260px; */
`;

const StyledButtonWrapper = styled.div<{$width?: number}>`
  width: ${({$width}) => ($width ? $width : 340)}px;

  & svg {
    height: 15px;
    width: 15px;
  }
`;

interface ISortFormButton {
  action: (event: MouseEvent) => void;
  actionDelete: React.MouseEventHandler<SVGSVGElement>;
  baseTitle: string;
  title?: string;
  isOpen: boolean;
  width?: number;
}

const SortFormButton: FC<ISortFormButton> = ({
  action,
  actionDelete,
  baseTitle,
  title,
  isOpen,
  width,
}) => {
  return (
    <StyledButtonWrapper $width={width}>
      <StyledButton onClick={action} $width={width}>
        <TitleStyled>{isOpen ? baseTitle : title}</TitleStyled>
        <StyledIcons>
          {title && <CloseFilterIcon onClick={(e: MouseEvent<SVGSVGElement>) => actionDelete(e)} />}

          {isOpen ? <DownIcon /> : <RightIcon />}
        </StyledIcons>
      </StyledButton>
    </StyledButtonWrapper>
  );
};

export default SortFormButton;
