import {instance} from 'api/instance';

import {IApiResponse} from 'types/services';
import {IComment, IPostComment} from 'types/state/Comment';

export const CommentService = {
  async getAll() {
    const response = await instance.get<IApiResponse<IComment>>('/comment/');

    return response.data.results;
  },

  async getByid(id: number) {
    const response = await instance.get<IComment>(`/comment/${id}/`);

    return response.data;
  },

  async getByProject(projectId: number) {
    const response = await instance.get<IApiResponse<IComment>>(`/comment/?object_id=${projectId}`);

    const results = response.data.results;

    results.sort((a, b) => (b.created_at || '').localeCompare(a.created_at || ''));

    return results;
  },

  async getByStage(stageId: number) {
    const response = await instance.get<IApiResponse<IComment>>(
      `/comment/?content_type=22&object_id=${stageId}`,
    );
    const results = response.data.results;
    results.sort((a, b) => (b.created_at || '').localeCompare(a.created_at || ''));
    return results;
  },

  async getByStageRouter(stageId: number) {
    const response = await instance.get<IApiResponse<IComment>>(
      `/comment/?content_type=74&object_id=${stageId}`,
    );
    const results = response.data.results;
    results.sort((a, b) => (b.created_at || '').localeCompare(a.created_at || ''));
    return results;
  },

  async getByExpertise(expertiseId: number) {
    const response = await instance.get<IApiResponse<IComment>>(
      `/comment/?content_type=32&object_id=${expertiseId}`,
    );
    return response.data.results;
  },

  async create(data: IPostComment) {
    try {
      return instance.post<IComment>('/comment/', data);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },
};
