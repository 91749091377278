import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import React, {useState} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {FilterButton} from '@Common/Buttons';
import SingleDropdown from '@Common/Dropdown/Single';
import {DropdownSingleType} from '@Common/Dropdown/Single/types';
import {CrossIcon} from '@Common/Images';
import {TextInput} from '@Common/Input';
import {TextHeader} from '@Common/Text';

import UseMutationProjectCustomerStatusAnswer from '@hooks/UseMutationProjectCustomerStatusAnswer';

import {IUser} from 'types/User';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 25px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  z-index: 1001;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

interface ModalProps {
  setIsOpen: (isOpen: boolean) => void;
  user: IUser;
  id: number;
}

const labels = [
  {
    value: 'ready',
    label: 'Готов стать бизнес-заказчиком',
  },
  {
    value: 'ready_after_changes',
    label: 'Готов стать бизнес-заказчиком после внесения изменений',
  },
  {
    value: 'not_ready',
    label: 'Не готов стать бизнес-заказчиком',
  },
  {
    value: 'interested',
    label: 'Требуются дополнения/необходимо время',
  },
];

type Data = {
  status: DropdownSingleType;
  comment: string;
};

const CustomerAnswerModal = ({setIsOpen, id}: ModalProps) => {
  const pageId = Number(useParams().id);

  const [data, setData] = useState<Data>({
    status: labels[0],
    comment: '',
  });

  const mutation = UseMutationProjectCustomerStatusAnswer(id, pageId);

  const onSave = () => {
    mutation.mutate({
      project_customer_status: data.status?.value || 'ready',
      comment: data.comment,
    });

    setIsOpen(false);
  };

  return (
    <>
      <Overlay>
        <ModalWrapper
          onClick={e => {
            e.stopPropagation();
          }}>
          <CloseButton onClick={() => setIsOpen(false)}>
            <CrossIcon />
          </CloseButton>
          <TextHeader align="center">Установка статуса проекту</TextHeader>

          <SingleDropdown
            isClearable={false}
            labels={labels}
            value={data.status}
            handleChange={value => setData(prev => ({...prev, status: value}))}
          />
          <TextInput
            height={100}
            value={data.comment}
            onChange={e => setData(prev => ({...prev, comment: e.target.value}))}
          />

          <ButtonWrapper>
            <FilterButton width="100px" isBlue={false} title={'Отправить'} action={onSave} />
          </ButtonWrapper>
        </ModalWrapper>
      </Overlay>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(CustomerAnswerModal);
