import styled from 'styled-components';

import React, {useState} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {CheckedIcon, CrossIcon, EditIcon, SaveDisk, UndoIcon} from '@Common/Images';

import {IUser} from 'types/User';

const StyledToggleStatus = styled.div<{$isEditing: boolean}>`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  cursor: ${({$isEditing}) => ($isEditing ? 'pointer' : 'default')};
  border: 1px solid
    ${({theme, $isEditing}) => ($isEditing ? theme.colors.greyCircuit : 'transparent')};
`;

const EditingStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  height: 40px;
`;

const Button = styled.button`
  display: flex;
  font-size: 16px;
  background-color: transparent;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 0;
  & path {
    fill: ${({theme}) => theme.colors.grey};
  }
`;

const ButtonsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

const checkStatus = (status?: boolean) => {
  return status ? <CheckedIcon /> : <CrossIcon />;
};

interface IToggleStatusProps {
  user: IUser;
  status?: boolean;
  handleSave: (value: boolean) => void;
}

const ToggleStatus = ({status = false, handleSave, user}: IToggleStatusProps) => {
  const [editing, setEditing] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(status);
  const {is_superuser, is_supervisor, is_tracker} = user;
  const userLaw = [is_superuser, is_supervisor, is_tracker].find(item => !!item);

  const handleUndo = () => {
    setUpdatedStatus(status);
    setEditing(false);
  };

  const handleUpdateStatus = () => {
    if (editing) setUpdatedStatus(prev => !prev);
  };

  const onSave = () => {
    handleSave(updatedStatus);
    setEditing(false);
  };

  return (
    <EditingStyled>
      <StyledToggleStatus onClick={handleUpdateStatus} $isEditing={editing}>
        {checkStatus(updatedStatus)}{' '}
      </StyledToggleStatus>
      <ButtonsStyled>
        {editing ? (
          <>
            <Button onClick={onSave}>
              <SaveDisk />
            </Button>

            <Button onClick={handleUndo}>
              <UndoIcon />
            </Button>
          </>
        ) : (
          userLaw && (
            <Button onClick={() => setEditing(true)}>
              <EditIcon />
            </Button>
          )
        )}
      </ButtonsStyled>
    </EditingStyled>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(ToggleStatus);
