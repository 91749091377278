import {ContentBoardMenuEnum} from 'constants/enum/ContentBoardMenu';

export const sortTitles = [
  {
    label: ContentBoardMenuEnum.dateCreateUp,
  },
  {
    label: ContentBoardMenuEnum.dateCreateDown,
  },
  {
    label: ContentBoardMenuEnum.nameUp,
  },
  {
    label: ContentBoardMenuEnum.nameDown,
  },
  {
    label: ContentBoardMenuEnum.dateUpdateUp,
  },
  {
    label: ContentBoardMenuEnum.dateUpdateDown,
  },
];
