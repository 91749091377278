import {createSlice} from '@reduxjs/toolkit';
import initialState from 'store/reports/state';

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
});

export default reportsSlice.reducer;
