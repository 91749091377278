import styled from 'styled-components';

import React from 'react';

import {FilterButton} from '@Common/Buttons';
import ModalWrapper from '@Common/Modal/Wrapper';
import {Colors, TextParagraph} from '@Common/Text';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

interface ModalProps {
  setIsOpen: (isOpen: boolean) => void;
  handleYes: () => void;
  stage: string;
}

const ChecklistCreateAlertModal = ({handleYes, setIsOpen, stage}: ModalProps) => {
  const handleClickYes = () => {
    handleYes();
    setIsOpen(false);
  };

  return (
    <ModalWrapper>
      <TextWrapper>
        <TextParagraph align="center" color={Colors.grey}>
          Уважаемый Инициатор!
        </TextParagraph>
        <TextParagraph align="center" color={Colors.grey}>
          Благодарим Вас за предоставление подробной информации!
        </TextParagraph>
        <TextParagraph align="center" color={Colors.grey}>
          По результатам прохождения чек-листа Ваша заявка находится на этапе маршрутизатора: «
          {stage}»
        </TextParagraph>
        <TextParagraph align="center" color={Colors.grey}>
          Пожалуйста, ожидайте дальнейших инструкций и обратной связи от платформы маршрутизации
          «Иннохаб Росатома»
        </TextParagraph>
      </TextWrapper>
      <ButtonWrapper>
        <FilterButton width="100px" isBlue={false} title={'Ок'} action={handleClickYes} />
      </ButtonWrapper>
    </ModalWrapper>
  );
};

export default ChecklistCreateAlertModal;
