import React from 'react';
import Select, {StylesConfig} from 'react-select';

import './index.css';
import {DropdownSingleType, MyOption, SingleDropdownProps} from './types';

const SingleDropdown = ({
  labels,
  handleChange,
  value,
  isError,
  placeholder,
  isClearable = true,
  isFocus,
  width,
}: SingleDropdownProps) => {
  const styles: StylesConfig<MyOption> = {
    container: baseStyles => ({
      ...baseStyles,
      width: width ? width : '100%',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: '100px',
      cursor: 'pointer',
      borderRadius: '10px',
      height: '40px',

      borderColor: state.isFocused ? '#9fb6ff' : isError ? 'red' : baseStyles.borderColor,
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: '10px',
      padding: '10px 4px',
      zIndex: '10000',
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      padding: '2px 6px',
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '0 8px',

      maxHeight: '150px',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
      scrollbarColor: '#888',
    }),
    input: baseStyles => ({
      ...baseStyles,
      margin: '0px',
      color: '#51575F',
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      margin: '0px',
    }),
    indicatorSeparator: baseStyles => ({
      ...baseStyles,
      display: 'none',
    }),
    clearIndicator: baseStyles => ({
      ...baseStyles,
      color: '#025EA1',
      padding: '0',
      svg: {
        width: '25px',
        height: '25px',
      },
    }),
    dropdownIndicator: baseStyles => ({
      ...baseStyles,
      color: '#025EA1',
      padding: '0 4px 0 0',
      svg: {
        width: '25px',
        height: '25px',
      },
    }),
    option: (baseStyles, {isFocused, isSelected}) => ({
      ...baseStyles,
      cursor: 'pointer',
      fontSize: '16px',
      padding: '4px 16px',
      borderRadius: '10px',
      backgroundColor: isSelected ? '#003274' : isFocused ? '#E0E1E8' : baseStyles.backgroundColor,
      wordBreak: 'break-word',

      '@media (max-width: 1300px)': {
        padding: '7px 7px',
      },
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      color: '#51575F',
      margin: '0',
    }),
  };

  const customNoOptionsMessage = ({inputValue}: {inputValue: string}) => {
    return inputValue ? 'Нет результатов' : 'Нет доступных опций';
  };

  return (
    <Select
      menuPlacement="auto"
      isClearable={isClearable}
      isSearchable={true}
      className="custom-select-control"
      classNamePrefix="custom-select"
      placeholder={placeholder || 'Выберите'}
      options={labels}
      onChange={selectedOption => handleChange(selectedOption as DropdownSingleType)}
      value={value}
      styles={styles}
      noOptionsMessage={customNoOptionsMessage}
      defaultMenuIsOpen={isFocus}
      autoFocus={isFocus}
    />
  );
};

export default SingleDropdown;
