import {styled} from 'styled-components';

import {useParams} from 'react-router-dom';

import {axiosDocument} from '@api/instance';
import React, {useRef, useState} from 'react';

import {PlusIcon, TrashIcon} from '@Common/Images';
import {StyledLink} from '@Common/Link';
import {StyledText} from '@Common/Text';

import {Colors} from '@constants/enum';
import {acceptFiles} from '@constants/regex';

import UseMutationDraftDocument from '@hooks/UseMutationDraftDocument';

import {IDraftsListItem} from 'types/state/Drafts';

import {IContentDocumentProps} from './types';

const Documents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding-left: 6px;
`;

const ContentDocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  min-height: 40px;
  justify-content: center;
`;

const Buttons = styled.div`
  display: flex;
`;

const SelectFileButton = styled.button`
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  & path {
    fill: ${({theme}) => theme.colors.grey};
  }
`;

const StyledTextLinks = styled(StyledText)`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledHelper = styled.div`
  display: flex;
  margin: 0;
  padding-left: 6px;
  word-break: break-word;

  color: ${({theme}) => theme.colors.black};
  font-weight: ${({theme}) => theme.fontWeight.normal};
  font-size: ${({theme}) => theme.fontSize.small};
`;

const FileInput = styled.input.attrs({
  type: 'file',
  accept: acceptFiles,
})`
  display: none;
`;

const ContentDocument = ({documentsLinks, type, isExtra}: IContentDocumentProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);

  const filteredDocumentsLinks = documentsLinks?.filter(link => link) || [];
  const documentsName =
    filteredDocumentsLinks?.map(link => decodeURIComponent(link?.split('/').pop() || '') || null) ||
    null;

  const pageId = Number(useParams().id);

  const mutation = UseMutationDraftDocument(pageId);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const newFilesArray = Array.from(files);
      setSelectedFiles(newFilesArray);
      if (isExtra) {
        const formData = new FormData();
        newFilesArray.forEach(file => {
          formData.append('extra_files', file);
        });
        void axiosDocument.patch<IDraftsListItem>(`/draft/${pageId}/`, formData);
      } else {
        mutation.mutate({
          [type]: newFilesArray[0] ? newFilesArray[0] : null,
        });
      }
    }
  };

  const onDelete = () => {
    setSelectedFiles(null);
    mutation.mutate({
      [type]: '',
    });
  };

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <ContentDocumentWrapper>
      <Documents>
        <StyledTextLinks $color={Colors.accentBlue}>
          {(selectedFiles?.length || 0) > 0
            ? selectedFiles?.map(file => (
                <StyledLink key={file.name} href={URL.createObjectURL(file)}>
                  {file.name}
                </StyledLink>
              ))
            : documentsLinks
            ? documentsLinks.map((link, index) => {
                if (!link) return null;
                return (
                  <StyledLink key={index} href={link}>
                    {documentsName ? documentsName[index] : '-'}
                  </StyledLink>
                );
              })
            : '-'}
        </StyledTextLinks>

        <Buttons>
          {(selectedFiles || (documentsLinks && filteredDocumentsLinks?.length > 0)) && (
            <SelectFileButton onClick={onDelete}>
              <TrashIcon />
            </SelectFileButton>
          )}
          <SelectFileButton onClick={openFileSelector}>
            <PlusIcon />
          </SelectFileButton>
        </Buttons>
        <FileInput
          ref={fileInputRef}
          onChange={handleFileChange}
          multiple={type === 'extra_files'}
        />
      </Documents>

      {type === 'extra_files' && <StyledHelper>Можно добавить несколько файлов</StyledHelper>}
    </ContentDocumentWrapper>
  );
};

export default ContentDocument;
