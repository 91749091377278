import styled from 'styled-components';

import {useQuery} from '@tanstack/react-query';
import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import {selectFilters} from '@store/filters/selectors';
import {RootState} from '@store/index';

import {ProjectService} from '@services/project';

import {PaginationButtons} from '@components/Menu';

import FiltersPanel from '@Common/FiltersPanel';
import {CheckedIcon, CrossIcon} from '@Common/Images';
import StatusChecker from '@Common/StatusChecker';
import Table from '@Common/Table';
import TableHeader from '@Common/Table/Header';
import {TableItem} from '@Common/Table/Item';
import TableNotFoundItem from '@Common/Table/NotFound';
import {TableRowStyled} from '@Common/Table/Row';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {formatDate} from '@utils/formatDate';

import {Props} from './types';

const JustifyCenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const templateColumns =
  '20px minmax(200px, 1fr) minmax(125px, 1fr) minmax(125px, 1fr) minmax(150px, 1fr) minmax(125px, 1fr) minmax(125px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr)';

const ProjectsTable = ({filters}: Props) => {
  const {
    search,
    tracker,
    projectArea,
    isInternal,
    accelerator,
    isFinished,
    routerStage,
    page,
    supervisor,
    expertiseRequired,
    answerRequired,
  } = filters;

  const [searchTerm, setSearchTerm] = useState('');
  const {
    data: projectsList,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [
      'projects',
      searchTerm,
      projectArea,
      isInternal,
      tracker,
      accelerator,
      isFinished,
      supervisor,
      routerStage,
      expertiseRequired,
      answerRequired,
      page,
    ],
    queryFn: () =>
      ProjectService.getAll({
        searchTerm,
        projectArea,
        isInternal,
        tracker,
        supervisor,
        accelerator,
        isFinished,
        routerStage,
        expertiseRequired,
        answerRequired,
        page,
      }),
  });
  useEffect(() => {
    if (!search) setSearchTerm(search);
  }, [search]);

  const count = useMemo(() => projectsList?.count, [projectsList?.count]);

  return (
    <>
      <FiltersPanel count={count} isProjects handleEnterSearch={() => setSearchTerm(search)} />

      <StatusChecker errorsArray={[isError]} loadingsArray={[isLoading]}>
        <Table>
          <TableHeader
            templateColumns={templateColumns}
            titles={[
              {title: '№'},
              {title: 'Название'},
              {title: 'Куратор'},
              {title: 'Акселератор'},
              {title: 'Направление'},
              {title: 'Дата обновления'},
              {title: 'Внутренний', align: 'center'},
              {title: 'Наличие бизнес-заказчика', align: 'center'},
              {title: 'Наличие внутренней экспертизы', align: 'center'},
              {title: 'Наличие внешней экспертизы', align: 'center'},
              // {title: 'Комментарий', align: 'center'},
            ]}
          />

          {projectsList?.results.length ? (
            projectsList?.results.map((project, index) => {
              return (
                <TableItem key={project.id} path={`/project/${project.id}`}>
                  <TableRowStyled $templateColumns={templateColumns}>
                    <TextParagraph color={Colors.textDark}>{index + 1}</TextParagraph>
                    <TextParagraph color={Colors.accentBlue}>{project.name}</TextParagraph>
                    <TextParagraph color={Colors.accentBlue}>
                      {project.supervisor?.fio || project.supervisor?.email1}
                    </TextParagraph>

                    <TextParagraph color={Colors.textDark}>
                      {project.accelerator?.name}
                    </TextParagraph>
                    <TextParagraph color={Colors.textDark}>
                      {project.project_area?.name}
                    </TextParagraph>
                    <TextParagraph color={Colors.textDark}>
                      {formatDate(project.updated_at)}
                    </TextParagraph>
                    <JustifyCenterWrapper>
                      {project.is_internal ? <CheckedIcon /> : <CrossIcon />}
                    </JustifyCenterWrapper>
                    <JustifyCenterWrapper color={Colors.textDark}>
                      {project.status_customer ? <CheckedIcon /> : <CrossIcon />}
                    </JustifyCenterWrapper>
                    <JustifyCenterWrapper color={Colors.textDark}>
                      {project.status_expert_feedback ? <CheckedIcon /> : <CrossIcon />}
                    </JustifyCenterWrapper>
                    <JustifyCenterWrapper color={Colors.textDark}>
                      {project.status_indep_expert_feedback ? <CheckedIcon /> : <CrossIcon />}
                    </JustifyCenterWrapper>
                    {/* <TextParagraph color={Colors.textDark}>
                    {project.customers_status[0]?.comment}
                  </TextParagraph> */}
                  </TableRowStyled>
                </TableItem>
              );
            })
          ) : (
            <TableNotFoundItem />
          )}
        </Table>
      </StatusChecker>
      <PaginationButtons margin={15} count={count} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  filters: selectFilters(state),
});

export default connect(mapStateToProps)(ProjectsTable);
