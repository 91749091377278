import {IFiltersState} from './types';

const initialState: IFiltersState = {
  search: '',
  isArchived: 'false',
  isInternal: null,
  accelerator: null,
  createdBy: null,
  projectArea: null,
  tracker: null,
  isFinished: null,
  routerStage: null,
  lastLogin: null,
  role: null,
  supervisor: null,
  expertiseRequired: null,
  answerRequired: null,
  page: 1,
  acceleratorRequest: null,
  techRequestStatus: null,
  filterByUpdate: null,
  scoringStatus: null,
};

export default initialState;
