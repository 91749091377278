import {useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';

import {CustomersService} from '@services/customers';

const UseCustomers = (projectId: number) => {
  const pageId = Number(useParams().id);

  const {
    data: customersAnswersList,
    isLoading: isLoadingCustomersAnswers,
    isError: isErrorCustomersAnswers,
  } = useQuery({
    queryKey: ['project-customer-response', pageId],
    queryFn: () => CustomersService.getByProject(projectId),
  });

  return {
    customersAnswersList: customersAnswersList || [],
    isLoadingCustomersAnswers,
    isErrorCustomersAnswers,
  };
};

export default UseCustomers;
