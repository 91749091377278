import SupportTable from 'components/Tables/Support';
import React, {FC} from 'react';

import {ContentBoardMenu, PaginationMenu} from '@components/Menu';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {PlusIcon} from '@Common/Images';

import useAppSelector from '@hooks/UseAppSelector';
const Support: FC = () => {
  const mockFunction = () => console.log('Support/mock');

  const supportList = useAppSelector(state => state.support.value);
  const lengthList = supportList.length;
  const PlusImage = <PlusIcon />;

  return (
    <>
      <ContentBoardMenu pageTitle={'Инструменты поддержки'}>
        <ContentBoardMenuButton title={'Добавить'} action={mockFunction} image={PlusImage} />
      </ContentBoardMenu>
      <PaginationMenu lengthList={lengthList} />

      <SupportTable editAction={mockFunction} />
    </>
  );
};

export default Support;
