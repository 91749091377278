import {useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import React from 'react';

import {ProfileService} from '@services/profile';

import StatusChecker from '@Common/StatusChecker';

import MemberInItem from './MemberInItem';

const ActivityMemberIn = () => {
  const pageId = Number(useParams().id);
  const {data, isLoading, isError} = useQuery({
    queryKey: ['member-in', pageId],
    queryFn: () => ProfileService.getMemberInById(pageId),
  });
  return (
    <StatusChecker loadingsArray={[isLoading]} errorsArray={[isError]}>
      <MemberInItem title="Инициатор" data={data?.projects} />
      <MemberInItem title="Куратор" data={data?.tracker_projects} />
      <MemberInItem title="Эксперт" data={data?.expert_projects} />
      <MemberInItem title="Исполнитель" data={data?.hired_projects} />
      <MemberInItem title="Бизнес-заказчик" data={data?.customer_projects} />
      <MemberInItem title="Руководитель проекта" data={data?.lead_projects} />
      <MemberInItem title="Технический специалист" data={data?.tech_spec_projects} />
      <MemberInItem title="Коммерческий специалист" data={data?.comm_spec_projects} />
      <MemberInItem title="Наблюдатель" data={data?.observer_projects} />
    </StatusChecker>
  );
};

export default ActivityMemberIn;
