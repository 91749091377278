import React from 'react';

import {TextHeaderMedium} from '@Common/Text';

import {TableRow} from './styled';

const ColumnNames = () => {
  return (
    <TableRow $templateColumns="">
      <TextHeaderMedium>Название</TextHeaderMedium>
      <TextHeaderMedium>Акселераторы</TextHeaderMedium>
    </TableRow>
  );
};

export default ColumnNames;
