import AccIcon from 'assets/images/acc.png';
import React, {useState} from 'react';

import {DraftsItemWrapper} from '@components/Wrappers/DraftsItem';

import {PanelHeader} from '@Common/DraftsItem/Panel';

import DraftsStagesContent from './Content';

const DraftsItemStages = ({
  workflow_id,
  isFinished,
}: {
  workflow_id: number;
  isFinished: boolean;
}) => {
  const [isShownContent, setIsShownContent] = useState(false);
  const handleShow = () => {
    setIsShownContent(prev => !prev);
  };

  return (
    <DraftsItemWrapper>
      <PanelHeader
        isShownContent={isShownContent}
        icon={<img src={AccIcon} height={24} />}
        title={'Этапы акселерации'}
        action={handleShow}
        tooltip={
          'Подробнее о действующих программах акселерации Вы можете узнать в разделе "Об ИнноХаб" в главном меню платформы'
        }
      />
      {isShownContent && <DraftsStagesContent isFinished={isFinished} workflow_id={workflow_id} />}
    </DraftsItemWrapper>
  );
};

export default DraftsItemStages;
