import styled from 'styled-components';

export const Documents = styled.div`
  border-radius: ${({theme}) => theme.borderRadius.full};
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  background: ${({theme}) => theme.colors.white};

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 50px;
  padding: 10px;
  cursor: pointer;
`;
