import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import DateTimePicker from '@Common/DateTimePicker';
import {StyledContentRow} from '@Common/DraftsItem/StyledContentRow';
import MultipleDropdown from '@Common/Dropdown/Multiple';
import {DropdownMultipleType} from '@Common/Dropdown/Multiple/types';
import SingleDropdown from '@Common/Dropdown/Single';
import {DropdownSingleType} from '@Common/Dropdown/Single/types';
import {TextInput} from '@Common/Input';
import StatusChecker from '@Common/StatusChecker';
import SwitchContainer from '@Common/SwitchContainer';
import InformationDraftRow from '@Common/Table/Row/InformationDraft';
import {TextHeaderMedium, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseMutationTechRequest from '@hooks/UseMutationTechRequest';
import UseProjectArea from '@hooks/UseProjectArea';
import UseTags from '@hooks/UseTags';

import {getOptionFromId} from '@utils/GetOptionsFromString';
import {renameStatus} from '@utils/renameStatus';

import {IUser} from 'types/User';

import {ITechRequestInformationContentProps, InfoData} from './types';

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TechRequestInformationContent = ({
  data,
  user,
}: {
  data: ITechRequestInformationContentProps;
  user: IUser;
}) => {
  const pageId = Number(useParams().id);
  const {is_supervisor, is_superuser, id} = user;

  const {tagsLabels, isErrorTags, isLoadingTags} = UseTags();
  const {projectAreaLabels, isErrorProjectArea, isLoadingProjectArea} = UseProjectArea();

  const initialData = useMemo(() => {
    const intersection = tagsLabels.filter(tag => data.tags.includes(tag.label));
    return {
      tags: intersection,
      status: data.status,
      description: data.description,
      company_name: data.company_name,
      prerequisites: data.prerequisites,
      results: data.results,
      budget: data.budget,
      open_to: data.open_to,
      deadline: data.deadline,
      archived: data.archived,
      createdByEmail: data.created_by.email1,
      createdByPhone: data.created_by.phone1,
      request_area_id: getOptionFromId(data.request_area_id, projectAreaLabels),
    };
  }, [tagsLabels, data, projectAreaLabels]);

  const [infoData, setInfoData] = useState<InfoData>(initialData);

  useEffect(() => {
    setInfoData(initialData);
  }, [initialData]);

  const onUndo = (
    field: keyof InfoData,
    startData: DropdownMultipleType | DropdownSingleType | boolean | string | null | Date,
  ) => {
    setInfoData((prev: any) => ({
      ...prev,
      [field]: startData,
    }));
  };
  const mutation = UseMutationTechRequest(pageId);

  const onSave = (field: keyof InfoData) => {
    if (field === 'tags') {
      mutation.mutate({
        tags: infoData.tags?.map((tag: any) => tag.label) || [],
      });
    } else if (['request_area_id'].includes(field as string)) {
      const value = infoData[field] as DropdownSingleType;
      mutation.mutate({
        [field]: value?.value || null,
      });
    } else {
      let value = infoData[field] as string | number;
      if ((field === 'deadline' || field === 'open_to') && value) {
        value = new Date(value).toISOString().split('T')[0];
      }
      if (field === 'budget' && typeof value === 'string') {
        value = +value.replace(/ /g, '').replace('руб.', '');
      }
      mutation.mutate({
        [field]: value,
      });
    }
  };

  const isNotAccessEdit = useMemo(
    () => !(data.created_by.id === id || is_superuser),
    [is_superuser, data, id],
  );

  const handleSetInfoData =
    (field: keyof InfoData) =>
    (value: DropdownSingleType | DropdownMultipleType | boolean | string | Date) => {
      setInfoData((prev: any) => ({...prev, [field]: value}));
    };

  if (!pageId) return null;

  return (
    <StyledTable>
      <TextParagraph color={Colors.accentBlue}>
        Обязательные поля помечены звёздочкой (*)
      </TextParagraph>
      <StatusChecker
        loadingsArray={[isLoadingTags, isLoadingProjectArea]}
        errorsArray={[isErrorTags, isErrorProjectArea]}>
        <InformationDraftRow
          isNotEditing={isNotAccessEdit}
          title="Теги"
          onUndo={() => onUndo('tags', initialData.tags)}
          onSave={() => onSave('tags')}
          tags={infoData.tags}>
          <MultipleDropdown
            labels={tagsLabels}
            value={infoData.tags}
            isFocus
            handleChange={handleSetInfoData('tags')}
          />
        </InformationDraftRow>
        <InformationDraftRow
          isNotEditing={isNotAccessEdit}
          isGray={false}
          title="Описание*"
          value={infoData.description}
          onUndo={() => onUndo('description', initialData.description)}
          onSave={() => onSave('description')}>
          <TextInput
            width="100%"
            value={infoData.description || ''}
            onChange={e => handleSetInfoData('description')(e.target.value)}
          />
        </InformationDraftRow>
        <InformationDraftRow
          isNotEditing={isNotAccessEdit}
          title="Предпосылки*"
          value={infoData.prerequisites}
          onUndo={() => onUndo('prerequisites', initialData.prerequisites)}
          onSave={() => onSave('prerequisites')}>
          <TextInput
            width="100%"
            value={infoData.prerequisites || ''}
            onChange={e => handleSetInfoData('prerequisites')(e.target.value)}
          />
        </InformationDraftRow>
        <InformationDraftRow
          isNotEditing={isNotAccessEdit}
          isGray={false}
          title="Результат*"
          value={infoData.results}
          onUndo={() => onUndo('results', initialData.results)}
          onSave={() => onSave('results')}>
          <TextInput
            width="100%"
            value={infoData.results || ''}
            onChange={e => handleSetInfoData('results')(e.target.value)}
          />
        </InformationDraftRow>
        <InformationDraftRow
          isNotEditing={isNotAccessEdit}
          title="Бюджет"
          value={
            infoData.budget
              ? infoData.budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' руб.'
              : ''
          }
          onUndo={() => onUndo('budget', initialData.budget)}
          onSave={() => onSave('budget')}>
          <TextInput
            isMoney
            isTextarea={false}
            width="100%"
            value={infoData.budget || ''}
            onChange={e => handleSetInfoData('budget')(e.target.value)}
          />
        </InformationDraftRow>
        <StyledContentRow $templateColumns="minmax(125px, 1fr) minmax(200px, 2fr)">
          <TextHeaderMedium>Статус</TextHeaderMedium>
          <SwitchContainer
            isNotEditing
            value={renameStatus(infoData.status) || '-'}></SwitchContainer>
        </StyledContentRow>
        <InformationDraftRow
          isNotEditing={isNotAccessEdit}
          title="Прием откликов, до"
          value={infoData.open_to ? new Date(infoData.open_to).toISOString().split('T')[0] : '-'}
          onUndo={() => onUndo('open_to', initialData.open_to)}
          onSave={() => onSave('open_to')}>
          <DateTimePicker
            selectedDate={infoData.open_to ? new Date(infoData.open_to) : null}
            setSelectedDate={value => handleSetInfoData('open_to')(value)}
          />
        </InformationDraftRow>
        <InformationDraftRow
          isNotEditing={isNotAccessEdit}
          isGray={false}
          title="Сроки работы"
          value={infoData.deadline ? new Date(infoData.deadline).toISOString().split('T')[0] : '-'}
          onUndo={() => onUndo('deadline', initialData.deadline)}
          onSave={() => onSave('deadline')}>
          <DateTimePicker
            selectedDate={infoData.deadline ? new Date(infoData.deadline) : null}
            setSelectedDate={value => handleSetInfoData('deadline')(value)}
          />
        </InformationDraftRow>
        <InformationDraftRow
          isNotEditing={isNotAccessEdit}
          title="Название компании"
          value={infoData.company_name}
          onUndo={() => onUndo('company_name', initialData.company_name)}
          onSave={() => onSave('company_name')}>
          <TextInput
            width="100%"
            value={infoData.company_name || ''}
            onChange={e => handleSetInfoData('company_name')(e.target.value)}
          />
        </InformationDraftRow>
        <InformationDraftRow
          isNotEditing={isNotAccessEdit}
          isGray={false}
          title="Направление*"
          value={infoData.request_area_id?.label}
          onUndo={() => onUndo('request_area_id', initialData.request_area_id)}
          onSave={() => onSave('request_area_id')}>
          <SingleDropdown
            isFocus
            labels={projectAreaLabels}
            value={infoData.request_area_id}
            handleChange={handleSetInfoData('request_area_id')}
          />
        </InformationDraftRow>
        {is_supervisor && (
          <>
            <StyledContentRow $isGray $templateColumns="minmax(125px, 1fr) minmax(200px, 2fr)">
              <TextHeaderMedium>Почта</TextHeaderMedium>
              <SwitchContainer
                isNotEditing
                value={infoData.createdByEmail || '-'}></SwitchContainer>
            </StyledContentRow>
            <StyledContentRow $templateColumns="minmax(125px, 1fr) minmax(200px, 2fr)">
              <TextHeaderMedium>Телефон</TextHeaderMedium>
              <SwitchContainer
                isNotEditing
                value={infoData.createdByPhone || '-'}></SwitchContainer>
            </StyledContentRow>
          </>
        )}
      </StatusChecker>
    </StyledTable>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(TechRequestInformationContent);
