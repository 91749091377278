import styled from 'styled-components';

import React, {useEffect, useState} from 'react';

import {
  IHypothesisProofData,
  IRequestProjectRouterCheckListItem,
} from 'types/state/ProjectRouterCheckList';

import ChoiceButtons from './ChoiceButtons';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
`;

interface IHypithesisProof {
  setRequestData: (data: IRequestProjectRouterCheckListItem) => void;
}
export const titlesHypothesis = {
  quiz21: 'На Ваш проект (идею, решение) есть спрос у конечного потребителя?',
  quiz22: 'Находили ли Вы потенциального Заказчика на Ваш проект (идею, решение)?',
  quiz23: 'Имеются ли ориентировочные технологические мощности, чтобы произвести Ваш продукт?',
  quiz24: 'Есть ли у Вас единомышленники, готовые с Вами реализовать эту идею?',
};
const tooltips = {
  quiz21: '',
  quiz22: '',
  quiz23: 'Технологические мощности – необходимые знания и оборудование, чтобы реализовать проект',
  quiz24: '',
};

const HypothesisProof = ({setRequestData}: IHypithesisProof) => {
  const [answers, setAnswers] = useState<IHypothesisProofData>({
    quiz21: null,
    quiz22: null,
    quiz23: null,
    quiz24: null,
  });

  useEffect(() => {
    setRequestData(answers);
  }, [answers, setRequestData]);

  return (
    <StyledContent>
      {Object.keys(answers).map(item => {
        const field = item as keyof typeof titlesHypothesis;
        return (
          <ChoiceButtons
            key={field}
            title={titlesHypothesis[field]}
            isActive={answers[field]}
            onClickNo={() => setAnswers({...answers, [field]: false})}
            onClickYes={() => setAnswers({...answers, [field]: true})}
            tooltipText={tooltips[field]}
          />
        );
      })}
    </StyledContent>
  );
};

export default HypothesisProof;
