import styled from 'styled-components';

import {useQueryClient, useMutation} from '@tanstack/react-query';
import React, {useState} from 'react';
import {createPortal} from 'react-dom';
import {useForm, SubmitHandler} from 'react-hook-form';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {TechRequestService} from '@services/techRequest';

import {ContentBoardMenu} from '@components/Menu';

import {FilterButton} from '@Common/Buttons';
import FiltersPanelDropdown from '@Common/Dropdown/FilterPanel';
import MultipleDropdown from '@Common/Dropdown/Multiple';
import {DropdownMultipleType} from '@Common/Dropdown/Multiple/types';
import {InfoIcon} from '@Common/Images';
import {TextInput} from '@Common/Input';
import Loader from '@Common/Loader';
import BaseModal from '@Common/Modal/Base';
import ModalRouter from '@Common/ModalRouter';
import StatusChecker from '@Common/StatusChecker';
import {TextParagraph, TextSmall} from '@Common/Text';
import TooltipCustom from '@Common/Tooltip';
import {FlexButton} from '@Common/Utils/FlexButton';

import {Colors} from '@constants/enum';

import {UseNavigationList} from '@hooks/UseNavigationList';
import UseProjectArea from '@hooks/UseProjectArea';
import UseTags from '@hooks/UseTags';

import {IPostRequestTechRequest} from 'types/patchRequest';
import {ITechRequestItem} from 'types/state/TechRequest';
import {IUser} from 'types/User';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const StyledButtonBlock = styled.div`
  display: flex;
  margin-top: 40px;
`;

const StyledContentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledFormRow = styled.div`
  display: grid;
  grid-template-columns: 15% 80%;
  gap: 30px;
  padding: 5px 10px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input<{$isNotField?: boolean}>`
  margin-right: 5px;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    border: ${({$isNotField}) => ($isNotField ? ' 2px solid red' : 'none')};
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 7px;

  & h3 {
    margin-left: 30px;
  }
`;

const StyledSubmitButton = styled(FlexButton)`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  font-size: 16px;

  max-width: 200px;

  background: #bee4ff;
  border: none;
  color: black;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({theme}) => theme.colors.oceanBlue};

    color: ${({theme}) => theme.colors.white};
  }
`;

const Empty = styled.div``;

const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TextWithInfo = styled.div`
  display: grid;
  grid-template-columns: 50px 20px;
`;

const TextModal = styled.p`
  display: flex;
  justify-content: center;
  text-align: center;
`;

interface FormData {
  techRequestName: string;
  companyName: string;
  description: string;
  budget: string;
  prerequisites: string;
  results: string;
  tags: string;
  consent2: boolean;
}

const TechRequestCreate = ({user}: {user: IUser}) => {
  UseNavigationList([
    {path: '/tech-requests', title: 'Тех. Запросы'},
    {path: '/tech-requests/create', title: 'Создание'},
  ]);

  const queryClient = useQueryClient();
  const [tags, setTags] = useState<DropdownMultipleType>([]);
  const {id} = user;

  const [helpModal, setHelpModal] = useState(true);
  const [projectArea, setProjectArea] = useState<string | null>('');
  const [budget, setBudget] = useState('');
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [techRequest, setTechRequest] = useState<ITechRequestItem | null>(null);

  const {
    register: formRegister,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>();

  const {isLoadingTags, isErrorTags, tagsLabels} = UseTags();
  const {projectAreaLabels} = UseProjectArea();

  const mutation = useMutation({
    mutationFn: (data: IPostRequestTechRequest) => TechRequestService.create(data),
    onSuccess: response => {
      void queryClient.invalidateQueries(['techRequests']);
      setTechRequest(response.data);
      setLoading(false);
    },
  });

  const onSubmit: SubmitHandler<FormData> = data => {
    setLoading(true);
    setModal(true);

    mutation.mutate({
      created_by_id: id,
      name: data.techRequestName,
      company_name: data.companyName,
      request_area_id: projectArea || '',
      description: data.description,
      budget: +budget.replace(/ /g, '').replace('руб.', ''),
      prerequisites: data.prerequisites,
      results: data.results,
      tags: tags?.map(tag => tag.label) || [],
    });
  };

  return (
    <StyledContent>
      {techRequest &&
        createPortal(
          <ModalRouter
            createTechRequest
            isOpen={modal}
            onClose={() => setModal(false)}
            techRequest={techRequest}
          />,
          document.body,
        )}
      <ContentBoardMenu
        pageTitle={
          <Title>
            Добавление тех. запроса
            <TooltipCustom
              content={
                'Информация в заполняемых графах должна быть чёткой, определенной, полной и достоверной'
              }
              big>
              <InfoIcon />
            </TooltipCustom>
          </Title>
        }
      />

      <StyledContentForm onSubmit={handleSubmit(onSubmit)}>
        <StyledFormRow>
          <Empty />
          <TextParagraph color={Colors.textDark}>
            Поля, помеченные звездочкой (*), являются обязательными.
          </TextParagraph>
        </StyledFormRow>
        <StyledFormRow>
          <Empty />
          <CheckboxWrapper>
            <CheckboxLabel>
              <CheckboxInput
                $isNotField={Boolean(errors.consent2)}
                type="checkbox"
                {...formRegister('consent2', {required: true})}
              />
              * Я ознакомлен с тем, что информация, размещаемая на данном портале:
            </CheckboxLabel>
            <TextSmall>
              <br />
              не подпадает под действие статьи 5 Закона РФ «О государственной тайне», «Перечня
              сведений, отнесенных к государственной тайне», Перечня сведений, подлежащих
              засекречиванию, Госкорпорации «Росатом» и не содержит сведений, составляющих
              государственную тайну Российской Федерации, в том числе сведений, подлежащих
              засекречиванию, Госкорпорации «Росатом»;
              <br />
              не содержит сведения, составляющие служебную тайну;
              <br />
              не содержит сведений, являющихся результатами интеллектуальной деятельности, права на
              которые принадлежат Российской Федерации;
              <br />
              не содержит сведений, составляющих коммерческую тайну, служебную информацию
              ограниченного распространения («Для служебного пользования»).
            </TextSmall>
          </CheckboxWrapper>
        </StyledFormRow>
        <StyledFormRow>
          <TextParagraph color={Colors.textDark}>Название тех. запроса *</TextParagraph>

          <TextInput
            isNotField={!!errors.techRequestName}
            register={formRegister('techRequestName', {required: true, maxLength: 250})}
            description={'Макс. 250 символов'}
          />
        </StyledFormRow>
        <StyledFormRow>
          <TextParagraph color={Colors.textDark}>Название компании</TextParagraph>

          <TextInput
            isNotField={!!errors.companyName}
            register={formRegister('companyName', {maxLength: 250})}
            description={'Макс. 250 символов'}
          />
        </StyledFormRow>
        <StyledFormRow>
          <TextParagraph color={Colors.textDark}>Описание *</TextParagraph>

          <TextInput
            isNotField={!!errors.description}
            register={formRegister('description', {required: true})}
            height={100}
          />
        </StyledFormRow>
        <StyledFormRow>
          <TextParagraph color={Colors.textDark}>Бюджет</TextParagraph>
          <TextInput
            isTextarea={false}
            isMoney
            value={budget}
            onChange={e => setBudget(e.target.value)}
          />
        </StyledFormRow>
        <StyledFormRow>
          <TextParagraph color={Colors.textDark}>Направление *</TextParagraph>

          <FiltersPanelDropdown
            labels={projectAreaLabels}
            handleChange={value => setProjectArea(value)}
            placeholder="Направление"
          />
        </StyledFormRow>
        <StatusChecker errorsArray={[isErrorTags]} loadingsArray={[isLoadingTags]}>
          <StyledFormRow>
            <TextWithInfo>
              <TextParagraph color={Colors.textDark}>Теги</TextParagraph>
              <TooltipCustom
                content={
                  'Ключевые слова или словосочетания, которые наиболее точно отражают специфику Вашего проекта (например, «вывод из эксплуатации», «импортозамещение», «литиевые батареи» и т.п.) Выберите их из выпадающего списка (при написании тега необходимо на него нажать для подтверждения)'
                }>
                <InfoIcon />
              </TooltipCustom>
            </TextWithInfo>
            <MultipleDropdown
              isAddedNewValue
              labels={tagsLabels}
              handleChange={setTags}
              value={tags}
            />
          </StyledFormRow>
        </StatusChecker>
        <StyledFormRow>
          <TextParagraph color={Colors.textDark}>Предпосылки *</TextParagraph>

          <TextInput
            isNotField={!!errors.prerequisites}
            register={formRegister('prerequisites', {required: true})}
            height={100}
          />
        </StyledFormRow>{' '}
        <StyledFormRow>
          <TextParagraph color={Colors.textDark}>Результат *</TextParagraph>

          <TextInput
            isNotField={!!errors.results}
            register={formRegister('results', {required: true})}
            height={100}
          />
        </StyledFormRow>
        {/* <StyledFormRow>
          <TextParagraph color={Colors.textDark}>Вложение</TextParagraph>

          <FileInput onClick={openFileSelector} ref={fileInputRef} onChange={handleFileChange} />
        </StyledFormRow> */}
        <StyledButtonBlock>
          {loading ? <Loader /> : <StyledSubmitButton type="submit">Сохранить</StyledSubmitButton>}
        </StyledButtonBlock>
      </StyledContentForm>
      {helpModal && (
        <BaseModal setIsOpen={setHelpModal}>
          <TextParagraph color={Colors.textDark}>
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
              <TextModal>Уважаемые пользователи!</TextModal>
              <TextModal>
                Будьте внимательны при заполнении разделов технологического запроса!
              </TextModal>
              <TextModal>
                Информация будет опубликована в открытом доступе для других пользователей платформы
                (исполнителей тех. запросов) согласно положениям политики о конфиденциальности.
              </TextModal>
              <TextModal>
                <FilterButton
                  width="50px"
                  isBlue={false}
                  title={'Ок'}
                  action={() => setHelpModal(false)}
                />
              </TextModal>
            </div>
          </TextParagraph>
        </BaseModal>
      )}
    </StyledContent>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(TechRequestCreate);
