import styled from 'styled-components';

import {NavLink} from 'react-router-dom';

const StyledLink = styled(NavLink)<{$current?: boolean}>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  cursor: pointer;
  text-decoration: none;
  color: ${({theme, $current}) =>
    $current ? theme.colors.grayTextOnDark : theme.colors.accentBlue};
`;

export default StyledLink;
