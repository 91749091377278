import {useNavigate} from 'react-router-dom';

import React, {memo, useRef} from 'react';
import {useCookies} from 'react-cookie';
import {logoutUser} from 'store/user/slice';

import {checkRoles} from '@components/ProfileItem/Info/Card';

import {FilterButton} from '@Common/Buttons';

import UseAppDispatch from '@hooks/UseAppDispatch';
import useAppSelector from '@hooks/UseAppSelector';
import UseClickOutside from '@hooks/UseClickOutside';

import ClientCardButtonList from './ButtonList';
import CardContainer from './Container';
import ClientCardPicture from './Picture';
import {ClientCardProps} from './types';

const ProfileCard = ({isShown, setIsShown}: ClientCardProps) => {
  const dispatch = UseAppDispatch();
  const ref = useRef(null);
  const navigate = useNavigate();
  const user = useAppSelector(state => state.user.data);
  const removeCookie = useCookies(['accelerator_answers'])[2];
  const logoutAction = () => {
    dispatch(logoutUser());
    removeCookie('accelerator_answers', {path: '/'});
    navigate('/auth');
  };

  const editProfile = () => {
    setIsShown(false);
    navigate(`profile/${user.id}/edit`);
  };

  UseClickOutside(ref, () => setIsShown(false));
  if (!isShown) {
    return null;
  }

  return (
    <CardContainer ref={ref}>
      <ClientCardPicture src={user.avatar || ''} />
      <span>{user.fio || user.email1}</span>
      {checkRoles(user)}
      <ClientCardButtonList>
        <FilterButton title={'Выйти'} action={logoutAction} />
        <FilterButton title={'Редактировать'} action={editProfile} />
      </ClientCardButtonList>
    </CardContainer>
  );
};

export default memo(ProfileCard);
