import {axiosDocument, instance} from 'api/instance';

import {IApiResponse} from 'types/services';
import {IExpertise, IPatchExpertise, IPostExpertise} from 'types/state/Expertise';

export const ExpertiseService = {
  async getAll() {
    return (await instance.get<IApiResponse<IExpertise>>('/project-expertise/')).data.results;
  },

  async getByProject(projectId: number) {
    return (
      await instance.get<IApiResponse<IExpertise>>(`/project-expertise/?project=${projectId}`)
    ).data.results;
  },

  async getById(id: string) {
    return (await instance.get<IExpertise>(`/project-expertise/${id}/`)).data;
  },

  async create(data: IPostExpertise) {
    return instance.post<IExpertise>('/project-expertise/', data);
  },

  async completion(id: number, text: string, attachments?: File[]) {
    const formData = new FormData();
    attachments?.forEach(file => {
      formData.append('attachments', file);
    });
    formData.append('text', text);
    return axiosDocument.post<IExpertise>(`/project-expertise/${id}/expertise-finish/`, formData);
  },

  async update(id: number, data: IPatchExpertise) {
    return instance.patch<IPostExpertise>(`/project-expertise/${id}/`, data);
  },
  async delete(id: number) {
    return instance.delete(`/project-expertise/${id}/`);
  },
};
