import styled from 'styled-components';

import React, {memo} from 'react';

import {CrossIcon, InfoIcon} from '@Common/Images';
import {CloseButton} from '@Common/Modal/ModalComment/styles';
import {Overlay} from '@Common/Modal/Wrapper';
import {TextParagraph} from '@Common/Text';
import TooltipCustom from '@Common/Tooltip';

import {Colors} from '@constants/enum';

import {ISideBarLinkProps} from 'types/components';

import Container from './Container';
import StyledSupport from './support';

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
`;

const SideBarLink = ({path, title, image, isHidden, href, tooltip, modal}: ISideBarLinkProps) => {
  if (modal)
    return (
      <>
        <StyledSupport onClick={() => modal.changeModal(!modal.modal)} $isHidden={isHidden}>
          {image}
          {isHidden ? null : (
            <>
              <span>{title}</span>
              {tooltip && (
                <TooltipCustom content={tooltip}>
                  <InfoIcon />
                </TooltipCustom>
              )}
            </>
          )}
        </StyledSupport>
        {modal.modal && (
          <Overlay onClick={() => modal.changeModal(!modal.modal)}>
            <ModalWrapper
              onClick={e => {
                e.stopPropagation();
              }}>
              <CloseButton onClick={() => modal.changeModal(!modal.modal)}>
                <CrossIcon />
              </CloseButton>

              <TextParagraph color={Colors.textDark}>
                <p>
                  Здравствуйте!
                  <br /> Направьте, пожалуйста, Ваш вопрос на почтовые адреса администраторам
                  платформы маршрутизации проектов:
                  <br />
                  <a rel="noreferrer" target="_blank" href="mailto:route@ih-rosatom.ru">
                    route@ih-rosatom.ru
                  </a>
                </p>
              </TextParagraph>
            </ModalWrapper>
          </Overlay>
        )}
      </>
    );

  if (href)
    return (
      <StyledSupport href={href} target="_blank" $isHidden={isHidden}>
        {image}
        {isHidden ? null : (
          <>
            <span>{title}</span>
            {tooltip && (
              <TooltipCustom content={tooltip}>
                <InfoIcon />
              </TooltipCustom>
            )}
          </>
        )}
      </StyledSupport>
    );

  return (
    <Container $isHidden={isHidden} to={path}>
      {image}
      {isHidden ? null : (
        <>
          <span>{title}</span>
          {tooltip && (
            <TooltipCustom content={tooltip}>
              <InfoIcon />
            </TooltipCustom>
          )}
        </>
      )}
    </Container>
  );
};

export default memo(SideBarLink);
