import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {useState} from 'react';

import {IPostProfileFeedback, ProfileService} from '@services/profile';

import {FilterButton} from '@Common/Buttons';
import CommentEditor from '@Common/CommentEditor';
import {CrossIcon} from '@Common/Images';
import {Rating} from '@Common/Rating';
import {TextHeader, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 10px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 50%;
`;

interface ModalProps {
  onClose: () => void;
}

const ProfileFeedbackModal = ({onClose}: ModalProps) => {
  const queryClient = useQueryClient();
  const pageId = Number(useParams().id);
  const [feedbackData, setFeedbackData] = useState({
    score: 0,
    comment: '',
  });
  const [error, setError] = useState(false);

  const mutation = useMutation({
    mutationFn: (data: IPostProfileFeedback) => ProfileService.createFeedback(data),
    onSuccess: () => {
      void queryClient.invalidateQueries(['profile-feedback', pageId]);
    },
  });

  const createScoring = () => {
    if (feedbackData.score && feedbackData.comment) {
      mutation.mutate({
        ...feedbackData,
        profile_id: pageId,
      });
      onClose();
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Overlay>
        <ModalWrapper
          onClick={e => {
            e.stopPropagation();
          }}>
          <CloseButton onClick={onClose}>
            <CrossIcon />
          </CloseButton>
          <TextHeader>Добавление отзыва</TextHeader>

          <TextParagraph color={Colors.grey}>Оценка *</TextParagraph>
          <Rating
            rating={feedbackData.score}
            setRating={rating => setFeedbackData(prev => ({...prev, score: rating}))}
          />

          <TextParagraph color={Colors.grey}>Комментарий *</TextParagraph>
          <CommentEditor
            value={feedbackData.comment}
            setValue={comment => setFeedbackData(prev => ({...prev, comment}))}
          />

          {error && <TextParagraph color={Colors.red}>Заполнены не все поля</TextParagraph>}

          <ButtonWrapper>
            <FilterButton isBlue={false} title={'Отправить'} action={createScoring} />
          </ButtonWrapper>
        </ModalWrapper>
      </Overlay>
    </>
  );
};

export default ProfileFeedbackModal;
