import styled from 'styled-components';

const PaginationMenuWrapper = styled.div`
  display: flex;
  padding: 30px 16px 0 25px;
  gap: 8px;
  flex-wrap: wrap;
`;

export default PaginationMenuWrapper;
