import styled from 'styled-components';

import React, {FC} from 'react';

import {PlusIcon} from '@Common/Images';
import {FlexButton} from '@Common/Utils/FlexButton';

const StyledPanelButton = styled(FlexButton)`
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: ${({theme}) => theme.borderRadius.full};
  background: ${({theme}) => theme.colors.lightBlue};
  color: ${({theme}) => theme.colors.grey};
  font-weight: ${({theme}) => theme.fontWeight.normal};
  font-size: ${({theme}) => theme.fontSize.medium};
  border: none;

  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({theme}) => theme.colors.oceanBlue};
    color: ${({theme}) => theme.colors.white};

    path {
      fill: ${({theme}) => theme.colors.white};
    }
  }
`;

interface IPanelButtonProps {
  action: () => void;
  isPlus?: boolean;
  title?: string;
}

const PanelButton: FC<IPanelButtonProps> = ({action, title, isPlus = true}) => {
  return (
    <StyledPanelButton onClick={action}>
      {title || 'Добавить'} {isPlus && <PlusIcon />}
    </StyledPanelButton>
  );
};

export default PanelButton;
