import styled from 'styled-components';

import React from 'react';

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
`;

const AuthFormTextInput = () => {
  return <Input></Input>;
};

export default AuthFormTextInput;
