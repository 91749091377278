import {ContentBoardMenuEnum} from 'constants/enum/ContentBoardMenu';

export const viewsTitles = [
  {
    label: ContentBoardMenuEnum.table,
  },
  {
    label: ContentBoardMenuEnum.cards,
  },
];
