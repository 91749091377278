import {styled} from 'styled-components';

import {TableRowStyled} from 'components/Common/Table/Row';

export const StyledContentRow = styled(TableRowStyled)`
  display: grid;
  justify-content: space-between;
  min-height: 40px;
  gap: 10px;

  padding: 15px;
  width: 100%;
`;
