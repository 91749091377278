import styled from 'styled-components';

import {FlexRowContainer} from '@Common/Utils';

const MenuLinksBar = styled(FlexRowContainer)`
  align-items: center;
  padding: 0;
  gap: 12px;
`;

export default MenuLinksBar;
