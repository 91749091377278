import {MenuLogo} from 'components/Menu';
import React from 'react';

import SideBarWrapper from '@components/Wrappers/SideBar';

import SideBarNav from './Nav';
import SideBarNavList from './NavList';

const SideBar = ({minimized}: {minimized: boolean}) => {
  return (
    <SideBarWrapper>
      <MenuLogo minimized={minimized} />
      <SideBarNav>
        <SideBarNavList minimized={minimized} />
      </SideBarNav>
    </SideBarWrapper>
  );
};

export default SideBar;
