import styled from 'styled-components';

import React, {FC} from 'react';

import {ITagProps} from 'types/components';

const TagContainer = styled.div`
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #6dacde;
`;

const Tag: FC<ITagProps> = ({title}) => {
  return <TagContainer>{title}</TagContainer>;
};

export default Tag;
