import {MyOption} from '@Common/Dropdown/Single/types';

import {labelsFills} from '@constants/status';

export const getOptionFromString = (field: keyof typeof labelsFills, initialState: string) => {
  const fieldValues = labelsFills[field];
  return fieldValues.find(type => type.value === initialState) || fieldValues[0];
};

export const getOptionFromId = (
  initialState: number | string | null,
  labels: MyOption[],
  baseLabel?: string,
) => {
  if (!initialState) return null;
  const foundLabel = labels.find(label => +label.value === +initialState);
  return foundLabel
    ? {label: foundLabel.label, value: foundLabel.value}
    : baseLabel
    ? {label: baseLabel, value: baseLabel}
    : null;
};

export const getOptionFromIdWithoutNull = (initialState: number | null, labels: MyOption[]) => {
  return labels.find(label => Number(label.value) === initialState) || labels[0];
};
