import styled from 'styled-components';

import React, {FC} from 'react';

import {CaretUpIcon, CaretDownIcon} from '@Common/Images';

interface ISortButton {
  children?: React.ReactNode;
  sortDirection: string;
  handleSubmit: () => void;
}

const StyledSortButton = styled.button<{$sortDirection: string}>`
  border: 0;
  background: unset;
  display: flex;
  cursor: pointer;

  & :first-child {
    fill: ${({$sortDirection, theme}) =>
      $sortDirection === 'up' ? theme.colors.greyCircuit : theme.colors.textDark};
  }

  & :last-child path {
    fill: ${({$sortDirection, theme}) =>
      $sortDirection === 'up' ? theme.colors.textDark : theme.colors.greyCircuit};
  }
`;

const SortButton: FC<ISortButton> = ({sortDirection, handleSubmit}) => {
  return (
    <StyledSortButton $sortDirection={sortDirection} onClick={handleSubmit}>
      <CaretUpIcon />
      <CaretDownIcon />
    </StyledSortButton>
  );
};

export default SortButton;
