import {ContentBoardMenuEnum} from 'constants/enum/ContentBoardMenu';

import {IProjectsState} from 'types/project';

const initialState: IProjectsState = {
  views: {
    viewsTitle: ContentBoardMenuEnum.table,
  },
  filteredValue: [],
  filterKeys: {
    direction: null,
    accelerator: null,
  },
  value: [
    {
      id: 1,
      title: 'Тест задачи',
      accelerator: 'Иннохаб',
      direction: '-',
      status: {percentage: 100, description: 'Постановка задачи'},
      isInterior: true,
    },
    {
      id: 2,
      title: 'Релиз',
      accelerator: 'Иннохаб',
      direction: '-',
      status: {percentage: 50, description: 'Постановка задачи'},
      isInterior: true,
    },
    {
      id: 3,
      title: 'Что-то там',
      accelerator: 'Второй',
      direction: 'Химия',
      status: {percentage: 50, description: 'Постановка задачи'},
      isInterior: true,
    },
    {
      id: 4,
      title: 'Ещё что-то',
      accelerator: 'Третий',
      direction: 'Экономика',
      status: {percentage: 50, description: 'Постановка задачи'},
      isInterior: true,
    },
    {
      id: 5,
      title: 'Релиз',
      accelerator: 'Иннохаб',
      direction: 'Химия',
      status: {percentage: 50, description: 'Постановка задачи'},
      isInterior: true,
    },
  ],
};

export default initialState;
