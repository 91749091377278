import {styled} from 'styled-components';

import UserPicture from 'assets/images/user_picture.png';
import React from 'react';

import {Picture} from '@Common/Picture';
import {Rating} from '@Common/Rating';
import {TextHeaderMedium, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseRoles from '@hooks/UseRoles';

import {IScoring} from 'types/state/Scoring';

const StyledContentItemFeedback = styled.div`
  display: flex;
  gap: 15px;
`;
const StyledReview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

interface IScoringFeedback {
  data: IScoring;
}

const ContentItemFeedback = ({data}: IScoringFeedback) => {
  const {isSupervisor, isSuperuser} = UseRoles();

  const {avatar, fio, email1} = data.created_by;
  const userSrc = avatar || UserPicture;
  const surname = fio || email1;

  return (
    <StyledContentItemFeedback>
      <Picture variant={'sm'} src={userSrc} alt={'Аватар'} />
      <StyledReview>
        <TextHeaderMedium>{surname}</TextHeaderMedium>
        <TextParagraph color={Colors.grey}>Технология</TextParagraph>
        <Rating fixRatings={data.technology} />
        <TextParagraph color={Colors.grey}>Команда</TextParagraph>
        <Rating fixRatings={data.team} />
        <TextParagraph color={Colors.grey}>Рынок</TextParagraph>
        <Rating fixRatings={data.market} />
        {(isSupervisor || isSuperuser) && (
          <>
            <TextParagraph color={Colors.grey}>
              Скрытый комментарий (Только для трекера)
            </TextParagraph>
            <div dangerouslySetInnerHTML={{__html: data.comment}} />
            {/* <TextParagraph color={Colors.textDark}>{data.comment}</TextParagraph> */}
          </>
        )}
        <TextParagraph color={Colors.grey}>Комментарий</TextParagraph>
        <div dangerouslySetInnerHTML={{__html: data.scoring}} />
      </StyledReview>
    </StyledContentItemFeedback>
  );
};

export default ContentItemFeedback;
