import React from 'react';

import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {IAcceleratorAnswer} from 'types/state/ProjectRouterCheckList';

import {StyledContentRow} from './styles';

interface Props {
  answers: IAcceleratorAnswer[];
}

const AnswersAccelerator = ({answers}: Props) => {
  return (
    <>
      {answers.map(answer => {
        return (
          <StyledContentRow key={answer.id}>
            <TextParagraph color={Colors.grayTextOnDark}>{answer.question.text}</TextParagraph>

            <TextParagraph color={Colors.grayTextOnDark}>{answer.answer}</TextParagraph>
          </StyledContentRow>
        );
      })}
    </>
  );
};

export default AnswersAccelerator;
