import React from 'react';

import DraftsItemCheckList from '@components/DraftsItem/CheckList';

import StagesPoints from '../StagesPoints';
import DraftsItemStepsHistory from '../StepsHistory';

const RouterContent = () => {
  return (
    <>
      <StagesPoints />
      <DraftsItemCheckList />
      <DraftsItemStepsHistory />
    </>
  );
};

export default RouterContent;
