import React, {useRef, useState, useCallback} from 'react';

import {CheckedIcon, CrossIcon, EditIcon, SaveDisk, UndoIcon} from '@Common/Images';

import {Button, ButtonsStyled, EditableInternalWrapper, IconWrapper} from './styled';
import {ContentInternalProps} from './types';

const ContentInternal = ({is_internal, setIsInternal, onSave, onUndo}: ContentInternalProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const isInternal = is_internal ? <CheckedIcon /> : <CrossIcon />;

  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onSave && onSave();
        setIsEditing(false);

        document.removeEventListener('mousedown', handleClickOutside);
      }
    },
    [onSave],
  );

  const toggleInternal = () => {
    if (isEditing) setIsInternal(!is_internal);
  };

  const handleUndo = () => {
    if (onUndo) onUndo();
    setIsEditing(false);
  };

  const handleSave = () => {
    if (onSave) onSave();

    setIsEditing(false);
  };

  return (
    <EditableInternalWrapper ref={ref}>
      <IconWrapper onClick={toggleInternal} $isEditing={isEditing}>
        {isInternal}
      </IconWrapper>
      <ButtonsStyled>
        {isEditing ? (
          <>
            {' '}
            {onSave && (
              <Button onClick={handleSave}>
                <SaveDisk />
              </Button>
            )}
            {onUndo && (
              <Button onClick={handleUndo}>
                <UndoIcon />
              </Button>
            )}{' '}
          </>
        ) : (
          <Button
            onClick={() => {
              setIsEditing(true);
              document.addEventListener('mousedown', handleClickOutside);
            }}>
            <EditIcon />
          </Button>
        )}
      </ButtonsStyled>
    </EditableInternalWrapper>
  );
};

export default ContentInternal;
