import styled from 'styled-components';

import React from 'react';

const StyledSideTableItem = styled.div`
  display: flex;
  justify-content: center;
  height: 65px;
  align-items: center;
  text-decoration: none;
`;

const TableNotFoundItem = () => {
  return <StyledSideTableItem>По заданным фильтрам ничего не найдено</StyledSideTableItem>;
};

export default TableNotFoundItem;
