import styled from 'styled-components';

import React from 'react';

import {CrossIcon} from '@Common/Images';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const StyledModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  z-index: 1001;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
`;

interface ModalProps {
  setIsOpen?: (isOpen: boolean) => void;
  children: React.ReactNode;
}

const ModalWrapper = ({children, setIsOpen}: ModalProps) => {
  return (
    <>
      <Overlay>
        <StyledModalWrapper
          onClick={e => {
            e.stopPropagation();
          }}>
          {setIsOpen && (
            <CloseButton onClick={() => setIsOpen(false)}>
              <CrossIcon />
            </CloseButton>
          )}
          {children}
        </StyledModalWrapper>
      </Overlay>
    </>
  );
};

export default ModalWrapper;
