import styled from 'styled-components';

import React from 'react';

import SideBarNavWrapper from '@components/Wrappers/SideBar/Nav';

import {FlexRowContainer} from '@Common/Utils';

const SideBarNavStyled = styled(FlexRowContainer)`
  padding: 10px;
  gap: 10px;
  background: var(--dark, #0f1120);
  flex-grow: 1;
`;

const SideBarNav = ({children}: {children: React.ReactNode}) => {
  return (
    <SideBarNavStyled>
      <SideBarNavWrapper>{children}</SideBarNavWrapper>
    </SideBarNavStyled>
  );
};

export default SideBarNav;
