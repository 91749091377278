import {NavLink} from 'react-router-dom';

import {ContentBoardMenu} from 'components/Menu';
import React, {useRef} from 'react';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {PlusIcon} from '@Common/Images';

import WorkflowsTable from '@Tables/Workflows';

import {UseNavigationList} from '@hooks/UseNavigationList';

const Workflows = () => {
  const mockFunction = () => console.log('Projects/mock');
  const pageTitle = useRef('Workflows');

  const navigationsList = useRef([
    {path: '/', title: pageTitle.current},
    {path: '/', title: 'Список'},
  ]);

  UseNavigationList(navigationsList.current);

  const PlusImage = <PlusIcon />;

  return (
    <>
      <ContentBoardMenu pageTitle={pageTitle.current}>
        <NavLink to={'create'}>
          <ContentBoardMenuButton title={'Добавить'} action={mockFunction} image={PlusImage} />
        </NavLink>
      </ContentBoardMenu>

      <WorkflowsTable />
    </>
  );
};

export default Workflows;
