import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {AcceleratorService} from '@services/accelerators';

import {transformDropdownData} from '@utils/TransformDropdownData';

const UseAccelerators = () => {
  const fetchAcceleratorsData = async () => {
    return (await AcceleratorService.getAll()).filter(item => item.name !== '');
  };

  const {
    data: acceleratorList,
    isLoading: isLoadingAccelerator,
    isError: isErrorAccelerator,
  } = useQuery({
    queryKey: ['accelerators'],
    queryFn: fetchAcceleratorsData,
  });

  const acceleratorsLabels = useMemo(() => {
    const labels = transformDropdownData(acceleratorList || []);
    labels.unshift({
      value: '0',
      label: 'Без акселератора',
    });
    return labels;
  }, [acceleratorList]);

  return {
    acceleratorList: acceleratorList || [],
    isLoadingAccelerator,
    isErrorAccelerator,
    acceleratorsLabels,
    acceleratorNames: acceleratorList?.map(({name}) => name) || [],
  };
};

export default UseAccelerators;
