import styled from 'styled-components';

import React, {PropsWithChildren} from 'react';

export const TableStyled = styled.div<{$isGray?: boolean}>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Table = ({children}: PropsWithChildren) => {
  return <TableStyled>{children}</TableStyled>;
};

export default Table;
