import styled from 'styled-components';

import React, {FC} from 'react';

import {CheckedIcon, CrossIcon, EditIcon} from '@Common/Images';

import StatusCell from '../StatusCell';

const StyledProjectItem = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 187px 176px 28% 181px 126px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #d9d9d9;
`;

const ProjectTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px 24px;
  gap: 10px;
`;

const ProjectAcceleratorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
`;

const AcceleratorName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;
  background: #bee4ff;
  border-radius: 3px;
`;

const TableCell = styled.div<{$center?: boolean; $padding?: string}>`
  justify-content: ${props => (props.$center ? 'center' : 'start')};
  display: flex;
  align-items: center;
  padding: ${props => props.$padding};
  gap: 10px;
`;

const CellText = styled.span`
  font-family: Rosatom, system-ui;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
`;

interface IProjectProps {
  direction: string;
  accelerator: string;
  title: string;
  editAction: () => void;
  isInterior: boolean;
}

const EditButton = styled.button`
  border: 0;
  background: unset;
  display: flex;
  cursor: pointer;
`;

const ProjectTableItem: FC<IProjectProps> = ({
  direction,
  accelerator,
  title,
  editAction,
  isInterior,
}) => {
  return (
    <StyledProjectItem>
      <ProjectTitleContainer>
        <CellText>{title}</CellText>
      </ProjectTitleContainer>
      <ProjectAcceleratorContainer>
        <AcceleratorName>
          <CellText>{accelerator}</CellText>
        </AcceleratorName>
      </ProjectAcceleratorContainer>
      <TableCell $center={true}>
        <CellText>{direction}</CellText>
      </TableCell>
      <TableCell $padding={'10px 16px'}>
        {!status ? (
          'Работа еще не начата'
        ) : (
          <StatusCell percentage={50} updatedOn={'23.03'} stageTitle={'Работа начата'} />
        )}
      </TableCell>
      <TableCell $center={true}>{isInterior ? <CheckedIcon /> : <CrossIcon />}</TableCell>
      <TableCell $center={true}>
        <EditButton onClick={editAction}>
          <EditIcon />
        </EditButton>
      </TableCell>
    </StyledProjectItem>
  );
};

export default ProjectTableItem;
