import styled from 'styled-components';

import React, {PropsWithChildren} from 'react';

import {CrossIcon} from '@Common/Images';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 25px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  padding-top: 40px;
  border-radius: 8px;
  z-index: 1001;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
`;

interface BaseModalProps extends PropsWithChildren {
  setIsOpen: (isOpen: boolean) => void;
}

const BaseModal = ({children, setIsOpen}: BaseModalProps) => {
  return (
    <>
      <Overlay>
        <ModalWrapper
          onClick={e => {
            e.stopPropagation();
          }}>
          <CloseButton onClick={() => setIsOpen(false)}>
            <CrossIcon />
          </CloseButton>

          {children}
        </ModalWrapper>
      </Overlay>
    </>
  );
};

export default BaseModal;
