import styled from 'styled-components';

import {TableRowStyled} from '@Common/Table/Row';

const TagsTableRow = styled(TableRowStyled)`
  grid-template-columns: 88% 10%;
  gap: 50px;
`;

export default TagsTableRow;
