import styled from 'styled-components';

import {FlexRowContainer} from 'components/Common/Utils';
import React, {FC} from 'react';

const StyledBoardWrapper = styled(FlexRowContainer)`
  flex-direction: column;
  width: 100%;
`;

const BoardWrapper: FC<{children: React.ReactNode}> = ({children}) => {
  return <StyledBoardWrapper>{children}</StyledBoardWrapper>;
};

export default BoardWrapper;
