import styled from 'styled-components';

import {TableRowStyled} from 'components/Common/Table/Row';
import {Colors, TextParagraph} from 'components/Common/Text/Paragraph';
import React, {FC} from 'react';
import {sort, sortToggle} from 'store/support/slice';

import {SortButton} from '@Common/Buttons';
import {TextHeaderMedium} from '@Common/Text';

import UseAppDispatch from '@hooks/UseAppDispatch';
import UseAppSelector from '@hooks/UseAppSelector';

import {ISupportItem} from 'types/state/Support';

const SupportTableStyled = styled.div``;

const JustifyCenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SupportTableRow = styled(TableRowStyled)`
  grid-template-columns: 88% 10%;
  gap: 50px;
`;

interface ISupportTable {
  editAction: () => void;
}

const SupportTable: FC<ISupportTable> = () => {
  const supportList = UseAppSelector(state => state.support.value);
  const sortDirection = UseAppSelector(state => state.support.sortDirection);
  const dispatch = UseAppDispatch();

  const handleSort = (sortTitle: keyof ISupportItem) => {
    dispatch(sortToggle());
    dispatch(sort({sortableField: sortTitle, sortDirection: sortDirection}));
  };
  return (
    <SupportTableStyled>
      <SupportTableRow $templateColumns="88% 10%">
        <TextHeaderMedium>Название</TextHeaderMedium>

        <JustifyCenterWrapper>
          <SortButton
            handleSubmit={() => handleSort('supportTool')}
            sortDirection={sortDirection}
          />
        </JustifyCenterWrapper>
      </SupportTableRow>
      {supportList.map((item, index) => {
        return (
          <SupportTableRow $templateColumns="88% 10%" key={item.id} $isGray={index % 2 == 0}>
            <TextParagraph color={Colors.textDark}>{item.supportTool}</TextParagraph>
          </SupportTableRow>
        );
      })}
    </SupportTableStyled>
  );
};

export default SupportTable;
