import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import initialState from 'store/recommendations/state';
import {sortArray} from 'utils';

import {IRecommendationsItem} from 'types/Recommendations';

const recommendationSlice = createSlice({
  name: 'recommendation',
  initialState,
  reducers: {
    sort: (
      state,
      action: PayloadAction<{sortableField: keyof IRecommendationsItem; sortDirection: string}>,
    ) => {
      sortArray(state.value, action.payload);
    },
    sortToggle: state => {
      state.sortDirection = state.sortDirection === 'up' ? 'down' : 'up';
    },
  },
});

export const {sort, sortToggle} = recommendationSlice.actions;

export default recommendationSlice.reducer;
