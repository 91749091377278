import {createSlice} from '@reduxjs/toolkit';

import {initialState} from './state';

const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    toggleView: state => {
      state.isTable = !state.isTable;
    },
  },
});

export const {toggleView} = viewSlice.actions;

export default viewSlice.reducer;
