import styled from 'styled-components';

import React from 'react';

const StyledPaginationButton = styled.button<{$isLeft: boolean; $isActive: boolean}>`
  display: flex;
  flex-grow: 1;
  padding: 0 16px;
  align-items: center;
  gap: 48px;
  border-radius: ${props => (props.$isLeft ? '10px 0 0 10px' : '0 10px 10px 0')};
  border: 1px solid #d9d9d9;
  background: #fff;

  cursor: ${({$isActive}) => ($isActive ? 'pointer' : '#default')};
  font-size: 14px;
  &:hover {
    background-color: ${({theme, $isActive}) => ($isActive ? theme.colors.greyCircuit : '#fff')};
  }
`;

interface IPaginationButtonProps {
  text: string;
  isLeft: boolean;
  onClick: () => void;
  isActive: boolean;
}

const PaginationButton = ({isLeft, text, onClick, isActive}: IPaginationButtonProps) => {
  return (
    <StyledPaginationButton $isActive={isActive} onClick={onClick} $isLeft={isLeft}>
      {text}
    </StyledPaginationButton>
  );
};

export default PaginationButton;
