import {instance} from 'api';

import {IApiResponse} from 'types/services';
import {
  IChangeProjectRouterStep,
  IPatchProjectRouterStepListItem,
  IPostProjectRouterStepListItem,
  IProjectRouterHistoryItem,
  IProjectRouterStepListItem,
} from 'types/state/ProjectRouterStepList';

export const ProjectRouterStepListService = {
  async getAll() {
    const response = await instance.get<IApiResponse<IProjectRouterStepListItem>>(
      '/project-router-step/',
    );

    return response.data.results;
  },

  async getProjectRouterHistory(project: number) {
    const response = await instance.get<IApiResponse<IProjectRouterHistoryItem>>(
      `/project-router-step-history/?project=${project}`,
    );

    return response.data.results;
  },

  async changeProjectRouterStep(data: IChangeProjectRouterStep) {
    await instance.post<void>('/project-router-step-history/', data);
  },

  async getById(id: number) {
    const response = await instance.get<IProjectRouterStepListItem>(`/project-router-step/${id}/`);

    return response.data;
  },

  async getByProject(projectId: number) {
    const response = await instance.get<IApiResponse<IProjectRouterStepListItem>>(
      `/project-router-step/?project=${projectId}`,
    );

    return response.data.results;
  },

  async add(data: IPostProjectRouterStepListItem) {
    try {
      return instance.post<IPostProjectRouterStepListItem>('/project-router-step/', data);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async delete(id: number) {
    try {
      return instance.delete<IPostProjectRouterStepListItem>(`/project-router-step/${id}/`);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async update(id: number, data: IPatchProjectRouterStepListItem) {
    try {
      return instance.patch<IPatchProjectRouterStepListItem>(`/project-router-step/${id}/`, data);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },
};
