import {DefaultTheme} from 'styled-components';

const theme: DefaultTheme = {
  borderRadius: {
    onlyTop: '10px 10px 0px 0px',
    full: '10px',
  },

  transition: 'all 0.2s ease-in-out',

  border: '1px solid #D9D9D9',

  fontWeight: {
    light: '300',
    normal: '400',
    bald: '700',
  },

  fontSize: {
    big: '32px',
    medium: '16px',
    small: '14px',
  },

  colors: {
    lightBlue: '#BEE4FF',
    skyBlue: '#6DACDE',
    oceanBlue: '#025EA1',
    darkBlue: '#003274',
    accentBlue: '#4495D1',
    buttonBackground: '#E0E1E8',
    greyCircuit: '#D9D9D9',
    textDark: '#0F1120',
    grayBackground: '#F0F1F6',
    grayTextOnDark: '#9A9CAB',
    lime: '#DCEDB6',
    grey: '#51575F',
    white: '#FFFFFF',
    black: '#000000',
    green: '#008000',
  },
};

export default theme;
