import styled from 'styled-components';

import React, {ReactNode} from 'react';

const StyledEditGroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledEditGroupsHeader = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  border-radius: 10px 10px 0 0;
`;

const StyledGroupsChildren = styled.div<{$isTitle?: boolean}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  border-radius: ${({$isTitle}) => ($isTitle ? '0 0 10px 10px' : '10px')};
  padding: 10px;
  gap: 10px;
`;

interface IGroups {
  title?: string;
  children: ReactNode;
}

const GroupsEditProfile = ({title, children}: IGroups) => {
  return (
    <StyledEditGroupsWrapper>
      {title && <StyledEditGroupsHeader>{title}</StyledEditGroupsHeader>}
      <StyledGroupsChildren $isTitle={Boolean(title)}>{children}</StyledGroupsChildren>
    </StyledEditGroupsWrapper>
  );
};

export default GroupsEditProfile;
