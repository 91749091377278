import styled from 'styled-components';

import {StyledLink} from '@Common/Link';

export const StyledContentRow = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};

  display: grid;
  grid-template-columns: 70% 30%;
  gap: 30px;

  padding: 24px 30px;
  width: 100%;
`;
export const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledSmallLink = styled(StyledLink)`
  display: flex;
  margin: 0;

  word-break: break-word;

  color: ${({theme}) => theme.colors.accentBlue};
  font-weight: ${({theme}) => theme.fontWeight.normal};
  font-size: ${({theme}) => theme.fontSize.small};
`;

export const QuestionAnswerColumns = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 50% 50%;
`;

export const QuestionAnswer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledFiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;
