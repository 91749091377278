import styled from 'styled-components';

import React, {FC} from 'react';

interface IDraftMenuButton {
  title: string;
  action: () => void;
  image?: React.ReactNode;
}

const StyledDraftMenuButton = styled.button`
  display: flex;
  border-radius: 10px;
  align-items: center;
  gap: 8px;
  border-width: 0;
`;

const DraftMenuButton: FC<IDraftMenuButton> = ({title, action, image}) => {
  return (
    <StyledDraftMenuButton onClick={action}>
      {title}
      {image}
    </StyledDraftMenuButton>
  );
};

export default DraftMenuButton;
