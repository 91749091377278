import styled from 'styled-components';

import React from 'react';

import {StyledContentRow} from '@Common/DraftsItem/StyledContentRow';
import SwitchContainer from '@Common/SwitchContainer';
import {TextHeaderMedium} from '@Common/Text';

import {IInformationDraftRowProps} from './types';

export const InformationDraftRowStyled = styled.div<{$isGray?: boolean}>`
  display: flex;
  flex-direction: column;
`;

const InformationDraftRow = (props: IInformationDraftRowProps) => {
  const {title, children, value, isGray = true} = props;

  return (
    <StyledContentRow $templateColumns="minmax(125px, 1fr) minmax(200px, 2fr)" $isGray={isGray}>
      <TextHeaderMedium>{title}</TextHeaderMedium>
      <SwitchContainer {...props} value={value || '-'}>
        {children}
      </SwitchContainer>
    </StyledContentRow>
  );
};

export default InformationDraftRow;
