import styled from 'styled-components';

import React, {useState, useMemo} from 'react';

import DropdownWrapper from '@components/Wrappers/Dropdown';

import {SortFormButton} from '@Common/Buttons';
import {TextInput} from '@Common/Input';

import DropdownScrollbarList from './DropdownScrollbarList';

interface IMultiplyDropdownProps {
  labels?: string[];
  title: string;
  sortField?: string;
  width?: number;
  setSelected: (labels: string[]) => void;
  selected: string[];
  isTags?: boolean; //Добавление для тэгов
  isUp?: boolean; //Дропдаун вверх
}

const DropdownListContainer = styled.div<{$width?: number; $isUp?: boolean}>`
  position: absolute;
  top: ${({$isUp}) => ($isUp ? '-90%' : '105%')};
  left: 0;
  z-index: 1;
  filter: drop-shadow(0px 15px 25px rgba(172, 172, 172, 0.25));
  width: ${({$width}) => ($width ? $width : 340)}px;
  max-height: 155px;
`;

const MultiplyDropdown = ({
  title,
  labels,
  width,
  setSelected,
  selected,
  isTags = false,
  isUp = false,
}: IMultiplyDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');

  const filteredLabels = useMemo(() => {
    return labels
      ? labels.filter(label => label.toLowerCase().includes(inputValue.toLowerCase()))
      : [];
  }, [labels, inputValue]);

  const handleSelect = (label: string) => {
    if (selected) {
      const newSlected = selected.filter(str => str !== label);

      if (newSlected.length === selected.length) {
        newSlected.push(label);
        setSelected([...newSlected]);
      } else {
        setSelected([...newSlected]);
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const toggleDropdown = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
    setInputValue('');
  };

  const deleteChoice = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    setSelected([]);
  };
  return (
    <DropdownWrapper>
      <SortFormButton
        width={width}
        isOpen={isOpen}
        baseTitle={title}
        title={'Выберете'}
        action={toggleDropdown}
        actionDelete={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => deleteChoice(e)}
      />
      {isOpen && (
        <DropdownListContainer $isUp={isUp} $width={width}>
          <TextInput value={inputValue} onChange={handleInputChange} />
          <DropdownScrollbarList
            isUp={isUp}
            addedValue={isTags ? inputValue : undefined}
            width={width}
            sidebar={false}
            labels={filteredLabels ? filteredLabels : []}
            selected={null}
            selectedMultiply={selected}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleSelect={handleSelect}
          />
        </DropdownListContainer>
      )}
    </DropdownWrapper>
  );
};
export default MultiplyDropdown;
