import styled from 'styled-components';

import {NavLink, useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import UserPicture from 'assets/images/user_picture.png';
import React from 'react';

import {ProfileService} from '@services/profile';

import {Picture} from '@Common/Picture';
import {Rating} from '@Common/Rating';
import StatusChecker from '@Common/StatusChecker';
import {Colors, TextHeaderMedium, TextParagraph} from '@Common/Text';

import {formatDate} from '@utils/formatDate';

const StyledContentItemFeedback = styled.div`
  display: flex;
  padding: 7px;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  color: ${({theme}) => theme.colors.textDark};
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
`;

const StyledContent = styled.div`
  display: flex;
  gap: 15px;
`;
const StyledReview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const StyledDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  color: ${({theme}) => theme.colors.grey};
`;

const ActivityFeedback = () => {
  const pageId = Number(useParams().id);
  const {data, isLoading, isError} = useQuery({
    queryKey: ['profile-feedback', pageId],
    queryFn: () => ProfileService.getFeedbackById(pageId),
  });

  return (
    <>
      <StatusChecker errorsArray={[isError]} loadingsArray={[isLoading]}>
        {data?.results.map(({comment, score}) => (
          <StyledContentItemFeedback key={comment.id}>
            <StyledContent>
              <Picture
                variant={'sm'}
                src={comment.created_by.avatar || UserPicture}
                alt={'Аватар'}
              />
              <StyledReview>
                {comment.created_by.is_related ? (
                  <NavLink to={`/profile/${comment.created_by_id}`}>
                    <TextHeaderMedium isLink>
                      {comment.created_by.fio || comment.created_by.email1}
                    </TextHeaderMedium>
                  </NavLink>
                ) : (
                  <TextHeaderMedium>
                    {comment.created_by.fio || comment.created_by.email1}
                  </TextHeaderMedium>
                )}

                <TextParagraph color={Colors.grey}>Оценка</TextParagraph>
                <Rating fixRatings={score} />

                <div dangerouslySetInnerHTML={{__html: comment.text}} />
              </StyledReview>
            </StyledContent>
            <StyledDate>{formatDate(comment.created_at)}</StyledDate>
          </StyledContentItemFeedback>
        ))}
      </StatusChecker>
    </>
  );
};

export default ActivityFeedback;
