import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import React from 'react';

import {ProjectTaskService} from '@services/projectTask';

import {FilterButton} from '@Common/Buttons';
import {TextHeaderMedium, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {formatDate} from '@utils/formatDate';

import {IPatchProjectTask, IProjectTask} from 'types/state/ProjectTask';

const StyledContentRow = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};

  display: grid;
  align-items: center;
  grid-template-columns: 45% 25% 25%;
  gap: 10px;

  padding: 24px 30px;
  width: 100%;
`;

interface IGoalsItem {
  data: IProjectTask;
}

const GoalsItem = ({data}: IGoalsItem) => {
  const queryClient = useQueryClient();
  const pageId = Number(useParams().id);
  const mutation = useMutation({
    mutationFn: (finishedAt: IPatchProjectTask) => ProjectTaskService.update(data.id, finishedAt),
    onSuccess: () => {
      void queryClient.invalidateQueries(['projecttask', pageId]);
    },
  });

  const finishedTask = () => {
    const formattedDate = new Date().toISOString();
    mutation.mutate({
      done_at: formattedDate,
    });
  };

  return (
    <StyledContentRow>
      <TextHeaderMedium>{data.description}</TextHeaderMedium>
      <TextParagraph color={Colors.textDark}>{formatDate(data.will_done)}</TextParagraph>
      {data.done_at ? (
        <TextHeaderMedium>{formatDate(data.done_at)}</TextHeaderMedium>
      ) : (
        <FilterButton isBlue={false} action={finishedTask} title="Завершить" />
      )}
    </StyledContentRow>
  );
};

export default GoalsItem;
