import React from 'react';

import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {formatDate} from '@utils/formatDate';

import {ICommentProjectStep} from 'types/state/ProjectStepList';

import {StyledLink, StyledResults, StyledResultItem} from './styles';

interface IExpertiseComment {
  comments: ICommentProjectStep[];
}

const ExpertiseComment = ({comments}: IExpertiseComment) => {
  return (
    <StyledResults>
      {comments.map(comment => (
        <StyledResultItem key={comment.created_at}>
          <TextParagraph color={Colors.textDark}>{formatDate(comment.created_at)}</TextParagraph>
          <div dangerouslySetInnerHTML={{__html: comment.body}} />

          {comment.attachments?.map(attachment => {
            const documentName = decodeURIComponent(attachment.split('/').pop() || '');
            return (
              <StyledLink key={attachment} href={attachment}>
                {documentName}
              </StyledLink>
            );
          })}
        </StyledResultItem>
      ))}
    </StyledResults>
  );
};

export default ExpertiseComment;
