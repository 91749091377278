import styled from 'styled-components';

import {Link, useLocation, useNavigate} from 'react-router-dom';

import React, {FormEvent, useEffect, useState} from 'react';

import {AuthService} from '@services/auth';

import AuthContainer from '@Common/AuthContainer';
import {LoginFormButton} from '@Common/Buttons';
import {LoginForm, LoginInput} from '@Common/Forms/Login';
import Loader from '@Common/Loader';
import {TextHeader, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

const AppLink = styled(Link)`
  text-decoration: none;
  color: rgb(23, 80, 203);
  align-items: center;
  display: flex;
  gap: 8px;

  &:hover {
    color: #1a225f;
  }

  & path {
    fill: #1750cb;
  }
`;

type SearchParams = {
  uid: string | null;
  token: string | null;
};

const ActivationPage = () => {
  const [passwordFirst, setPasswordFirst] = useState('');
  const [passwordSecond, setPasswordSecond] = useState('');
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState<SearchParams>({
    uid: '',
    token: '',
  });

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    setSearchParams({
      uid: urlSearchParams.get('uid'),
      token: urlSearchParams.get('token'),
    });
  }, [location.search]);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (passwordFirst === passwordSecond) {
      setIsLoading(true);

      const postData = {
        password: passwordFirst,
        uid: searchParams.uid || '',
        token: searchParams.token || '',
      };
      await AuthService.activate(postData)
        .catch(() => setIsErrorShown(true))
        .finally(() => setIsLoading(false));
      setIsErrorShown(false);
      navigate('/auth');
    } else {
      setIsErrorShown(true);
    }
  };

  return (
    <AuthContainer>
      <TextHeader align="center" color="white">
        ИННОХАБ РОСАТОМА
        <br /> ПЛАТФОРМА МАРШРУТИЗАЦИИ ПРОЕКТОВ
      </TextHeader>
      <LoginForm onSubmit={(e: FormEvent) => onSubmit(e)}>
        <TextParagraph color={Colors.textDark}>Активация</TextParagraph>
        <LoginInput
          type={'password'}
          value={passwordFirst}
          autoComplete={'on'}
          onChange={e => setPasswordFirst(e.target.value)}
          placeholder={'Пароль'}
        />
        <LoginInput
          type={'password'}
          value={passwordSecond}
          autoComplete={'on'}
          onChange={e => setPasswordSecond(e.target.value)}
          placeholder={'Повторите пароль'}
        />
        <LoginFormButton type="submit">Активировать</LoginFormButton>
        {isLoading && <Loader />}
        {isErrorShown && (
          <TextParagraph align="center" color={Colors.textDark}>
            Пароли не совпадают
          </TextParagraph>
        )}
        <AppLink to={'/auth'}>Войти</AppLink>
        <AppLink to={'/registration'}>Регистрация</AppLink>
      </LoginForm>
    </AuthContainer>
  );
};

export default ActivationPage;
