import styled from 'styled-components';

import React, {FC} from 'react';
import {ImpulseSpinner} from 'react-spinners-kit';

import {FlexRowContainer} from './Utils';

const LoaderWrapper = styled(FlexRowContainer)`
  justify-content: center;
  width: 100%;
  margin-top: 50px;
`;

const Loader: FC = () => {
  return (
    <LoaderWrapper>
      <ImpulseSpinner size={50} />
    </LoaderWrapper>
  );
};

export default Loader;
