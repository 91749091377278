import styled from 'styled-components';

import React, {FC} from 'react';

import {TableRowStyled} from '@Common/Table/Row';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import useAppSelector from '@hooks/UseAppSelector';

const ReservationTableStyled = styled.div``;

const ReservationTableRow = styled(TableRowStyled)`
  grid-template-columns: 40% 40% 10%;
  gap: 50px;
`;

interface IReservationTable {
  editAction: () => void;
}

const ReservationTable: FC<IReservationTable> = () => {
  const ReservationList = useAppSelector(state => state.reservation.value);

  return (
    <ReservationTableStyled>
      {ReservationList.map((item, index) => {
        return (
          <ReservationTableRow $templateColumns="88% 10%" key={item.id} $isGray={index % 2 == 1}>
            <TextParagraph color={Colors.textDark}>{item.title}</TextParagraph>
            <TextParagraph color={Colors.textDark}>{item.path}</TextParagraph>
          </ReservationTableRow>
        );
      })}
    </ReservationTableStyled>
  );
};

export default ReservationTable;
