import {useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';

import {ExpertiseService} from '@services/expertise';

const UseExpertise = (projectId: number) => {
  const pageId = Number(useParams().id);

  const {
    data: expertiseList,
    isLoading: isLoadingExpertise,
    isError: isErrorExpertise,
  } = useQuery({
    queryKey: ['expertise', pageId],
    queryFn: () => ExpertiseService.getByProject(projectId),
  });

  return {
    expertiseList: expertiseList || [],
    isLoadingExpertise,
    isErrorExpertise,
  };
};

export default UseExpertise;
