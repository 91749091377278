import {styled} from 'styled-components';

import {useNavigate, useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {DraftsService} from '@services/drafts';

import {FilterButton} from '@Common/Buttons';
import StatusChecker from '@Common/StatusChecker';
import {Colors, TextHeaderMedium, TextParagraph} from '@Common/Text';

import UseAccelerators from '@hooks/UseAccelerators';
import UseWorkflows from '@hooks/UseWorkflows';

import {getOptionFromId} from '@utils/GetOptionsFromString';

import {IUser} from 'types/User';

import ContentStatusAccelerator from './Accelerator';

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledStatusRow = styled.div`
  display: grid;
  background-color: ${({theme}) => theme.colors.grayBackground};
  gap: 20px;

  padding: 15px;
  width: 100%;
`;

export interface IContentStatusProps {
  accelerator_id: number | null;
  workflow_id: number | null;
  is_draft: boolean;
  user: IUser;
}

const ContentStatus = ({accelerator_id, workflow_id, user, is_draft}: IContentStatusProps) => {
  const queryClient = useQueryClient();
  const pageId = Number(useParams().id);
  const navigation = useNavigate();
  const {is_superuser, is_supervisor} = user;
  const {acceleratorsLabels, isErrorAccelerator, isLoadingAccelerator} = UseAccelerators();

  const initialAccelerator = useMemo(
    () => getOptionFromId(accelerator_id, acceleratorsLabels),

    [acceleratorsLabels, accelerator_id],
  );
  const [accelerator, setAccelerator] = useState(initialAccelerator);
  useEffect(() => {
    setAccelerator(initialAccelerator);
  }, [initialAccelerator]);

  const {workflowsLabels, isErrorWorkflows, isLoadingWorkflows} = UseWorkflows(accelerator?.value);

  const initialWorkflow = useMemo(
    () => getOptionFromId(workflow_id, workflowsLabels),

    [workflowsLabels, workflow_id],
  );

  const [workflow, setWorkflow] = useState(initialWorkflow);
  useEffect(() => {
    setWorkflow(initialWorkflow);
  }, [initialWorkflow]);

  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    setShowButton(!!(accelerator && workflow));
  }, [accelerator, workflow]);

  const mutation = useMutation({
    mutationFn: async () => {
      if (accelerator && workflow) {
        await DraftsService.updateAccelerator(
          pageId,
          Number(accelerator.value),
          Number(workflow.value),
        );
        navigation(`/project/${pageId}`);
      }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(['project', pageId]);
    },
  });

  const handleApply = () => {
    if (accelerator && workflow) {
      mutation.mutate();
    }

    setShowButton(false);
  };

  return (
    <StyledStatusRow>
      <TextHeaderMedium>Статус</TextHeaderMedium>

      <StatusWrapper>
        <StatusChecker errorsArray={[isErrorAccelerator]} loadingsArray={[isLoadingAccelerator]}>
          <ContentStatusAccelerator
            is_draft={is_draft}
            accelerator_id={accelerator_id}
            title="Акселератор"
            labels={acceleratorsLabels}
            value={accelerator}
            setValue={setAccelerator}
          />
          <StatusChecker errorsArray={[isErrorWorkflows]} loadingsArray={[isLoadingWorkflows]}>
            <ContentStatusAccelerator
              is_draft={is_draft}
              accelerator_id={accelerator_id}
              title="Workflow"
              labels={workflowsLabels}
              value={workflow}
              setValue={setWorkflow}
            />
          </StatusChecker>
          {(is_superuser || is_supervisor) && showButton && !accelerator_id && (
            <FilterButton isBlue={false} title={'Применить'} action={handleApply} />
          )}
          {is_draft && (
            <TextParagraph color={Colors.red}>
              Для выбора акселератора необходимо пройти чек-лист
            </TextParagraph>
          )}
        </StatusChecker>
      </StatusWrapper>
    </StyledStatusRow>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(ContentStatus);
