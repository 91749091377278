import {styled} from 'styled-components';

import React, {useRef, useState} from 'react';

import {PlusIcon} from '@Common/Images';
import {StyledLink} from '@Common/Link';
import {StyledText} from '@Common/Text';

import {Colors} from '@constants/enum';

const Documents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ContentDocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  min-height: 40px;
  justify-content: center;
`;

const SelectFileButton = styled.button`
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  & path {
    fill: ${({theme}) => theme.colors.grey};
  }
`;

const StyledTextLinks = styled(StyledText)`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const FileInput = styled.input.attrs({type: 'file', accept: '.jpg,.jpeg,.png,.bmp'})`
  display: none;
`;

export interface IGroupsAvatarItem {
  documentLink: string;
  setAvatar: (value: File) => void;
}

const GroupsAvatarItem = ({documentLink, setAvatar}: IGroupsAvatarItem) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const documentsName = decodeURIComponent(documentLink.split('/').pop() || '') || null;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (file) {
      setSelectedFile(file);
      setAvatar(file);
    }
  };

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <ContentDocumentWrapper>
      <Documents>
        <StyledTextLinks $color={Colors.accentBlue}>
          {selectedFile ? (
            <StyledLink href={URL.createObjectURL(selectedFile)}>{selectedFile.name}</StyledLink>
          ) : (
            <StyledLink href={documentLink}>{documentsName || '-'}</StyledLink>
          )}
        </StyledTextLinks>

        <SelectFileButton onClick={openFileSelector}>
          <PlusIcon />
        </SelectFileButton>

        <FileInput ref={fileInputRef} onChange={handleFileChange} />
      </Documents>
    </ContentDocumentWrapper>
  );
};

export default GroupsAvatarItem;
