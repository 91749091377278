import styled from 'styled-components';

import React from 'react';

import StatusChecker from '@Common/StatusChecker';

import UseTechRequestResponses from '@hooks/UseTechRequestResponses';

import Response from './Response';

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TechRequestResponsesContent = ({techRequestId}: {techRequestId: number}) => {
  const {data, isErrorTechRequestResponses, isLoadingTechRequestResponses} =
    UseTechRequestResponses(techRequestId);

  return (
    <StyledTable>
      <StatusChecker
        errorsArray={[isErrorTechRequestResponses]}
        loadingsArray={[isLoadingTechRequestResponses]}>
        {data
          ?.filter(response => response.status !== 'declined')
          .map(response => (
            <Response techRequestId={techRequestId} response={response} key={response.id} />
          ))}
      </StatusChecker>
    </StyledTable>
  );
};

export default TechRequestResponsesContent;
