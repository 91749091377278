import {IProjectArea} from 'types/Projectarea';

const initialState: IProjectArea = {
  count: 0,
  next: '',
  previous: null,
  results: [],
  isLoading: true,
};

export default initialState;
