import styled from 'styled-components';

import React, {useEffect, useState} from 'react';

import AcceptModal from '@Common/Modal/Accept';

import {IRequestProjectRouterCheckListItem, IScalingData} from 'types/state/ProjectRouterCheckList';

import ChoiceButtons from './ChoiceButtons';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`;

interface IScaling {
  setActive: () => void;
  setRequestData: (data: IRequestProjectRouterCheckListItem) => void;
}

export const titlesScaling = {
  quiz1:
    'На данный момент продукт Проекта, который Вы зарегистрировали в маршрутизаторе, выпускается и продается серийно?',
  quiz2:
    'Необходима ли Вам помощь в дальнейшем масштабировании Вашего продукта, выход на дополнительные рынки?',
  quiz3: 'Требуется ли для Вашего проекта дополнительное финансирование?',
};

const Scaling = ({setActive, setRequestData}: IScaling) => {
  const [answers, setAnswers] = useState<IScalingData>({
    quiz1: null,
    quiz2: null,
    quiz3: null,
  });

  const [isModalNo, setIsModalNo] = useState(false);
  useEffect(() => {
    setRequestData(answers);
  }, [setRequestData, answers]);

  return (
    <StyledContent>
      <ChoiceButtons
        onClickYes={() => setAnswers({...answers, quiz1: true})}
        onClickNo={() => setIsModalNo(true)}
        isActive={answers.quiz1}
        title={titlesScaling.quiz1}
        tooltipText="Серийный продукт — это товар, который производится в большом количестве единиц и имеет одинаковые характеристики
        Для IT-проектов под серийностью понимаются продажи приложения в общедоступных сервисах или нахождение платформы/сайта в открытом доступе с полным функционалом "
      />

      {answers.quiz1 && (
        <>
          <ChoiceButtons
            onClickYes={() => setAnswers({...answers, quiz2: true})}
            onClickNo={() => setAnswers({...answers, quiz2: false})}
            isActive={answers.quiz2}
            title={titlesScaling.quiz2}
            tooltipText="Под «масштабированием» понимается расширение модельного ряда, увеличение технологических мощностей и т.д.
            Под «интеграцией» – взаимодействие с отраслевыми организациями, в том числе сделки слияния и поглощения
            Под «дополнительными рынками» – зарубежные и альтернативные 
            российские рынки 
            "
          />
          <ChoiceButtons
            onClickYes={() => setAnswers({...answers, quiz3: true})}
            onClickNo={() => setAnswers({...answers, quiz3: false})}
            isActive={answers.quiz3}
            title={titlesScaling.quiz3}
            tooltipText="Дополнительное финансирование может быть направлено на масштабирование продукции, увеличение производственных мощностей, НИОКР по разработке новых продуктовых направлений и т.п.
            "
          />
        </>
      )}
      {isModalNo && <AcceptModal handleYes={setActive} setIsOpen={setIsModalNo} />}
    </StyledContent>
  );
};

export default Scaling;
