import styled from 'styled-components';

import {ru} from 'date-fns/locale';
import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import './customDatePicker.css';
const DateTimePickerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DateDisplay = styled.div`
  border-radius: ${({theme}) => theme.borderRadius.full};
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  padding: 10px;

  &:hover {
    background-color: ${({theme}) => theme.colors.greyCircuit};
  }
`;

const CustomDatePicker = styled.div`
  position: absolute;
  top: 100%;
  z-index: 9999;
`;

interface IDateTimePicker {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
}

const DateTimePicker = ({selectedDate, setSelectedDate}: IDateTimePicker) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (isOpen && !target.closest('.react-datepicker')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <DateTimePickerContainer>
      <DateDisplay onClick={handleButtonClick}>
        {selectedDate?.toLocaleDateString() || ''}
      </DateDisplay>

      {isOpen && (
        <CustomDatePicker>
          <DatePicker
            showYearDropdown
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd.MM.yyyy"
            placeholderText="Выберите дату"
            shouldCloseOnSelect={false}
            inline
            locale={ru}
          />
        </CustomDatePicker>
      )}
    </DateTimePickerContainer>
  );
};

export default DateTimePicker;
