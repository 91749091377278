import styled from 'styled-components';

import UserPicture from 'assets/images/user_picture.png';
import React from 'react';

interface ClientCardPictureProps {
  src: string | null;
}

const Picture = styled.img`
  border-radius: 100%;
  width: 100px;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;
`;

const ClientCardPicture = ({src}: ClientCardPictureProps) => {
  return <Picture src={src || UserPicture} alt={'user-picture'} />;
};

export default ClientCardPicture;
