import React, {FC, useState} from 'react';

import {DraftsItemWrapper} from '@components/Wrappers/DraftsItem';

import {PanelHeader} from '@Common/DraftsItem/Panel';
import {InfoIcon} from '@Common/Images';

import {IDraftsListItem} from 'types/state/Drafts';

import {DraftsInformationContent} from './Content';

const DraftsItemInformations: FC<{data: IDraftsListItem | null}> = ({data}) => {
  const [isShownContent, setIsShownContent] = useState(false);
  const handleShow = () => {
    setIsShownContent(prev => !prev);
  };

  return (
    <DraftsItemWrapper isHalf={true}>
      <PanelHeader
        isShownContent={isShownContent}
        icon={<InfoIcon />}
        title={'Информация'}
        action={handleShow}
      />
      {isShownContent && data && <DraftsInformationContent data={data} />}
    </DraftsItemWrapper>
  );
};

export default DraftsItemInformations;
