import {useMutation, useQueryClient} from '@tanstack/react-query';

import {CustomersService} from '@services/customers';

import {IPostCustomerResponseAnswer} from 'types/state/Customer';

const UseMutationProjectCustomerStatusAnswer = (id: number, pageId: number) => {
  const queryClient = useQueryClient();

  const fetchFn = (data: IPostCustomerResponseAnswer) =>
    CustomersService.createAnswer({...data}, id);

  return useMutation({
    mutationFn: fetchFn,
    onSuccess: () => {
      void queryClient.invalidateQueries(['project-customer-response', pageId]);
    },
  });
};
export default UseMutationProjectCustomerStatusAnswer;
