import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {useState} from 'react';

import {TechRequestService} from '@services/techRequest';

import {FilterButton} from '@Common/Buttons';
import {CrossIcon} from '@Common/Images';
import {TextInput} from '@Common/Input';
import {TextHeader, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {IPostRequestResponse} from 'types/patchRequest';

import {ButtonWrapper, CloseButton, ModalWrapper, Overlay} from './styles';

interface ModalProps {
  isOpen: boolean;
  projectId: number;
  projectName: string;
  onClose: () => void;
}

const TechRequestResponseModal = ({isOpen, onClose, projectId, projectName}: ModalProps) => {
  const queryClient = useQueryClient();

  const [comment, setComment] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [isSend, setIsSend] = useState(false);

  const mutation = useMutation({
    mutationFn: (data: IPostRequestResponse) => TechRequestService.createResponse(data),
    onSuccess: () => {
      void queryClient.invalidateQueries(['techRequests']);
      void queryClient.invalidateQueries(['techRequestsResponses']);
    },
  });

  const createComment = async () => {
    if (comment) {
      await mutation.mutateAsync({
        project_request: projectId,
        comment: comment,
      });
      setIsSend(true);
    } else {
      setError(true);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Overlay
        onClick={() => {
          setIsSend(false);
          onClose();
        }}>
        <ModalWrapper
          onClick={e => {
            e.stopPropagation();
          }}>
          <CloseButton
            onClick={() => {
              setIsSend(false);
              onClose();
            }}>
            <CrossIcon />
          </CloseButton>
          {isSend ? (
            <TextHeader>
              Вы отправили свою кандидатуру для выполнения технологического запроса «{projectName}»
              Бизнес-заказчику. Пожалуйста, ожидайте, когда он ознакомится с вашим откликом.
            </TextHeader>
          ) : (
            <>
              <TextHeader>Добавление комментария</TextHeader>
              <TextInput height={100} value={comment} onChange={e => setComment(e.target.value)} />
              {error && <TextParagraph color={Colors.red}>Комментарий не добавлен</TextParagraph>}
              <ButtonWrapper>
                <FilterButton isBlue={false} title={'Отправить'} action={createComment} />
              </ButtonWrapper>
            </>
          )}
        </ModalWrapper>
      </Overlay>
    </>
  );
};

export default TechRequestResponseModal;
