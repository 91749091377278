import ReservationTable from 'components/Tables/Reservation';
import React, {FC} from 'react';

import {ContentBoardMenu} from '@components/Menu';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {PlusIcon} from '@Common/Images';

import {UseNavigationList} from '@hooks/UseNavigationList';

const Reservation: FC<{children?: React.ReactNode}> = () => {
  const mockFunction = () => console.log('Reservation/mock');

  const navigationList = [{path: '/reservation', title: 'Резервирование'}];

  UseNavigationList(navigationList);

  const PlusImage = <PlusIcon />;

  return (
    <>
      <ContentBoardMenu pageTitle={'Резервирование'}>
        <ContentBoardMenuButton
          title={'Создать резервную копию'}
          action={mockFunction}
          image={PlusImage}
        />
      </ContentBoardMenu>

      <ReservationTable editAction={mockFunction} />
    </>
  );
};

export default Reservation;
