import {MyOption} from 'components/Common/Dropdown/Single/types';
import React from 'react';
import Select, {InputActionMeta, StylesConfig} from 'react-select';

import './index.css';
import {DropdownMultipleType, MultyDropdownProps} from './types';

const MultipleDropdown = ({
  labels,
  handleChange,
  value,
  isError,
  placeholder,
  isClearable = true,
  isFocus,
  width,
  isAddedNewValue = false,
}: MultyDropdownProps) => {
  const styles: StylesConfig<MyOption> = {
    container: baseStyles => ({
      ...baseStyles,
      width: width ? width : '100%',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: '100px',
      cursor: 'pointer',
      borderRadius: '10px',
      minHeight: '40px',

      borderColor: state.isFocused ? '#9fb6ff' : isError ? 'red' : baseStyles.borderColor,
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: '10px',
      padding: '10px 4px',
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      padding: '2px 12px',
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '0 8px',

      maxHeight: '150px',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
      scrollbarColor: '#888',
    }),
    input: baseStyles => ({
      ...baseStyles,
      margin: '0px',
      color: '#51575F',
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      margin: '0px',
    }),
    indicatorSeparator: baseStyles => ({
      ...baseStyles,
      display: 'none',
    }),
    clearIndicator: baseStyles => ({
      ...baseStyles,
      color: '#025EA1',
      padding: '0',
      svg: {
        width: '25px',
        height: '25px',
      },
    }),
    dropdownIndicator: baseStyles => ({
      ...baseStyles,
      color: '#025EA1',
      padding: '0 8px',
      svg: {
        width: '25px',
        height: '25px',
      },
    }),
    option: (baseStyles, {isFocused, isSelected}) => ({
      ...baseStyles,
      cursor: 'pointer',
      fontSize: '16px',
      padding: '4px 16px',
      borderRadius: '10px',
      backgroundColor: isSelected ? '#003274' : isFocused ? '#E0E1E8' : baseStyles.backgroundColor,
      wordBreak: 'break-word',

      '@media (max-width: 1300px)': {
        padding: '7px 7px',
      },
    }),
    multiValue: baseStyles => ({
      ...baseStyles,
      color: '#51575F',
      backgroundColor: 'rgb(109, 172, 222);',
      borderRadius: '8px',

      alignItems: 'center',
    }),
    multiValueLabel: baseStyles => ({
      ...baseStyles,
      fontSize: '16px',
      paddingTop: '0px',
    }),
    multiValueRemove: baseStyles => ({
      ...baseStyles,
      padding: '0',
      ':hover': {
        backgroundColor: 'transparent',
        color: '#E0E1E8',
      },
    }),
  };

  const customNoOptionsMessage = ({inputValue}: {inputValue: string}) => {
    return inputValue ? 'Нет результатов' : 'Нет доступных опций';
  };

  const handleInputChange = (inputValue: string, action: InputActionMeta) => {
    const trimmedValue = inputValue.trim(); // Удаляем пробелы в начале и конце строки

    if (action.action === 'input-change' && isAddedNewValue && trimmedValue) {
      const newValue = {label: trimmedValue, value: trimmedValue}; // Создаем новую опцию

      // Проверяем, нет ли уже такой опции в списке
      if (!labels.find(option => option.label === trimmedValue)) {
        labels.push(newValue); // Добавляем новую опцию в список
      }
    }
  };

  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      menuPlacement="auto"
      isClearable={isClearable}
      isSearchable={true}
      className="custom-select-control"
      classNamePrefix="custom-select"
      placeholder={placeholder || 'Выберите'}
      options={labels}
      onChange={selectedOption => handleChange(selectedOption as DropdownMultipleType)}
      value={value}
      styles={styles}
      noOptionsMessage={customNoOptionsMessage}
      defaultMenuIsOpen={isFocus}
      onInputChange={handleInputChange}
      autoFocus={isFocus}
    />
  );
};

export default MultipleDropdown;
