import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import initialState from './state';

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      state.page = 1;
    },
    setProjectArea: (state, action: PayloadAction<string | null>) => {
      state.projectArea = action.payload;
      state.page = 1;
    },

    setIsInternal: (state, action: PayloadAction<string | null>) => {
      state.isInternal = action.payload;
      state.page = 1;
    },

    setIsArchived: (state, action: PayloadAction<string | null>) => {
      state.isArchived = action.payload;
      state.page = 1;
    },

    setAccelerator: (state, action: PayloadAction<string | null>) => {
      state.accelerator = action.payload;
      state.page = 1;
    },
    setAcceleratorRequest: (state, action: PayloadAction<string | null>) => {
      state.acceleratorRequest = action.payload;
      state.page = 1;
    },
    setTracker: (state, action: PayloadAction<string | null>) => {
      state.tracker = action.payload;
      state.page = 1;
    },
    setCreatedBy: (state, action: PayloadAction<string | null>) => {
      state.createdBy = action.payload;
      state.page = 1;
    },
    setSupervisor: (state, action: PayloadAction<string | null>) => {
      state.supervisor = action.payload;
      state.page = 1;
    },
    setIsFinished: (state, action: PayloadAction<string | null>) => {
      state.isFinished = action.payload;
      state.page = 1;
    },
    setRouterStage: (state, action: PayloadAction<string | null>) => {
      state.routerStage = action.payload;
      state.page = 1;
    },
    setLastLogin: (state, action: PayloadAction<string | null>) => {
      state.lastLogin = action.payload;
      state.page = 1;
    },
    setRole: (state, action: PayloadAction<string | null>) => {
      state.role = action.payload;
      state.page = 1;
    },
    setExpertiseRequired: (state, action: PayloadAction<string | null>) => {
      state.expertiseRequired = action.payload;
      state.page = 1;
    },
    setAnswerRequired: (state, action: PayloadAction<string | null>) => {
      state.answerRequired = action.payload;
      state.page = 1;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTechRequestStatus: (state, action: PayloadAction<string | null>) => {
      state.techRequestStatus = action.payload;
    },
    setScoringStatus: (state, action: PayloadAction<string | null>) => {
      state.scoringStatus = action.payload;
    },
    setFilterByUpdate: (state, action: PayloadAction<string | null>) => {
      state.filterByUpdate = action.payload;
    },
    clearFilters: () => initialState,
  },
});

export const {
  clearFilters,
  setPage,
  setSearch,
  setLastLogin,
  setRole,
  setIsFinished,
  setRouterStage,
  setProjectArea,
  setIsInternal,
  setIsArchived,
  setAccelerator,
  setSupervisor,
  setCreatedBy,
  setTracker,
  setExpertiseRequired,
  setAcceleratorRequest,
  setAnswerRequired,
  setTechRequestStatus,
  setScoringStatus,
  setFilterByUpdate,
} = filtersSlice.actions;

export default filtersSlice.reducer;
