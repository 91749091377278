import {styled} from 'styled-components';

import {useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import React, {FC, useState} from 'react';
import {createPortal} from 'react-dom';

import {ProjectTaskService} from '@services/projectTask';

import {StyledAddButtonWrapper} from '@components/Wrappers/AddButtonWrapper';
import {PanelContentWrapper} from '@components/Wrappers/DraftsItem';

import {PanelButton} from '@Common/Buttons';
import Loader from '@Common/Loader';
import ModalTask from '@Common/ModalTasks';
import {TextHeaderMedium} from '@Common/Text';

import GoalsItem from './item';

const StyledContentRow = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};

  display: grid;
  align-items: center;
  grid-template-columns: 45% 25% 25%;
  gap: 10px;

  padding: 24px 30px;
  width: 100%;
`;

const DraftsGoalsContent: FC = () => {
  const pageId = Number(useParams().id);
  const [showModal, setShowModal] = useState(false);
  const {
    data: taskList,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['projecttask', pageId],
    queryFn: () => ProjectTaskService.getByProject(pageId),
  });

  if (isError) return <div>Ошибка</div>;

  if (isLoading) return <Loader />;

  return (
    <>
      <PanelContentWrapper>
        <StyledContentRow>
          <TextHeaderMedium>Название</TextHeaderMedium>
          <TextHeaderMedium>Плановая дата</TextHeaderMedium>
          <TextHeaderMedium>Завершена</TextHeaderMedium>
        </StyledContentRow>
        {taskList.map(task => (
          <GoalsItem key={task.id} data={task} />
        ))}
      </PanelContentWrapper>
      <StyledAddButtonWrapper>
        {<PanelButton action={() => setShowModal(true)} />}
      </StyledAddButtonWrapper>

      {createPortal(
        <ModalTask onClose={() => setShowModal(false)} isOpen={showModal} />,
        document.body,
      )}
    </>
  );
};

export default DraftsGoalsContent;
