import {styled} from 'styled-components';

import React, {FC} from 'react';

const StyledInput = styled.input`
  display: none;
`;

interface IRatingInputProps {
  currentRating: number;
  action: (currentRating: number) => void;
}

const component: FC<IRatingInputProps> = ({currentRating, action}) => {
  return (
    <StyledInput
      type="radio"
      name="rating"
      value={currentRating}
      onClick={() => action(currentRating)}
    />
  );
};

export default component;
