import styled, {css} from 'styled-components';

import React, {useEffect, useState} from 'react';

import TooltipCustom from '@Common/Tooltip';

export const StyledText = styled.p<{
  $align?: string;
  $color: string;
  $active?: boolean;
  $textIndent?: string;
}>`
  display: flex;
  margin: 0;
  text-indent: ${({$textIndent}) => $textIndent || 0};
  word-break: break-word;
  text-align: ${({$align}) => $align || 'left'};
  font-weight: ${({theme}) => theme.fontWeight.normal};
  font-size: ${({theme}) => theme.fontSize.medium};

  ${({$color, $active}) => {
    switch ($color) {
      case Colors.accentBlue:
        return css`
          color: ${({theme}) => ($active ? theme.colors.white : theme.colors.accentBlue)};
        `;
      case Colors.grayTextOnDark:
        return css`
          color: ${({theme}) => ($active ? theme.colors.textDark : theme.colors.grayTextOnDark)};
        `;
      case Colors.grey:
        return css`
          color: ${({theme}) => ($active ? theme.colors.white : theme.colors.grey)};
        `;
      case Colors.white:
        return css`
          color: ${({theme}) => theme.colors.white};
        `;
      case Colors.textDark:
        return css`
          color: ${({theme}) => theme.colors.textDark};
        `;
      case Colors.darkBlue:
        return css`
          color: #1750cb;
        `;
      case Colors.red:
        return css`
          color: red;
        `;
    }
  }};
`;

export enum Colors {
  accentBlue = 'blue',
  darkBlue = 'darkBlue',
  grayTextOnDark = 'lightGray',
  grey = 'gray',
  textDark = 'black',
  white = 'white',
  red = 'red',
}

interface IText {
  children: React.ReactNode;
  color: Colors;
  align?: 'center' | 'right';
  isActive?: boolean;
  textIndent?: string;
  onClick?: () => void;
}

const TextParagraph = ({children, textIndent, align, color, isActive = false, onClick}: IText) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;
    const timer = setTimeout(() => setCopied(false), 2000);
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <>
      {onClick ? (
        <TooltipCustom content={copied ? 'Скопировано!' : 'Скопировать'}>
          <StyledText
            onClick={e => {
              onClick && onClick();
              setCopied(true);
              e.preventDefault();
            }}
            $textIndent={textIndent}
            $align={align}
            $color={color}
            $active={isActive}>
            {children}
          </StyledText>
        </TooltipCustom>
      ) : (
        <StyledText $textIndent={textIndent} $align={align} $color={color} $active={isActive}>
          {children}
        </StyledText>
      )}
    </>
  );
};

export default TextParagraph;
