import {createSlice} from '@reduxjs/toolkit';
import initialState from 'store/draft/state';

const draftSlice = createSlice({
  name: 'draft',
  initialState,
  reducers: {},
});

export default draftSlice.reducer;
