import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import React, {useState} from 'react';
import {createPortal} from 'react-dom';

import {StyledAddButtonWrapper} from '@components/Wrappers/AddButtonWrapper';

import {PanelButton} from '@Common/Buttons';
import CommentModal from '@Common/Modal/ModalComment';

import {DraftsCommentsList} from './CommentsList';

const StyledContent = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
`;

const DraftsCommentsContent = () => {
  const pageId = Number(useParams().id);
  const [showModal, setShowModal] = useState(false);
  return (
    <StyledContent>
      <DraftsCommentsList />

      <StyledAddButtonWrapper>
        {<PanelButton action={() => setShowModal(true)} />}
      </StyledAddButtonWrapper>
      {pageId &&
        createPortal(
          <CommentModal
            content_type={13}
            action="comment"
            onClose={() => setShowModal(false)}
            isOpen={showModal}
            object_id={pageId}
          />,
          document.body,
        )}
    </StyledContent>
  );
};

export default DraftsCommentsContent;
