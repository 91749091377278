import React, {FC} from 'react';

import {toggleView} from '@store/view/slice';

import {DatabaseIcon, GridIcon} from '@Common/Images';

import UseAppDispatch from '@hooks/UseAppDispatch';
import UseAppSelector from '@hooks/UseAppSelector';

import {ContentBoardMenuButton} from '../Menu';

const ContentBoardViewButton: FC = () => {
  const viewsIsTable = UseAppSelector(state => state.view.isTable);
  const dispatch = UseAppDispatch();

  const handleToggle = () => {
    dispatch(toggleView());
  };

  const gridSwitcherIcon = viewsIsTable ? <DatabaseIcon /> : <GridIcon />;

  return <ContentBoardMenuButton action={handleToggle} image={gridSwitcherIcon} />;
};

export default ContentBoardViewButton;
