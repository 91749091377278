import styled from 'styled-components';

import {useMutation} from '@tanstack/react-query';
import React, {useRef, useState} from 'react';

import {ProfileService} from '@services/profile';

import {StyledSmallLink} from '@components/DraftsItem/CheckList/Content/styles';

import {FilterButton} from '@Common/Buttons';
import {PlusIcon} from '@Common/Images';
import {StyledLink} from '@Common/Link';
import {Colors, TextHeader, TextParagraph} from '@Common/Text';

import {FileInput} from '../ModalComment/styles';
import ModalWrapper from '../Wrapper';

import {Documents} from './styles';

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

interface ModalProps {
  onClose: () => void;
}

const ImportModal = ({onClose}: ModalProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState('');
  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const mutation = useMutation({
    mutationFn: async (data: File) => {
      await ProfileService.import(data);
    },
    onSuccess: () => {
      onClose();
    },
    onError: (e: Error) => {
      setError(e.message);
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setSelectedFile(file);
  };

  const handleImport = () => {
    if (selectedFile) {
      mutation.mutate(selectedFile);
    }
  };

  return (
    <ModalWrapper setIsOpen={onClose}>
      <TextHeader align="center">Импорт анкет</TextHeader>
      <Documents onClick={openFileSelector}>
        {selectedFile ? (
          <StyledLink href={URL.createObjectURL(selectedFile)}>{selectedFile.name}</StyledLink>
        ) : (
          <TextParagraph color={Colors.textDark}>Выбрать файл</TextParagraph>
        )}

        <PlusIcon />
      </Documents>
      <FileInput ref={fileInputRef} onChange={handleFileChange} />

      <StyledSmallLink
        href={'https://copy.nextproject.online/static/docs/profile_import_example.csv'}>
        Пример CSV файла
      </StyledSmallLink>
      <TextParagraph color={Colors.grey}>
        Импортированные профили будут помечены как эксперты <br />
        Поиск дубликатов происходит по полю Email1 <br />
        Первая строка в файле будет пропущена
      </TextParagraph>

      <TextParagraph color={Colors.grey}>
        Формат: <br />
        Email 1, Email 2, ФИО, Компания, Отрасль, Должность, Телефон 1, Телефон 2, Телефон 3,
        Источник, Доп. инфо
      </TextParagraph>
      {error && <TextParagraph color={Colors.red}>{error}</TextParagraph>}
      <ButtonWrapper>
        <FilterButton width="150px" isBlue={false} title={'Импортировать'} action={handleImport} />
      </ButtonWrapper>
    </ModalWrapper>
  );
};

export default ImportModal;
