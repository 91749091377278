import styled from 'styled-components';

import {FlexColumnContainer} from '@Common/Utils';

const CardContainer = styled(FlexColumnContainer)`
  width: 400px;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: ${props => props.theme.borderRadius.full};
  border: ${props => props.theme.border};
  background: ${props => props.theme.colors.grayBackground};
  position: absolute;
  top: 71px;
  right: 23px;
  z-index: 20;
`;

export default CardContainer;
