import {NavLink} from 'react-router-dom';

import UserPicture from 'assets/images/user_picture.png';
import React from 'react';

import {StyledLink} from '@Common/Link';
import {Picture} from '@Common/Picture';
import {TextHeaderMedium, TextParagraph, TextSmall} from '@Common/Text';

import {Colors} from '@constants/enum';

import {formatDate} from '@utils/formatDate';

import {ICommentProjectStep} from 'types/state/ProjectStepList';

import {StyledComment, StyledReview} from './styles';

interface ICommentListItem {
  comment: ICommentProjectStep;
}

const CommentProjectStep = ({comment}: ICommentListItem) => {
  const {avatar, fio, email1, is_related, id} = comment.created_by;

  return (
    <StyledComment>
      <Picture variant={'sm'} src={avatar || UserPicture} alt={'Аватар'} />
      <StyledReview>
        {is_related ? (
          <NavLink to={`/profile/${id}`}>
            <TextHeaderMedium isLink>{fio || email1}</TextHeaderMedium>
          </NavLink>
        ) : (
          <TextHeaderMedium>{fio || email1}</TextHeaderMedium>
        )}
        <TextSmall>{formatDate(comment.created_at)}</TextSmall>
        <TextParagraph color={Colors.grey}>{comment.title}</TextParagraph>
        <div dangerouslySetInnerHTML={{__html: comment.body}} />
        {comment.attachments?.map(attachment => {
          const documentName = decodeURIComponent(attachment.split('/').pop() || '');
          return (
            <StyledLink key={attachment} href={attachment}>
              {documentName}
            </StyledLink>
          );
        })}
      </StyledReview>
    </StyledComment>
  );
};

export default CommentProjectStep;
