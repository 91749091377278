import styled from 'styled-components';

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 20px;
  background: #ffffff;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 32px 0 rgba(172, 172, 172, 0.25);

  max-width: 360px;
`;

export const LoginInput = styled.input<{$width?: string}>`
  padding: 12px 20px 12px 18px;
  font-size: 16px;
  width: ${({$width}) => `${$width || '320px'}`};

  outline: 0;
  border: 1px solid rgba(24, 24, 29, 0.14);
  border-radius: 8px;

  &:focus {
    outline: 1px #00c4ff solid;
  }
`;
