import styled from 'styled-components';

import React, {useState} from 'react';

import {StarFillIcon} from '@Common/Images';
import {RatingInput} from '@Common/Input';

const StyledMessage = styled.div`
  display: flex;
`;

const Label = styled.label<{$fixRatings?: number; $cur: number; $hover: number; $rating: number}>`
  display: flex;
  cursor: ${({$fixRatings}) => ($fixRatings ? 'default' : 'pointer')};
  width: 24px;
  height: 24px;

  align-items: center;
  & path {
    fill: ${({$cur, $hover, $rating, theme}) =>
      $cur <= ($hover || $rating) ? theme.colors.darkBlue : 'transparent'};
  }
`;

export interface IFixRating {
  fixRatings: number;
  rating?: never;
  setRating?: never;
}

export interface IRating {
  rating: number;
  fixRatings?: never;
  setRating: (rating: number) => void;
}

export type RatingProps = IFixRating | IRating;

const Rating = ({fixRatings, rating, setRating}: RatingProps) => {
  const [hover, setHover] = useState(0);
  const handleSetRating = (currentRating: number) => {
    if (setRating) {
      setRating(currentRating);
    }
  };

  const mouseEnter = (currentRating: number) => {
    if (!fixRatings) {
      setHover(currentRating);
    }
  };

  const mouseLeave = () => {
    if (!fixRatings) {
      setHover(0);
    }
  };
  return (
    <StyledMessage>
      {[...Array<number>(5)].map((_, index) => {
        const currentRating: number = index + 1;
        return (
          <Label
            $fixRatings={fixRatings}
            key={index}
            $cur={currentRating}
            $hover={hover}
            $rating={rating !== undefined ? rating : fixRatings}>
            <RatingInput currentRating={currentRating} action={handleSetRating} />
            <StarFillIcon
              onMouseEnter={() => mouseEnter(currentRating)}
              onMouseLeave={mouseLeave}
            />
          </Label>
        );
      })}
    </StyledMessage>
  );
};

export default Rating;
