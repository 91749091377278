import styled from 'styled-components';

export const StyledExpertButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  background: ${({theme}) => theme.colors.buttonBackground};
  border: ${({theme}) => `1px solid ${theme.colors.buttonBackground}`};
  border-radius: 0 0 10px 10px;
`;

export const StyledResultItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 10px;
`;

export const StyledContentRow = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};

  display: flex;
  flex-direction: column;

  align-items: start;
  gap: 10px;

  padding: 24px 30px;
  width: 100%;
`;

export const StyledLink = styled.a.attrs<{href: string}>(({href}) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  href: href,
}))`
  display: flex;
  margin: 0;

  word-break: break-word;

  color: ${({theme}) => theme.colors.accentBlue};
  font-weight: ${({theme}) => theme.fontWeight.normal};
  font-size: ${({theme}) => theme.fontSize.small};
`;
