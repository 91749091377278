import {styled} from 'styled-components';

import {acceptFilesWithImage} from '@constants/regex';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 10px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 50%;
`;

export const FileInput = styled.input.attrs({
  type: 'file',
  accept: acceptFilesWithImage,
})`
  display: none;
`;

export const Documents = styled.div<{$isDragging: boolean}>`
  border-radius: ${({theme}) => theme.borderRadius.full};
  border: ${({theme, $isDragging}) =>
    $isDragging ? `1px solid ${theme.colors.accentBlue}` : `1px solid ${theme.colors.greyCircuit}`};
  background: ${({theme}) => theme.colors.white};

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-width: 650px;
  min-height: 50px;
  padding: 10px;
  cursor: pointer;
`;

export const FilesStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;
