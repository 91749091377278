import React, {FC, useState} from 'react';

import {DraftsItemWrapper} from '@components/Wrappers/DraftsItem';

import {PanelHeader} from '@Common/DraftsItem/Panel';
import {TubeIcon} from '@Common/Images';

import UseAppSelector from '@hooks/UseAppSelector';

import DraftsExpertiseContent from './Content';

const DraftsItemExpertise: FC = () => {
  const {is_expert} = UseAppSelector(state => state.user.data);
  const [isShownContent, setIsShownContent] = useState(false);
  const handleShow = () => {
    setIsShownContent(prev => !prev);
  };

  return (
    <DraftsItemWrapper isRed={is_expert}>
      <PanelHeader
        isShownContent={isShownContent}
        icon={<TubeIcon />}
        title={'Экспертиза'}
        action={handleShow}
      />
      {isShownContent && <DraftsExpertiseContent />}
    </DraftsItemWrapper>
  );
};

export default DraftsItemExpertise;
