import styled from 'styled-components';

import React, {FC} from 'react';

import {FlexColumnContainer} from '@Common/Utils';

const StyledDraftsItemWrapper = styled(FlexColumnContainer)<{$isHalf?: boolean; $isRed?: boolean}>`
  width: ${({$isHalf}) => ($isHalf ? '50%' : '100% ')};
  padding: 40px 25px;
  justify-content: center;
  align-items: start;
  gap: 20px;
  border: ${({$isRed}) => ($isRed ? '1px solid red' : '1px solid transparent')};
  border-color: ${({$isRed}) => ($isRed ? 'red' : 'transparent')};
  border-radius: ${({theme}) => theme.borderRadius.full};
  background: ${({theme}) => theme.colors.white};
  box-shadow: ${({theme}) => `0px 0px 32px  ${theme.colors.greyCircuit}`};
`;

const DraftsItemWrapper: FC<{children: React.ReactNode; isHalf?: boolean; isRed?: boolean}> = ({
  children,
  isHalf = false,
  isRed,
}) => {
  return (
    <StyledDraftsItemWrapper $isRed={isRed} $isHalf={isHalf}>
      {children}
    </StyledDraftsItemWrapper>
  );
};

export default DraftsItemWrapper;
