import {ISupportState} from 'types/state/Support';

const initialState: ISupportState = {
  sortDirection: 'up',
  value: [
    {
      id: 1,
      supportTool: 'Инструмент 1',
    },
    {
      id: 2,
      supportTool: 'Инструмент 2',
    },
    {
      id: 3,
      supportTool: 'Инструмент 5',
    },
    {
      id: 4,
      supportTool: 'инструмент 4',
    },
  ],
};

export default initialState;
