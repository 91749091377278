import styled from 'styled-components';

import {NavLink} from 'react-router-dom';

import React from 'react';

import {TextHeaderMedium} from '@Common/Text';

import UseCheckProfile from '@hooks/UseCheckProfile';

import {IMemberProject} from 'types/state/Profiles';

const ProjectItemLinkStyled = styled(NavLink)`
  display: flex;
  padding: 7px;
  width: 100%;
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  color: ${({theme}) => theme.colors.textDark};
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
  &:hover {
    background-color: ${({theme}) => theme.colors.greyCircuit};
  }
`;

const ProjectItemStyled = styled.div`
  display: flex;
  padding: 7px;
  width: 100%;
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  color: ${({theme}) => theme.colors.textDark};
  border-radius: 10px;
`;

interface MemberInItemProps {
  data?: IMemberProject[];
  title: string;
}

const MemberInItem = ({data, title}: MemberInItemProps) => {
  const {isEditableProfile} = UseCheckProfile();
  if (data?.length === 0) return null;

  return (
    <>
      <TextHeaderMedium>{title} в</TextHeaderMedium>
      {data?.map(item => {
        return isEditableProfile ? (
          <ProjectItemLinkStyled
            key={item.id}
            to={item.in_work ? `/project/${item.id}` : `/draft/${item.id}`}>
            {item.name}
          </ProjectItemLinkStyled>
        ) : (
          <ProjectItemStyled key={item.id}>{item.name}</ProjectItemStyled>
        );
      })}
    </>
  );
};

export default MemberInItem;
