import styled from 'styled-components';

import React, {FC} from 'react';

import {EditIcon, SaveDisk} from '@Common/Images';
import Loader from '@Common/Loader';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {DropdownWithScrollBar, MultiplyDropdown} from './WithScrollbar';

interface IBaseProps {
  nameList: string[];
  isEditing: boolean;
  handleEditClick: () => void;
  title: string;
  notEdit?: boolean;
  isTags?: boolean;
  isLoading?: boolean;
}

interface IEditableMultiplyDropdownProps extends IBaseProps {
  selectedMultiply: string[];
  setSelectedMultiply: (labels: string[]) => void;
  setSelected?: never;
  selected?: never;
}

interface IEditableDropdownProps extends IBaseProps {
  setSelectedMultiply?: never;
  selectedMultiply?: never;
  setSelected: (labels: string) => void;
  selected: string;
}

export type EditableDropdownType = IEditableMultiplyDropdownProps | IEditableDropdownProps;

const EditableDropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  /* max-width: 312px; */
`;

const Button = styled.button`
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  & path {
    fill: ${({theme}) => theme.colors.grey};
  }
`;

const EditableDropdown: FC<EditableDropdownType> = ({
  nameList,
  isEditing,
  isTags,
  handleEditClick,
  title,
  selected,
  setSelected,
  selectedMultiply,
  setSelectedMultiply,
  isLoading = false,
}) => {
  return (
    <EditableDropdownWrapper>
      <>
        {selectedMultiply &&
          (isEditing ? (
            isLoading ? (
              <Loader />
            ) : (
              <MultiplyDropdown
                isTags={isTags}
                setSelected={setSelectedMultiply}
                selected={selectedMultiply}
                width={225}
                labels={nameList}
                title={title}
              />
            )
          ) : (
            <div></div>
          ))}

        {selected &&
          (isEditing ? (
            isLoading ? (
              <Loader />
            ) : (
              <DropdownWithScrollBar
                setSelected={setSelected}
                selected={selected}
                width={225}
                labels={nameList}
                title={title}
              />
            )
          ) : (
            <TextParagraph color={Colors.textDark}>{title}</TextParagraph>
          ))}
      </>

      <Button onClick={handleEditClick}>{isEditing ? <SaveDisk /> : <EditIcon />}</Button>
    </EditableDropdownWrapper>
  );
};

export default EditableDropdown;
