import {styled} from 'styled-components';

export const StyledAddButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;
