import styled from 'styled-components';

import React, {FC} from 'react';

const StyledSideBarPanelTitle = styled.div`
  color: ${props => props.theme.colors.grayTextOnDark};
  font-size: 16px;
  padding: 24px 0 22px 0;
`;

const SideBarPanelTitle: FC<{children?: React.ReactNode; title: string}> = ({title}) => {
  return (
    <StyledSideBarPanelTitle>
      <span>{title}</span>
    </StyledSideBarPanelTitle>
  );
};

export default SideBarPanelTitle;
