import styled from 'styled-components';

import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {useEffect, useState} from 'react';

import {ProjectRouterCheckListService} from '@services/projectRouterCheckList';

import {MenuButtonCheckList} from '@components/CheckListCreate';
import Accelerator from '@components/CheckListCreate/Accelerator';
import ConceptProof from '@components/CheckListCreate/ConceptProof';
import HypothesisProof from '@components/CheckListCreate/HypothesisProof';
import InvestmentProject from '@components/CheckListCreate/InvestmentProject';
import Scaling from '@components/CheckListCreate/Scaling';
import {ContentBoardMenu} from '@components/Menu';

import {FilterButton} from '@Common/Buttons';
import Loader from '@Common/Loader';
import AcceptModal from '@Common/Modal/Accept';
import ChecklistCreateAlertModal from '@Common/Modal/ChecklistCreate';
import StatusChecker from '@Common/StatusChecker';
import {Colors, StyledText} from '@Common/Text';

import UseDraftData from '@hooks/UseDraft';
import {UseNavigationList} from '@hooks/UseNavigationList';

import {IRequestProjectRouterCheckListItem} from 'types/state/ProjectRouterCheckList';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledContentWrapper = styled.div`
  border-radius: ${({theme}) => theme.borderRadius.full};
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  background: ${({theme}) => theme.colors.white};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -1px;
`;

const StyledWrapper = styled.div`
  border: none;
  background: ${({theme}) => theme.colors.white};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledMenu = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  & > :last-child {
    border-right: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  }
  z-index: 1000;
`;

export const SaveButtonWrapper = styled.div`
  padding: 20px;
  display: flex;
`;

const StyledError = styled(StyledText)`
  padding: 10px 20px;
`;

const panelButtons = {
  '0': 'АКСЕЛЕРАТОР',
  '1': 'МАСШТАБИРОВАНИЕ',
  '2': 'ИНВЕСТИЦИОННЫЙ ПРОЕКТ',
  '3': 'РАЗРАБОТКА КОНЦЕПЦИИ',
  '4': 'СОЗДАНИЕ ГИПОТЕЗЫ',
};

const CheckListCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const pageId = Number(useParams().id);
  const {data, isErrorDraft, page, isLoadingDraft} = UseDraftData(pageId);
  const [requestData, setRequestData] = useState<IRequestProjectRouterCheckListItem>({});
  const currentLocation = useLocation().pathname;

  const [isModalShown, setIsModalShown] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [isError, setIsError] = useState(false);

  const [active, setActive] = useState(0);

  const navigationList = [
    {path: '/draft', title: 'Заявки'},
    {path: page ? `${page}/${pageId}` : '/', title: data?.name || 'Loading...'},
    {path: currentLocation, title: 'Маршрутизатор'},
  ];

  UseNavigationList(navigationList);

  const checkError = () => {
    if (Object.values(requestData).some(value => value === null)) {
      setIsError(true);
    } else {
      setIsModalShown(true);
    }
  };

  useEffect(() => {
    setIsError(false);
  }, [active]);

  const mutation = useMutation({
    mutationFn: async (data: IRequestProjectRouterCheckListItem) => {
      await ProjectRouterCheckListService.add(data);
      setAlertShown(true);
    },

    onSuccess: () => {
      void queryClient.invalidateQueries(['project-router-checklist', pageId]);
    },
  });

  const save = async () => {
    const data: IRequestProjectRouterCheckListItem = {
      ...requestData,
      router: 1,
      project: pageId,
    };

    setIsModalShown(false);
    await mutation.mutateAsync(data);
  };

  // const onUndo = () => {
  //   active > 1 && setActive(prev => prev - 1);
  // };

  if (!data) return <Loader />;
  return (
    <StyledContent>
      <ContentBoardMenu pageTitle={`Маршрутизатор/${data.name}`} />
      <StyledWrapper>
        <StyledMenu>
          <MenuButtonCheckList
            title={panelButtons[active.toString() as keyof typeof panelButtons]}
            tooltip={
              active === 0
                ? 'Подробнее о действующих программах акселерации Вы можете узнать в разделе "Об ИнноХаб" в главном меню платформы. После изучения Вы можете подать заявку в соответствующий акселератор через кнопку "Да" в данном разделе'
                : ''
            }
          />
          {/* {active > 1 && <FilterButton width="100px" action={onUndo} isBlue={true} title="Назад" />} */}
        </StyledMenu>
        <StyledContentWrapper>
          <StatusChecker loadingsArray={[isLoadingDraft]} errorsArray={[isErrorDraft]}>
            {active === 0 && <Accelerator setActive={() => setActive(1)} />}
            {active === 1 && (
              <Scaling setRequestData={setRequestData} setActive={() => setActive(2)} />
            )}
            {active === 2 && (
              <InvestmentProject setRequestData={setRequestData} setActive={() => setActive(3)} />
            )}
            {active === 3 && (
              <ConceptProof setRequestData={setRequestData} setActive={() => setActive(4)} />
            )}
            {active === 4 && <HypothesisProof setRequestData={setRequestData} />}
            {isError && (
              <StyledError $color={Colors.red}>Вы ответили не на все вопросы</StyledError>
            )}
            {active !== 0 && (
              <SaveButtonWrapper>
                <FilterButton action={checkError} isBlue={true} title="Готово" />
              </SaveButtonWrapper>
            )}
          </StatusChecker>
        </StyledContentWrapper>
      </StyledWrapper>

      {isModalShown && <AcceptModal handleYes={save} setIsOpen={setIsModalShown} />}

      {alertShown && (
        <ChecklistCreateAlertModal
          stage={panelButtons[active.toString() as keyof typeof panelButtons]}
          setIsOpen={setAlertShown}
          handleYes={() => navigate(`/${page}/${pageId}`)}
        />
      )}
    </StyledContent>
  );
};

export default CheckListCreate;
