import React from 'react';

const FilterGIcon = () => {
  return (
    <svg className="a-s-fa-Ha-pa c-qd" height="17px" viewBox="2 4 20 17" width="17px">
      <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
    </svg>
  );
};

export default FilterGIcon;
