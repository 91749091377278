import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {useEffect, useState} from 'react';

import {ProjectTaskService} from '@services/projectTask';

import {IPostProjectTask} from 'types/state/ProjectTask';

import {FilterButton} from './Buttons';
import DateTimePicker from './DateTimePicker';
import {CrossIcon} from './Images';
import {Colors, TextHeader, TextParagraph} from './Text';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 50%;
`;

const StyledTextareaInput = styled.textarea<{$height?: number | null}>`
  color: var(--grey, #51575f);
  font-size: 16px;
  border: 0;
  outline: 0;
  width: 100%;

  resize: none;
  overflow-y: hidden;
  min-height: 250px;
  height: ${({$height}) => ($height ? `${$height}px` : '30px')};
`;

const StyledInputWrapper = styled.div<{$isNotField?: boolean}>`
  display: flex;
  padding: 5px 5px;
  align-items: center;
  width: 100%;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid ${({theme, $isNotField}) => ($isNotField ? 'red' : theme.colors.greyCircuit)};
  background: #fff;

  height: auto;
`;

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalTask = ({isOpen, onClose}: ModalProps) => {
  const queryClient = useQueryClient();
  const pageId = Number(useParams().id);
  const [error, setError] = useState(false);
  const [value, setValue] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    setValue('');
    setSelectedDate(null);
    setError(false);
  }, [isOpen]);
  const mutationGoals = useMutation({
    mutationFn: (data: IPostProjectTask) => ProjectTaskService.create(data),
    onSuccess: () => {
      void queryClient.invalidateQueries(['projecttask']);
    },
  });

  const handlTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const createGoal = async () => {
    if (value && selectedDate) {
      const formattedDate = selectedDate.toISOString();

      await mutationGoals.mutateAsync({
        project: pageId,
        description: value,
        will_done: formattedDate,
      });

      onClose();
    } else {
      setError(true);
    }
  };
  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Overlay onClick={onClose}>
        <ModalWrapper
          onClick={e => {
            e.stopPropagation();
          }}>
          <CloseButton onClick={onClose}>
            <CrossIcon />
          </CloseButton>
          <TextHeader>Добавление цели/задачи</TextHeader>

          <StyledInputWrapper>
            <StyledTextareaInput value={value} onChange={handlTextareaChange} />
          </StyledInputWrapper>

          <TextParagraph color={Colors.textDark}>Плановая дата *</TextParagraph>
          <DateTimePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

          {error && (
            <TextParagraph color={Colors.red}>Описание или дата не добавлены</TextParagraph>
          )}

          <ButtonWrapper>
            <FilterButton isBlue={false} title={'Сохранить'} action={createGoal} />
          </ButtonWrapper>
        </ModalWrapper>
      </Overlay>
    </>
  );
};

export default ModalTask;
