import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import React from 'react';

import {TextHeaderMedium} from '@Common/Text';

import UseMutationDraft from '@hooks/UseMutationDraft';

import {IDraftsListItem} from 'types/state/Drafts';

import ToggleStatus from './ToggleStatus';

const StyledContent = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
`;

const StyledStatusRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;
`;

type Props = {
  data: IDraftsListItem;
};

const ProjectStatusContent = ({data}: Props) => {
  const pageId = Number(useParams().id);
  const mutation = UseMutationDraft(pageId);

  const onSave = (field: keyof IDraftsListItem, value: boolean) => {
    mutation.mutate({
      [field]: value,
    });
  };

  return (
    <StyledContent>
      <StyledStatusRow>
        <TextHeaderMedium>Наличие бизнес-заказчика</TextHeaderMedium>
        <TextHeaderMedium>Наличие внутренней экспертизы</TextHeaderMedium>
        <TextHeaderMedium>Наличие внешней экспертизы</TextHeaderMedium>
      </StyledStatusRow>
      <StyledStatusRow>
        <ToggleStatus
          handleSave={value => onSave('status_customer', value)}
          status={data?.status_customer}
        />
        <ToggleStatus
          handleSave={value => onSave('status_expert_feedback', value)}
          status={data?.status_expert_feedback}
        />
        <ToggleStatus
          handleSave={value => onSave('status_indep_expert_feedback', value)}
          status={data?.status_indep_expert_feedback}
        />
      </StyledStatusRow>
    </StyledContent>
  );
};

export default ProjectStatusContent;
