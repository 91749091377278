import ProfilesTable from 'components/Tables/Profiles';
import React, {useState} from 'react';

import {ContentBoardMenu} from '@components/Menu';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {DownloadImageComponent, PlusImageComponent} from '@Common/Images';
import ImportModal from '@Common/Modal/Import';
import RegistrationModal from '@Common/Modal/Registration';

import {UseNavigationList} from '@hooks/UseNavigationList';

const Profiles = () => {
  UseNavigationList([
    {path: '/profile', title: 'Профили'},
    {path: '/profile', title: 'Список'},
  ]);

  const [isRegistrationModalShown, setIsRegistrationModalShown] = useState(false);
  const [importModal, setImportModal] = useState(false);
  return (
    <>
      <ContentBoardMenu pageTitle={'Профили'}>
        <ContentBoardMenuButton
          title={'Добавить'}
          action={() => setIsRegistrationModalShown(true)}
          image={PlusImageComponent}
        />

        <ContentBoardMenuButton
          title={'Импортировать'}
          action={() => setImportModal(true)}
          image={DownloadImageComponent}
        />
      </ContentBoardMenu>

      <ProfilesTable />
      {isRegistrationModalShown && (
        <RegistrationModal onClose={() => setIsRegistrationModalShown(false)} />
      )}

      {importModal && <ImportModal onClose={() => setImportModal(false)} />}
    </>
  );
};

export default Profiles;
