import styled from 'styled-components';

import {TableRowStyled} from 'components/Common/Table/Row';

const TableRow = styled(TableRowStyled)`
  grid-template-columns: 40% 45%;
  gap: 50px;
`;

const TableCell = styled.div`
  display: flex;
  gap: 7px;
`;

export {TableCell, TableRow};
