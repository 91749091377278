import {instance} from 'api';

import {IApiResponse} from 'types/services';
import {IWorkflowsItem} from 'types/workflows';

export const workflowsService = {
  async getAll() {
    return (await instance.get<IApiResponse<IWorkflowsItem>>('/workflow/')).data.results;
  },

  async geByAccelerator(acceleratorId: string) {
    return (
      await instance.get<IApiResponse<IWorkflowsItem>>(`/workflow/?accelerators=${acceleratorId}`)
    ).data.results;
  },
};
