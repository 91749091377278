import styled from 'styled-components';

import React, {FC} from 'react';

const StyledDropdownSmallWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownWrapper: FC<{children: React.ReactNode}> = ({children}) => {
  return <StyledDropdownSmallWrapper>{children}</StyledDropdownSmallWrapper>;
};

export default DropdownWrapper;
