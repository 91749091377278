import {useQuery} from '@tanstack/react-query';
import {CompanyService} from 'services/company';

const UseCompany = (value = '') => {
  const fetchCompanyData = async () => {
    return await CompanyService.getById(value);
  };

  const {
    data: company,
    isLoading: isLoadingCompany,
    isError: isErrorCompany,
  } = useQuery({
    queryKey: ['companies', value],
    queryFn: fetchCompanyData,
    retry: false,
  });

  return {
    company: company,
    isLoadingCompany,
    isErrorCompany,
  };
};

export default UseCompany;
