import {ContentBoardMenuEnum} from 'constants/enum/ContentBoardMenu';

import {IProfilesState} from 'types/state/Profiles';

const initialState: IProfilesState = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  isLoading: true,
  views: ContentBoardMenuEnum.cards,
};

export default initialState;
