import React, {FC, useState} from 'react';

import {DraftsItemWrapper} from '@components/Wrappers/DraftsItem';

import {PanelHeader} from '@Common/DraftsItem/Panel';
import {InfoIcon} from '@Common/Images';

import TechRequestResponsesContent from './Content';

const TechRequestItemResponses: FC<{techRequestId: number}> = ({techRequestId}) => {
  const [isShownContent, setIsShownContent] = useState(false);

  const handleShow = () => {
    setIsShownContent(prev => !prev);
  };

  return (
    <DraftsItemWrapper isHalf={true}>
      <PanelHeader
        isShownContent={isShownContent}
        icon={<InfoIcon />}
        title={'Отклики'}
        action={handleShow}
      />
      {isShownContent && techRequestId && (
        <TechRequestResponsesContent techRequestId={techRequestId} />
      )}
    </DraftsItemWrapper>
  );
};

export default TechRequestItemResponses;
