import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import React, {useState} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {ContentBoardMenu} from '@components/Menu';
import TechRequestItemInformation from '@components/TechRequestItem/Information';
import TechRequestItemResponses from '@components/TechRequestItem/Responses';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {PlusImageComponent, TrashIcon} from '@Common/Images';
import Loader from '@Common/Loader';
import TechRequestResponseModal from '@Common/Modal/TechRequestResponseModal';
import TextModal from '@Common/Modal/Text';

import UseMutationTechRequest from '@hooks/UseMutationTechRequest';
import {UseNavigationList} from '@hooks/UseNavigationList';
import UseTechRequest from '@hooks/UseTechRequest';

import {renameStatus} from '@utils/renameStatus';

import {IUser} from 'types/User';

const InformationContainer = styled.div`
  display: flex;
  align-items: start;
  padding: 0;
  border-radius: 0 0 10px 10px;
  background: white;
  flex-grow: 1;
  gap: 16px;
`;

const DraftContentWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const TechRequest = ({user}: {user: IUser}) => {
  const pageId = Number(useParams().id);
  const {is_supervisor, is_customer, is_project, is_superuser} = user;
  const [isOpen, setIsOpen] = useState(false);
  const [shownModal, setShownModal] = useState(false);

  const {data, isErrorTechRequest, isLoadingTechRequest} = UseTechRequest(pageId);

  UseNavigationList([
    {path: '/tech-requests', title: 'Тех. Запросы'},
    {path: window.location.pathname, title: data?.name || 'Loading...'},
  ]);

  const mutation = UseMutationTechRequest(pageId);

  const updateToOpen = () => {
    mutation.mutate({
      status: 'open',
    });
  };

  const updateToDeclined = (comment: string) => {
    mutation.mutate({
      status: 'declined',
      comment,
    });
  };

  const updateToHired = () => {
    mutation.mutate({
      status: 'hired',
    });
  };

  const updateToFinished = () => {
    mutation.mutate({
      status: 'finished',
    });
  };

  const handleArchived = () => {
    mutation.mutate({
      archived: true,
    });
  };

  const handleRequest = () => {
    setIsOpen(true);
  };

  if (isErrorTechRequest) return <div>Произошла ошибка</div>;
  if (isLoadingTechRequest || !data) return <Loader />;

  return (
    <DraftContentWrapper>
      <ContentBoardMenu pageTitle={data.name}>
        {(is_customer || is_superuser) && !data.archived ? (
          <ContentBoardMenuButton title={'Удалить'} action={handleArchived} image={<TrashIcon />} />
        ) : (
          is_supervisor &&
          data.status === 'declined' && (
            <ContentBoardMenuButton
              title={'Удалить'}
              action={handleArchived}
              image={<TrashIcon />}
            />
          )
        )}
        {is_project && (
          <ContentBoardMenuButton
            title={'Стать исполнителем'}
            action={handleRequest}
            image={PlusImageComponent}
          />
        )}
        {is_supervisor && data.status === 'draft' && (
          <ContentBoardMenuButton
            title={'Согласовать'}
            action={updateToOpen}
            image={PlusImageComponent}
          />
        )}
        {is_supervisor && data.status === 'draft' && (
          <ContentBoardMenuButton
            title={'Отклонить на корректировку'}
            action={() => setShownModal(true)}
            image={PlusImageComponent}
          />
        )}
        {is_supervisor && data.status === 'open' && (
          <ContentBoardMenuButton
            title={'Исполнитель найден'}
            action={updateToHired}
            image={PlusImageComponent}
          />
        )}
        {is_supervisor && data.status === 'hired' && (
          <ContentBoardMenuButton
            title={'Завершен'}
            action={updateToFinished}
            image={PlusImageComponent}
          />
        )}
        <p>Статус: {renameStatus(data.status) || '-'}</p>
      </ContentBoardMenu>
      <InformationContainer>
        <TechRequestItemInformation data={data} />
        <TechRequestItemResponses techRequestId={data.id} />
      </InformationContainer>

      {isOpen && (
        <TechRequestResponseModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          projectId={data.id}
          projectName={data.name}
        />
      )}
      {shownModal && (
        <TextModal
          title="Укажите причину отклонения Тех. запроса"
          onSubmit={updateToDeclined}
          setIsOpen={setShownModal}
        />
      )}
    </DraftContentWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(TechRequest);
