import styled from 'styled-components';

import React from 'react';
import {Tooltip} from 'react-tooltip';

import 'react-tooltip/dist/react-tooltip.css';
import {ITooltipProps} from './types';

const TooltipStyles = styled.div<{$big: boolean}>`
  display: flex;

  & svg {
    width: ${props => (props.$big ? '40' : '20')}px;
    height: ${props => (props.$big ? '40' : '20')}px;
    padding: 3px;
  }
`;

const TooltipCustom = ({children, content, big = false}: ITooltipProps) => {
  if (!content) return <>{children}</>;

  const id = content?.length >= 25 ? content?.substring(0, 25) : content;
  return (
    <>
      <TooltipStyles $big={big} data-tooltip-id={`my-tooltip ${id}`} data-tooltip-content={content}>
        {children}
      </TooltipStyles>

      <Tooltip
        id={`my-tooltip ${id}`}
        opacity={1}
        style={{
          borderRadius: 0,
          fontSize: '16px',
          maxWidth: '500px',
          height: 'auto',
          zIndex: '1000',
        }}
      />
    </>
  );
};

export default TooltipCustom;
