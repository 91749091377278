import styled from 'styled-components';

import React, {ReactNode} from 'react';

import {Colors, TextParagraph} from '@Common/Text';

const ContactStyled = styled.div`
  display: flex;
  gap: 5px;
  svg {
    width: 20px;
    height: 20px;
  }
  svg g path {
    fill: #0f1120;
  }
`;

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ContentRowsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

interface IContactProps {
  title: string;
  data: (string | null)[];
  icon: ReactNode;
}

const ProfileInfoContact = ({title, data, icon}: IContactProps) => {
  return (
    <ContactStyled>
      {icon}
      <ContentStyled>
        <TextParagraph color={Colors.textDark}>{title}</TextParagraph>
        <ContentRowsStyled>
          {data.map((item, index) => {
            if (!item) return null;
            return (
              <TextParagraph key={`${item} + ${index}`} color={Colors.grey}>
                {item}
              </TextParagraph>
            );
          })}
        </ContentRowsStyled>
      </ContentStyled>
    </ContactStyled>
  );
};

export default ProfileInfoContact;
