import {NavLink} from 'react-router-dom';

import ProjectsTable from 'components/Tables/Projects';
import React from 'react';
import {connect} from 'react-redux';
import {IFiltersState} from 'store/filters/types';

import {selectFilters} from '@store/filters/selectors';
import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {ContentBoardMenu} from '@components/Menu';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {PlusImageComponent, UploadIcon} from '@Common/Images';
import TooltipCustom from '@Common/Tooltip';

import {UseNavigationList} from '@hooks/UseNavigationList';

import {handleSubmitExport} from '@utils/handleExportProject';

import {IUser} from 'types/User';

type StateProps = {
  user: IUser;
  filters: IFiltersState;
};

const Projects = ({user, filters}: StateProps) => {
  const {is_project, is_customer, is_expert} = user;

  const {
    tracker,
    supervisor,
    routerStage,
    projectArea,
    isArchived,
    isInternal,
    accelerator,
    isFinished,
  } = filters;

  UseNavigationList([
    {path: '/project', title: is_project ? 'Мои проекты' : 'Проекты'},
    {path: '/project', title: 'Список'},
  ]);

  return (
    <>
      <ContentBoardMenu pageTitle={is_project ? 'Мои проекты' : 'Проекты'}>
        {!is_project && (
          <TooltipCustom
            content={'Экспортировать данные по всем Вашим заявкам в формате .zip (архив)'}>
            <ContentBoardMenuButton
              title={'Экспортировать'}
              action={() =>
                handleSubmitExport({
                  tracker,
                  projectArea,
                  isArchived,
                  isInternal,
                  accelerator,
                  supervisor,
                  routerStage,
                  isFinished,
                  in_work: true,
                })
              }
              image={<UploadIcon />}
            />
          </TooltipCustom>
        )}
        {!(is_customer || is_expert || is_project) && (
          <NavLink to={'/draft/create'}>
            <ContentBoardMenuButton
              title={'Добавить проект'}
              action={() => {}}
              image={PlusImageComponent}
            />
          </NavLink>
        )}
      </ContentBoardMenu>

      <ProjectsTable />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
  filters: selectFilters(state),
});

export default connect(mapStateToProps)(Projects);
