import styled from 'styled-components';

import React, {useEffect, useState} from 'react';

import {IAcceleratorAnswer, IProjectRouterCheckListItem} from 'types/state/ProjectRouterCheckList';

import MenuItem from './item';

const MenuWrapper = styled.div`
  padding: 0 5px;
  display: flex;
  justify-content: start;
  top: 100px;
`;
const constTitles = [
  'Акселератор',
  'Масштабирование',
  'Инвестиционный проект',
  'Разработка концепции',
  'Создание гипотезы',
];

interface ICheckListContentMenu {
  active: string;
  setActive: (title: string) => void;
  checkList: IProjectRouterCheckListItem | null | undefined;
  answers: IAcceleratorAnswer[] | undefined;
}
const CheckListContentMenu = ({active, setActive, answers, checkList}: ICheckListContentMenu) => {
  const [titles, setTitles] = useState([...constTitles]);

  useEffect(() => {
    if (!checkList) return;
    const tempArray = [...constTitles];

    if (!answers?.length) {
      tempArray.splice(tempArray.indexOf(constTitles[0]), 1);
    }
    if (checkList.quiz1 === null && checkList.quiz2 === null && checkList.quiz3 === null) {
      tempArray.splice(tempArray.indexOf(constTitles[1]), 1);
    }
    if (
      checkList.quiz4 === null &&
      checkList.quiz5 === null &&
      checkList.quiz6 === null &&
      checkList.quiz7 === null &&
      checkList.quiz8 === null &&
      checkList.quiz9 === null &&
      checkList.quiz10 === null &&
      checkList.quiz11 === null &&
      checkList.quiz12 === null
    ) {
      tempArray.splice(tempArray.indexOf(constTitles[2]), 1);
    }
    if (
      checkList.quiz13 === null &&
      checkList.quiz14 === null &&
      checkList.quiz15 === null &&
      checkList.quiz16 === null &&
      checkList.quiz17 === null &&
      checkList.quiz18 === null &&
      checkList.quiz19 === null &&
      checkList.quiz20 === null
    ) {
      tempArray.splice(tempArray.indexOf(constTitles[3]), 1);
    }
    if (
      checkList.quiz21 === null &&
      checkList.quiz22 === null &&
      checkList.quiz23 === null &&
      checkList.quiz24 === null
    ) {
      tempArray.splice(tempArray.indexOf(constTitles[4]), 1);
    }

    setTitles(tempArray);
  }, [answers, checkList]);

  return (
    <MenuWrapper>
      {titles.map(title => (
        <MenuItem key={title} active={active} setActive={setActive} title={title} />
      ))}
    </MenuWrapper>
  );
};

export default CheckListContentMenu;
