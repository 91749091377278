import styled from 'styled-components';

import React, {FC} from 'react';

import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

const StyledNoActivity = styled.div`
  border-radius: ${({theme}) => theme.borderRadius.full};
  background-color: ${({theme}) => theme.colors.greyCircuit};
  display: flex;

  padding: 10px;
  width: 100%;
`;

const NoActivity: FC = () => {
  return (
    <StyledNoActivity>
      <TextParagraph color={Colors.grey}>Нет активности, напишите комментарий</TextParagraph>
    </StyledNoActivity>
  );
};

export default NoActivity;
