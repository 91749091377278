export function sortArray<T>(
  array: Array<T>,
  payload: {sortableField: keyof T; sortDirection: string},
) {
  array.sort(function (a, b) {
    const firstItem = (a[payload.sortableField] as string)?.toUpperCase();
    const secondItem = (b[payload.sortableField] as string)?.toUpperCase();
    if (payload.sortDirection === 'up') {
      if (firstItem < secondItem) {
        return -1;
      } else if (firstItem > secondItem) {
        return 1;
      } else return 0;
    } else {
      if (firstItem > secondItem) {
        return -1;
      } else if (firstItem < secondItem) {
        return 1;
      } else return 0;
    }
  });
}

//commit
