import React, {useRef, useState} from 'react';

import {FilterButton} from '@Common/Buttons';
import CommentEditor from '@Common/CommentEditor';
import {PlusIcon} from '@Common/Images';
import {StyledLink} from '@Common/Link';
import ModalWrapper from '@Common/Modal/Wrapper';
import {Colors, TextHeader, TextParagraph} from '@Common/Text';

import {ICommentStep} from 'types/state/ProjectStepList';

import {ButtonWrapper, Documents, FileInput, FilesStyles} from './styles';

interface ModalProps {
  onClose: () => void;
  onSave: (data: ICommentStep) => void;
}

const AcceleratorCommentModal = ({onClose, onSave}: ModalProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState(false);
  const [text, setText] = useState<string>('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      setAttachments(Array.from(files));
    }
  };

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;

    if (files) {
      setAttachments(Array.from(files));
    }
    setIsDragging(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleSave = () => {
    if (text) {
      onSave({text, attachments});
      onClose();
    } else {
      setError(true);
    }
  };

  return (
    <ModalWrapper setIsOpen={() => onClose()}>
      <TextHeader>Добавление комментария</TextHeader>

      <CommentEditor value={text} setValue={setText} />
      <Documents
        $isDragging={isDragging}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={openFileSelector}>
        <FilesStyles>
          {attachments.map(file => (
            <StyledLink key={file.name} href={URL.createObjectURL(file)}>
              {file.name}
            </StyledLink>
          ))}
        </FilesStyles>
        <PlusIcon />
      </Documents>
      <FileInput multiple ref={fileInputRef} onChange={handleFileChange} />

      {error && <TextParagraph color={Colors.red}>Комментарий не добавлен</TextParagraph>}

      <ButtonWrapper>
        <FilterButton isBlue={false} title={'Отправить'} action={handleSave} />
      </ButtonWrapper>
    </ModalWrapper>
  );
};

export default AcceleratorCommentModal;
