import styled from 'styled-components';

import {Dispatch, bindActionCreators} from '@reduxjs/toolkit';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {selectFilters} from '@store/filters/selectors';
import {
  clearFilters,
  setAccelerator,
  setAcceleratorRequest,
  setExpertiseRequired,
  setIsArchived,
  setIsFinished,
  setIsInternal,
  setLastLogin,
  setPage,
  setProjectArea,
  setRole,
  setRouterStage,
  setSearch,
  setSupervisor,
  setCreatedBy,
  setTracker,
  setAnswerRequired,
  setTechRequestStatus,
  setScoringStatus,
  setFilterByUpdate,
} from '@store/filters/slice';
import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {PaginationButtons} from '@components/Menu';

import FiltersPanelDropdown from '@Common/Dropdown/FilterPanel';
import SearchInput from '@Common/Input/Search';
import StatusChecker from '@Common/StatusChecker';

import UseAccelerators from '@hooks/UseAccelerators';
import UseCustomersAll from '@hooks/UseCustomersAll';
import UseProjectArea from '@hooks/UseProjectArea';
import UseSupervisors from '@hooks/UseSupervisors';
import UseTrackers from '@hooks/UseTrackers';

import {
  filterByUpdateLabels,
  isAnswerRequired,
  isArchivedLabels,
  isExpertiseRequired,
  isFinishedLabels,
  isInternalLabels,
  lastLoginLabels,
  roleLabels,
  routerStagesLabels,
  scoringStatusLabels,
  TechRequestStagesLabels,
} from './data';
import {Props} from './types';
const FiltersContainer = styled.div`
  display: flex;
  padding: 15px 10px;
  align-items: center;
  gap: 14px;
  flex-wrap: wrap;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  padding-left: 10px;
`;

const SubmitButton = styled.button`
  background: rgb(23, 80, 203);
  padding: 8px 20px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 8px;
  border: 0;
  font-size: 16px;
  height: 40px;
`;

const FiltersPanel = ({
  handleEnterSearch,
  isProfiles,
  isDrafts,
  isProjects,
  isTechRequests,
  count,
  setSupervisor,
  setProjectArea,
  setRouterStage,
  setIsInternal,
  setAccelerator,
  setIsArchived,
  setIsFinished,
  setLastLogin,
  clearFilters,
  setTracker,
  setExpertiseRequired,
  setAnswerRequired,
  setRole,
  filters,
  user,
  setAcceleratorRequest,
  setCreatedBy,
  setTechRequestStatus,
  setScoringStatus,
  setFilterByUpdate,
}: Props) => {
  const {projectAreaLabels, isErrorProjectArea, isLoadingProjectArea} = UseProjectArea();
  const {supervisorsLabels, isErrorSupervisors, isLoadingSupervisors} = UseSupervisors();
  const {customersLabels, isErrorCustomers, isLoadingCustomers} = UseCustomersAll();
  const {trackersLabels, isErrorTrackers, isLoadingTrackers} = UseTrackers();
  const {acceleratorsLabels, isErrorAccelerator, isLoadingAccelerator} = UseAccelerators();
  const {is_project, is_expert, is_customer} = user;
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, [clearFilters]);

  const {search} = filters;
  return (
    <StatusChecker
      loadingsArray={[
        isLoadingAccelerator,
        isLoadingProjectArea,
        isLoadingSupervisors,
        isLoadingTrackers,
        isLoadingCustomers,
      ]}>
      <FiltersContainer>
        <SearchInput handleEnter={handleEnterSearch} />
        {!is_project && (
          <>
            {(isDrafts || isProjects) && (
              <>
                {!is_customer && !is_expert && (
                  <>
                    {!isErrorSupervisors && (
                      <FiltersPanelDropdown
                        labels={supervisorsLabels}
                        handleChange={value => setSupervisor(value)}
                        placeholder="Куратор"
                      />
                    )}
                    {!isErrorTrackers && (
                      <FiltersPanelDropdown
                        labels={trackersLabels}
                        handleChange={value => setTracker(value)}
                        placeholder="Трекер"
                      />
                    )}
                  </>
                )}

                {!isErrorProjectArea && !is_expert && (
                  <FiltersPanelDropdown
                    labels={projectAreaLabels}
                    handleChange={value => setProjectArea(value)}
                    placeholder="Направление"
                  />
                )}

                {!is_customer && !is_expert && (
                  <>
                    {isDrafts && (
                      <>
                        <FiltersPanelDropdown
                          labels={isArchivedLabels}
                          handleChange={value => setIsArchived(value)}
                          placeholder="Архивный: Не выбрано"
                          initialData={isArchivedLabels[1]}
                        />

                        {isErrorAccelerator ? null : (
                          <FiltersPanelDropdown
                            labels={acceleratorsLabels}
                            handleChange={value => setAcceleratorRequest(value)}
                            placeholder="Акселератор"
                          />
                        )}
                        <FiltersPanelDropdown
                          labels={scoringStatusLabels}
                          handleChange={value => setScoringStatus(value)}
                          placeholder="Статус скоринга"
                        />
                        <FiltersPanelDropdown
                          initialData={filterByUpdateLabels[0]}
                          isClearable={false}
                          labels={filterByUpdateLabels}
                          handleChange={value => setFilterByUpdate(value)}
                          placeholder="Сортировка"
                        />
                      </>
                    )}

                    {isProjects && (
                      <>
                        <FiltersPanelDropdown
                          labels={routerStagesLabels}
                          handleChange={value => setRouterStage(value)}
                          placeholder="Этап маршрутизатора"
                        />

                        {isErrorAccelerator ? null : (
                          <FiltersPanelDropdown
                            labels={acceleratorsLabels}
                            handleChange={value => setAccelerator(value)}
                            placeholder="Акселератор"
                          />
                        )}
                      </>
                    )}

                    <FiltersPanelDropdown
                      labels={isInternalLabels}
                      handleChange={value => setIsInternal(value)}
                      placeholder="Внутренний: Не выбрано"
                    />

                    {isProjects && (
                      <>
                        <FiltersPanelDropdown
                          labels={isFinishedLabels}
                          handleChange={value => setIsFinished(value)}
                          placeholder="Завершён: Не выбрано"
                        />
                      </>
                    )}
                  </>
                )}
                {/* 
                {is_customer && (
                  <FiltersPanelDropdown
                    isClearable={false}
                    labels={projectCustomerLabels}
                    handleChange={value => console.log(value)}
                    placeholder="Проекты"
                    initialData={projectCustomerLabels[0]}
                  />
                )} */}

                {is_expert && (
                  <FiltersPanelDropdown
                    labels={isExpertiseRequired}
                    handleChange={value => setExpertiseRequired(value)}
                    placeholder="Экспертиза"
                  />
                )}
                {is_customer && (
                  <FiltersPanelDropdown
                    labels={isAnswerRequired}
                    handleChange={value => setAnswerRequired(value)}
                    placeholder="Бизнес-заказчик"
                  />
                )}
              </>
            )}

            {isProfiles && (
              <>
                <FiltersPanelDropdown
                  labels={lastLoginLabels}
                  handleChange={value => setLastLogin(value)}
                  placeholder="Последний вход"
                />

                <FiltersPanelDropdown
                  labels={roleLabels}
                  handleChange={value => setRole(value)}
                  placeholder="Роль"
                />
              </>
            )}

            {isTechRequests && (
              <>
                {!isErrorCustomers && (
                  <FiltersPanelDropdown
                    labels={customersLabels}
                    handleChange={value => setCreatedBy(value)}
                    placeholder="Автор"
                  />
                )}
                <FiltersPanelDropdown
                  labels={projectAreaLabels}
                  handleChange={value => setProjectArea(value)}
                  placeholder="Направление"
                />

                <FiltersPanelDropdown
                  labels={TechRequestStagesLabels}
                  handleChange={value => setTechRequestStatus(value)}
                  placeholder="Статус"
                />
              </>
            )}
          </>
        )}
        <PaginationButtons count={count} />
      </FiltersContainer>
      <SubmitButtonContainer>
        {search && <SubmitButton onClick={handleEnterSearch}>Применить</SubmitButton>}
      </SubmitButtonContainer>
    </StatusChecker>
  );
};

const mapStateToProps = (state: RootState) => ({
  filters: selectFilters(state),
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSupervisor,
      setCreatedBy,
      setProjectArea,
      setRouterStage,
      setIsInternal,
      setAccelerator,
      setIsArchived,
      setIsFinished,
      setLastLogin,
      setRole,
      setSearch,
      setPage,
      setTracker,
      setExpertiseRequired,
      clearFilters,
      setAcceleratorRequest,
      setAnswerRequired,
      setTechRequestStatus,
      setScoringStatus,
      setFilterByUpdate,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPanel);
