import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import React from 'react';

import {ProfileService} from '@services/profile';

import StatusChecker from '@Common/StatusChecker';

import ProfileInfoCard from './Card';
import ProfileInfoContacts from './Contacts';

const ContentInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProfileInfo = () => {
  const pageId = Number(useParams().id);

  const {data, isLoading, isError} = useQuery({
    queryKey: ['profile', pageId],
    queryFn: () => ProfileService.getById(pageId),
  });
  return (
    <StatusChecker loadingsArray={[isLoading]} errorsArray={[isError]}>
      {data && (
        <ContentInfoStyled>
          <ProfileInfoCard data={data} />
          <ProfileInfoContacts data={data} />
        </ContentInfoStyled>
      )}
    </StatusChecker>
  );
};

export default ProfileInfo;
