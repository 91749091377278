import {IReportsState} from 'types/state/Reports';

const initialState: IReportsState = {
  value: [
    {
      id: 1,
      title: 'Проекты сводная',
    },
    {
      id: 2,
      title: 'Статус по акселератору',
    },
    {
      id: 3,
      title: 'Эксперты',
    },
    {
      id: 4,
      title: 'Акселераторы',
    },
  ],
};

export default initialState;
