import {axiosDocument, instance} from 'api';
import {ProfileService} from 'services/profile';

import {IAuthResponse} from 'types/services';
import {IActiveteData, IEmailPassword, IRegistrationData, IResetPassword} from 'types/User';

export const AuthService = {
  async main(data: IEmailPassword) {
    return await instance.post<IAuthResponse>('/api-token-auth/', data).then(({data}) => {
      localStorage.setItem('token', data.token);
      localStorage.setItem('profile_id', data.profile_id);

      instance.defaults.headers.common['Authorization'] = `Token ${data.token}`;
      axiosDocument.defaults.headers.common['Authorization'] = `Token ${data.token}`;

      return ProfileService.getById(data.profile_id).then(data => data);
    });
  },

  async resetPassword(data: IResetPassword) {
    try {
      const response = await instance.post('/password-reset/', data);
      if (response && response.status === 400) {
        throw new Error('Ошибка запроса (400 Bad Request)');
      }
      return response;
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async activate(data: IActiveteData) {
    try {
      return await instance.post('/profile-activate/', data);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
    }
  },

  async registration(data: IRegistrationData) {
    await instance.post('/registration/', data);
  },
};
