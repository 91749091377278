export const techDevStatus = [
  {
    value: 'idea',
    label: 'Идея/концепция',
  },
  {
    value: 'research',
    label: 'Фундаментальные исследования',
  },
  {
    value: 'lab_prototype',
    label: 'Лабораторный образец',
  },
  {
    value: 'prototype',
    label: 'Опытный (промышленный) образец',
  },
  {
    value: 'testing',
    label: 'Опытные испытания',
  },
  {
    value: 'small_production',
    label: 'Единичное/мелкосерийное производство',
  },
  {
    value: 'big_production',
    label: 'Серийное производство',
  },
];

export const marketStatus = [
  {
    value: 'none',
    label: 'Рынок не изучался',
  },
  {
    value: 'consulting',
    label: 'Единичные контакты с потенциальными клиентами',
  },
  {
    value: 'received_requirements',
    label: 'Продукт дорабатывался в соответствии с требованиями клиентов',
  },
  {
    value: 'pilot_shipment',
    label: 'Произведены опытные поставки',
  },
  {
    value: 'small_sales',
    label: 'Единичные продажи',
  },
  {
    value: 'business',
    label: 'Продажи поставлены на поток',
  },
];

export const labelsFills = {
  tech_dev_status: techDevStatus,
  market_status: marketStatus,
};
