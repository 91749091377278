import React from 'react';

import {ICommentProjectStep} from 'types/state/ProjectStepList';

import {StyledResults, StyledResultItem} from './styles';

interface ICustomerComment {
  comments: ICommentProjectStep[];
}

const CustomerComment = ({comments}: ICustomerComment) => {
  return (
    <StyledResults>
      {comments.map(comment => (
        <StyledResultItem key={comment.created_at}>
          <div dangerouslySetInnerHTML={{__html: comment.body}} />
        </StyledResultItem>
      ))}
    </StyledResults>
  );
};

export default CustomerComment;
