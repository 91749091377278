import {styled} from 'styled-components';

import React from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

const EditorWrapper = styled.div`
  .ql-editor {
    max-height: 125px;
    overflow-y: auto;
    min-height: 75px;
  }

  .ql-editor::-webkit-scrollbar {
    width: 8px;
  }
  .ql-editor::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .ql-editor::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  .ql-editor::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
    [{align: ''}],
    ['link'],
    ['clean'],
  ],
};

interface ICommentEditor {
  setValue: (value: string) => void;
  value: string | null;
}

const CommentEditor = ({setValue, value}: ICommentEditor) => {
  return (
    <EditorWrapper>
      <ReactQuill
        modules={modules}
        theme="snow"
        onChange={setValue}
        value={value || ''}
        placeholder="Ввод..."
      />
    </EditorWrapper>
  );
};

export default CommentEditor;
