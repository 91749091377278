import {createSlice} from '@reduxjs/toolkit';
import initialState from 'store/profiles/state';

const profilesSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {},
});

export default profilesSlice.reducer;
