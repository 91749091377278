import {instance} from 'api/instance';

import {IApiResponse} from 'types/services';
import {
  ICustomerResponse,
  IPostCustomerResponse,
  IPostCustomerResponseAnswer,
} from 'types/state/Customer';

export const CustomersService = {
  async getByProject(projectId: number) {
    return (
      await instance.get<IApiResponse<ICustomerResponse>>(
        `/project-customer-response/?project=${projectId}`,
      )
    ).data.results;
  },

  async create(data: IPostCustomerResponse) {
    return instance.post<ICustomerResponse>('/project-customer-response/', data);
  },

  async createAnswer(data: IPostCustomerResponseAnswer, id: number) {
    return instance.post<ICustomerResponse>(
      `/project-customer-response/${id}/customer-status-set/`,
      data,
    );
  },

  async delete(id: number) {
    return instance.delete(`/project-customer-response/${id}/`);
  },
};
