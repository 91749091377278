import styled from 'styled-components';

import React from 'react';

const StyledPanelContentWrapper = styled.div`
  border-radius: ${({theme}) => theme.borderRadius.full};
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  background: ${({theme}) => theme.colors.white};

  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  & > :last-child {
    border-bottom: none;
  }
`;

const PanelContentWrapper = ({children}: {children: React.ReactNode}) => {
  return <StyledPanelContentWrapper>{children}</StyledPanelContentWrapper>;
};

export default PanelContentWrapper;
