import {ContentBoardMenu} from 'components/Menu';
import React from 'react';

const WorkflowCreate = () => {
  return (
    <>
      <ContentBoardMenu pageTitle={'Добавить'} />
    </>
  );
};

export default WorkflowCreate;
