import styled from 'styled-components';

import React, {FC} from 'react';

const StyledNavbarWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 45px 12px 34px;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #f0f1f6;
`;

const NavBarWrapper: FC<{children?: React.ReactNode}> = ({children}) => {
  return <StyledNavbarWrapper>{children}</StyledNavbarWrapper>;
};

export default NavBarWrapper;
