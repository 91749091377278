import styled from 'styled-components';

export const TableRowStyled = styled.div<{
  $isGray?: boolean;
  $templateColumns: string;
  $textAlign?: string;
}>`
  box-sizing: border-box;
  display: grid;
  align-items: center;
  gap: 15px;
  width: 100%;
  min-height: 65px;
  text-align: ${({$textAlign}) => $textAlign || 'left'};
  justify-content: ${({$textAlign}) => $textAlign || 'left'};
  grid-template-columns: ${({$templateColumns}) => $templateColumns};
  padding: 0 15px;
  background: ${({theme, $isGray = false}) =>
    $isGray ? theme.colors.grayBackground : theme.colors.white};
  border-bottom: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
`;
