import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {ProfileService} from '@services/profile';

import {transformDropdownData} from '@utils/TransformDropdownData';

const UseTrackers = () => {
  const {
    data: trackersList,
    isLoading: isLoadingTrackers,
    isError: isErrorTrackers,
  } = useQuery({
    queryKey: ['trackers'],
    queryFn: () => ProfileService.getTrackers(),
  });

  const trackersLabels = useMemo(() => transformDropdownData(trackersList || []), [trackersList]);

  return {
    trackersList: trackersList || [],
    isLoadingTrackers,
    isErrorTrackers,
    trackersLabels,
  };
};

export default UseTrackers;
