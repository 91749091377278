import {useParams} from 'react-router-dom';

import UseAppSelector from './UseAppSelector';
import UseRoles from './UseRoles';

const UseCheckProfile = () => {
  const pageId = Number(useParams().id);
  const {isSuperuser, isSupervisor} = UseRoles();
  const {id} = UseAppSelector(state => state.user.data);

  const isEditableProfile = isSuperuser || isSupervisor || pageId === id;
  return {isEditableProfile};
};

export default UseCheckProfile;
