import {useParams} from 'react-router-dom';

import React, {useState} from 'react';
import {createPortal} from 'react-dom';

import {selectUser} from '@store/user/selectors';

import {PanelContentWrapper} from '@components/Wrappers/DraftsItem';

import {FilterButton} from '@Common/Buttons';
import ExpertiseModal from '@Common/Modal/ModalExpertise';
import StatusChecker from '@Common/StatusChecker';
import {TextHeaderMedium} from '@Common/Text';

import UseAppSelector from '@hooks/UseAppSelector';
import UseExpertise from '@hooks/UseExpertise';

import DraftsExpertiseItem, {StyledContentRow} from './Item';

const titles = ['Эксперт', 'Дата', 'Этап/Тех. запрос', 'Срок', 'Статус'];

const DraftsExpertiseContent = () => {
  const pageId = Number(useParams().id);
  const {is_superuser, is_supervisor} = UseAppSelector(selectUser);
  const [showModalExpertise, setShowModalExpertise] = useState(false);
  const {expertiseList, isErrorExpertise, isLoadingExpertise} = UseExpertise(pageId);

  return (
    <StatusChecker loadingsArray={[isLoadingExpertise]} errorsArray={[isErrorExpertise]}>
      <PanelContentWrapper>
        <StyledContentRow>
          {titles.map(title => (
            <TextHeaderMedium key={title}>{title}</TextHeaderMedium>
          ))}
          {(is_superuser || is_supervisor) && (
            <FilterButton
              isBlue={true}
              action={() => setShowModalExpertise(true)}
              title={'Запросить экспертизу'}
            />
          )}
        </StyledContentRow>
        {expertiseList.map(item => (
          <DraftsExpertiseItem key={item.id} data={item} />
        ))}
      </PanelContentWrapper>

      {showModalExpertise &&
        createPortal(
          <ExpertiseModal onClose={() => setShowModalExpertise(false)} />,
          document.body,
        )}
    </StatusChecker>
  );
};

export default DraftsExpertiseContent;
