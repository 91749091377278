import styled from 'styled-components';

import React from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import SingleDropdown from '@Common/Dropdown/Single';
import {TextParagraph, TextSmall} from '@Common/Text';

import {Colors} from '@constants/enum';

import {Props} from './types';
const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 10px;
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  border-radius: ${({theme}) => theme.borderRadius.full};
  background-color: ${({theme}) => theme.colors.greyCircuit};
`;

const ContentStatusAccelerator = ({
  value,
  setValue,
  title,
  labels,
  user,
  accelerator_id,
  is_draft,
}: Props) => {
  const {is_superuser, is_supervisor} = user;
  return (
    <FieldWrapper>
      <TextSmall>{title}</TextSmall>
      {(is_superuser || is_supervisor) && !accelerator_id && !is_draft ? (
        <SingleDropdown labels={labels} value={value} handleChange={setValue} />
      ) : (
        <TextParagraph color={Colors.textDark}>{value?.label || '-'}</TextParagraph>
      )}
    </FieldWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(ContentStatusAccelerator);
