import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {ProfileService} from '@services/profile';

import {transformDropdownData} from '@utils/TransformDropdownData';

const UseCustomersAll = () => {
  const {
    data: customersList,
    isLoading: isLoadingCustomers,
    isError: isErrorCustomers,
  } = useQuery({
    queryKey: ['customers'],
    queryFn: () => ProfileService.getCustomers(),
  });

  const customersLabels = useMemo(
    () => transformDropdownData(customersList || []),
    [customersList],
  );

  return {
    customersList,
    isLoadingCustomers,
    isErrorCustomers,
    customersLabels,
  };
};

export default UseCustomersAll;
