import {useQueryClient} from '@tanstack/react-query';
import React, {FormEvent, useState} from 'react';
import {connect} from 'react-redux';

import {rolesData} from '@routes/Profiles/data';
import {WrapContainer} from '@routes/Profiles/Edit';
import {CheckboxInput, CheckboxLabel} from '@routes/Registration';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {AuthService} from '@services/auth';

import {StyledSmallLink} from '@components/DraftsItem/CheckList/Content/styles';
import GroupsEditProfile from '@components/ProfileItem/Edit/Groups';
import GroupsItemCheckboxEditProfile from '@components/ProfileItem/Edit/GroupsItemCheckbox';

import {LoginFormButton} from '@Common/Buttons';
import {LoginInput} from '@Common/Forms/Login';
import {CrossIcon} from '@Common/Images';
import Loader from '@Common/Loader';
import {TextHeader, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {IUser} from 'types/User';

import {CloseButton, ModalContent, ModalWrapper, Overlay} from './styles';

interface ModalProps {
  onClose: () => void;
  user: IUser;
}

const RegistrationModal = ({onClose, user}: ModalProps) => {
  const {is_customer, is_expert, is_project} = user;
  const [error, setError] = useState('');
  const [acceptPersonalData, setAcceptPersonalData] = useState(false);
  const queryClient = useQueryClient();
  const [userData, setUserData] = useState({
    fio: '',
    email1: '',
    phone1: '',
  });

  const [roles, setRoles] = useState({
    is_supervisor: false,
    is_superuser: false,
    is_project: true,
    is_expert: false,
    is_hired: false,
    is_customer: false,
    is_internal: false,
    is_observer: false,
    is_tracker: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [isHelpShown, setIsHelpShown] = useState(false);

  const onSubmit = async (event: FormEvent) => {
    if (acceptPersonalData) {
      setIsLoading(true);
      event.preventDefault();

      await AuthService.registration({...userData, ...roles})
        .then(() => {
          setIsHelpShown(true);
          setError('');
          void queryClient.invalidateQueries(['profiles']);
        })
        .catch((e: Error) => {
          setError(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Overlay>
      <ModalWrapper
        onClick={e => {
          e.stopPropagation();
        }}>
        <CloseButton onClick={onClose}>
          <CrossIcon />
        </CloseButton>
        <TextHeader align="center">Добавление профиля в систему</TextHeader>

        <ModalContent>
          <LoginInput
            $width="100%"
            type="text"
            value={userData.fio}
            autoComplete={'on'}
            onChange={e => setUserData(prev => ({...prev, fio: e.target.value}))}
            placeholder={'ФИО'}
          />
          <LoginInput
            $width="100%"
            type={'email'}
            value={userData.email1}
            autoComplete={'on'}
            onChange={e => setUserData(prev => ({...prev, email1: e.target.value}))}
            placeholder={'Email'}
          />

          <LoginInput
            $width="100%"
            type={'text'}
            value={userData.phone1}
            autoComplete={'on'}
            onChange={e => setUserData(prev => ({...prev, phone1: e.target.value}))}
            placeholder={'Телефон'}
          />
          {/* <CheckboxLabel>
            <CheckboxInput type="checkbox" />
            Участник является сотрудником атомной отрасли
          </CheckboxLabel> */}

          {!is_project && (
            <GroupsEditProfile title="Роли">
              <WrapContainer>
                {rolesData.map(item => {
                  if (
                    (!is_customer || !is_expert) &&
                    (item.field === 'is_superuser' || item.field === 'is_supervisor')
                  )
                    return null;
                  return (
                    <GroupsItemCheckboxEditProfile
                      key={item.field}
                      helper={item.helper}
                      title={item.title}
                      value={!!roles[item.field]}
                      setValue={value => setRoles(prev => ({...prev, [item.field]: value}))}
                    />
                  );
                })}
              </WrapContainer>
            </GroupsEditProfile>
          )}
          <CheckboxLabel>
            <CheckboxInput
              type="checkbox"
              onChange={e => setAcceptPersonalData(e.target.checked)}
            />
            Я даю согласие на обработку персональных данных *
          </CheckboxLabel>
          <StyledSmallLink
            href={'https://copy.nextproject.online/upload/docs/consent_personal_data.docx'}>
            * Согласие на обработку персональных данных
          </StyledSmallLink>
        </ModalContent>
        <LoginFormButton onClick={onSubmit}>Добавить</LoginFormButton>
        {isLoading && <Loader />}
        {error && (
          <TextParagraph align="center" color={Colors.textDark}>
            {error}
          </TextParagraph>
        )}

        {isHelpShown && (
          <TextParagraph align="center" color={Colors.grey}>
            На указанную почту было выслано письмо с ссылкой для активации
          </TextParagraph>
        )}
      </ModalWrapper>
    </Overlay>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(RegistrationModal);
