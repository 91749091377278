import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {IUser} from 'types/User';

import {initialState} from './state';

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    authUser: (state, action: PayloadAction<IUser>) => {
      state.data = action.payload;
      state.isLogged = true;
    },
    logoutUser: state => {
      localStorage.clear();
      state.isLogged = false;
      state.data = initialState.data;
    },
    updateUser: (state, action: PayloadAction<IUser>) => {
      state.data = action.payload;
    },
  },
});

export default userSlice.reducer;
export const {authUser, logoutUser, updateUser} = userSlice.actions;
