import React from 'react';

import {TextHeaderMedium} from '@Common/Text';

import LegalEntitiesTableRow from './TableRow';

const LegalEntitiesTable = () => {
  return (
    <>
      <LegalEntitiesTableRow $templateColumns="88% 10%">
        <TextHeaderMedium>Название</TextHeaderMedium>
      </LegalEntitiesTableRow>
    </>
  );
};

export default LegalEntitiesTable;
