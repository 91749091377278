import styled from 'styled-components';

import React, {useEffect, useState} from 'react';

import AcceptModal from '@Common/Modal/Accept';

import {
  IInvestmentProjectData,
  IRequestProjectRouterCheckListItem,
} from 'types/state/ProjectRouterCheckList';

import ChoiceButtons from './ChoiceButtons';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
`;

interface IInvestmentProject {
  setActive: () => void;
  setRequestData: (data: IRequestProjectRouterCheckListItem) => void;
}

export const titlesInvestment = {
  quiz4:
    'Были ли первые продажи Вашего продукта Заказчикам или имеются закреплённые договоренности с Заказчиками о возможной покупке продукции?',
  quiz5:
    'Требуется ли Вашему проекту дополнительное финансирование/инвестиции для организации производства/продаж?',
  quiz6: 'Сформирована ли команда для разработки и запуска ИНВЕСТИЦИОННОГО ПРОЕКТА?',
  quiz7:
    'Необходимо ли Вам выстроить кооперацию с другим предприятием для получения необходимого оборудования, мощностей, компетенций и т.д.?',
  quiz8: 'Есть ли у Вас понимание, за счёт чего Вы будете конкурировать на рынке?',
  quiz9: 'Выбрана ли бизнес-модель продаж продукта?',
  quiz10: 'Есть более 1 потребителя готового заключить контракт?',
  quiz11: 'Есть ли инвесторы, готовые вложить инвестиции в Ваш проект?',
  quiz12: 'Есть ли патенты или другие РИД по проекту?',
};

const tooltips = {
  quiz4:
    'Под закрепленными договоренностями подразумеваются письменные договоры о  будущей покупке продукции',
  quiz5: '',
  quiz6:
    'В типовую команду как правило включены: руководитель проекта, технический специалист, коммерческий специалист ',
  quiz7:
    'Под предприятиями подразумеваются внутриотраслевые организации ГК «Росатом» и внешние компании',
  quiz8: '',
  quiz9: '',
  quiz10: 'Устные и/или письменные договоренности с потенциальным Заказчиком',
  quiz11: '',
  quiz12: 'РИД – результаты интеллектуальной деятельности',
};

const InvestmentProject = ({setActive, setRequestData}: IInvestmentProject) => {
  const [answers, setAnswers] = useState<IInvestmentProjectData>({
    quiz4: null,
    quiz5: null,
    quiz6: null,
    quiz7: null,
    quiz8: null,
    quiz9: null,
    quiz10: null,
    quiz11: null,
    quiz12: null,
  });

  const [isModalNo, setIsModalNo] = useState(false);
  useEffect(() => {
    setRequestData(answers);
  }, [answers, setRequestData]);

  return (
    <StyledContent>
      <ChoiceButtons
        title={
          'Были ли первые продажи Вашего продукта Заказчикам или имеются закреплённые договоренности с Заказчиками о возможной покупке продукции?'
        }
        isActive={answers.quiz4}
        onClickNo={() => setIsModalNo(true)}
        onClickYes={() => setAnswers({...answers, quiz4: true})}
        tooltipText={tooltips.quiz4}
      />

      {answers.quiz4 &&
        Object.keys(answers).map(item => {
          if (item === 'quiz4') return null;
          const field = item as keyof typeof titlesInvestment;
          return (
            <ChoiceButtons
              key={field}
              title={titlesInvestment[field]}
              isActive={answers[field]}
              onClickNo={() => setAnswers({...answers, [field]: false})}
              onClickYes={() => setAnswers({...answers, [field]: true})}
              tooltipText={tooltips[field]}
            />
          );
        })}

      {isModalNo && <AcceptModal handleYes={setActive} setIsOpen={setIsModalNo} />}
    </StyledContent>
  );
};

export default InvestmentProject;
