import {createAsyncThunk} from '@reduxjs/toolkit';
import {CompanyService} from 'services/company';

import {ICompanyItem} from 'types/state/company';

export const getAllCompany = createAsyncThunk<ICompanyItem[]>(
  'company/all',
  async (_, thunkApi) => {
    try {
      return await CompanyService.getAll();
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
