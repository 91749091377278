import {instance} from 'api';

import {IApiResponse} from 'types/services';
import {IPostScoring, IScoring} from 'types/state/Scoring';

export const ScoringService = {
  async getAll() {
    const response = await instance.get<IApiResponse<IScoring>>('/scoring/');

    return response.data.results;
  },

  async getByProject(projectId: number) {
    const response = await instance.get<IApiResponse<IScoring>>(`/scoring/?object_id=${projectId}`);
    const results = response.data.results;

    results.sort((a, b) => (a.created_at || '').localeCompare(b.created_at || ''));

    return results;
  },

  async deleteScoring(projectId: number) {
    await instance.delete<IApiResponse<IScoring>>(`/scoring/${projectId}`);
  },

  async create(data: IPostScoring) {
    try {
      return instance.post<IPostScoring>('/scoring/', data);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },
};
