import styled from 'styled-components';

import {useQueryClient, useMutation} from '@tanstack/react-query';
import React, {useState} from 'react';
import {createPortal} from 'react-dom';
import {useForm, SubmitHandler} from 'react-hook-form';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {DraftsService} from '@services/drafts';

import {ContentBoardMenu} from '@components/Menu';

import MultipleDropdown from '@Common/Dropdown/Multiple';
import {DropdownMultipleType} from '@Common/Dropdown/Multiple/types';
import SingleDropdown from '@Common/Dropdown/Single';
import {DropdownSingleType} from '@Common/Dropdown/Single/types';
import {InfoIcon} from '@Common/Images';
import {TextInput} from '@Common/Input';
import Loader from '@Common/Loader';
import ModalRouter from '@Common/ModalRouter';
import StatusChecker from '@Common/StatusChecker';
import {TextParagraph, TextSmall} from '@Common/Text';
import TooltipCustom from '@Common/Tooltip';
import {FlexButton} from '@Common/Utils/FlexButton';

import {Colors} from '@constants/enum';

import UseCfo from '@hooks/UseCfo';
import UseCompany from '@hooks/UseCompany';
import {UseNavigationList} from '@hooks/UseNavigationList';
import UseTags from '@hooks/UseTags';

import {IPatchRequestField} from 'types/patchRequest';
import {IDraftsListItem} from 'types/state/Drafts';
import {IUser} from 'types/User';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const StyledButtonBlock = styled.div`
  display: flex;
  margin-top: 40px;
`;

const StyledContentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledFormRow = styled.div`
  display: grid;
  grid-template-columns: 15% 80%;
  gap: 30px;
  padding: 5px 10px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input<{$isNotField?: boolean}>`
  margin-right: 5px;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    border: ${({$isNotField}) => ($isNotField ? ' 2px solid red' : 'none')};
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 7px;

  & h3 {
    margin-left: 30px;
  }
`;

const StyledSubmitButton = styled(FlexButton)`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  font-size: 16px;

  max-width: 200px;

  background: #bee4ff;
  border: none;
  color: black;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({theme}) => theme.colors.oceanBlue};

    color: ${({theme}) => theme.colors.white};
  }
`;
const StyledCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Empty = styled.div``;

const TextWithInfo = styled.div`
  display: grid;
  grid-template-columns: 50px 20px;
`;

interface FormData {
  projectName: string;
  email: string;
  tags: string;
  inn: string;
  isInternal: boolean;
  consent1: boolean;
  consent2: boolean;
}

type Props = {
  user: IUser;
};

const DraftCreate = ({user}: Props) => {
  UseNavigationList([
    {path: '/draft', title: 'Заявки'},
    {path: '/draft/create', title: 'Создание'},
  ]);
  const {is_internal} = user;

  const queryClient = useQueryClient();
  const [companyValue, setCompanyValue] = useState('');
  const [tags, setTags] = useState<DropdownMultipleType>([]);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [draft, setDraft] = useState<IDraftsListItem | null>(null);

  const checkInn = (inn: string) => {
    setCompanyValue(inn);
  };

  const {
    register: formRegister,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm<FormData>();

  const isInternal = watch('isInternal');
  const inn = watch('inn');

  const {isLoadingCfo, isErrorCfo, cfoLabels} = UseCfo();
  const {isLoadingTags, isErrorTags, tagsLabels} = UseTags();
  const {company, isLoadingCompany, isErrorCompany} = UseCompany(companyValue);

  const [cfoValue, setCfoValue] = useState<DropdownSingleType>(null);

  const mutation = useMutation({
    mutationFn: (data: IPatchRequestField) => DraftsService.create(data),
    onSuccess: response => {
      void queryClient.invalidateQueries(['drafts']);
      setDraft(response.data);
      setLoading(false);
    },
  });

  const onSubmit: SubmitHandler<FormData> = data => {
    setLoading(true);
    setModal(true);

    mutation.mutate({
      name: data.projectName,
      founder_email: data.email,
      is_internal: data.isInternal,
      external_inn: isInternal ? null : data.inn || null,
      inner_cfo: isInternal ? cfoValue?.value || null : null,
      tags: tags?.map(tag => tag.label) || [],
    });
  };

  return (
    <StyledContent>
      {draft &&
        createPortal(
          <ModalRouter isOpen={modal} onClose={() => setModal(false)} draft={draft} />,
          document.body,
        )}
      <ContentBoardMenu pageTitle={'Заявка на проект'} />

      <StyledContentForm onSubmit={handleSubmit(onSubmit)}>
        <StyledFormRow>
          <Empty />
          <TextParagraph color={Colors.textDark}>
            Поля, помеченные звездочкой (*), являются обязательными.
          </TextParagraph>
        </StyledFormRow>

        <StyledFormRow>
          <Empty />
          <CheckboxWrapper>
            <CheckboxLabel>
              <CheckboxInput
                $isNotField={Boolean(errors.consent2)}
                type="checkbox"
                {...formRegister('consent2', {required: true})}
              />
              * Я ознакомлен с тем, что информация, размещаемая на данном портале:
            </CheckboxLabel>
            <TextSmall>
              <br />
              не подпадает под действие статьи 5 Закона РФ «О государственной тайне», «Перечня
              сведений, отнесенных к государственной тайне», Перечня сведений, подлежащих
              засекречиванию, Госкорпорации «Росатом» и не содержит сведений, составляющих
              государственную тайну Российской Федерации, в том числе сведений, подлежащих
              засекречиванию, Госкорпорации «Росатом»;
              <br />
              не содержит сведения, составляющие служебную тайну;
              <br />
              не содержит сведений, являющихся результатами интеллектуальной деятельности, права на
              которые принадлежат Российской Федерации;
              <br />
              не содержит сведений, составляющих коммерческую тайну, служебную информацию
              ограниченного распространения («Для служебного пользования»).
            </TextSmall>
          </CheckboxWrapper>
        </StyledFormRow>

        <StyledFormRow>
          <TextParagraph color={Colors.textDark}>Название проекта *</TextParagraph>

          <TextInput
            isNotField={!!errors.projectName}
            register={formRegister('projectName', {required: true, maxLength: 250})}
            description={'Макс. 250 символов'}
          />
        </StyledFormRow>

        <StyledFormRow>
          <Empty />
          <CheckboxLabel>
            <CheckboxInput
              type="checkbox"
              {...formRegister('isInternal')}
              defaultChecked={is_internal}
            />
            Проект сотрудника атомной отрасли
          </CheckboxLabel>
        </StyledFormRow>

        <StyledFormRow>
          <TextWithInfo>
            <TextParagraph color={Colors.textDark}>{isInternal ? 'ЦФО' : 'Инн'}</TextParagraph>
            <TooltipCustom
              content={
                isInternal
                  ? 'ЦФО – центр финансовой ответственности (выберите название дивизиона или компании ГК «Росатом», в которой Вы работаете)'
                  : 'Введите ИНН компании, в которой Вы работаете. После нажмите «проверить», система автоматически определит Вашу организацию'
              }>
              <InfoIcon />
            </TooltipCustom>
          </TextWithInfo>
          {isInternal ? (
            <StatusChecker errorsArray={[isErrorCfo]} loadingsArray={[isLoadingCfo]}>
              <SingleDropdown
                isClearable
                labels={cfoLabels}
                handleChange={setCfoValue}
                value={cfoValue}
              />
            </StatusChecker>
          ) : (
            <StyledCompanyWrapper>
              <TextInput
                register={formRegister('inn', {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Пожалуйста, введите только числа.',
                  },
                  maxLength: {
                    value: 15,
                    message: 'Максимум 15 цифр',
                  },
                  minLength: {
                    value: 10,
                    message: 'Минимум 10 цифр',
                  },
                })}
                description={'Так же доступен ввод ИННИП, ОГРН, ОГРНИП. (10-15 цифр)'}
                errorMessage={errors.inn?.message}
              />
              {isErrorCompany ? (
                <TextParagraph color={Colors.textDark}>Не найдено</TextParagraph>
              ) : isLoadingCompany ? (
                <Loader />
              ) : (
                company && <TextParagraph color={Colors.accentBlue}>{company.name}</TextParagraph>
              )}
              <StyledSubmitButton type="button" onClick={() => checkInn(inn)}>
                Проверить
              </StyledSubmitButton>
            </StyledCompanyWrapper>
          )}
        </StyledFormRow>

        <StatusChecker errorsArray={[isErrorTags]} loadingsArray={[isLoadingTags]}>
          <StyledFormRow>
            <TextWithInfo>
              <TextParagraph color={Colors.textDark}>Теги</TextParagraph>
              <TooltipCustom
                content={
                  'Ключевые слова или словосочетания, которые наиболее точно отражают специфику Вашего проекта (например, «вывод из эксплуатации», «импортозамещение», «литиевые батареи» и т.п.) Выберите их из выпадающего списка (при написании тега необходимо на него нажать для подтверждения)'
                }>
                <InfoIcon />
              </TooltipCustom>
            </TextWithInfo>
            <MultipleDropdown
              isAddedNewValue
              labels={tagsLabels}
              handleChange={setTags}
              value={tags}
            />
          </StyledFormRow>
        </StatusChecker>
        <StyledButtonBlock>
          {loading ? <Loader /> : <StyledSubmitButton type="submit">Сохранить</StyledSubmitButton>}
        </StyledButtonBlock>
      </StyledContentForm>
    </StyledContent>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(DraftCreate);
