import {instance} from '@api/instance';
import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {transformDropdownData} from '@utils/TransformDropdownData';

import {IApiResponse} from 'types/services';
type CompetencyApi = {
  id: number;
  name: string;
  slug: string;
};

const UseCompetency = () => {
  const getAll = async () => {
    const response = await instance.get<IApiResponse<CompetencyApi>>(
      '/competency/?ordering=id&limit=10000&offset=0',
    );

    return response.data.results;
  };
  const {
    data: competencyList,
    isLoading: isLoadingCompetency,
    isError: isErrorCompetency,
  } = useQuery({
    queryKey: ['competency'],
    queryFn: () => getAll(),
  });

  const competencyLabels = useMemo(
    () => transformDropdownData(competencyList || []),
    [competencyList],
  );
  return {
    competencyList: competencyList || [],
    isLoadingCompetency,
    isErrorCompetency,
    competencyLabels,
  };
};

export default UseCompetency;
