import styled from 'styled-components';

import React, {FC} from 'react';

import {EditIcon} from '@Common/Images';
import {NoBorderButton} from '@Common/Utils/Buttons';

import {IEditButton} from 'types/components';

const StyledEditButton = styled(NoBorderButton)`
  background: unset;
`;

const EditButton: FC<IEditButton> = ({action}) => {
  return (
    <StyledEditButton onClick={action}>
      <EditIcon />
    </StyledEditButton>
  );
};

export default EditButton;
