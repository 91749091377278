import {styled} from 'styled-components';

import {useParams} from 'react-router-dom';

import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import React, {useMemo, useState} from 'react';
import {createPortal} from 'react-dom';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {ProjectStepListService} from '@services/projectStepList';

import {PanelContentWrapper} from '@components/Wrappers/DraftsItem';

import {FilterButton} from '@Common/Buttons';
import {PanelHeader} from '@Common/DraftsItem/Panel';
import {StarIcon} from '@Common/Images';
import AcceleratorCommentModal from '@Common/Modal/AcceleratorComment';
import StatusChecker from '@Common/StatusChecker';

import {ICommentStep} from 'types/state/ProjectStepList';
import {IUser} from 'types/User';

import StagesContentItem from './Item';
import AcceleratorPoints from './Points';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StagesButton = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

type Props = {
  user: IUser;
  workflow_id: number;
  isFinished: boolean;
};

const DraftsStagesContent = ({workflow_id, user, isFinished}: Props) => {
  const pageId = Number(useParams().id);
  const queryClient = useQueryClient();
  const [modal, setModal] = useState('');

  const [shownHistory, setShownHistory] = useState(false);
  const {is_superuser, is_supervisor} = user;
  const {
    data: projectStepList,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ['project-step', pageId],
    queryFn: async () => await ProjectStepListService.getByProject(pageId),
  });

  const activeStepOrdering = useMemo(
    () => projectStepList?.find(step => step.is_active)?.step.ordering || 0,
    [projectStepList],
  );

  const mutationNext = useMutation({
    mutationFn: async (data: ICommentStep) => {
      return await ProjectStepListService.nextStep(pageId, data.text, data.attachments);
    },
    onSuccess: responce => {
      void queryClient.invalidateQueries(['step', pageId]);
      void queryClient.invalidateQueries(['project-step', pageId]);
      if (!responce.data.ordering) void queryClient.invalidateQueries(['project', pageId]);
    },
  });

  const mutationPrev = useMutation({
    mutationFn: async () => {
      await ProjectStepListService.prevStep(pageId);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(['step', pageId]);
      void queryClient.invalidateQueries(['project-step', pageId]);
    },
  });

  const mutationComment = useMutation({
    mutationFn: async (data: ICommentStep) => {
      await ProjectStepListService.createComment(pageId, data.text, data.attachments);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(['step', pageId]);
      void queryClient.invalidateQueries(['project-step', pageId]);
    },
  });

  const prevStage = () => {
    mutationPrev.mutate();
  };

  const nextStage = (data: ICommentStep) => {
    mutationNext.mutate(data);
  };

  const createComment = (data: ICommentStep) => {
    mutationComment.mutate(data);
  };

  return (
    <>
      <AcceleratorPoints isFinished={isFinished} workflow_id={workflow_id} />
      <StatusChecker errorsArray={[isError]} loadingsArray={[isLoading]}>
        <PanelContentWrapper>
          <PanelHeader
            isShownContent={shownHistory}
            icon={<StarIcon />}
            title={'История этапов'}
            action={() => setShownHistory(prev => !prev)}
          />
          {shownHistory &&
            projectStepList?.map(item => <StagesContentItem key={item.id} item={item} />)}
        </PanelContentWrapper>

        <ButtonsWrapper>
          {!isFinished && (
            <StagesButton>
              {(is_superuser || is_supervisor) && activeStepOrdering > 0 && (
                <FilterButton isBlue={true} action={prevStage} title={'Предыдущий этап'} />
              )}

              {(is_superuser || is_supervisor) && (
                <FilterButton
                  isBlue={true}
                  action={() => setModal('end')}
                  title={'Завершить этап'}
                />
              )}
              <FilterButton
                isBlue={true}
                action={() => setModal('commment')}
                title={'Добавить комментарий'}
              />
            </StagesButton>
          )}
        </ButtonsWrapper>
      </StatusChecker>

      {modal &&
        createPortal(
          <AcceleratorCommentModal
            onSave={modal === 'end' ? nextStage : createComment}
            onClose={() => setModal('')}
          />,
          document.body,
        )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(DraftsStagesContent);
