import styled from 'styled-components';

import {useNavigate} from 'react-router-dom';

import React from 'react';

import {IDraftsListItem} from 'types/state/Drafts';
import {ITechRequestItem} from 'types/state/TechRequest';

import {Colors, TextHeader, TextHeaderMedium, TextParagraph} from './Text';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px 20px;

  min-width: 200px;
  min-height: 300px;
  border-radius: 8px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledButton = styled.button`
  display: flex;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  min-width: 50px;
  justify-content: center;
  border-radius: ${({theme}) => theme.borderRadius.full};
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit} `};

  background-color: ${({theme}) => theme.colors.white};

  &:hover {
    background-color: ${({theme}) => theme.colors.greyCircuit};
  }
`;

interface ModalProps {
  isOpen: boolean;
  createTechRequest?: boolean;
  onClose: () => void;
  draft?: IDraftsListItem;
  techRequest?: ITechRequestItem;
}

const ModalRouter = ({isOpen, onClose, draft, techRequest, createTechRequest}: ModalProps) => {
  const navigate = useNavigate();

  if (!isOpen) {
    return null;
  }

  const handleOk = () => {
    if (!draft) return;
    navigate(`/draft/${draft.id}`);
    onClose();
  };

  const navigateToTechRequest = () => {
    if (!techRequest) return;
    navigate('/tech-requests/');
    onClose();
  };

  return (
    <>
      <Overlay />
      {createTechRequest ? (
        <ModalWrapper>
          <TextHeader align="center">
            «Ваш технологический запрос отправлен на верификацию. Пожалуйста, ожидайте.
          </TextHeader>
          <StyledButtonsWrapper>
            <StyledButton onClick={navigateToTechRequest}>Ок</StyledButton>
          </StyledButtonsWrapper>
        </ModalWrapper>
      ) : (
        <ModalWrapper>
          <TextHeader>Вы создали заявку на проект.</TextHeader>
          <TextHeaderMedium> Для участия в маршрутизаторе необходимо:</TextHeaderMedium>
          <TextParagraph color={Colors.textDark}>
            ● Заполнить описание <br /> ● Заполнить направление <br /> ● Загрузить презентацию{' '}
            <br /> Эти пункты можно выполнить в редактировании заявки
          </TextParagraph>
          <StyledButtonsWrapper>
            <StyledButton onClick={handleOk}>Ок</StyledButton>
          </StyledButtonsWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default ModalRouter;
