import styled from 'styled-components';

import React from 'react';

import {FlexRowContainer} from '@Common/Utils';

const StyledPaginationDivider = styled(FlexRowContainer)`
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border: 1px solid var(--blue-3, #025ea1);
  background: var(--blue-3, #025ea1);
  color: #fff;
  font-size: 14px;
  min-width: 55px;
`;

type PaginationDivider = {
  text: string;
};

const PaginationDivider = ({text}: PaginationDivider) => {
  return <StyledPaginationDivider>{text}</StyledPaginationDivider>;
};

export default PaginationDivider;
