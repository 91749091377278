import {NavLink} from 'react-router-dom';

import TechRequestsTable from 'components/Tables/TechRequests';
import React from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {ContentBoardMenu} from '@components/Menu';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {PlusImageComponent} from '@Common/Images';

import {UseNavigationList} from '@hooks/UseNavigationList';

import {IUser} from 'types/User';

type StateProps = {
  user: IUser;
};

const TechRequests = ({user}: StateProps) => {
  UseNavigationList([
    {path: '/tech-requests', title: 'Тех. Запросы'},
    {path: '/tech-requests', title: 'Список'},
  ]);
  const {is_project} = user;

  return (
    <>
      <ContentBoardMenu pageTitle="Тех. Запросы">
        <NavLink to={'/tech-requests/create'}>
          {!is_project && (
            <ContentBoardMenuButton
              title={'Добавить'}
              action={() => {}}
              image={PlusImageComponent}
            />
          )}
        </NavLink>
      </ContentBoardMenu>

      <TechRequestsTable />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(TechRequests);
