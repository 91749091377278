import styled from 'styled-components';

import React from 'react';

import MultipleDropdown from '@Common/Dropdown/Multiple';
import {TextHeaderMedium} from '@Common/Text';

import UseCompetency from '@hooks/UseCompetency';

const StyledEditItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  color: ${({theme}) => theme.colors.textDark};
`;

interface IEditProfileItem {
  title: string;
  value?: string[];
  setValue: (value: string[]) => void;
}
const CompetenciesItemEditProfile = ({title, value, setValue}: IEditProfileItem) => {
  const {competencyLabels} = UseCompetency();
  const intersection = competencyLabels.filter(comp => value?.includes(comp.label));
  return (
    <StyledEditItem>
      <TextHeaderMedium>{title}</TextHeaderMedium>
      <MultipleDropdown
        isAddedNewValue={false}
        labels={competencyLabels}
        value={intersection}
        handleChange={options => setValue(options?.map(comp => comp.label) || [])}
      />
    </StyledEditItem>
  );
};

export default CompetenciesItemEditProfile;
