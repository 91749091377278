import {NavLink, Navigate} from 'react-router-dom';

import React from 'react';
import {connect} from 'react-redux';

import {selectFilters} from '@store/filters/selectors';
import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {ContentBoardMenu} from '@components/Menu';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {PlusImageComponent, UploadIcon} from '@Common/Images';
import TooltipCustom from '@Common/Tooltip';

import DraftsTable from '@Tables/Drafts';

import {UseNavigationList} from '@hooks/UseNavigationList';

import {handleSubmitExport} from '@utils/handleExportProject';

import {StateProps} from './types';

const Drafts = ({user, filters}: StateProps) => {
  const {is_project, is_customer, is_expert} = user;
  const {
    tracker,
    supervisor,
    projectArea,
    isArchived,
    isInternal,
    accelerator,
    acceleratorRequest,
  } = filters;
  UseNavigationList([
    {path: '/draft', title: is_project ? 'Мои заявки' : 'Заявки'},
    {path: '/draft', title: 'Список'},
  ]);

  if (is_customer) return <Navigate to="/project" />;

  return (
    <>
      <ContentBoardMenu pageTitle={is_project ? 'Мои заявки' : 'Заявки'}>
        {!is_project && (
          <TooltipCustom
            content={'Экспортировать данные по всем Вашим заявкам в формате .zip (архив)'}>
            <ContentBoardMenuButton
              title={'Экспортировать'}
              action={() =>
                handleSubmitExport({
                  tracker,
                  supervisor,
                  projectArea,
                  isArchived,
                  isInternal,
                  accelerator,
                  acceleratorRequest,
                  in_work: false,
                })
              }
              image={<UploadIcon />}
            />
          </TooltipCustom>
        )}

        {!is_customer && !is_expert && (
          <NavLink to={'create'}>
            <ContentBoardMenuButton
              title={'Добавить'}
              action={() => {}}
              image={PlusImageComponent}
            />
          </NavLink>
        )}
      </ContentBoardMenu>
      <DraftsTable />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
  filters: selectFilters(state),
});

export default connect(mapStateToProps)(Drafts);
