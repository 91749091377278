import {styled} from 'styled-components';

export const FormItem = styled.div`
  display: flex;
  justify-content: left;
  min-height: 50px;
  padding: 0 16px;
  align-items: center;

  gap: 10px;
  border-radius: 10px;

  font-weight: ${({theme}) => theme.fontWeight.normal};
  font-size: ${({theme}) => theme.fontSize.medium};
  color: ${({theme}) => theme.colors.grey};

  border: ${({theme}) => `1px solid ${theme.colors.grayTextOnDark}`};
  background: ${({theme}) => theme.colors.white};

  &:focus {
    outline: ${({theme}) => `1px solid ${theme.colors.skyBlue}`};
    border: none;
  }
`;
