import {IReservationState} from 'types/state/Reservation';

const initialState: IReservationState = {
  value: [
    {
      id: 1,
      title: 'Путь к файлам',
      path: 'sdfsgdg',
    },
    {
      id: 2,
      title: 'Последняя копия',
      path: 'sdfgsd',
    },
  ],
};

export default initialState;
