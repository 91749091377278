import {useQuery} from '@tanstack/react-query';
import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {ProfileService} from '@services/profile';

import {PaginationButtons} from '@components/Menu';

import FiltersPanel from '@Common/FiltersPanel';
import StatusChecker from '@Common/StatusChecker';
import Table from '@Common/Table';
import TableHeader from '@Common/Table/Header';
import {TableItem} from '@Common/Table/Item';
import TableNotFoundItem from '@Common/Table/NotFound';
import {TableRowStyled} from '@Common/Table/Row';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseAppSelector from '@hooks/UseAppSelector';

import {IUser} from 'types/User';

const templateColumns = 'minmax(200px, 1fr) minmax(125px, 250px) minmax(125px, 150px)';

const ProfilesTable = ({user}: {user: IUser}) => {
  const {search, role, lastLogin, page} = UseAppSelector(state => state.filters);
  const [searchTerm, setSearchTerm] = useState('');
  const {is_customer, is_expert, id} = user;

  const {data, isLoading, isError} = useQuery({
    queryKey: ['profiles', searchTerm, lastLogin, role, page],
    queryFn: async () =>
      await ProfileService.getAll({
        searchTerm,
        role,
        lastLogin,
        page,
      }),
  });

  const {
    data: dataProfile,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
  } = useQuery({
    queryKey: ['profiles'],
    queryFn: async () => await ProfileService.getById(id),
  });

  const count = useMemo(() => (is_customer ? 1 : data?.count), [data?.count, is_customer]);
  useEffect(() => {
    if (!search) setSearchTerm(search);
  }, [search]);

  return (
    <>
      <FiltersPanel count={count} isProfiles handleEnterSearch={() => setSearchTerm(search)} />
      <StatusChecker
        errorsArray={[isError, isErrorProfile]}
        loadingsArray={[isLoading, isLoadingProfile]}>
        <Table>
          <TableHeader
            templateColumns={templateColumns}
            titles={[{title: 'Фио'}, {title: 'Email'}, {title: 'Телефон'}]}
          />

          {(is_expert || is_customer) && dataProfile && (
            <TableItem path={`/profile/${dataProfile.id}`}>
              <TableRowStyled $templateColumns={templateColumns}>
                <TextParagraph color={Colors.accentBlue}>{dataProfile.fio || '-'}</TextParagraph>
                <TextParagraph
                  onClick={async () => {
                    await navigator.clipboard.writeText(dataProfile.email1);
                  }}
                  color={Colors.textDark}>
                  {dataProfile.email1}
                </TextParagraph>
                <TextParagraph
                  onClick={async () => {
                    await navigator.clipboard.writeText(dataProfile.phone1 || '');
                  }}
                  color={Colors.textDark}>
                  {dataProfile.phone1}
                </TextParagraph>
              </TableRowStyled>
            </TableItem>
          )}

          {!is_customer &&
            !is_expert &&
            (data?.results.length ? (
              data?.results.map(({fio, email1, phone1, id}) => {
                return (
                  <TableItem key={id} path={`/profile/${id}`}>
                    <TableRowStyled $templateColumns={templateColumns}>
                      <TextParagraph color={Colors.accentBlue}>{fio || '-'}</TextParagraph>
                      <TextParagraph
                        onClick={async () => {
                          await navigator.clipboard.writeText(email1);
                        }}
                        color={Colors.textDark}>
                        {email1}
                      </TextParagraph>
                      <TextParagraph
                        onClick={async () => {
                          await navigator.clipboard.writeText(phone1 || '');
                        }}
                        color={Colors.textDark}>
                        {phone1}
                      </TextParagraph>
                    </TableRowStyled>
                  </TableItem>
                );
              })
            ) : (
              <TableNotFoundItem />
            ))}
        </Table>
      </StatusChecker>
      <PaginationButtons margin={15} count={count} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(ProfilesTable);
