import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {ProfileService} from '@services/profile';

import {transformDropdownData} from '@utils/TransformDropdownData';

export const UseExperts = () => {
  const {
    data: expertsList,
    isLoading: isLoadingExperts,
    isError: isErrorExperts,
  } = useQuery({
    queryKey: ['experts'],
    queryFn: () => ProfileService.getExperts(),
  });

  const expertsLabels = useMemo(() => transformDropdownData(expertsList || []), [expertsList]);

  return {
    expertsList: expertsList || [],
    isLoadingExperts,
    isErrorExperts,
    expertsLabels,
  };
};
