import styled from 'styled-components';

import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {
  CommentsIcon,
  FolderIcon,
  InfoIcon,
  ListCheckIcon,
  ProfilesIcon,
  TechRequestIcon,
} from '@Common/Images';
import {SideBarLink} from '@Common/Links';

import {oldLinks} from './data';
import SideBarPanelTitle from './PanelTitle';
import {ISideBarNavListProps} from './type';

const InfoStyled = styled.div`
  color: ${({theme}) => theme.colors.grayTextOnDark};
  font-size: 16px;
  padding-bottom: 10px;
  width: 240px;
  text-align: center;
`;

const SideBarNavList = ({minimized, user}: ISideBarNavListProps) => {
  const {is_superuser, is_supervisor, is_project, is_customer, is_expert} = user;
  const sidebarLinksList = useRef([
    {
      title: is_project ? 'Мои заявки' : 'Заявки',
      path: '/draft',
      image: <ListCheckIcon />,
      tooltip:
        'Здесь Вы можете найти все заявки на проекты, которые Вы завели в систему. Здесь не отображаются заявки, которым присвоен статус проекта',
    },
    {
      title: is_project ? 'Мои проекты' : 'Проекты',
      path: '/project',
      image: <FolderIcon />,
      tooltip:
        'Здесь Вы можете найти все Ваши проекты, которые были переведены из статуса заявки Куратором. Здесь можно отследить статус проекта, наличие Заказчика и Экспертизы',
    },
    {
      title: 'Тех. Запросы',
      path: '/tech-requests',
      image: <TechRequestIcon />,
      tooltip:
        'Здесь представлены технологические запросы (проблемы, запросы на улучшения, новые проекты и т.д.) от реальных бизнес-заказчиков (компаний-пользователей платформы), на которые Вы можете откликнуться с предложением своего проекта-решения для дальнейшего взаимодействия',
    },
    {
      title: 'Профили',
      path: '/profile',
      image: <ProfilesIcon />,
      tooltip:
        'Здесь представлены профили специалистов, которые входят в состав команд Ваших заявок/проектов: Руководители проекта, Технические и Коммерческие специалисты. Так же вы можете добавить новый профиль для специалиста, через кнопку "добавить"',
    },
  ]);

  const [helpModal, setHelpModal] = useState(false);

  return (
    <>
      {!minimized && <InfoStyled>ПЛАТФОРМА МАРШРУТИЗАЦИИ ПРОЕКТОВ</InfoStyled>}
      {sidebarLinksList.current.map(item => {
        if (is_customer && (item.path === '/draft' || item.path === '/profile')) {
          return null;
        }
        if (is_project && item.path === '/profile') {
          return null;
        }
        if (is_expert && (item.path === '/tech-requests' || item.path === '/profile')) {
          return null;
        }
        return <SideBarLink key={item.title} {...item} isHidden={minimized} />;
      })}
      {(is_superuser || is_supervisor) && (
        <>
          <SideBarPanelTitle title={'ADMIN'} />
          {oldLinks.map(link => (
            <SideBarLink
              key={link.href}
              href={link.href}
              path={'none'}
              title={link.title}
              isHidden={minimized}
              image={link.image}
              tooltip={link.tooltip}
            />
          ))}
        </>
      )}
      <>
        <SideBarPanelTitle title={'Ссылки'} />
        <SideBarLink
          modal={{modal: helpModal, changeModal: setHelpModal}}
          path={'none'}
          title={'Тех. поддержка'}
          isHidden={minimized}
          image={<CommentsIcon />}
          tooltip="В данном разделе Вы можете направить Ваши вопросы администраторам платформы"
        />
        <SideBarLink
          path={'/aggregator'}
          title={'Меры поддержки'}
          isHidden={minimized}
          image={<CommentsIcon />}
          tooltip="В данном разделе Вы можете подробнее ознакомиться с существующими мерами поддержки Вашего проекта"
        />
        <SideBarLink
          href="https://ih.rosatom.ru/"
          path={'none'}
          title={'Об ИнноХаб'}
          isHidden={minimized}
          image={<InfoIcon />}
          tooltip={'В данном разделе Вы можете подробнее узнать о деятельности ИнноХаба Росатома'}
        />
      </>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(SideBarNavList);
