import React from 'react';

import {ContentBoardMenu} from '@components/Menu';

const EditSettingsPage = () => {
  return (
    <>
      <ContentBoardMenu pageTitle={'Изменение'} />
    </>
  );
};

export default EditSettingsPage;
