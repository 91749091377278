import {styled} from 'styled-components';

import {useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {useState} from 'react';
import {createPortal} from 'react-dom';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {CustomersService} from '@services/customers';

import {FilterButton} from '@Common/Buttons';
import DeleteButton from '@Common/Buttons/Delete';
import AcceptModal from '@Common/Modal/Accept';
import CustomerAnswerModal from '@Common/Modal/CustomerAnswer';
import {TextParagraph, TextSmall} from '@Common/Text';

import {Colors} from '@constants/enum';

import {formatDateWithoutTime} from '@utils/formatDate';

import CustomerComment from './CustomerComment';
import {StyledExpertButtons} from './styles';
import {Props} from './types';

export const StyledContentRow = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};

  display: grid;
  grid-template-columns: auto 13% 17% 12% 12% 11% 10%;
  align-items: center;
  gap: 15px;

  padding: 10px;
  width: 100%;
`;

const DraftsCustomerItem = ({data, user}: Props) => {
  const queryClient = useQueryClient();
  const {is_superuser, id: userId, is_customer} = user;
  const pageId = Number(useParams().id);
  const [shownResult, setShownResult] = useState(false);
  const [isShownAccept, setIsShownAccept] = useState(false);
  const [modal, setModal] = useState(false);

  const deleteMutation = useMutation({
    mutationFn: () => CustomersService.delete(data.id),
    onSuccess: () => {
      void queryClient.invalidateQueries(['project-customer-response', pageId]);
    },
  });

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  return (
    <>
      <StyledContentRow>
        <TextParagraph color={Colors.accentBlue}>
          {data.customer.fio || data.customer.email1}
        </TextParagraph>
        <TextSmall>{formatDateWithoutTime(data.created_at)}</TextSmall>
        <TextParagraph color={Colors.textDark}>
          {data.project_step?.accelerator?.name || 'Акселератор не выбран'}/
          {data.project_step?.step?.name || ''}
        </TextParagraph>
        <TextSmall>{formatDateWithoutTime(data.deadline)}</TextSmall>
        <TextParagraph color={Colors.accentBlue}>
          {data.project_customer_status_display ? (
            data.project_customer_status_display
          ) : is_customer && userId === data.customer.id ? (
            <FilterButton isBlue={false} title={'Принять решение'} action={() => setModal(true)} />
          ) : (
            'Ожидание ответа бизнес-заказчика'
          )}
        </TextParagraph>
        <StyledExpertButtons>
          {
            <FilterButton
              isBlue={false}
              title={'Показать комментарий'}
              action={() => setShownResult(prev => !prev)}
            />
          }
        </StyledExpertButtons>

        {(is_superuser || userId === data.created_by_id) && (
          <DeleteButton action={() => setIsShownAccept(true)} />
        )}
      </StyledContentRow>
      {shownResult && <CustomerComment comments={data.comments} />}
      {modal && <CustomerAnswerModal id={data.id} setIsOpen={() => setModal(false)} />}

      {isShownAccept &&
        createPortal(
          <AcceptModal handleYes={handleDelete} setIsOpen={setIsShownAccept} />,
          document.body,
        )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(DraftsCustomerItem);
