import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {tagsService} from '@services/tags';

import {transformDropdownData} from '@utils/TransformDropdownData';

const UseTags = () => {
  const {
    data: tagsList,
    isLoading: isLoadingTags,
    isError: isErrorTags,
  } = useQuery({
    queryKey: ['tags'],
    queryFn: () => tagsService.getAll(),
  });

  const tagsLabels = useMemo(() => transformDropdownData(tagsList || []), [tagsList]);
  return {
    tagsList: tagsList || [],
    isLoadingTags,
    isErrorTags,
    tagsLabels,
    nameTags: tagsList?.map(({name}) => name) || [],
  };
};

export default UseTags;
