import styled from 'styled-components';

import React from 'react';

import {TextInput} from '@Common/Input';
import {Colors, TextHeaderMedium, TextParagraph} from '@Common/Text';

const StyledEditItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  color: ${({theme}) => theme.colors.textDark};
`;

interface IEditProfileItem {
  title: string;
  value: string | null;
  setValue: (value: string) => void;
  helper?: string;
  checkError?: boolean;
}
const GroupsItemEditProfile = ({checkError, title, value, setValue, helper}: IEditProfileItem) => {
  return (
    <StyledEditItem>
      <TextHeaderMedium>{title}</TextHeaderMedium>
      <TextInput
        isNotField={checkError && !value}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <TextParagraph color={Colors.grey}>{helper}</TextParagraph>
    </StyledEditItem>
  );
};

export default GroupsItemEditProfile;
