import {Link} from 'react-router-dom';

import {ContentBoardMenu} from 'components/Menu';
import {UseNavigationList} from 'hooks/UseNavigationList';
import React from 'react';

const WorkflowsItemPage = () => {
  const navigationsList = [
    {path: '/workflows', title: 'Workflows'},
    {path: '/', title: 'WorkflowName'},
  ];

  UseNavigationList(navigationsList);

  return (
    <>
      <ContentBoardMenu pageTitle={'Workflow'}>
        <Link to={'edit'}>Редактировать</Link>
      </ContentBoardMenu>
    </>
  );
};

export default WorkflowsItemPage;
