import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import React, {useMemo} from 'react';
import {ProjectRouterStepListService} from 'services/projectRouterStepList';

import {CheckIcon, PlusIcon, RefreshIcon} from '@Common/Images';

import {stepList} from './stepList';

export const DraftRouterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

export const WrapperPoints = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const RouterStageLine = styled.div`
  width: 70px;
  height: 54px;
  background: transparent;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #003274;
    transform: translateY(-50%);
  }
`;

export const StyledRouterStageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  min-height: 125px;

  & span {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--blue-4, #003274);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const DraftRouterPoint = styled.div<{$active: boolean; $current: boolean}>`
  width: 54px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 100%;
  background: ${({theme, $active, $current}) =>
    $current
      ? theme.colors.darkBlue
      : $active
      ? theme.colors.skyBlue
      : theme.colors.grayBackground};
  display: flex;
  color: white;
  justify-content: center;
  padding: 10px 0;
  font-size: 24px;
`;

export const stageSpreader = (stage: number, check: number) => {
  if (stage !== check) {
    return (
      <StyledRouterStageContainer>
        <RouterStageLine />
      </StyledRouterStageContainer>
    );
  }
};

const StagesPoints = () => {
  const pageId = Number(useParams().id);
  const {data: projectRouterStepList} = useQuery({
    queryKey: ['projectroutersteplist', pageId],
    queryFn: async () => await ProjectRouterStepListService.getByProject(pageId),
  });

  const activeStage = useMemo(
    () => projectRouterStepList?.find(({is_active}) => is_active === true),
    [projectRouterStepList],
  );

  return (
    <DraftRouterContainer>
      {activeStage &&
        stepList.map(item => {
          return (
            <React.Fragment key={item.stage}>
              {stageSpreader(item.stage, 4)}
              <StyledRouterStageContainer>
                <DraftRouterPoint
                  $current={item.stage === (activeStage?.step || 0)}
                  $active={item.stage >= (activeStage?.step || 0)}>
                  {5 - item.stage}
                </DraftRouterPoint>
                <span>
                  {item.stage > (activeStage?.step || 0) ? (
                    <CheckIcon />
                  ) : item.stage < (activeStage?.step || 0) ? (
                    <PlusIcon style={{transform: 'rotate(45deg)'}} />
                  ) : (
                    <RefreshIcon />
                  )}
                  {item.description}
                </span>
              </StyledRouterStageContainer>
            </React.Fragment>
          );
        })}
    </DraftRouterContainer>
  );
};

export default StagesPoints;
