import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {CompanyService} from '@services/company';

import {transformDropdownData} from '@utils/TransformDropdownData';

const UseCompanies = () => {
  const fetchCfoData = async () => {
    return (await CompanyService.getAll()).filter(item => item.name !== '');
  };

  const {
    data: companyList,
    isLoading: isLoadingCompany,
    isError: isErrorCompany,
  } = useQuery({
    queryKey: ['companies'],
    queryFn: fetchCfoData,
  });

  const companyLabels = useMemo(() => transformDropdownData(companyList || []), [companyList]);

  return {
    companyList: companyList || [],
    isLoadingCompany,
    isErrorCompany,
    companyLabels,
    nameCfo: companyList?.map(({name}) => name) || [],
  };
};

export default UseCompanies;
