import {useQuery} from '@tanstack/react-query';

import {TechRequestService} from '@services/techRequest';

const UseTechRequest = (pageId: number) => {
  const fetchTechRequestData = () => TechRequestService.getById(pageId);

  const {data, isLoading, isError} = useQuery({
    queryKey: ['techRequests', pageId],
    queryFn: fetchTechRequestData,
  });
  return {
    data: data,
    isLoadingTechRequest: isLoading,
    isErrorTechRequest: isError,
  };
};
export default UseTechRequest;
