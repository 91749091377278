import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';
import {transformDropdownData} from 'utils/TransformDropdownData';

import {ProjectAreaService} from '@services/projectArea';

const UseProjectArea = () => {
  const fetchProjectAreaData = async () => {
    return (await ProjectAreaService.getAll()).filter(item => item.name !== '');
  };

  const {
    data: projectAreaList,
    isLoading: isLoadingProjectArea,
    isError: isErrorProjectArea,
  } = useQuery({
    queryKey: ['projectArea'],
    queryFn: fetchProjectAreaData,
  });

  const projectAreaLabels = useMemo(
    () => transformDropdownData(projectAreaList || []),
    [projectAreaList],
  );

  return {
    projectAreaList: projectAreaList || [],
    isLoadingProjectArea,
    isErrorProjectArea,
    projectAreaLabels,
    projectAreaNames: projectAreaList?.map(({name}) => name) || [],
  };
};

export default UseProjectArea;
