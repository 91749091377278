import {styled} from 'styled-components';

import React, {useMemo} from 'react';

import {NoActivity} from '@Common/CommentsList';
import CommentProjectStep from '@Common/CommentsList/CommentProjectStep';
import {TextParagraph, TextSmall} from '@Common/Text';

import {Colors} from '@constants/enum';

import {formatDate} from '@utils/formatDate';

import {IProjectStepListItem} from 'types/state/ProjectStepList';

const StyledStagesContentItem = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};

  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 10px;
  width: 100%;
`;

const ButtonName = styled.div`
  cursor: pointer;
  display: flex;
`;

const StagesDate = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledStagesComments = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  background-color: ${({theme}) => theme.colors.grayBackground};
  border-radius: ${({theme}) => theme.borderRadius.full};
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
`;

interface IStageItem {
  item: IProjectStepListItem;
}

const StagesContentItem = ({item}: IStageItem) => {
  const formattedCreatedDate = useMemo(() => formatDate(item.created_at), [item.created_at]);

  return (
    <StyledStagesContentItem>
      <ButtonName>
        <TextParagraph color={Colors.accentBlue}>
          {item.accelerator.name} / {item.step.workflow.name} / {item.step.name}
        </TextParagraph>
      </ButtonName>
      <StagesDate>
        <TextSmall>
          {formattedCreatedDate} ({item.created_by.fio || item.created_by.email1}){' '}
        </TextSmall>
        {item.finished_at && (
          <TextSmall>
            - {formatDate(item.finished_at)} ({item.finished_by.fio || item.finished_by.email1})
          </TextSmall>
        )}
      </StagesDate>
      <StyledStagesComments>
        {item.comments?.length ? (
          item.comments?.map(comment => {
            return <CommentProjectStep key={comment.created_at} comment={comment} />;
          })
        ) : (
          <NoActivity />
        )}
      </StyledStagesComments>
    </StyledStagesContentItem>
  );
};

export default StagesContentItem;
