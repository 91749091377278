import styled from 'styled-components';

import React, {FC} from 'react';

const TableMenuWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 188px 176px 28% 181px 126px;
  align-items: center;
  gap: 8px;
  background: #ffffff;
  border-bottom: 1px solid #d9d9d9;
`;

const TableMenu: FC<{children?: React.ReactNode}> = ({children}) => {
  return <TableMenuWrapper>{children}</TableMenuWrapper>;
};

export default TableMenu;
export {default as ColumnTitle} from './ColumnTitle';
