import styled from 'styled-components';

import React, {FC} from 'react';

interface IStatusCell {
  percentage: number;
  updatedOn: string;
  stageTitle: string;
}

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  flex-grow: 1;
  gap: 8px;
  flex-shrink: 0;
`;

const StatusBar = styled.div`
  width: 70%;
  height: 8px;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--gray-background, #f0f1f6);
`;

const ProgressBar = styled.div<{$progress: number}>`
  border-radius: 16px;
  width: ${props => props.$progress}%;
  height: 8px;
  background: ${props => (props.$progress === 100 ? '#DCEDB6' : '#BEE4FF')};
`;

const StatusText = styled.span`
  color: var(--grey, #51575f);
  font-size: 14px;
`;

const StatusCell: FC<IStatusCell> = ({percentage, updatedOn, stageTitle}) => {
  return (
    <StatusWrapper>
      <StatusBar>
        <ProgressBar $progress={23} />
      </StatusBar>
      <StatusText>
        {stageTitle} ({updatedOn}) {percentage}%
      </StatusText>
    </StatusWrapper>
  );
};

export default StatusCell;
