import styled from 'styled-components';

import React from 'react';
import {sort, sortToggle} from 'store/recommendations/slice';

import {SortButton} from '@Common/Buttons';
import {TableRowStyled} from '@Common/Table/Row';
import {Tag} from '@Common/Tag';
import {TextHeaderMedium, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseAppDispatch from '@hooks/UseAppDispatch';
import UseAppSelector from '@hooks/UseAppSelector';

import {IRecommendationsItem} from 'types/Recommendations';

const RecommendationsTableStyled = styled.div``;

const RecommendationsTableRow = styled(TableRowStyled)`
  grid-template-columns: 30% 25% 30%;
  gap: 50px;
`;

const RecommendationsTableCell = styled.div`
  display: flex;
  gap: 7px;
`;

const RecommendationsTable = () => {
  const recommendationsList = UseAppSelector(state => state.recommendations.value);
  const sortDirection = UseAppSelector(state => state.recommendations.sortDirection);
  const dispatch = UseAppDispatch();

  const handleSort = (sortTitle: keyof IRecommendationsItem) => {
    dispatch(sortToggle());
    dispatch(sort({sortableField: sortTitle, sortDirection: sortDirection}));
  };
  return (
    <RecommendationsTableStyled>
      <RecommendationsTableRow $templateColumns="88% 10%">
        <TextHeaderMedium>
          <SortButton handleSubmit={() => handleSort('tool')} sortDirection={sortDirection} />
          Инструмент поддержки
        </TextHeaderMedium>
        <TextHeaderMedium>
          <SortButton handleSubmit={() => handleSort('stage')} sortDirection={sortDirection} />
          Этап
        </TextHeaderMedium>
        <TextHeaderMedium>
          <SortButton handleSubmit={() => handleSort('tag')} sortDirection={sortDirection} />
          Тeги
        </TextHeaderMedium>
      </RecommendationsTableRow>
      {recommendationsList.map((item: IRecommendationsItem, index) => {
        return (
          <RecommendationsTableRow
            $templateColumns="88% 10%"
            key={item.id}
            $isGray={index % 2 == 0}>
            <TextParagraph color={Colors.textDark}>{item.tool}</TextParagraph>
            <TextParagraph color={Colors.textDark}>{item.stage}</TextParagraph>
            <RecommendationsTableCell>
              <Tag title={item.tag} />
            </RecommendationsTableCell>
          </RecommendationsTableRow>
        );
      })}
    </RecommendationsTableStyled>
  );
};

export default RecommendationsTable;
