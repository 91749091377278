import styled from 'styled-components';

import React, {FC} from 'react';

const StyledTextLight = styled.h3`
  display: flex;
  margin: 0;

  color: ${({theme}) => theme.colors.grey};
  font-weight: ${({theme}) => theme.fontWeight.light};
  font-size: ${({theme}) => theme.fontSize.medium};
`;

const TextLight: FC<{children?: React.ReactNode}> = ({children}) => {
  return <StyledTextLight>{children}</StyledTextLight>;
};

export default TextLight;
