import {useQuery} from '@tanstack/react-query';

import {TechRequestService} from '@services/techRequest';

const UseTechRequestResponses = (techRequestId: number) => {
  const fetchTechRequestResponsesData = () =>
    TechRequestService.getByTechRequestIdResponse(techRequestId);

  const {data, isLoading, isError} = useQuery({
    queryKey: ['techRequestsResponses', techRequestId],
    queryFn: fetchTechRequestResponsesData,
  });
  return {
    data: data?.results,
    isLoadingTechRequestResponses: isLoading,
    isErrorTechRequestResponses: isError,
  };
};
export default UseTechRequestResponses;
