import styled from 'styled-components';

import React, {useState} from 'react';

import {setSearch} from '@store/filters/slice';

import {CloseFilterIcon} from '@Common/Images';

import UseAppDispatch from '@hooks/UseAppDispatch';

import {IInputSearchProps} from './types';

const StylesClearButton = styled.button`
  position: absolute;
  right: 10px; /* регулируйте расстояние кнопки от правого края инпута */
  top: 50%; /* размещение кнопки по вертикали в центре инпута */
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  & svg {
    width: 25px;
    height: 25px;
  }
`;

const SearchInputStyled = styled.input`
  border-radius: 8px;
  padding: 8px 18px;
  font-size: 16px;
  width: 450px;
  outline: none;
  height: 40px;
  border: rgba(24, 24, 29, 0.25) 1px solid;
  position: relative;

  &:focus {
    outline: 1px #00c4ff solid;
  }
`;

const SearchInput = ({handleEnter}: IInputSearchProps) => {
  const dispatch = UseAppDispatch();
  const [value, setValue] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    dispatch(setSearch(event.target.value));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleEnter();
    }
  };

  const handleClear = () => {
    setValue('');
    dispatch(setSearch(''));
  };

  return (
    <div style={{position: 'relative'}}>
      <SearchInputStyled
        type="text"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        placeholder="Поиск"
      />
      {value && ( // отображаем кнопку очистки, если есть значение в поле ввода
        <StylesClearButton onClick={handleClear}>
          <CloseFilterIcon />
        </StylesClearButton>
      )}
    </div>
  );
};

export default SearchInput;
