import styled from 'styled-components';

import {useQuery} from '@tanstack/react-query';
import React, {FC, useRef} from 'react';

import {AcceleratorService} from '@services/accelerators';

import {CheckedIcon, CrossIcon} from '@Common/Images';
import Loader from '@Common/Loader';
import {TableItem} from '@Common/Table/Item';
import {TableRowStyled} from '@Common/Table/Row';
import {TextHeaderMedium, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

const AcceleratorsTableStyled = styled.div``;

const JustifyCenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const AcceleratorsTableRow = styled(TableRowStyled)`
  grid-template-columns: 30% 14% 22% 14%;
  transition: all 0.2s ease-in-out;
  gap: 54px;
`;

interface IAcceleratorsTable {
  editAction: () => void;
}

const AcceleratorsTable: FC<IAcceleratorsTable> = () => {
  const tableColumnTitles = useRef(['Начало', 'Завершение', 'Активен']);

  const {
    data: acceleratorList,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['accelerators'],
    queryFn: () => AcceleratorService.getAll(),
  });

  if (isError) return <div>Произошла ошибка</div>;

  if (isLoading) return <Loader />;

  return (
    <AcceleratorsTableStyled>
      <AcceleratorsTableRow $templateColumns="88% 10%">
        <TextHeaderMedium>Название</TextHeaderMedium>
        {tableColumnTitles.current.map(title => (
          <JustifyCenterWrapper key={title}>
            <TextHeaderMedium>{title}</TextHeaderMedium>
          </JustifyCenterWrapper>
        ))}
      </AcceleratorsTableRow>
      {acceleratorList.map(({id, name, start_at, finish_at, is_active}) => {
        return (
          <TableItem key={id} path={`/accelerator/${id}`}>
            <AcceleratorsTableRow $templateColumns="88% 10%">
              <TextParagraph color={Colors.accentBlue}>{name}</TextParagraph>

              <JustifyCenterWrapper>
                <TextParagraph color={Colors.textDark}>{start_at}</TextParagraph>
              </JustifyCenterWrapper>

              <JustifyCenterWrapper>
                <TextParagraph color={Colors.textDark}>{finish_at}</TextParagraph>
              </JustifyCenterWrapper>

              <JustifyCenterWrapper>
                {is_active ? <CheckedIcon /> : <CrossIcon />}
              </JustifyCenterWrapper>
            </AcceleratorsTableRow>
          </TableItem>
        );
      })}
    </AcceleratorsTableStyled>
  );
};

export default AcceleratorsTable;
