import Loader from 'components/Common/Loader';
import React from 'react';

import {IStatusWrapperProps} from './types';

const StatusChecker = ({errorsArray = [], loadingsArray = [], children}: IStatusWrapperProps) => {
  if (errorsArray.some(status => status === true)) {
    return <div>Произошла ошибка</div>;
  }

  if (loadingsArray.some(status => status === true)) {
    return <Loader />;
  }

  return <>{children}</>;
};

export default StatusChecker;
