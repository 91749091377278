import {useMutation, useQueryClient} from '@tanstack/react-query';

import {TechRequestService} from '@services/techRequest';

import {IPatchRequestField} from 'types/patchRequest';

const UseMutationTechRequest = (pageId: number) => {
  const queryClient = useQueryClient();

  const fetchFn = (data: IPatchRequestField) => TechRequestService.update(pageId, data);
  return useMutation({
    mutationFn: fetchFn,
    onSuccess: () => {
      void queryClient.invalidateQueries(['techRequests', pageId]);
    },
  });
};
export default UseMutationTechRequest;
