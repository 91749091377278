import styled from 'styled-components';

import React, {FC} from 'react';

import {FindIcon} from '@Common/Images';
import {TextParagraph} from '@Common/Text';
import {FormItem} from '@Common/Utils';

import {Colors} from '@constants/enum';

const mockFunction = () => console.log('INPUT');
const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 580px;
  & svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 15px;
    width: 15px;
    left: 3%;
  }
`;

const StyledInput = styled(FormItem).attrs(() => ({
  type: 'text',
  placeholder: 'Поиск',
}))`
  cursor: auto;
  width: 580px;

  padding-left: 39px;
`;

const SortFormInput: FC = () => {
  return (
    <StyledInputWrapper>
      <FindIcon />
      <TextParagraph color={Colors.grey}>
        <StyledInput as="input" onFocus={mockFunction} />
      </TextParagraph>
    </StyledInputWrapper>
  );
};

export default SortFormInput;
