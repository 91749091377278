import styled from 'styled-components';

import React from 'react';

import {FilterButton} from '@Common/Buttons';
import {CrossIcon} from '@Common/Images';
import {TextHeader} from '@Common/Text';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 25px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  z-index: 1001;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

interface ModalProps {
  setIsOpen: (isOpen: boolean) => void;
  handleYes: () => void;
}

const AcceptModal = ({handleYes, setIsOpen}: ModalProps) => {
  const handleClickYes = () => {
    handleYes();
    setIsOpen(false);
  };

  return (
    <>
      <Overlay>
        <ModalWrapper
          onClick={e => {
            e.stopPropagation();
          }}>
          <CloseButton onClick={() => setIsOpen(false)}>
            <CrossIcon />
          </CloseButton>
          <TextHeader align="center">Вы уверены?</TextHeader>

          <ButtonWrapper>
            <FilterButton width="100px" isBlue={false} title={'Да'} action={handleClickYes} />
            <FilterButton
              width="100px"
              isBlue={false}
              title={'Нет'}
              action={() => setIsOpen(false)}
            />
          </ButtonWrapper>
        </ModalWrapper>
      </Overlay>
    </>
  );
};

export default AcceptModal;
