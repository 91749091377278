import {ContentBoardMenu} from 'components/Menu';
import React from 'react';

const WorkflowEditPage = () => {
  return (
    <>
      <ContentBoardMenu pageTitle={'Изменение'} />
    </>
  );
};

export default WorkflowEditPage;
