import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {ProfileService} from '@services/profile';

import {transformDropdownData} from '@utils/TransformDropdownData';

const UseSupervisors = () => {
  const {
    data: supervisorsList,
    isLoading: isLoadingSupervisors,
    isError: isErrorSupervisors,
  } = useQuery({
    queryKey: ['supervisors'],
    queryFn: () => ProfileService.getSupervisors(),
  });

  const supervisorsLabels = useMemo(
    () => transformDropdownData(supervisorsList || []),
    [supervisorsList],
  );

  return {
    supervisorsList: supervisorsList || [],
    isLoadingSupervisors,
    isErrorSupervisors,
    supervisorsLabels,
  };
};

export default UseSupervisors;
