import styled from 'styled-components';

import {NavLink} from 'react-router-dom';

import React, {useState} from 'react';

import {selectUser} from '@store/user/selectors';

import {EditIcon} from '@Common/Images';
import {StyledLink} from '@Common/Link';
import {TextHeaderMedium, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseAppSelector from '@hooks/UseAppSelector';

import {Props} from './types';

const templateColumns =
  '20px minmax(200px, 1fr) minmax(200px, 1fr) minmax(125px, 1fr) minmax(125px, 1fr) 60px';

const TableRowAggregatorStyled = styled.div<{
  $templateColumns: string;
  $textAlign?: string;
}>`
  box-sizing: border-box;
  display: grid;
  align-items: center;
  cursor: pointer;
  gap: 15px;
  width: 100%;
  min-height: 65px;
  text-align: 'left';
  justify-content: 'left';
  grid-template-columns: ${({$templateColumns}) => $templateColumns};
  padding: 0 15px;
  background: ${({theme}) => theme.colors.white};

  &:hover {
    background-color: ${({theme}) => theme.colors.greyCircuit};
  }
`;

const AggregatorWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
`;

const AggregatorParamsStyled = styled.div`
  padding: 10px;
`;

const AggregatorDescStyled = styled.div`
  padding: 10px;
  border-right: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
`;

const ListStyled = styled.ul`
  padding: 0px;
  margin: 0px;
`;

const AggregatorItem = ({aggregatorItem, index}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {is_superuser} = UseAppSelector(selectUser);
  return (
    <>
      <TableRowAggregatorStyled
        onClick={() => setIsOpen(prev => !prev)}
        $templateColumns={templateColumns}>
        <TextParagraph color={Colors.textDark}>{index + 1}</TextParagraph>
        <TextParagraph color={Colors.textDark}>{aggregatorItem.found.name}</TextParagraph>
        <TextParagraph color={Colors.textDark}>{aggregatorItem.name}</TextParagraph>
        <TextParagraph color={Colors.accentBlue}>
          <span onClick={e => e.stopPropagation()}>
            <StyledLink href={aggregatorItem.url}>Ссылка на источник</StyledLink>
          </span>
        </TextParagraph>
        <TextParagraph color={Colors.textDark}>{aggregatorItem.category.name}</TextParagraph>
        {is_superuser && (
          <NavLink to={`${aggregatorItem.id}`}>
            <EditIcon />
          </NavLink>
        )}
      </TableRowAggregatorStyled>

      {isOpen && (
        <AggregatorWrapperStyled>
          <AggregatorDescStyled>
            <TextHeaderMedium align="center">Описание программы</TextHeaderMedium>
            <TextParagraph color={Colors.textDark}>{aggregatorItem.description}</TextParagraph>
          </AggregatorDescStyled>
          <AggregatorParamsStyled>
            <TextHeaderMedium align="center">Параметры поддержки</TextHeaderMedium>
            <ListStyled>
              {aggregatorItem.support_measures.split('\n').map((param, id) => (
                <li key={id}>{param}</li>
              ))}
            </ListStyled>
          </AggregatorParamsStyled>
        </AggregatorWrapperStyled>
      )}
    </>
  );
};

export default AggregatorItem;
