import {useLocation} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';

import {DraftsService} from '@services/drafts';
import {ProjectService} from '@services/project';

import {IPatchRequestField} from 'types/patchRequest';

const UseMutationDraftDocument = (pageId: number) => {
  const queryClient = useQueryClient();
  const locationPathName = useLocation().pathname;

  const page = locationPathName.match(/\/(\w+)\//)?.[1];
  const fetchFn =
    page === 'draft'
      ? (data: IPatchRequestField) => DraftsService.updateDocument(pageId, data)
      : (data: IPatchRequestField) => ProjectService.updateDocument(pageId, data);
  return useMutation({
    mutationFn: fetchFn,
    onSuccess: () => {
      void queryClient.invalidateQueries(['draft', pageId]);
      void queryClient.invalidateQueries(['project', pageId]);
    },
  });
};
export default UseMutationDraftDocument;
