import React, {FC, useState} from 'react';

import {DraftsItemWrapper} from '@components/Wrappers/DraftsItem';

import {PanelHeader} from '@Common/DraftsItem/Panel';
import {UserIcon} from '@Common/Images';

import {IDraftsListItem} from 'types/state/Drafts';

import {DraftsParticipantsContent} from './Content';

const DraftsItemParticipants: FC<{data: IDraftsListItem | null}> = ({data}) => {
  const [isShownContent, setIsShownContent] = useState(false);
  const handleShow = () => {
    setIsShownContent(prev => !prev);
  };

  return (
    <DraftsItemWrapper isHalf={true}>
      <PanelHeader
        isShownContent={isShownContent}
        icon={<UserIcon />}
        title={'Участники'}
        action={handleShow}
      />
      {isShownContent && data && <DraftsParticipantsContent data={data} />}
    </DraftsItemWrapper>
  );
};

export default DraftsItemParticipants;
