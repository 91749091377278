import {ILegalEntitiesState} from 'types/state/LegalEntities';

const initialState: ILegalEntitiesState = {
  sortDirection: 'up',
  value: [
    {
      id: 1,
      name: 'ООО Дивергент',
    },
    {
      id: 2,
      name: 'Другая компания',
    },
    {
      id: 3,
      name: 'Гугл',
    },
    {
      id: 4,
      name: 'Яндекс',
    },
  ],
};

export default initialState;
