import styled from 'styled-components';

import React from 'react';

import {TextInput} from '@Common/Input';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

interface AcceleratorAnswerProps {
  value: string | null;
  setValue: (value: string) => void;
  hint: string;
  question: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
`;

const AcceleratorAnswer = ({value, setValue, hint, question}: AcceleratorAnswerProps) => {
  return (
    <Wrapper>
      <TextParagraph color={Colors.textDark}>{question}</TextParagraph>
      <TextParagraph color={Colors.grayTextOnDark}>{hint}</TextParagraph>
      <TextInput
        limit={1000}
        isTextarea
        height={100}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </Wrapper>
  );
};

export default AcceleratorAnswer;
