import styled from 'styled-components';

import React from 'react';

import {ITextHeader} from './types';

const StyledHeader = styled.h1<{$align?: string; $color?: string}>`
  display: flex;
  margin: 0;
  text-align: ${({$align}) => $align || 'left'};
  justify-content: ${({$align}) => $align || 'left'};
  color: ${({theme, $color}) => ($color ? $color : theme.colors.textDark)};
  font-weight: ${({theme}) => theme.fontWeight.bald};
  font-size: ${({theme}) => theme.fontSize.big};
`;

const TextHeader = ({align, color, children}: ITextHeader) => {
  return (
    <StyledHeader $color={color} $align={align}>
      {children}
    </StyledHeader>
  );
};

export default TextHeader;
