import ReportsTable from 'components/Tables/Reports';
import React, {FC} from 'react';

import {ContentBoardMenu, PaginationMenu} from '@components/Menu';

import {UseNavigationList} from '@hooks/UseNavigationList';

const Reports: FC<{children?: React.ReactNode}> = () => {
  const mockFunction = () => console.log('Support/mock');

  const navigationList = [
    {path: '/reports', title: 'Отчёты'},
    {path: '/reports', title: 'Список'},
  ];

  UseNavigationList(navigationList);

  return (
    <>
      <ContentBoardMenu pageTitle={'Отчёты'}></ContentBoardMenu>
      <PaginationMenu />
      <ReportsTable editAction={mockFunction} />
    </>
  );
};

export default Reports;
