import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useMemo} from 'react';

import {SupportMeasurePatchData, SupportMeasureService} from '@services/support_measure';

import {transformDropdownData} from '@utils/TransformDropdownData';

const UseSupportMeasure = (pageId: number) => {
  const queryClient = useQueryClient();

  const fetchSupportMeasureFn = async () => {
    return await SupportMeasureService.getById(pageId);
  };

  const {
    data: supportMeasure,
    isLoading: isLoadingSupportMeasure,
    isError: isErrorSupportMeasure,
  } = useQuery({
    queryKey: ['support_measure', pageId],
    queryFn: fetchSupportMeasureFn,
  });

  const fetchSupportMeasureFounds = async () => {
    return await SupportMeasureService.getFounds();
  };

  const {
    data: supportMeasureFoundsList,
    isLoading: isLoadingSupportMeasureFounds,
    isError: isErrorSupportMeasureFounds,
  } = useQuery({
    queryKey: ['support_measure_founds'],
    queryFn: fetchSupportMeasureFounds,
  });

  const fetchSupportMeasureCategories = async () => {
    return await SupportMeasureService.getCategories();
  };

  const {
    data: supportMeasureCategoriesList,
    isLoading: isLoadingSupportMeasureCategories,
    isError: isErrorSupportMeasureCategories,
  } = useQuery({
    queryKey: ['support_measure_categories'],
    queryFn: fetchSupportMeasureCategories,
  });

  const updateFoundFn = (data: {id: number; name: string}) =>
    SupportMeasureService.updateFoundById(data.id, data.name);
  const updateSupportMeasureFound = useMutation({
    mutationFn: updateFoundFn,
    onSuccess: data => {
      void queryClient.invalidateQueries(['support_measure_founds', data.id]);
    },
  });

  const updateCategoryFn = (data: {id: number; name: string}) =>
    SupportMeasureService.updateCategoryById(data.id, data.name);
  const updateSupportMeasureCategory = useMutation({
    mutationFn: updateCategoryFn,
    onSuccess: data => {
      void queryClient.invalidateQueries(['support_measure_categories', data.id]);
    },
  });

  const fetchFn = (data: SupportMeasurePatchData) => SupportMeasureService.update(pageId, data);
  const updateSupportMeasure = useMutation({
    mutationFn: fetchFn,
    onSuccess: () => {
      void queryClient.invalidateQueries(['support_measure', pageId]);
    },
  });

  const foundsLabels = useMemo(
    () => transformDropdownData(supportMeasureFoundsList || []),
    [supportMeasureFoundsList],
  );

  const categoriesLabels = useMemo(
    () => transformDropdownData(supportMeasureCategoriesList || []),
    [supportMeasureCategoriesList],
  );

  return {
    updateSupportMeasure,
    supportMeasure,
    isLoadingSupportMeasure,
    isErrorSupportMeasure,
    founds: {
      supportMeasureFoundsList,
      isLoadingSupportMeasureFounds,
      isErrorSupportMeasureFounds,
      updateSupportMeasureFound,
      labels: foundsLabels,
    },
    categories: {
      supportMeasureCategoriesList,
      isLoadingSupportMeasureCategories,
      isErrorSupportMeasureCategories,
      updateSupportMeasureCategory,
      labels: categoriesLabels,
    },
  };
};
export default UseSupportMeasure;
