import styled from 'styled-components';

import React, {useState} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {DraftsItemWrapper} from '@components/Wrappers/DraftsItem';

import {PanelHeader} from '@Common/DraftsItem/Panel';
import {StyledContentRow} from '@Common/DraftsItem/StyledContentRow';
import {InfoIcon} from '@Common/Images';
import SwitchContainer from '@Common/SwitchContainer';
import {TextHeaderMedium} from '@Common/Text';
import {FlexButton} from '@Common/Utils/FlexButton';

import UseMutationTechRequestResponse from '@hooks/UseMutationTechRequestResponse';

import {renameStatusResponse} from '@utils/renameStatus';

import {ITechRequestItem} from 'types/state/TechRequest';
import {IUser} from 'types/User';

const ResponseItem = styled.div`
  width: 100%;
`;

const StyledResponseButton = styled(FlexButton)`
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: ${({theme}) => theme.borderRadius.full};
  background: ${({theme}) => theme.colors.lightBlue};
  color: ${({theme}) => theme.colors.grey};
  font-weight: ${({theme}) => theme.fontWeight.normal};
  font-size: ${({theme}) => theme.fontSize.medium};
  border: none;

  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({theme}) => theme.colors.oceanBlue};
    color: ${({theme}) => theme.colors.white};

    path {
      fill: ${({theme}) => theme.colors.white};
    }
  }
`;

const Response = ({
  response,
  techRequestId,
  user,
}: {
  response: ITechRequestItem;
  techRequestId: number;
  user: IUser;
}) => {
  const [isShownContent, setIsShownContent] = useState(false);

  const {is_customer, is_superuser} = user;

  const mutation = UseMutationTechRequestResponse(response.id, techRequestId);

  const updateToSelected = () => {
    mutation.mutate({
      project_request: techRequestId,
      status: 'selected',
    });
  };

  const updateToDeclined = () => {
    mutation.mutate({project_request: techRequestId, status: 'declined'});
  };

  const handleShow = () => {
    setIsShownContent(prev => !prev);
  };

  return (
    <DraftsItemWrapper isHalf={false}>
      <PanelHeader
        isShownContent={isShownContent}
        icon={<InfoIcon />}
        title={response.created_by.fio}
        action={handleShow}
      />
      <StyledContentRow $templateColumns="1fr 1fr">
        {(is_customer || is_superuser) && response.status === 'active' ? (
          <>
            <StyledResponseButton onClick={updateToSelected}>Согласовать</StyledResponseButton>
            <StyledResponseButton onClick={updateToDeclined}>Отклонить</StyledResponseButton>
          </>
        ) : (
          <TextHeaderMedium>{renameStatusResponse(response.status)}</TextHeaderMedium>
        )}
      </StyledContentRow>

      {isShownContent && techRequestId && (
        <ResponseItem>
          <StyledContentRow $isGray $templateColumns="minmax(125px, 1fr) minmax(200px, 2fr)">
            <TextHeaderMedium>Почта</TextHeaderMedium>
            <SwitchContainer
              isNotEditing
              value={response.created_by.email1 || '-'}></SwitchContainer>
          </StyledContentRow>
          <StyledContentRow $templateColumns="minmax(125px, 1fr) minmax(200px, 2fr)">
            <TextHeaderMedium>Телефон</TextHeaderMedium>
            <SwitchContainer
              isNotEditing
              value={response.created_by.phone1 || '-'}></SwitchContainer>
          </StyledContentRow>
          <StyledContentRow $isGray $templateColumns="minmax(125px, 1fr) minmax(200px, 2fr)">
            <TextHeaderMedium>Комментарий</TextHeaderMedium>
            <SwitchContainer
              isNotEditing
              value={response.comments.map(comment => comment.body)}></SwitchContainer>
          </StyledContentRow>
        </ResponseItem>
      )}
    </DraftsItemWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(Response);
