import RecommendationsTable from 'components/Tables/Recommendations';
import React, {FC, useRef} from 'react';

import {ContentBoardMenu, PaginationMenu} from '@components/Menu';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {PlusIcon} from '@Common/Images';

import useAppSelector from '@hooks/UseAppSelector';
import {UseNavigationList} from '@hooks/UseNavigationList';

const Recommendations: FC<{children?: React.ReactNode}> = () => {
  const mockFunction = () => console.log('Recommendations/mock');

  const recommendationsList = useAppSelector(state => state.recommendations.value);
  const lengthList = recommendationsList.length;

  const navigationList = useRef([
    {path: '/recommendations', title: 'Рекомендации'},
    {path: '/recommendations', title: 'Список'},
  ]);

  UseNavigationList(navigationList.current);

  const PlusImage = <PlusIcon />;

  return (
    <>
      <ContentBoardMenu pageTitle={'Рекомендации'}>
        <ContentBoardMenuButton title={'Добавить заявку'} action={mockFunction} image={PlusImage} />
      </ContentBoardMenu>
      <PaginationMenu lengthList={lengthList} />
      <RecommendationsTable />
    </>
  );
};

export default Recommendations;
