import {useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import {format} from 'date-fns';
import React, {useState} from 'react';

import {ExpertiseService} from '@services/expertise';

import {FilterButton} from '@Common/Buttons';
import CommentEditor from '@Common/CommentEditor';
import DateTimePicker from '@Common/DateTimePicker';
import MultipleDropdown from '@Common/Dropdown/Multiple';
import {DropdownMultipleType} from '@Common/Dropdown/Multiple/types';
import ModalWrapper from '@Common/Modal/Wrapper';
import StatusChecker from '@Common/StatusChecker';
import {TextHeader, TextHeaderMedium, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {UseExperts} from '@hooks/UseExperts';

import {IPostExpertise} from 'types/state/Expertise';

import {ButtonWrapper, ExpertiseContainer} from './styles';
import {IData} from './types';

interface ModalProps {
  onClose: () => void;
}

const ExpertiseModal = ({onClose}: ModalProps) => {
  const queryClient = useQueryClient();
  const pageId = Number(useParams().id);
  const [error, setError] = useState(false);

  const [data, setData] = useState<IData>({
    experts: [],
    comment: '',
    date: new Date(),
  });

  const {isErrorExperts, isLoadingExperts, expertsLabels} = UseExperts();

  const mutationExpertise = useMutation({
    mutationFn: (data: IPostExpertise) => ExpertiseService.create(data),
    onSuccess: () => {
      void queryClient.invalidateQueries(['expertise', pageId]);
    },
  });

  const createExpertise = async () => {
    if (data.comment && data.experts?.length && data.date) {
      await mutationExpertise.mutateAsync({
        project: pageId,
        experts: data.experts?.map(({value}) => +value),
        deadline: format(data.date, 'yyyy-MM-dd'),
        comment: data.comment,
      });

      onClose();
    } else {
      setError(true);
    }
  };
  const handleSetValue = (field: keyof IData) => (value: string | DropdownMultipleType | Date) => {
    setData(prev => ({...prev, [field]: value}));
  };

  return (
    <ModalWrapper setIsOpen={onClose}>
      <ExpertiseContainer>
        <StatusChecker errorsArray={[isErrorExperts]} loadingsArray={[isLoadingExperts]}>
          <TextHeader>Запрос экспертизы</TextHeader>

          <TextHeaderMedium>Эксперты *</TextHeaderMedium>
          <MultipleDropdown
            labels={expertsLabels}
            value={data.experts}
            handleChange={handleSetValue('experts')}
          />
          <TextHeaderMedium>Срок *</TextHeaderMedium>
          <DateTimePicker selectedDate={data.date} setSelectedDate={handleSetValue('date')} />
          <TextHeaderMedium>Комментарий *</TextHeaderMedium>
          <CommentEditor value={data.comment} setValue={handleSetValue('comment')} />
          {error && <TextParagraph color={Colors.red}>Заполнены не все поля</TextParagraph>}

          <ButtonWrapper>
            <FilterButton isBlue={false} title={'Запросить экспертизу'} action={createExpertise} />
          </ButtonWrapper>
        </StatusChecker>
      </ExpertiseContainer>
    </ModalWrapper>
  );
};

export default ExpertiseModal;
