import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {ProfileService} from '@services/profile';

import {transformDropdownData} from '@utils/TransformDropdownData';

const UseProfiles = () => {
  const fetchProfileData = async () => {
    return (await ProfileService.getAllWithoutLimit({searchTerm: ''})).filter(
      item => item.fio !== '',
    );
  };

  const {
    data: profilesList,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['profiles'],
    queryFn: fetchProfileData,
  });

  const supervisors = profilesList?.filter(item => item.is_supervisor);
  const trackers = profilesList?.filter(item => item.is_tracker);

  const profilesLabels = useMemo(() => transformDropdownData(profilesList || []), [profilesList]);
  const supervisorsLabels = useMemo(() => transformDropdownData(supervisors || []), [supervisors]);
  const trackersLabels = useMemo(() => transformDropdownData(trackers || []), [trackers]);

  return {
    profilesList: profilesList || [],
    isLoadingProfiles: isLoading,
    isErrorProfiles: isError,
    profilesLabels,
    supervisorsLabels,
    trackersLabels,
    profilesNames: profilesList?.map(({fio}) => fio) || [],
  };
};

export default UseProfiles;
