import {createSlice} from '@reduxjs/toolkit';
import initialState from 'store/reservation/state';

const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {},
});

export default reservationSlice.reducer;
