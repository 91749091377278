import {useLocation} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';

import {DraftsService} from '@services/drafts';
import {ProjectService} from '@services/project';

import UseAppSelector from './UseAppSelector';

const UseMutationDeleteDraft = (pageId: number) => {
  const queryClient = useQueryClient();
  const {
    projectArea,
    isInternal,
    isArchived,
    search,
    accelerator,
    supervisor,
    tracker,
    isFinished,
    routerStage,
  } = UseAppSelector(state => state.filters);

  const locationPathName = useLocation().pathname;

  const page = locationPathName.match(/\/(\w+)\//)?.[1];

  const fetchFn =
    page === 'draft' ? () => DraftsService.delete(pageId) : () => ProjectService.delete(pageId);
  return useMutation({
    mutationFn: fetchFn,
    onSuccess: () => {
      void queryClient.invalidateQueries([
        'drafts',
        projectArea,
        isInternal,
        isArchived,
        search,
        accelerator,
        supervisor,
        1,
      ]);
      void queryClient.invalidateQueries([
        'projects',
        search,
        projectArea,
        isArchived,
        isInternal,
        tracker,
        accelerator,
        isFinished,
        supervisor,
        routerStage,
        1,
      ]);
    },
  });
};

export default UseMutationDeleteDraft;
