import {useLocation} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import {DraftsService} from 'services/drafts';
import {ProjectService} from 'services/project';

const UseDraftData = (pageId: number) => {
  const locationPathName = useLocation().pathname;

  const page = locationPathName.match(/\/(\w+)\//)?.[1] || 'draft';
  const fetchDraftData =
    page === 'draft' ? () => DraftsService.getById(pageId) : () => ProjectService.getById(pageId);

  const {data, isLoading, isError} = useQuery({
    queryKey: [page, pageId],
    queryFn: fetchDraftData,
  });
  return {
    data: data,
    isLoadingDraft: isLoading,
    isErrorDraft: isError,
    page,
  };
};
export default UseDraftData;
