import styled from 'styled-components';

export const StyledReview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StyledComment = styled.div`
  display: flex;
  gap: 10px;
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  border-radius: ${({theme}) => theme.borderRadius.full};
  padding: 10px;
`;

export const StyledLink = styled.a.attrs<{href: string}>(({href}) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  href: href,
}))`
  display: flex;
  margin: 0;

  word-break: break-word;

  color: ${({theme}) => theme.colors.accentBlue};
  font-weight: ${({theme}) => theme.fontWeight.normal};
  font-size: ${({theme}) => theme.fontSize.small};
`;
