import {useParams} from 'react-router-dom';

import {UseNavigationList} from 'hooks/UseNavigationList';
import {UseTag} from 'hooks/UseTag';
import React, {useEffect, useState} from 'react';

import {ContentBoardMenu} from '@components/Menu';

import EditTagsForm from '@Common/Forms/EditTags';
import Loader from '@Common/Loader';

import {PageProps} from './types';

const TagsEditPage = ({pageTitle}: PageProps) => {
  const [tagName, setTagName] = useState('');
  const {id} = useParams();
  const tagFulfilledId = String(id);

  const navigationList = [
    {path: '/tags', title: 'Теги'},
    {path: '/', title: 'Изменение'},
  ];

  UseNavigationList(navigationList);

  const {tagData, isError, isLoading} = UseTag(tagFulfilledId);
  useEffect(() => {
    if (tagData && tagData.name) setTagName(tagData.name);
  }, [tagData]);

  if (isLoading) return <Loader />;

  if (isError) return <div>Произошла ошибка</div>;

  return (
    <>
      <ContentBoardMenu pageTitle={pageTitle} />
      <EditTagsForm id={tagFulfilledId} tagName={tagName}>
        <input
          type={'text'}
          value={tagName}
          onChange={e => setTagName(e.target.value)}
          required={true}
        />
      </EditTagsForm>
    </>
  );
};

export default TagsEditPage;
