import styled from 'styled-components';

import React, {useState} from 'react';

import UseCheckProfile from '@hooks/UseCheckProfile';

import ActivityActive from './Active';
import ActivityFeedback from './Feedback';
import ActivityMemberIn from './MemberIn';
import ProfileActivityMenu from './Menu';

const WrapperActivity = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProfileActivity = () => {
  const [activeMenu, setActiveMenu] = useState(1);
  const {isEditableProfile} = UseCheckProfile();
  return (
    <WrapperActivity>
      <ProfileActivityMenu active={activeMenu} setActiveMenu={setActiveMenu} />

      {activeMenu === 1 && <ActivityMemberIn />}

      {isEditableProfile && (
        <>
          {activeMenu === 3 && <ActivityFeedback />}
          {activeMenu === 2 && <ActivityActive />}
        </>
      )}
    </WrapperActivity>
  );
};

export default ProfileActivity;
