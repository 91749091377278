import {IUser} from 'types/User';

export const initialState: {data: IUser; isLogged: boolean} = {
  isLogged: false,
  data: {
    id: 2,
    email1: '',
    email2: null,
    phone1: null,
    phone2: null,
    phone3: null,
    fio: '',
    company_name: '',
    position: null,
    source: null,
    extra: null,
    competencies: [],
    avatar: null,
    color: null,
    tags: [],
    subscribes_project: [],
    subscribes_project_request: [],
    subscribe_all_project_requests: false,
    notifications_site: [],
    notifications_mail: [],
    notifications_ws: [],
    is_superuser: false,
    is_supervisor: false,
    is_tracker: false,
    is_project: true,
    is_expert: false,
    is_hired: false,
    is_customer: false,
    is_observer: false,
    is_internal: false,
    social_telegram: null,
    social_linkedin: null,
    social_vk: null,
    social_google: null,
    social_skype: null,
    comment_count: 0,
    last_login: '',
  },
};
