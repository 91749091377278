import {ReactComponent as CloseFilterIcon} from 'assets/images/close-filter-icon.svg';
import {ReactComponent as DownIcon} from 'assets/images/downButtonIcon.svg';
import {ReactComponent as AcceleratorIcon} from 'assets/images/fi-rr-accelerator.svg';
import {ReactComponent as AddIcon} from 'assets/images/fi-rr-add.svg';
import {ReactComponent as BookIcon} from 'assets/images/fi-rr-address-book.svg';
import {ReactComponent as AppsSortIcon} from 'assets/images/fi-rr-apps-sort.svg';
import {ReactComponent as BoxIcon} from 'assets/images/fi-rr-box.svg';
import {ReactComponent as BulbIcon} from 'assets/images/fi-rr-bulb.svg';
import {ReactComponent as CaretDownIcon} from 'assets/images/fi-rr-caret-down.svg';
import {ReactComponent as CaretRightIcon} from 'assets/images/fi-rr-caret-right.svg';
import {ReactComponent as CaretUpIcon} from 'assets/images/fi-rr-caret-up.svg';
import {ReactComponent as CheckIcon} from 'assets/images/fi-rr-check.svg';
import {ReactComponent as ProfilesIcon} from 'assets/images/fi-rr-comment-user.svg';
import {ReactComponent as CommentsIcon} from 'assets/images/fi-rr-comments.svg';
import {ReactComponent as CompressIcon} from 'assets/images/fi-rr-compress-alt.svg';
import {ReactComponent as CubeIcon} from 'assets/images/fi-rr-cube.svg';
import {ReactComponent as DashboardIcon} from 'assets/images/fi-rr-dashboard.svg';
import {ReactComponent as DatabaseIcon} from 'assets/images/fi-rr-database.svg';
import {ReactComponent as DocumentIcon} from 'assets/images/fi-rr-document.svg';
import {ReactComponent as DownloadIcon} from 'assets/images/fi-rr-download.svg';
import {ReactComponent as DuplicateIcon} from 'assets/images/fi-rr-duplicate.svg';
import {ReactComponent as EditIcon} from 'assets/images/fi-rr-edit.svg';
import {ReactComponent as EnvelopeIcon} from 'assets/images/fi-rr-envelope.svg';
import {ReactComponent as FileCheckIcon} from 'assets/images/fi-rr-file-check.svg';
import {ReactComponent as FilterIcon} from 'assets/images/fi-rr-filter.svg';
import {ReactComponent as FlagIcon} from 'assets/images/fi-rr-flag.svg';
import {ReactComponent as FolderIcon} from 'assets/images/fi-rr-folder.svg';
import {ReactComponent as GridIcon} from 'assets/images/fi-rr-grid.svg';
import {ReactComponent as InfoIcon} from 'assets/images/fi-rr-info.svg';
import {ReactComponent as InteractiveIcon} from 'assets/images/fi-rr-interactive.svg';
import {ReactComponent as LabelIcon} from 'assets/images/fi-rr-label.svg';
import {ReactComponent as LayersIcon} from 'assets/images/fi-rr-layers.svg';
import {ReactComponent as ListCheckIcon} from 'assets/images/fi-rr-list-check.svg';
import {ReactComponent as BurgerMenuIcon} from 'assets/images/fi-rr-menu-burger.svg';
import {ReactComponent as MinusIcon} from 'assets/images/fi-rr-minus.svg';
import {ReactComponent as PhoneCallIcon} from 'assets/images/fi-rr-phone-call.svg';
import {ReactComponent as PlusIcon} from 'assets/images/fi-rr-plus.svg';
import {ReactComponent as UndoIcon} from 'assets/images/fi-rr-redo-alt.svg';
import {ReactComponent as RefreshIcon} from 'assets/images/fi-rr-refresh.svg';
import {ReactComponent as ShareIcon} from 'assets/images/fi-rr-share.svg';
import {ReactComponent as LastInIcon} from 'assets/images/fi-rr-sign-in-alt.svg';
import {ReactComponent as StarFillIcon} from 'assets/images/fi-rr-star-fill.svg';
import {ReactComponent as StarIcon} from 'assets/images/fi-rr-star.svg';
import {ReactComponent as TubeIcon} from 'assets/images/fi-rr-test-tube.svg';
import {ReactComponent as TrashIcon} from 'assets/images/fi-rr-trash.svg';
import {ReactComponent as UploadIcon} from 'assets/images/fi-rr-upload.svg';
import {ReactComponent as UserIcon} from 'assets/images/fi-rr-user.svg';
import {ReactComponent as FindIcon} from 'assets/images/Group.svg';
import {ReactComponent as CrossIcon} from 'assets/images/No.svg';
import {ReactComponent as ProjectStatusIcon} from 'assets/images/projectStatus.svg';
import {ReactComponent as RightIcon} from 'assets/images/rightButtonIcon.svg';
import {ReactComponent as RoutesIcon} from 'assets/images/routesIcon.svg';
import {ReactComponent as SaveDisk} from 'assets/images/savedisk.svg';
import {ReactComponent as TechRequestIcon} from 'assets/images/techRequest.svg';
import {ReactComponent as CheckedIcon} from 'assets/images/Yes.svg';
import React from 'react';

const PlusImageComponent = <PlusIcon />;
const DownloadImageComponent = <DownloadIcon />;

export {default as FilterGIcon} from './Filter';
export {
  AcceleratorIcon,
  RoutesIcon,
  AddIcon,
  AppsSortIcon,
  BookIcon,
  BulbIcon,
  BurgerMenuIcon,
  CaretDownIcon,
  BoxIcon,
  CaretRightIcon,
  CaretUpIcon,
  CheckIcon,
  CheckedIcon,
  CloseFilterIcon,
  CommentsIcon,
  CompressIcon,
  CrossIcon,
  CubeIcon,
  DashboardIcon,
  ProjectStatusIcon,
  DatabaseIcon,
  DocumentIcon,
  DownIcon,
  DownloadIcon,
  DownloadImageComponent,
  DuplicateIcon,
  EditIcon,
  EnvelopeIcon,
  FileCheckIcon,
  TechRequestIcon,
  FilterIcon,
  FindIcon,
  FolderIcon,
  GridIcon,
  InfoIcon,
  InteractiveIcon,
  LabelIcon,
  LastInIcon,
  LayersIcon,
  ListCheckIcon,
  MinusIcon,
  PhoneCallIcon,
  PlusIcon,
  PlusImageComponent,
  ProfilesIcon,
  RefreshIcon,
  RightIcon,
  SaveDisk,
  ShareIcon,
  StarFillIcon,
  StarIcon,
  TrashIcon,
  TubeIcon,
  UndoIcon,
  UploadIcon,
  UserIcon,
  FlagIcon,
};
