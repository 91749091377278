import React from 'react';

import {InteractiveIcon} from '@Common/Images';

export const oldLinks = [
  {
    href: 'https://copy.nextproject.online/workflow/',
    title: 'Workflows',
    image: <InteractiveIcon />,
    tooltip: 'Переход по внешней ссылке на страницу workflows',
  },
  {
    href: 'https://copy.nextproject.online/accelerator/',
    title: 'Акселераторы',
    image: <InteractiveIcon />,
    tooltip: 'Переход по внешней ссылке на страницу акселераторов',
  },

  {
    href: 'https://copy.nextproject.online/competency/',
    title: 'Компетенции',
    image: <InteractiveIcon />,
    tooltip: 'Переход по внешней ссылке на страницу компетенций',
  },
  {
    href: 'https://copy.nextproject.online/projectarea/',
    title: 'Направления',
    image: <InteractiveIcon />,
    tooltip: 'Переход по внешней ссылке на страницу направлений',
  },
  {
    href: 'https://copy.nextproject.online/tag/',
    title: 'Теги',
    image: <InteractiveIcon />,
    tooltip: 'Переход по внешней ссылке на страницу тегов',
  },
];
