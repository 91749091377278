import styled from 'styled-components';

import React from 'react';

import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

const StyledMenuItem = styled.div<{$isActive?: boolean}>`
  display: flex;
  padding: 10px 16px;
  border-radius: ${({theme}) => theme.borderRadius.onlyTop};
  border: ${({theme, $isActive}) =>
    $isActive ? `1px solid ${theme.colors.greyCircuit}` : `1px solid ${theme.colors.white}`};
  border-bottom: none;
  cursor: pointer;
`;

interface ImenuItem {
  active: string;
  setActive: (title: string) => void;
  title: string;
}

const MenuItem = ({active, setActive, title}: ImenuItem) => {
  return (
    <StyledMenuItem $isActive={active === title} onClick={() => setActive(title)}>
      <TextParagraph color={Colors.accentBlue}>{title}</TextParagraph>
    </StyledMenuItem>
  );
};

export default MenuItem;
