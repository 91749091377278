import React, {useState} from 'react';

import {TextInput} from '@Common/Input';
import SwitchContainer from '@Common/SwitchContainer';
import {TableRowStyled} from '@Common/Table/Row';

type CategoriesRowProps = {
  id: number;
  initialName: string;
  index: number;
  templateColumns: string;
  updateSupportMeasureCategory: (id: number, name: string) => void;
};

const CategoriesRow = ({
  id,
  initialName,
  index,
  templateColumns,
  updateSupportMeasureCategory,
}: CategoriesRowProps) => {
  const [name, setName] = useState(initialName);

  const onSave = () => {
    updateSupportMeasureCategory(id, name);
  };

  const onUndo = () => {
    setName(initialName);
  };
  return (
    <TableRowStyled $isGray={index % 2 === 0} $templateColumns={templateColumns}>
      <SwitchContainer value={name} onSave={onSave} onUndo={onUndo}>
        <TextInput value={name} onChange={e => setName(e.target.value)} />
      </SwitchContainer>
    </TableRowStyled>
  );
};

export default CategoriesRow;
