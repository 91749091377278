import {useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import React, {useMemo} from 'react';

import {ProjectStepListService} from '@services/projectStepList';

import {
  DraftRouterContainer,
  DraftRouterPoint,
  StyledRouterStageContainer,
  WrapperPoints,
  stageSpreader,
} from '@components/DraftsItem/Router/StagesPoints';

const AcceleratorPoints = ({
  workflow_id,
  isFinished,
}: {
  workflow_id: number;
  isFinished: boolean;
}) => {
  const pageId = Number(useParams().id);
  const {data: stepList} = useQuery({
    queryKey: ['step', workflow_id],
    queryFn: async () => await ProjectStepListService.getStepsByWorkflow(workflow_id),
  });

  const {data: activeSteps} = useQuery({
    queryKey: ['step', pageId],
    queryFn: async () => await ProjectStepListService.getActive(pageId),
  });

  const activeStepOrdering = useMemo(
    () => (activeSteps && activeSteps[0] ? activeSteps[0].step.ordering : -1),
    [activeSteps],
  );
  return (
    <WrapperPoints>
      <DraftRouterContainer>
        {stepList?.map(step => {
          return (
            <React.Fragment key={step.id}>
              {stageSpreader(step.ordering, 0)}

              <StyledRouterStageContainer>
                <DraftRouterPoint
                  $current={!isFinished && step.ordering === activeStepOrdering}
                  $active={isFinished || step.ordering < activeStepOrdering}>
                  {step.ordering + 1}
                </DraftRouterPoint>
                <span>{step.name}</span>
              </StyledRouterStageContainer>
            </React.Fragment>
          );
        })}
      </DraftRouterContainer>
    </WrapperPoints>
  );
};

export default AcceleratorPoints;
