import styled from 'styled-components';

import React, {useRef, useEffect} from 'react';

const Container = styled.div<{$height: number | null; $width?: number}>`
  position: absolute;
  top: ${({$height}) => ($height ? `-${$height}%` : '105%')};
  left: 0;
  z-index: 1000;

  width: ${({$width}) => ($width ? $width : 340)}px;
  max-height: 155px;

  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};

  background-color: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.borderRadius.full};
  overflow: hidden;
  padding: 10px 2.5px 10px 0;
`;

const DropdownMenu = styled.ul<{$sidebar: boolean}>`
  /* width: 336px; */
  max-height: 135px;

  margin: 0;
  padding: 0 7px;
  display: flex;
  flex-direction: column;
  gap: 2.5px;

  list-style-type: none;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.colors.grayTextOnDark};
    border-radius: ${({theme}) => theme.borderRadius.full};
  }

  &::-webkit-scrollbar-track {
    background-color: ${({theme}) => theme.colors.greyCircuit};
    border-radius: ${({theme}) => theme.borderRadius.full};
    margin-block: 2.5px;
  }
`;

const DropdownMenuItem = styled.li<{$isSelected: boolean}>`
  display: flex;
  cursor: pointer;

  padding: 4px 16px;

  font-size: ${({theme}) => theme.fontSize.medium};
  font-weight: ${({theme}) => theme.fontWeight.normal};

  border-radius: ${({$isSelected, theme}) => ($isSelected ? theme.borderRadius.full : 0)};

  color: ${({$isSelected, theme}) => ($isSelected ? theme.colors.white : theme.colors.grey)};
  background-color: ${({$isSelected, theme}) =>
    $isSelected ? theme.colors.darkBlue : 'transparent'};

  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({theme}) => theme.colors.grey};
    background-color: ${({theme}) => theme.colors.buttonBackground};
  }
`;

interface DropdownListProps {
  sidebar: boolean;
  labels: string[];
  selected: string | null;
  selectedMultiply?: string[] | null;
  handleSelect: (arg0: string) => void;
  children?: React.ReactNode;
  width?: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  addedValue?: string; //Добавление для тэгов
  isUp?: boolean;
}

const DropdownScrollbarList: React.FC<DropdownListProps> = ({
  sidebar = true,
  labels,
  selected,
  selectedMultiply,
  handleSelect,
  children,
  width,
  isOpen,
  setIsOpen,
  addedValue,
  isUp = false,
}) => {
  const dropdownListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownListRef.current && !dropdownListRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, setIsOpen]);

  if (!labels.length) {
    labels = [addedValue || 'Не найдено'];
  }

  const getSelectedAndUnselectedLabels = (labels: string[]) => {
    //Массив выбранных и невыбранных значений
    let selectedLabels: string[] = [];
    let unselectedLabels: string[] = labels;
    //Если есть мультивыбор, то добавление массива, если нет мультивыбора, то одной строки
    if (selectedMultiply) {
      selectedLabels = labels.filter(label => selectedMultiply.includes(label));
      unselectedLabels = labels.filter(label => !selectedMultiply.includes(label));
    } else if (selected) {
      selectedLabels = [selected];
      unselectedLabels = labels.filter(label => label !== selected);
    }

    return {selectedLabels, unselectedLabels};
  };

  const {selectedLabels, unselectedLabels} = getSelectedAndUnselectedLabels(labels);

  //Отрисовка элементов списка. labels - массив для отрисовки, isSelected (выбранные, не выбранные)
  const renderDropdownItems = (labels: string[], isSelected: boolean) => {
    return labels.map((label, key) => (
      <DropdownMenuItem key={key} $isSelected={isSelected} onClick={() => handleSelect(label)}>
        {children}
        {label}
      </DropdownMenuItem>
    ));
  };

  const height =
    labels.length === 3
      ? labels.length * 95
      : labels.length === 2
      ? labels.length * 105
      : labels.length === 1
      ? 125
      : 380;

  return (
    <Container $height={isUp ? height : null} ref={dropdownListRef} $width={width}>
      <DropdownMenu $sidebar={sidebar}>
        {renderDropdownItems(selectedLabels, true)}
        {renderDropdownItems(unselectedLabels, false)}
      </DropdownMenu>
    </Container>
  );
};
export default DropdownScrollbarList;
