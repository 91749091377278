import {createSlice} from '@reduxjs/toolkit';

import initialState from './state';

const workflowsSlice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {},
});

export default workflowsSlice.reducer;
