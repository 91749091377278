import {IRecommendationsState} from 'types/Recommendations';

const initialState: IRecommendationsState = {
  sortDirection: 'up',
  value: [
    {
      id: 1,
      tool: 'Инструмент 1',
      stage: 'Масштабирование',
      tag: 'telegram',
    },
    {
      id: 2,
      tool: 'Инструмент 2',
      stage: 'Разворачивание',
      tag: 'tg',
    },
  ],
};

export default initialState;
