import {format, parseISO} from 'date-fns';
import {ru} from 'date-fns/locale';

export const formatDate = (stringDate?: string | null): string => {
  if (!stringDate) return '';
  return format(parseISO(stringDate), "d MMMM yyyy г. 'в' HH:mm", {locale: ru});
};

export const formatDateWithoutTime = (stringDate?: string | null): string => {
  if (!stringDate) return '';
  return format(parseISO(stringDate), 'dd MMMM yyyy г.', {locale: ru});
};
