import styled from 'styled-components';

const ContentBoardMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  background: #f0f1f6;
  border-radius: 10px 10px 0 0;
  justify-content: space-between;
  padding: 16px 0 16px 24px;
`;

const ContentBoardMenuButtonList = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  padding-right: 21px;
`;

export {ContentBoardMenuButtonList, ContentBoardMenuWrapper};
