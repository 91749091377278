import {createBrowserRouter, RouterProvider, Navigate} from 'react-router-dom';

import ErrorPage from 'ErrorPage';
import React from 'react';
import Drafts from 'routes/Drafts';
import DraftItem from 'routes/Drafts/Item';
import Recommendations from 'routes/Recommendations';
import TagsPage from 'routes/Tags';
import WorkflowEditPage from 'routes/Workflows/Edit';
import WorkflowsItemPage from 'routes/Workflows/Item';

import {Accelerators} from './Accelerators';
import ActivationPage from './Activation';
import Authorization from './Authorization';
import CheckListCreate from './CheckListCreate';
import {Dashboards} from './Dashboards';
import DraftCreate from './DraftCreate';
import EditSettingsPage from './EditSettings';
import LegalEntities from './LegalEntities';
import {NewsletterTemplates} from './NewsletterTemplates';
import Profiles from './Profiles';
import EditProfileItem from './Profiles/Edit';
import ProfileItem from './Profiles/item';
import Projects from './Projects';
import RegistrationPage from './Registration';
import {Reports} from './Reports';
import {Reservation} from './Reservation';
import ResetPasswordPage from './ResetPassword';
import Root from './Root';
import {Support} from './Support';
import SupportMeasure from './SupportMeasure';
import SupportMeasureItemPage from './SupportMeasure/item';
import TagsEditPage from './Tags/Edit';
import TechRequest from './TechRequest';
import TechRequestCreate from './TechRequestCreate';
import TechRequests from './TechRequests';
import Workflows from './Workflows';
import WorkflowCreate from './Workflows/Create';

const router = createBrowserRouter([
  {
    path: '/auth',
    element: <Authorization />,
  },
  {
    path: '/password_reset',
    element: <ResetPasswordPage />,
  },
  {
    path: '/registration',
    element: <RegistrationPage />,
  },
  {
    path: '/activate',
    element: <ActivationPage />,
  },
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/draft" />,
      },
      {
        path: 'project',

        children: [
          {
            index: true,
            element: <Projects />,
          },
          {
            path: ':id',
            children: [
              {
                index: true,
                element: <DraftItem />,
              },
              {
                path: 'route',
                element: <CheckListCreate />,
              },
            ],
          },
        ],
      },
      {
        path: 'draft',

        children: [
          {
            index: true,
            element: <Drafts />,
          },
          {
            path: ':id',
            children: [
              {
                index: true,
                element: <DraftItem />,
              },
              {
                path: 'route',
                element: <CheckListCreate />,
              },
            ],
          },
          {
            path: 'create',
            element: <DraftCreate />,
          },
        ],
      },

      {
        path: '/dashboards',
        element: <Dashboards />,
      },
      {
        path: 'tech-requests',

        children: [
          {
            index: true,
            element: <TechRequests />,
          },
          {
            path: ':id',
            children: [
              {
                index: true,
                element: <TechRequest />,
              },
              {
                path: 'route',
                element: <CheckListCreate />,
              },
            ],
          },
          {
            path: 'create',
            element: <TechRequestCreate />,
          },
        ],
      },
      {
        path: '/workflows',
        children: [
          {
            index: true,
            element: <Workflows />,
          },
          {
            path: 'create',
            element: <WorkflowCreate />,
          },
          {
            path: ':id',
            children: [
              {
                index: true,
                element: <WorkflowsItemPage />,
              },
              {
                path: 'edit',
                element: <WorkflowEditPage />,
              },
            ],
          },
        ],
      },
      {
        path: '/accelerators',
        element: <Accelerators />,
      },
      {
        path: '/project_request',
        children: [
          {
            index: true,
            element: <Profiles />,
          },
        ],
      },
      {
        path: '/profile',
        children: [
          {
            index: true,
            element: <Profiles />,
          },
          {
            path: ':id',
            element: <ProfileItem />,
          },
          {
            path: ':id/edit',
            element: <EditProfileItem />,
          },
        ],
      },
      {
        path: '/newsletter-templates',
        element: <NewsletterTemplates />,
      },
      {
        path: '/reports',
        element: <Reports />,
      },
      {
        path: '/settings',
        children: [
          {
            path: 'edit',
            element: <EditSettingsPage />,
          },
        ],
      },
      {
        path: '/reservation',
        element: <Reservation />,
      },
      {
        path: '/legal-entities',
        element: <LegalEntities />,
      },
      {
        path: '/support',
        element: <Support />,
      },
      {
        path: '/aggregator',
        element: <SupportMeasure />,
      },
      {
        path: '/aggregator/:id',
        element: <SupportMeasureItemPage />,
      },
      {
        path: '/recommendations',
        element: <Recommendations />,
      },
      {
        path: '/tags',
        children: [
          {index: true, element: <TagsPage pageTitle={'Теги'} />},
          {path: ':id/edit', element: <TagsEditPage pageTitle={'Редактирование'} />},
        ],
      },
    ],
  },
]);

const Router = () => <RouterProvider router={router} />;
export default Router;
