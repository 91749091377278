import {createSlice} from '@reduxjs/toolkit';
import initialState from 'store/newsletterTemplates/state';

const newsletterTemplatesSlice = createSlice({
  name: 'newsletterTemplates',
  initialState,
  reducers: {},
});

export default newsletterTemplatesSlice.reducer;
