import React from 'react';

import Loader from '@Common/Loader';

import UseTags from '@hooks/UseTags';

import ColumnNames from './ColumnNames';
import Record from './Record';

const TagsTable = () => {
  const {isLoadingTags, isErrorTags, tagsList} = UseTags();

  if (isLoadingTags) return <Loader />;

  if (isErrorTags) return <div>Произошла ошибка</div>;

  return (
    <>
      <ColumnNames />
      {tagsList.map(tag => {
        return <Record {...tag} key={tag.id} />;
      })}
    </>
  );
};

export default TagsTable;
