import {CheckBoxRole} from './types';

export const rolesData: CheckBoxRole[] = [
  {
    field: 'is_superuser',
    title: 'Админ',
    helper: '',
  },
  {
    field: 'is_project',
    title: 'Инициатор',
    helper: 'Инициатор проектов',
  },
  {
    field: 'is_supervisor',
    title: 'Куратор',
    helper: 'Сопровождает проекты и тех. запросы',
  },
  {
    field: 'is_tracker',
    title: 'Трекер',
    helper: '',
  },
  {
    field: 'is_expert',
    title: 'Эксперт',
    helper: 'Эксперт в какой-либо области',
  },
  {
    field: 'is_hired',
    title: 'Исполнитель',
    helper: '',
  },
  {
    field: 'is_customer',
    title: 'Бизнес-заказчик',
    helper: '',
  },
  {
    field: 'is_internal',
    title: 'Являюсь сотрудником атомной отрасли',
    helper: 'Пользователь с доступом к проектам, помеченным параметром "внутренний"',
  },
  {
    field: 'is_observer',
    title: 'Наблюдатель',
    helper: 'Автоматически устанавливается из рассылки на проект',
  },
];
