import React, {useRef} from 'react';

import {ContentBoardMenu} from '@components/Menu';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {PlusImageComponent} from '@Common/Images';

import LegalEntitiesTable from '@Tables/LegalEntities';

import {UseNavigationList} from '@hooks/UseNavigationList';

const LegalEntities = () => {
  const mockFunction = () => console.log('LegalEntities/mock');

  const navigationList = useRef([
    {path: '/legal-entities', title: 'Юр. лица'},
    {path: '/legal-entities', title: 'Список'},
  ]);

  UseNavigationList(navigationList.current);

  return (
    <>
      <ContentBoardMenu pageTitle={'Юр. лица'}>
        <ContentBoardMenuButton
          title={'Добавить заявку'}
          action={mockFunction}
          image={PlusImageComponent}
        />
      </ContentBoardMenu>

      <LegalEntitiesTable />
    </>
  );
};

export default LegalEntities;
