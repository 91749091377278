import {useNavigate} from 'react-router-dom';

import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useDispatch} from 'react-redux';
import {ProfileService} from 'services/profile';
import {authUser} from 'store/user/slice';

import Loader from '@Common/Loader';

import {axiosDocument, instance} from './api';

const AuthApp = ({children}: PropsWithChildren) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const profile_id = localStorage.getItem('profile_id');
  const [isLoading, setIsLoading] = useState(true);
  const removeCookie = useCookies(['accelerator_answers'])[2];
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && profile_id) {
      instance.defaults.headers.common['Authorization'] = `Token ${token}`;
      axiosDocument.defaults.headers.common['Authorization'] = `Token ${token}`;

      void ProfileService.getById(profile_id)
        .then(data => {
          dispatch(authUser(data));
        })
        .catch(error => {
          console.error('Error fetching profile data:', error);
          localStorage.removeItem('token');
          localStorage.removeItem('profile_id');
          removeCookie('accelerator_answers', {path: '/'});
        })
        .finally(() => setIsLoading(false));
    } else {
      navigate('/auth');
      removeCookie('accelerator_answers', {path: '/'});
      setIsLoading(false);
    }
  }, [dispatch, profile_id, token, navigate, removeCookie]);

  if (isLoading) return <Loader />;

  return <>{children}</>;
};

export default AuthApp;
