import styled from 'styled-components';

import React, {FC} from 'react';

import {TableRowStyled} from '@Common/Table/Row';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseAppSelector from '@hooks/UseAppSelector';

const ReportsTableStyled = styled.div``;

const ReportsTableRow = styled(TableRowStyled)`
  grid-template-columns: 90%;
`;

interface IReportsTable {
  editAction: () => void;
}

const ReportsTable: FC<IReportsTable> = () => {
  const reportsList = UseAppSelector(state => state.reports.value);

  return (
    <ReportsTableStyled>
      {reportsList.map(item => {
        return (
          <ReportsTableRow $templateColumns="88% 10%" key={item.id}>
            <TextParagraph color={Colors.accentBlue}>{item.title}</TextParagraph>
          </ReportsTableRow>
        );
      })}
    </ReportsTableStyled>
  );
};

export default ReportsTable;
