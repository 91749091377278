import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import {Dispatch, bindActionCreators} from '@reduxjs/toolkit';
import {useQueryClient, useQuery, useMutation} from '@tanstack/react-query';
import React, {useMemo, useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';
import {updateUser} from '@store/user/slice';

import {ProfileService} from '@services/profile';

import {ContentBoardMenu} from '@components/Menu';
import CompetenciesItemEditProfile from '@components/ProfileItem/Edit/CompetenciesItem';
import GroupsEditProfile from '@components/ProfileItem/Edit/Groups';
import GroupsAvatarItem from '@components/ProfileItem/Edit/GroupsAvatarItem';
import GroupsItemEditProfile from '@components/ProfileItem/Edit/GroupsItem';
import GroupsItemCheckboxEditProfile from '@components/ProfileItem/Edit/GroupsItemCheckbox';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {SaveDisk} from '@Common/Images';
import StatusChecker from '@Common/StatusChecker';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {UseNavigationList} from '@hooks/UseNavigationList';

import {IPutUser} from 'types/User';

import {rolesData} from './data';
import {IDataRoles, Props} from './types';

const StyledContentEdit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  justify-content: space-between;
  width: 100%;
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  color: ${({theme}) => theme.colors.textDark};
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
`;

export const WrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
`;

const EditProfileItem = ({user, updateUser}: Props) => {
  const queryClient = useQueryClient();
  const pageId = Number(useParams().id);
  const {is_superuser, is_supervisor, is_customer} = user;
  const {
    data: profileData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['profile', pageId],
    queryFn: () => ProfileService.getById(pageId),
  });

  const initialData = useMemo(
    () => ({
      data: {
        fio: profileData?.fio || '',
        email1: profileData?.email1 || '',
        email2: profileData?.email2 || '',
        phone1: profileData?.phone1 || '',
        phone2: profileData?.phone2 || '',
        phone3: profileData?.phone3 || '',
        company_name: profileData?.company_name || '',
        position: profileData?.position || '',
        competencies: profileData?.competencies || [],
        social_telegram: profileData?.social_telegram || '',
        social_linkedin: profileData?.social_linkedin || '',
        social_vk: profileData?.social_vk || '',
        social_google: profileData?.social_google || '',
        social_skype: profileData?.social_skype || '',
        tags: profileData?.tags || [],
        subscribes_project: profileData?.subscribes_project || [],
        subscribes_project_request: profileData?.subscribes_project_request || [],
      },
      roleData: {
        is_supervisor: profileData?.is_supervisor,
        is_superuser: profileData?.is_superuser,
        is_project: profileData?.is_project,
        is_expert: profileData?.is_expert,
        is_hired: profileData?.is_hired,
        is_customer: profileData?.is_customer,
        is_internal: profileData?.is_internal,
        is_observer: profileData?.is_observer,
        is_tracker: profileData?.is_tracker,
      },
      avatar: {
        avatar: profileData?.avatar || '',
      },
    }),
    [profileData],
  );

  const [data, setData] = useState(initialData.data);
  const [dataRoles, setDataRoles] = useState<IDataRoles>(initialData.roleData);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    setData(initialData.data);
    setDataRoles(initialData.roleData);
  }, [initialData]);

  const mutation = useMutation({
    mutationFn: (data: IPutUser) => ProfileService.update({id: pageId, data, avatar}),
    onSuccess: responce => {
      void queryClient.invalidateQueries(['profile', pageId]);
      if (responce.data.id === user.id) updateUser(responce.data);
      setError('');
    },
    onError: (e: Error) => setError(e.message),
  });

  useEffect(() => {
    if (profileData?.avatar)
      fetch(profileData.avatar)
        .then(response => response.blob())
        .then(blob => {
          setAvatar(new File([blob], 'user.png', {type: 'image/png'}));
        })
        .catch(error => {
          console.error('Ошибка загрузки изображения:', error);
        });
  }, [profileData?.avatar]);

  const handleSave = () => {
    mutation.mutate({...data, ...dataRoles});
  };

  UseNavigationList([
    {
      path: '/profile',
      title: 'Профили',
    },
    {
      path: `/profile/${pageId}`,
      title: 'Профиль',
    },
    {
      path: `/profile/${pageId}/edit`,
      title: 'Редактирование',
    },
  ]);

  const handleDataChange = (field: keyof typeof data) => (value: string | string[]) => {
    setData(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDataRolesChange = (field: keyof IDataRoles) => (value: boolean) => {
    setDataRoles(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <>
      <ContentBoardMenu pageTitle="Изменение">
        <ContentBoardMenuButton title="Сохранить" action={handleSave} image={<SaveDisk />} />
      </ContentBoardMenu>
      <StatusChecker errorsArray={[isError]} loadingsArray={[isLoading]}>
        <StyledContentEdit>
          {error && <TextParagraph color={Colors.red}>{error}</TextParagraph>}
          <GroupsEditProfile>
            <GroupsItemEditProfile
              title="ФИО *"
              checkError
              value={data.fio}
              setValue={v => setData(prev => ({...prev, fio: v}))}
              helper="Пример: Иванов Иван Иванович"
            />
          </GroupsEditProfile>

          <GroupsEditProfile title="Emails">
            <GroupsItemEditProfile
              title="Email1 *"
              checkError
              value={data.email1}
              setValue={handleDataChange('email1')}
              helper="Основной Email"
            />
            <GroupsItemEditProfile
              title="Email2"
              value={data.email2}
              setValue={handleDataChange('email2')}
              helper="Дополнительный Email"
            />
          </GroupsEditProfile>

          <GroupsEditProfile title="Телефоны">
            <GroupsItemEditProfile
              title="Телефон 1"
              value={data.phone1}
              setValue={handleDataChange('phone1')}
            />
            <GroupsItemEditProfile
              title="Телефон 2"
              value={data.phone2}
              setValue={handleDataChange('phone2')}
            />
            <GroupsItemEditProfile
              title="Телефон 3"
              value={data.phone3}
              setValue={handleDataChange('phone3')}
            />
          </GroupsEditProfile>

          <GroupsEditProfile>
            <GroupsItemEditProfile
              title="Компания"
              value={data.company_name}
              setValue={handleDataChange('company_name')}
            />
            <GroupsItemEditProfile
              title="Должность"
              value={data.position}
              setValue={handleDataChange('position')}
            />
            <CompetenciesItemEditProfile
              title="Компетенции"
              value={data.competencies}
              setValue={handleDataChange('competencies')}
            />
          </GroupsEditProfile>

          <GroupsEditProfile title="Социальные сети">
            <GroupsItemEditProfile
              title="Telegram"
              value={data.social_telegram}
              setValue={handleDataChange('social_telegram')}
            />
            <GroupsItemEditProfile
              title="VK"
              value={data.social_vk}
              setValue={handleDataChange('social_vk')}
            />
          </GroupsEditProfile>

          {(is_superuser || is_supervisor) && (
            <GroupsEditProfile title="Роли">
              <WrapContainer>
                {rolesData.map(item => {
                  if (item.field === 'is_superuser' && !is_superuser) return null;
                  return (
                    <GroupsItemCheckboxEditProfile
                      key={item.field}
                      helper={item.helper}
                      title={item.title}
                      value={!!dataRoles[item.field]}
                      setValue={handleDataRolesChange(item.field)}
                    />
                  );
                })}
              </WrapContainer>
            </GroupsEditProfile>
          )}

          <GroupsEditProfile title={`Аватар${is_customer ? ' (логотип компании)' : ''}`}>
            <GroupsAvatarItem
              documentLink={initialData.avatar.avatar || ''}
              setAvatar={setAvatar}
            />
          </GroupsEditProfile>
          <div>
            <ContentBoardMenuButton title="Сохранить" action={handleSave} image={<SaveDisk />} />
          </div>
        </StyledContentEdit>
      </StatusChecker>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateUser,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileItem);
