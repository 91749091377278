import {useParams} from 'react-router-dom';

import React from 'react';

import StatusChecker from '@Common/StatusChecker';
import Table from '@Common/Table';
import {TextHeader} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseSupportMeasure from '@hooks/UseSupportMeasure';

import FoundsRow from './Row';

const templateColumns = '1fr';

const CategoriesTable = () => {
  const pageId = Number(useParams().id);
  const {categories} = UseSupportMeasure(pageId);
  const {
    isErrorSupportMeasureCategories,
    isLoadingSupportMeasureCategories,
    supportMeasureCategoriesList,
    updateSupportMeasureCategory,
  } = categories;

  return (
    <StatusChecker
      errorsArray={[isErrorSupportMeasureCategories]}
      loadingsArray={[isLoadingSupportMeasureCategories]}>
      <Table>
        <TextHeader color={Colors.grey}>Категории</TextHeader>
        {supportMeasureCategoriesList?.map((item, index) => (
          <FoundsRow
            key={item.id}
            updateSupportMeasureCategory={(id, name) =>
              updateSupportMeasureCategory.mutate({id, name})
            }
            index={index}
            initialName={item.name}
            id={item.id}
            templateColumns={templateColumns}
          />
        ))}
      </Table>
    </StatusChecker>
  );
};

export default CategoriesTable;
