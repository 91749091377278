import {instance} from 'api';

import {IApiResponse} from 'types/services';
import {
  IProjectRouterCheckListItem,
  IRequestProjectRouterCheckListItem,
  IAcceleratorRequestData,
  IAcceleratorAnswer,
  IAcceleratorQuestion,
} from 'types/state/ProjectRouterCheckList';

export const ProjectRouterCheckListService = {
  async getAll() {
    const response = await instance.get<IApiResponse<IProjectRouterCheckListItem>>(
      '/project-router-checklist/',
    );

    return response.data.results;
  },

  async getById(id: number) {
    const response = await instance.get<IProjectRouterCheckListItem>(
      `/project-router-checklist/${id}/`,
    );

    return response.data;
  },

  async getByProject(pageId: number) {
    const response = await instance.get<IApiResponse<IProjectRouterCheckListItem>>(
      `/project-router-checklist/?project=${pageId}`,
    );
    return response.data.results[0] ? response.data.results[0] : null;
  },

  async add(data: IRequestProjectRouterCheckListItem) {
    try {
      return instance.post<IRequestProjectRouterCheckListItem>('/project-router-checklist/', data);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async delete(id: number) {
    try {
      return instance.delete<IRequestProjectRouterCheckListItem>(
        `/project-router-checklist/${id}/`,
      );
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async update(id: number, data: IRequestProjectRouterCheckListItem) {
    try {
      return instance.patch<IRequestProjectRouterCheckListItem>(
        `/project-router-checklist/${id}/`,
        data,
      );
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async getQuestions() {
    try {
      return (
        await instance.get<IApiResponse<IAcceleratorQuestion>>('/project-accelerator-question/')
      ).data.results;
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async getAcceleratorAnswers(id: number) {
    try {
      return (await instance.get<IAcceleratorAnswer[]>(`/draft/${id}/accelerator-answers/`)).data;
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async requestAccelerator(id: number, data: IAcceleratorRequestData) {
    try {
      return instance.post<IRequestProjectRouterCheckListItem>(
        `/draft/${id}/request-accelerator/`,
        data,
      );
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },
};
