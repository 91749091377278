import {ContentBoardMenuEnum} from 'constants/enum/ContentBoardMenu';

import {ReactComponent as BookIcon} from 'assets/images/fi-rr-address-book.svg';
import {ReactComponent as DashboardIcon} from 'assets/images/fi-rr-dashboard.svg';
import {ReactComponent as LabelIcon} from 'assets/images/fi-rr-label.svg';

import {ILabel} from 'types/components';

export const groupsTitle: ILabel[] = [
  {
    icon: DashboardIcon,
    label: ContentBoardMenuEnum.accelerator,
  },
  {
    icon: BookIcon,
    label: ContentBoardMenuEnum.status,
  },
  {
    icon: LabelIcon,
    label: ContentBoardMenuEnum.tags,
  },
];
