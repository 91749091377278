import styled from 'styled-components';

import React, {FC} from 'react';

const StyledTextSmall = styled.h3`
  display: flex;
  margin: 0;

  color: ${({theme}) => theme.colors.grey};
  font-weight: ${({theme}) => theme.fontWeight.normal};
  font-size: ${({theme}) => theme.fontSize.small};
`;

const TextSmall: FC<{children?: React.ReactNode}> = ({children}) => {
  return <StyledTextSmall>{children}</StyledTextSmall>;
};

export default TextSmall;
