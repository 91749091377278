import styled from 'styled-components';

import {NavLink, useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import React from 'react';

import {ProfileService} from '@services/profile';

import {StyledLink} from '@Common/Link';
import StatusChecker from '@Common/StatusChecker';
import {Colors, TextHeaderMedium, TextParagraph} from '@Common/Text';

import {formatDate} from '@utils/formatDate';

const StyledContentItemActive = styled.div`
  display: flex;
  padding: 7px 12px;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  color: ${({theme}) => theme.colors.textDark};
  border-radius: 10px;
  transition: all ease-in-out 0.2s;
`;

const StyledReview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const StyledDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  color: ${({theme}) => theme.colors.grey};
`;

const LinkWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const ActivityActive = () => {
  const pageId = Number(useParams().id);
  const {data, isLoading, isError} = useQuery({
    queryKey: ['profile-activity', pageId],
    queryFn: () => ProfileService.getActivityById(pageId),
  });

  return (
    <StatusChecker errorsArray={[isError]} loadingsArray={[isLoading]}>
      {data?.map(activity => (
        <StyledContentItemActive key={activity.id}>
          <StyledReview>
            {activity.commented_object ? (
              <LinkWrapper>
                <TextHeaderMedium>{activity.title} - </TextHeaderMedium>
                <NavLink to={`/${activity.commented_object.type}/${activity.commented_object.id}`}>
                  <TextHeaderMedium isLink>{activity.commented_object.name}</TextHeaderMedium>
                </NavLink>
              </LinkWrapper>
            ) : (
              <TextHeaderMedium>{activity.title}</TextHeaderMedium>
            )}

            {activity.action === 'comment' ? (
              <div dangerouslySetInnerHTML={{__html: activity.body}} />
            ) : (
              <TextParagraph color={Colors.grey}>{activity.body}</TextParagraph>
            )}
            {activity.attachments?.map(attachment => {
              const documentName = decodeURIComponent(attachment.split('/').pop() || '');
              return (
                <StyledLink key={attachment} href={attachment}>
                  {documentName}
                </StyledLink>
              );
            })}
          </StyledReview>
          <StyledDate>{formatDate(activity.created_at)}</StyledDate>
        </StyledContentItemActive>
      ))}
    </StatusChecker>
  );
};

export default ActivityActive;
