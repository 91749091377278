import styled from 'styled-components';

import React, {useEffect, useState} from 'react';

import AcceptModal from '@Common/Modal/Accept';

import {
  IConceptProofData,
  IRequestProjectRouterCheckListItem,
} from 'types/state/ProjectRouterCheckList';

import ChoiceButtons from './ChoiceButtons';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
`;

interface IConceptProof {
  setActive: () => void;
  setRequestData: (data: IRequestProjectRouterCheckListItem) => void;
}

export const titlesConcept = {
  quiz13: 'Есть ли у Вашего проекта MVP?',
  quiz14:
    'У Вас есть подтверждение (информация от клиента/анализ рынка и т.д), что Ваш продукт решает  конкретные «боли» потребителя?',
  quiz15: 'Имеются ли у Вашего проекта конкуренты на рынке?',
  quiz16:
    'Проводился ли Вами сравнительный анализ с основными конкурентами Вашего проекта/продукта с выделением преимуществ/недостатков?',
  quiz17:
    'При наличии продукта потребитель готов сейчас же заключить контракт или включить его в план закупок?',
  quiz18:
    'Требуется ли кооперация для создания прототипа Вашего продукта (оборудование, специалисты, мощности и т.д.)?',
  quiz19: 'Требуется ли помощь во взаимодействии с Заказчиками?',
  quiz20: 'Есть ли патенты или другие РИД по Вашему проекту?',
};

export const tooltips = {
  quiz13:
    'MVP (с англ. minimal valuable product)  – минимально жизнеспособный продукт. Т.е. уже созданный продукт Вашего проекта, который обладает минимальным набором функций, удовлетворяющих основные потребности потребителей. Например, бета-версия сайта или прототип механизма',
  quiz14: '',
  quiz15: '',
  quiz16:
    'Преимущества и недостатки как с точки зрения технологии, так и со стороны экономических показателей (например, цена)',
  quiz17: '',
  quiz18: 'Кооперация с внутриотраслевыми организациями ГК «Росатом» и внешними компаниями',
  quiz19: 'Поиск Заказчика на Ваш проект, помощь в заключении договоров и др.',
  quiz20: 'РИД – результаты интеллектуальной деятельности',
};

const ConceptProof = ({setActive, setRequestData}: IConceptProof) => {
  const [answers, setAnswers] = useState<IConceptProofData>({
    quiz13: null,
    quiz14: null,
    quiz15: null,
    quiz16: null,
    quiz17: null,
    quiz18: null,
    quiz19: null,
    quiz20: null,
  });

  const [isModalNo, setIsModalNo] = useState(false);
  useEffect(() => {
    setRequestData(answers);
  }, [answers, setRequestData]);

  return (
    <StyledContent>
      <ChoiceButtons
        title={'Есть ли у Вашего проекта MVP?'}
        isActive={answers.quiz13}
        onClickNo={() => setIsModalNo(true)}
        onClickYes={() => setAnswers({...answers, quiz13: true})}
        tooltipText={tooltips.quiz13}
      />

      {answers.quiz13 &&
        Object.keys(answers).map(item => {
          if (item === 'quiz13') return null;
          const field = item as keyof typeof titlesConcept;
          return (
            <ChoiceButtons
              key={field}
              title={titlesConcept[field]}
              isActive={answers[field]}
              onClickNo={() => setAnswers({...answers, [field]: false})}
              onClickYes={() => setAnswers({...answers, [field]: true})}
              tooltipText={tooltips[field]}
            />
          );
        })}

      {isModalNo && <AcceptModal handleYes={setActive} setIsOpen={setIsModalNo} />}
    </StyledContent>
  );
};

export default ConceptProof;
