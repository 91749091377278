import styled from 'styled-components';

const StyledUserPicture = styled.img`
  border-radius: 100%;
  width: 45px;
  height: 45px;
  object-fit: cover;
`;

export default StyledUserPicture;
