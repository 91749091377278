import React from 'react';

import NavigationMenuWrapper from '@components/Wrappers/NavigationMenu';

import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import useAppSelector from '@hooks/UseAppSelector';

import NavigationMenuLink from './Link';
import MenuLinksBar from './LinksBar';
import StyledLink from './styled';

const NavigationMenu = () => {
  const links = useAppSelector(state => state.navigation.value);

  if (links.length === 0) {
    return null;
  }
  return (
    <NavigationMenuWrapper>
      <MenuLinksBar>
        <StyledLink to={'/'}>Главная</StyledLink>
        {links.map(({title, path}, key) => {
          return (
            <NavigationMenuLink key={title}>
              <TextParagraph color={Colors.grayTextOnDark}>/</TextParagraph>
              <StyledLink $current={key === links.length - 1} to={path}>
                {title}
              </StyledLink>
            </NavigationMenuLink>
          );
        })}
      </MenuLinksBar>
    </NavigationMenuWrapper>
  );
};

export default NavigationMenu;
