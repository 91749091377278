import React from 'react';

import {TextHeader} from '@Common/Text';

import {ContentBoardMenuButtonList, ContentBoardMenuWrapper} from './styled';
import {BoardMenuProps} from './types';

const ContentBoardMenu = ({children, pageTitle}: BoardMenuProps) => {
  return (
    <ContentBoardMenuWrapper>
      <TextHeader>{pageTitle}</TextHeader>
      <ContentBoardMenuButtonList> {children}</ContentBoardMenuButtonList>
    </ContentBoardMenuWrapper>
  );
};

export default ContentBoardMenu;
