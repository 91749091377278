import styled from 'styled-components';

import {NavLink} from 'react-router-dom';

import React from 'react';

const StyledSideTableItem = styled(NavLink)`
  display: flex;

  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  & > div {
    transition: background-color 0.2s ease-in-out;
  }

  &:hover > div {
    background-color: ${({theme}) => theme.colors.greyCircuit};
  }
`;

interface ITableItem {
  path: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const TableItem = ({path, children, onClick}: ITableItem) => {
  return (
    <StyledSideTableItem onClick={onClick} to={path}>
      {children}
    </StyledSideTableItem>
  );
};

export default TableItem;
