import {createAsyncThunk} from '@reduxjs/toolkit';
import {ProjectAreaService} from 'services/projectArea';

import {IProjectAreaItem} from 'types/Projectarea';

export const getAllProjectArea = createAsyncThunk<IProjectAreaItem[]>(
  'projectarea/all',
  async (_, thunkApi) => {
    try {
      return await ProjectAreaService.getAll();
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
