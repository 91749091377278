import {IDraftsState} from 'types/state/Drafts';

const initialState: IDraftsState = {
  count: 0,
  next: '',
  previous: null,
  results: [],
};

export default initialState;
