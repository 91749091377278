import styled from 'styled-components';

import React from 'react';

import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

const CounterStyled = styled.div`
  margin-left: 114px;
`;

type Counter = {
  count: number;
};

const Counter = ({count}: Counter) => {
  return (
    <CounterStyled>
      <TextParagraph color={Colors.textDark}>Записей: {count}</TextParagraph>
    </CounterStyled>
  );
};

export default Counter;
