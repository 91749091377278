import UseAppSelector from './UseAppSelector';

const UseRoles = () => {
  const isSuperuser = UseAppSelector(state => state.user.data.is_superuser);
  const isTracker = UseAppSelector(state => state.user.data.is_tracker);
  const isExpert = UseAppSelector(state => state.user.data.is_expert);
  const isCustomer = UseAppSelector(state => state.user.data.is_customer);
  const isHired = UseAppSelector(state => state.user.data.is_hired);
  const isObserver = UseAppSelector(state => state.user.data.is_observer);
  const isProject = UseAppSelector(state => state.user.data.is_project);
  const isSupervisor = UseAppSelector(state => state.user.data.is_supervisor);

  return {
    isSuperuser,
    isTracker,
    isExpert,
    isCustomer,
    isHired,
    isObserver,
    isProject,
    isSupervisor,
  };
};

export default UseRoles;
