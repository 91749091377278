import {useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import React, {useState} from 'react';

import {ProjectRouterCheckListService} from '@services/projectRouterCheckList';

import {PanelContentWrapper} from '@components/Wrappers/DraftsItem';

import StatusChecker from '@Common/StatusChecker';
import {TextHeaderMedium} from '@Common/Text';

import Answers from './Answers';
import AnswersAccelerator from './AnswersAccelerator';
import {CheckListContentMenu} from './Menu';
import {StyledContentRow, StyledTable} from './styles';

const DraftsCheckListContent = () => {
  const [active, setActive] = useState('Акселератор');
  const pageId = Number(useParams().id);

  const {
    data: checkList,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['projectrouterchecklist'],
    queryFn: () => ProjectRouterCheckListService.getByProject(pageId),
  });

  const {
    data: acceleratorAnswers,
    isLoading: isLoadingAccelerator,
    isError: isErrorAccelerator,
  } = useQuery({
    queryKey: ['accelerator-answers', pageId],
    queryFn: () => ProjectRouterCheckListService.getAcceleratorAnswers(pageId),
  });

  return (
    <StyledTable>
      <CheckListContentMenu
        answers={acceleratorAnswers}
        checkList={checkList}
        active={active}
        setActive={setActive}
      />
      <StatusChecker
        errorsArray={[isError, isErrorAccelerator]}
        loadingsArray={[isLoading, isLoadingAccelerator]}>
        <PanelContentWrapper>
          <StyledContentRow>
            <TextHeaderMedium>Вопрос</TextHeaderMedium>
            <TextHeaderMedium>Ответ</TextHeaderMedium>
          </StyledContentRow>
          {acceleratorAnswers && active === 'Акселератор' && (
            <AnswersAccelerator answers={acceleratorAnswers} />
          )}
          {checkList && active !== 'Акселератор' && <Answers type={active} data={checkList} />}
        </PanelContentWrapper>
      </StatusChecker>
    </StyledTable>
  );
};

export default DraftsCheckListContent;
