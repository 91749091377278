import {ICompanyState} from 'types/state/company';

const initialState: ICompanyState = {
  count: 0,
  next: '',
  previous: null,
  results: [],
  isLoading: true,
};

export default initialState;
