import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import initialState from 'store/legalEntities/state';

import {ILegalEntitiesItem} from 'types/state/LegalEntities';

const legalEntitiesSlice = createSlice({
  name: 'legalEntities',
  initialState,
  reducers: {
    sortUp: (state, action: PayloadAction<keyof ILegalEntitiesItem>) => {
      state.value.sort(function (a, b) {
        const textA = (a[action.payload] as string).toUpperCase();
        const textB = (b[action.payload] as string).toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
    sortDown: (state, action: PayloadAction<keyof ILegalEntitiesItem>) => {
      state.value.sort(function (a, b) {
        const textA = (a[action.payload] as string).toUpperCase();
        const textB = (b[action.payload] as string).toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    },
    sortToggle: state => {
      state.sortDirection = state.sortDirection === 'up' ? 'down' : 'up';
    },
  },
});

export const {sortUp, sortDown, sortToggle} = legalEntitiesSlice.actions;
export default legalEntitiesSlice.reducer;
