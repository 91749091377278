import {instance} from 'api/instance';

import {ICfoItem, ICfoState} from 'types/Cfo';

export const CfoService = {
  async getAll() {
    const response = await instance.get<ICfoState>('/cfo/?limit=5000');

    return response.data.results;
  },

  async getById(id: number) {
    const response = await instance.get<ICfoItem>(`/cfo/${id}/`);

    return response.data;
  },
};
