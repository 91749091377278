import styled from 'styled-components';

import BackGround from 'assets/images/backgroundAuth.png';
import React, {PropsWithChildren} from 'react';

const Container = styled.div`
  background-image: url(${BackGround});
  background-size: cover;
  display: flex;
  flex-grow: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 100px;
`;

const AuthContainer = ({children}: PropsWithChildren) => {
  return <Container>{children}</Container>;
};

export default AuthContainer;
