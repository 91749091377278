import {useQuery} from '@tanstack/react-query';
import React, {useEffect, useState} from 'react';

import {selectFilters} from '@store/filters/selectors';

import {SupportMeasureService} from '@services/support_measure';

import AggregatorItem from '@components/AggregatorItem';
import {PaginationButtons} from '@components/Menu';

import FiltersPanel from '@Common/FiltersPanel';
import StatusChecker from '@Common/StatusChecker';
import Table from '@Common/Table';
import TableHeader from '@Common/Table/Header';

import UseAppSelector from '@hooks/UseAppSelector';

const templateColumns =
  '20px minmax(200px, 1fr) minmax(200px, 1fr) minmax(125px, 1fr) minmax(125px, 1fr) 60px';

const AggregatorTable = () => {
  const {search} = UseAppSelector(selectFilters);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchSupportMeasureData = async () => {
    return await SupportMeasureService.getAll({search: searchTerm});
  };

  const {
    data: supportMeasureList,
    isLoading: isLoadingSupportMeasure,
    isError: isErrorSupportMeasure,
  } = useQuery({
    queryKey: ['support_measure', searchTerm],
    queryFn: fetchSupportMeasureData,
  });
  useEffect(() => {
    if (!search) setSearchTerm(search);
  }, [search]);
  return (
    <>
      <FiltersPanel
        count={supportMeasureList?.length}
        handleEnterSearch={() => setSearchTerm(search)}
      />
      <StatusChecker
        loadingsArray={[isLoadingSupportMeasure]}
        errorsArray={[isErrorSupportMeasure]}>
        <Table>
          <TableHeader
            templateColumns={templateColumns}
            titles={[
              {title: '№'},
              {title: 'Источник меры поддержки'},
              {title: 'Название программы'},
              {title: 'Ссылка'},
              {title: 'Категория'},
              {title: ''},
            ]}
          />
          {supportMeasureList?.map((item, index) => (
            <AggregatorItem key={item.id} index={index} aggregatorItem={item} />
          ))}
        </Table>
      </StatusChecker>
      <PaginationButtons margin={15} count={supportMeasureList?.length} />
    </>
  );
};

export default AggregatorTable;
