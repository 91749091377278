import {instance} from 'api';

import {
  IPatchRequestDraft,
  IPatchRequestResponse,
  IPostRequestResponse,
  IPostRequestTechRequest,
} from 'types/patchRequest';
import {ITechRequestItem, ITechRequestState} from 'types/state/TechRequest';

interface ITechRequestFilters {
  searchTerm: string | null;
  createdBy?: string | null;
  request_area?: string | null;
  status?: string | null;
  expertiseRequired?: string | null;
  page: number;
}

export const TechRequestService = {
  async getAll({searchTerm, createdBy, page, request_area, status}: ITechRequestFilters) {
    const response = await instance.get<ITechRequestState>(
      `/project-request/?search=${searchTerm || ''}&created_by=${createdBy || ''}&request_area=${
        request_area || ''
      }&status=${status || ''}&limit=50&offset=${(page - 1) * 50}`,
    );
    return response.data;
  },

  async create(data: IPostRequestTechRequest) {
    return instance.post<ITechRequestItem>('/project-request/', data);
  },

  async getById(id: number) {
    const response = await instance.get<ITechRequestItem>(`/project-request/${id}/`);

    return response.data;
  },

  async update(id: number, data: IPatchRequestDraft) {
    try {
      return instance.patch<ITechRequestState>(`/project-request/${id}/`, data);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async delete(id: number) {
    try {
      return instance.delete<ITechRequestState>(`/project-request/${id}/`);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async createResponse(data: IPostRequestResponse) {
    return instance.post<ITechRequestItem>('/project-request-response/', data);
  },

  async getByTechRequestIdResponse(id: number) {
    const response = await instance.get<ITechRequestState>(
      `/project-request-response/?project_request=${id}`,
    );

    return response.data;
  },

  async updateResponse(id: number, data: IPatchRequestResponse) {
    try {
      return instance.patch<ITechRequestState>(`/project-request-response/${id}/`, data);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },
};
