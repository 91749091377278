import {styled} from 'styled-components';

const EditableInternalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const IconWrapper = styled.div<{$isEditing: boolean}>`
  display: flex;
  padding: 6px;
  cursor: ${({$isEditing}) => ($isEditing ? 'pointer' : 'default')};
  border: ${({$isEditing, theme}) =>
    $isEditing ? `1px solid ${theme.colors.grayTextOnDark}` : '1px solid transparent'};
  border-radius: ${({theme}) => theme.borderRadius.full};
  &:hover {
    background-color: ${({theme, $isEditing}) =>
      $isEditing ? theme.colors.grayTextOnDark : 'transparent'};
  }
`;

const ButtonsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

const Button = styled.button`
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  & path {
    fill: ${({theme}) => theme.colors.grey};
  }
`;
export {Button, ButtonsStyled, EditableInternalWrapper, IconWrapper};
