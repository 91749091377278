import {ReactComponent as Logo} from 'assets/images/Logo.svg';
import {ReactComponent as MinimizedLogo} from 'assets/images/LogoCropped.svg';
import React from 'react';

import Container from './Container';

type LogoProps = Record<'minimized', boolean>;

const MenuLogo = ({minimized}: LogoProps) => {
  if (minimized) {
    return (
      <Container to="/" $isMinimized>
        <MinimizedLogo />
      </Container>
    );
  }

  return (
    <Container to="/">
      <Logo />
    </Container>
  );
};

export default MenuLogo;
