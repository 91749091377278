import styled from 'styled-components';

import React from 'react';

import {TrashIcon} from '@Common/Images';
import {FlexButton} from '@Common/Utils/FlexButton';

const StyledButton = styled(FlexButton)<{$width?: string}>`
  ${({$width}) =>
    $width
      ? `
  width: ${$width};
`
      : ''}
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  background: ${({theme}) => theme.colors.white};
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  & svg g path {
    fill: ${({theme}) => theme.colors.black};
  }

  &:hover {
    background: ${({theme}) => theme.colors.lightBlue};

    color: ${({theme}) => theme.colors.white};
  }
`;

interface IDeleteButton {
  action: () => void;
  width?: string;
}

const DeleteButton = ({action, width}: IDeleteButton) => {
  return (
    <StyledButton $width={width} onClick={action}>
      <TrashIcon />
    </StyledButton>
  );
};

export default DeleteButton;
