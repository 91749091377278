import styled from 'styled-components';

import {Link, useNavigate} from 'react-router-dom';

import React, {FormEvent, useState} from 'react';

import {authUser} from '@store/user/slice';

import {AuthService} from '@services/auth';

import AuthContainer from '@Common/AuthContainer';
import {LoginFormButton} from '@Common/Buttons';
import {LoginForm, LoginInput} from '@Common/Forms/Login';
import {CrossIcon} from '@Common/Images';
import Loader from '@Common/Loader';
import {CloseButton} from '@Common/Modal/ModalComment/styles';
import {Overlay} from '@Common/Modal/Wrapper';
import {TextHeader, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseAppDispatch from '@hooks/UseAppDispatch';

const AppLink = styled(Link)`
  text-decoration: none;
  color: rgb(23, 80, 203);
  align-items: center;
  display: flex;
  gap: 8px;

  &:hover {
    color: #1a225f;
  }

  & path {
    fill: #1750cb;
  }
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
`;

const TechHelp = styled.div`
  cursor: pointer;
`;

const Auth = () => {
  const [helpModal, setHelpModal] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = UseAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (event: FormEvent) => {
    setIsLoading(true);
    event.preventDefault();
    localStorage.clear();
    void AuthService.main({username, password})
      .then(data => {
        dispatch(authUser(data));
        navigate('/');
      })
      .catch((e: Error) => {
        setError(e.message);
        setIsLoading(false);
      });
  };

  return (
    <AuthContainer>
      <TextHeader align="center" color="white">
        ИННОХАБ РОСАТОМА
        <br /> ПЛАТФОРМА МАРШРУТИЗАЦИИ ПРОЕКТОВ
      </TextHeader>
      <LoginForm onSubmit={(e: FormEvent) => onSubmit(e)}>
        <TextParagraph color={Colors.textDark}>Пожалуйста, авторизуйтесь</TextParagraph>
        <LoginInput
          type={'email'}
          value={username}
          autoComplete={'on'}
          onChange={e => setUsername(e.target.value)}
          placeholder={'Email'}
        />
        <LoginInput
          type={'password'}
          value={password}
          autoComplete={'on'}
          onChange={e => setPassword(e.target.value)}
          placeholder={'Пароль'}
        />
        <LoginFormButton type="submit">Войти</LoginFormButton>
        {isLoading && <Loader />}
        {error && (
          <TextParagraph align="center" color={Colors.textDark}>
            {error}
          </TextParagraph>
        )}
        <AppLink to={'/registration'}>Регистрация</AppLink>
        <AppLink to={'/password_reset'}>Забыли пароль?</AppLink>
        <TechHelp onClick={() => setHelpModal(true)}>
          <TextParagraph color={Colors.darkBlue}>Тех. поддержка</TextParagraph>
        </TechHelp>
      </LoginForm>
      {helpModal && (
        <Overlay onClick={() => setHelpModal(false)}>
          <ModalWrapper
            onClick={e => {
              e.stopPropagation();
            }}>
            <CloseButton onClick={() => setHelpModal(false)}>
              <CrossIcon />
            </CloseButton>

            <TextParagraph color={Colors.textDark}>
              <p>
                Здравствуйте!
                <br /> Направьте, пожалуйста, Ваш вопрос на почтовые адреса администраторам
                платформы маршрутизации проектов:
                <br />
                <a rel="noreferrer" target="_blank" href="mailto:route@ih-rosatom.ru">
                  route@ih-rosatom.ru
                </a>
              </p>
            </TextParagraph>
          </ModalWrapper>
        </Overlay>
      )}
    </AuthContainer>
  );
};

export default Auth;
