import styled from 'styled-components';

import {NavLink, useNavigate, useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {ProfileService} from '@services/profile';

import {ContentBoardMenu} from '@components/Menu';
import ProfileActivity from '@components/ProfileItem/Activity';
import ProfileInfo from '@components/ProfileItem/Info';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {AddIcon, DocumentIcon, EditIcon, TrashIcon} from '@Common/Images';
import AcceptModal from '@Common/Modal/Accept';
import ProfileFeedbackModal from '@Common/Modal/Feedback';

import {UseNavigationList} from '@hooks/UseNavigationList';

import {IUser} from 'types/User';

const ContentStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  padding: 20px;
`;

type Props = {
  user: IUser;
};

const ProfileItem = ({user}: Props) => {
  const navigate = useNavigate();
  const {is_superuser, is_supervisor} = user;
  const queryClient = useQueryClient();
  const pageId = Number(useParams().id);
  const [modals, setModals] = useState({
    resetModals: false,
    deleteModals: false,
    feedbackModals: false,
  });
  const handleReset = () => {
    navigate('/password_reset');
  };

  const deleteMutation = useMutation({
    mutationFn: () => ProfileService.delete(pageId),
    onSuccess: () => {
      void queryClient.invalidateQueries(['profiles']);
    },
  });
  const handleDelete = useCallback(() => {
    deleteMutation.mutate();
    navigate('/profile');
  }, [deleteMutation, navigate]);

  UseNavigationList([
    {
      path: '/profile',
      title: 'Профили',
    },
    {
      path: `/profile/${pageId}`,
      title: 'Профиль',
    },
  ]);

  const handleModal = (field: keyof typeof modals, value: boolean) => {
    setModals(prev => ({...prev, [field]: value}));
  };

  return (
    <>
      <ContentBoardMenu pageTitle="Профиль">
        {(is_superuser || is_supervisor) && (
          <>
            <NavLink to={'edit'}>
              <ContentBoardMenuButton
                title={'Редактировать'}
                action={() => {}}
                image={<EditIcon />}
              />
            </NavLink>
            <ContentBoardMenuButton
              title={'Отзыв'}
              action={() => handleModal('feedbackModals', true)}
              image={<DocumentIcon />}
            />
          </>
        )}
        {is_superuser && (
          <>
            <ContentBoardMenuButton
              title={'Сбросить пароль'}
              action={() => handleModal('resetModals', true)}
              image={<AddIcon />}
            />

            <ContentBoardMenuButton
              title={'Удалить'}
              action={() => handleModal('deleteModals', true)}
              image={<TrashIcon />}
            />
          </>
        )}
      </ContentBoardMenu>
      <ContentStyled>
        <ProfileInfo />
        <ProfileActivity />
      </ContentStyled>

      {modals.resetModals && (
        <AcceptModal
          handleYes={handleReset}
          setIsOpen={value => handleModal('resetModals', value)}
        />
      )}
      {modals.deleteModals && (
        <AcceptModal
          handleYes={handleDelete}
          setIsOpen={value => handleModal('deleteModals', value)}
        />
      )}

      {modals.feedbackModals && (
        <ProfileFeedbackModal onClose={() => handleModal('feedbackModals', false)} />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(ProfileItem);
