import {TableRowStyled} from 'components/Common/Table/Row';
import {StyledHeaderMedium} from 'components/Common/Text/HeaderMedium/component';
import React from 'react';

import {ITableHeaderProps} from './types';

const TableHeader = ({titles, templateColumns}: ITableHeaderProps) => {
  return (
    <TableRowStyled $templateColumns={templateColumns}>
      {titles.map(item => {
        return (
          <StyledHeaderMedium $align={item.align} key={item.title}>
            {item.title}
          </StyledHeaderMedium>
        );
      })}
    </TableRowStyled>
  );
};

export default TableHeader;
