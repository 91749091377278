import styled from 'styled-components';

import {Link} from 'react-router-dom';

import React, {useState} from 'react';

import {NavBarLinksMenu, UserName} from '@components/NavBar';
import ProfileCard from '@components/ProfileCard';
import NavBarWrapper from '@components/Wrappers/NavBar';

import {NavBarButton} from '@Common/Buttons';
import {BurgerMenuIcon} from '@Common/Images';

import UseAppSelector from '@hooks/UseAppSelector';

import StyledUserPicture from './Picture';
import {UserNavbarProps} from './types';

const LinkToAuth = styled(Link)`
  padding: 14px;
  text-decoration: none;
  color: rgba(24, 24, 29, 0.75);
  font-weight: 500;
`;

const MenuNavbarUser = ({menuToggle}: UserNavbarProps) => {
  const [isClientsCardShown, setIsClientsCardShown] = useState(false);
  const clientCardSwitcher = () => setIsClientsCardShown(!isClientsCardShown);
  const user = UseAppSelector(state => state.user);

  return (
    <NavBarWrapper>
      <NavBarButton action={menuToggle}>
        <BurgerMenuIcon />
      </NavBarButton>
      {!user.isLogged ? (
        <LinkToAuth to={'/auth'}>Авторизуйтесь</LinkToAuth>
      ) : (
        <NavBarLinksMenu>
          <NavBarButton action={clientCardSwitcher}>
            <StyledUserPicture src={user.data.avatar || ''} alt={user.data.email1} />

            <UserName text={user.data.fio || user.data.email1} />
          </NavBarButton>
          <ProfileCard setIsShown={setIsClientsCardShown} isShown={isClientsCardShown} />
        </NavBarLinksMenu>
      )}
    </NavBarWrapper>
  );
};

export default MenuNavbarUser;
