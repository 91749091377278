import styled from 'styled-components';

import React, {FC} from 'react';

const StyledNavigationMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 26px 44px;
  gap: 10px;
  width: 100%;
  background: ${({theme}) => theme.colors.white};
`;

const NavigationMenuWrapper: FC<{children?: React.ReactNode}> = ({children}) => {
  return <StyledNavigationMenuWrapper>{children}</StyledNavigationMenuWrapper>;
};

export default NavigationMenuWrapper;
