import {useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import React from 'react';

import {CommentService} from '@services/comment';

import {Comment, NoActivity} from '@Common/CommentsList';
import StatusChecker from '@Common/StatusChecker';

const DraftsCommentsList = () => {
  const pageId = Number(useParams().id);
  const fetchFn = () => CommentService.getByProject(pageId);

  const {
    data: commentsList,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['comments', pageId],
    queryFn: fetchFn,
  });

  const commentsListLength = commentsList?.length || 0;

  return (
    <StatusChecker errorsArray={[isError]} loadingsArray={[isLoading]}>
      {commentsListLength > 0 ? (
        commentsList?.map(comment => (
          <Comment
            key={comment.id}
            comment_id={comment.id}
            created_at={comment.created_at}
            created_by={comment.created_by}
            action={comment.action}
            text={comment.text}
          />
        ))
      ) : (
        <NoActivity />
      )}
    </StatusChecker>
  );
};

export default DraftsCommentsList;
