import {ContentBoardMenu} from 'components/Menu';
import TagsTable from 'components/Tables/Tags';
import {UseNavigationList} from 'hooks/UseNavigationList';
import React from 'react';

import {PageProps} from './types';

const TagsPage = ({pageTitle}: PageProps) => {
  const navigationList = [
    {path: '/tags', title: 'Теги'},
    {path: '/tags', title: 'Список'},
  ];

  UseNavigationList(navigationList);

  return (
    <>
      <ContentBoardMenu pageTitle={pageTitle} />
      <TagsTable />
    </>
  );
};

export default TagsPage;
