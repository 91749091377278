import {useParams} from 'react-router-dom';

import React from 'react';

import StatusChecker from '@Common/StatusChecker';
import Table from '@Common/Table';
import {TextHeader} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseSupportMeasure from '@hooks/UseSupportMeasure';

import FoundsRow from './Row';

const templateColumns = '1fr';

const FoundsTable = () => {
  const pageId = Number(useParams().id);
  const {founds} = UseSupportMeasure(pageId);
  const {
    isErrorSupportMeasureFounds,
    isLoadingSupportMeasureFounds,
    supportMeasureFoundsList,
    updateSupportMeasureFound,
  } = founds;

  return (
    <StatusChecker
      errorsArray={[isErrorSupportMeasureFounds]}
      loadingsArray={[isLoadingSupportMeasureFounds]}>
      <Table>
        <TextHeader color={Colors.grey}>Источники меры поддержки</TextHeader>

        {supportMeasureFoundsList?.map((item, index) => (
          <FoundsRow
            key={item.id}
            updateSupportMeasureFound={(id, name) => updateSupportMeasureFound.mutate({id, name})}
            index={index}
            initialName={item.name}
            id={item.id}
            templateColumns={templateColumns}
          />
        ))}
      </Table>
    </StatusChecker>
  );
};

export default FoundsTable;
