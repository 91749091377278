import styled from 'styled-components';

import React from 'react';

import {TableRowStyled} from '@Common/Table/Row';
import {TextHeaderMedium, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import useAppSelector from '@hooks/UseAppSelector';

import {INewsletterTemplatesItem} from 'types/NewsletterTemplates';

const NewsletterTemplatesTableStyled = styled.div``;

const NewsletterTemplatesTableRow = styled(TableRowStyled)`
  grid-template-columns: 35% 35% 20%;
  gap: 50px;
`;

const NewsletterTemplatesTable = () => {
  const NewsletterTemplatesList = useAppSelector(state => state.newsletterTemplates.value);

  return (
    <NewsletterTemplatesTableStyled>
      <NewsletterTemplatesTableRow $templateColumns="88% 10%">
        <TextHeaderMedium>Название шаблона</TextHeaderMedium>
        <TextHeaderMedium>Тема письма</TextHeaderMedium>
      </NewsletterTemplatesTableRow>
      {NewsletterTemplatesList.map((item: INewsletterTemplatesItem, index) => {
        return (
          <NewsletterTemplatesTableRow
            $templateColumns="88% 10%"
            key={item.id}
            $isGray={index % 2 == 0}>
            <TextParagraph color={Colors.accentBlue}>{item.title}</TextParagraph>
            <TextParagraph color={Colors.textDark}>{item.theme}</TextParagraph>
          </NewsletterTemplatesTableRow>
        );
      })}
    </NewsletterTemplatesTableStyled>
  );
};

export default NewsletterTemplatesTable;
