import React from 'react';

import {TextHeaderMedium} from '@Common/Text';

import TagsTableRow from './Row';

const ColumnNames = () => {
  return (
    <TagsTableRow $templateColumns="88% 10%">
      <TextHeaderMedium>Название</TextHeaderMedium>
    </TagsTableRow>
  );
};

export default ColumnNames;
