import {instance} from '@api/instance';
import {AxiosResponse} from 'axios';

export interface IHandleSubmitExportProps {
  id?: number | null;
  projectArea?: string | null;
  isInternal?: string | null;
  isArchived?: string | null;
  tracker?: string | null;
  supervisor?: string | null;
  accelerator?: string | null;
  isFinished?: string | null;
  routerStage?: string | null;
  acceleratorRequest?: string | null;
  in_work?: boolean;
  search?: string;
}

const checkBoolean = (value?: string | null) => {
  return value ? (value === 'true' ? true : false) : null;
};

const checkNumber = (value?: string | null) => {
  return value ? +value : null;
};

export const handleSubmitExport = async ({
  id,
  tracker,
  isInternal,
  isArchived,
  projectArea,
  accelerator,
  supervisor,
  isFinished,
  routerStage,
  in_work,
  acceleratorRequest,
}: IHandleSubmitExportProps) => {
  try {
    const data = id
      ? {id}
      : {
          id: null,
          workflow_tracker__tracker: checkNumber(tracker),
          is_internal: checkBoolean(isInternal),
          archived: checkBoolean(isArchived),
          project_area: checkNumber(projectArea),
          accelerator: checkNumber(accelerator),
          supervisor: checkNumber(supervisor),
          finished: checkBoolean(isFinished),
          routerStage: checkNumber(routerStage),
          requested_accelerator: checkNumber(acceleratorRequest),
          in_work,
        };
    const response: AxiosResponse<ArrayBuffer> = await instance.post('/project-export/', data, {
      responseType: 'blob',
    });
    const dataBlob = [response.data];
    const blob = new Blob(dataBlob, {
      type: response.headers['Content-Type'] as string,
    });
    const url = URL.createObjectURL(blob);
    Object.assign(document.createElement('a'), {
      href: url,
      download: 'exported_file.zip',
    }).click();

    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Ошибка при получении данных:', error);
  }
};
