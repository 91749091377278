import {axiosDocument, instance} from 'api';

import {IPatchRequestDraft, IPatchRequestField} from 'types/patchRequest';
import {IDraftsListItem, IDraftsState} from 'types/state/Drafts';

interface IProjectFilters {
  searchTerm: string | null;
  projectArea?: string | null;
  isInternal?: string | null;
  isArchived?: string | null;
  tracker?: string | null;
  supervisor?: string | null;
  accelerator?: string | null;
  routerStage?: string | null;
  isFinished?: string | null;
  expertiseRequired?: string | null;
  answerRequired?: string | null;
  page: number;
}

export const ProjectService = {
  async getAll({
    searchTerm,
    projectArea,
    isInternal,
    isArchived,
    tracker,
    accelerator,
    supervisor,
    isFinished,
    routerStage,
    expertiseRequired,
    answerRequired,
    page,
  }: IProjectFilters) {
    const response = await instance.get<IDraftsState>(
      `/project/?search=${searchTerm || ''}&project_area=${projectArea || ''}&archived=${
        isArchived || ''
      }&is_internal=${isInternal || ''}&tracker=${tracker || ''}&accelerator=${
        accelerator || ''
      }&finished=${isFinished || ''}&supervisor=${supervisor || ''}&router_step=${
        routerStage || ''
      }&expertise=${expertiseRequired || ''}&customer_response=${
        answerRequired || ''
      }&limit=50&offset=${(page - 1) * 50}`,
    );
    return response.data;
  },

  async getById(id: number) {
    const response = await instance.get<IDraftsListItem>(`/project/${id}/`);

    return response.data;
  },

  async update(id: number, data: IPatchRequestDraft) {
    try {
      return instance.patch<IDraftsListItem>(`/project/${id}/`, data);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async delete(id: number) {
    try {
      return instance.delete<IDraftsListItem>(`/project/${id}/`);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async updateDocument(id: number, data: IPatchRequestField) {
    try {
      return axiosDocument.patch<IDraftsListItem>(`/project/${id}/`, data);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },
  async finishProject({id}: {id: number}) {
    try {
      return instance.post<IDraftsListItem>(`/project/${id}/finish/`);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },
};
