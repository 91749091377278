import React from 'react';

import {Colors, TextParagraph} from '@Common/Text';

import ModalWrapper from '../Wrapper';

interface ModalProps {
  onClose: () => void;
  content: string[];
  description: string;
}

const Modal = ({onClose, content, description}: ModalProps) => {
  return (
    <ModalWrapper setIsOpen={onClose}>
      <TextParagraph color={Colors.red}>ОБЯЗАТЕЛЬНЫЕ УСЛОВИЯ</TextParagraph>
      <TextParagraph color={Colors.textDark}>{description}</TextParagraph>
      {content.map(line => (
        <TextParagraph color={Colors.textDark} key={line}>
          ● {line}
        </TextParagraph>
      ))}
    </ModalWrapper>
  );
};

export default Modal;
