import styled from 'styled-components';

const SideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 32px 0 rgba(172, 172, 172, 0.25);
  justify-content: stretch;
  z-index: 20;
`;

export default SideBarWrapper;
