import styled from 'styled-components';

import {useNavigate, useParams} from 'react-router-dom';

import {useQueryClient, useQuery, useMutation} from '@tanstack/react-query';
import React, {useEffect, useMemo, useState} from 'react';
import {useCookies} from 'react-cookie';

import {ProjectRouterCheckListService} from '@services/projectRouterCheckList';

import {FilterButton} from '@Common/Buttons';
import SingleDropdown from '@Common/Dropdown/Single';
import {DropdownSingleType} from '@Common/Dropdown/Single/types';
import {CrossIcon} from '@Common/Images';
import AcceptModal from '@Common/Modal/Accept';
import {CloseButton} from '@Common/Modal/ModalComment/styles';
import {ModalWrapper} from '@Common/Modal/Registration/styles';
import {Overlay} from '@Common/Modal/Wrapper';
import StatusChecker from '@Common/StatusChecker';
import {StyledText, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseAccelerators from '@hooks/UseAccelerators';

import {
  IAcceleratorData,
  IAcceleratorRequestData,
  IRequestProjectRouterCheckListItem,
} from 'types/state/ProjectRouterCheckList';

import AcceleratorAnswer from './AcceleratorAnswer';
import ChoiceButtons from './ChoiceButtons';
import {SaveButtonWrapper} from './styles';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`;

const StyledError = styled(StyledText)`
  padding: 10px 20px;
`;

export const titles = {
  quiz1:
    'На данный момент продукт Проекта, который Вы зарегистрировали в маршрутизаторе, выпускается и продается серийно?',
  quiz4:
    'Были ли первые продажи Вашего продукта Заказчикам или имеются закреплённые договоренности с Заказчиками о возможной покупке продукции?',
  quiz13: 'Есть ли у Вашего проекта MVP?',
  quiz21:
    'На Ваше решение/продукт есть подтвержденный (переговорами или письменно) спрос у конечного потребителя',
};

export const tooltips = {
  quiz1:
    'Серийный продукт — это товар, который производится в большом количестве единиц и имеет одинаковые характеристики Для IT-проектов под серийностью понимаются продажи приложения в общедоступных сервисах или нахождение платформы/сайта в открытом доступе с полным функционалом ',
  quiz4:
    'Под закрепленными договоренностями подразумеваются письменные договоры о  будущей покупке продукции',
  quiz13:
    'MVP (с англ. minimal valuable product)  – минимально жизнеспособный продукт. Т.е. уже созданный продукт Вашего проекта, который обладает минимальным набором функций, удовлетворяющих основные потребности потребителей. Например, бета-версия сайта или прототип механизма',
  quiz21: '',
};

type RouterAnswer = {
  quiz1: null | boolean;
  quiz4: null | boolean;
  quiz13: null | boolean;
  quiz21: null | boolean;
};

interface AcceleratorProps {
  setActive: () => void;
}

const Accelerator = ({setActive}: AcceleratorProps) => {
  const pageId = Number(useParams().id);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {acceleratorsLabels, isErrorAccelerator, isLoadingAccelerator} = UseAccelerators();
  const {
    data: questions,
    isLoading,
    isError: isErrorQuestion,
  } = useQuery({
    queryKey: ['project-accelerator-question'],
    queryFn: () => ProjectRouterCheckListService.getQuestions(),
  });

  const mutation = useMutation({
    mutationFn: async (data: IAcceleratorRequestData) => {
      await ProjectRouterCheckListService.requestAccelerator(pageId, data);
    },

    onSuccess: () => {
      void queryClient.invalidateQueries(['accelerator-answers', pageId]);
    },
    onError: () => {
      setIsDisableButton(false);
    },
  });

  const mutationChecklist = useMutation({
    mutationFn: async (data: IRequestProjectRouterCheckListItem) => {
      await ProjectRouterCheckListService.add(data);
    },

    onSuccess: () => {
      void queryClient.invalidateQueries(['project-router-checklist', pageId]);
    },
    onError: () => {
      setIsDisableButton(false);
    },
  });

  const [accelerator, setAccelerator] = useState<DropdownSingleType>(null);

  const [cookies, setCookie] = useCookies(['accelerator_answers']);

  const initialState = useMemo(
    () => (cookies.accelerator_answers as IAcceleratorData) || {},
    [cookies],
  );

  const [answers, setAnswers] = useState<IAcceleratorData>(initialState);

  useEffect(() => {
    return () => setCookie('accelerator_answers', answers, {path: '/'});
  }, [answers, setCookie]);

  const [routerAnswers, setRouterAnswers] = useState<RouterAnswer>({
    quiz1: null,
    quiz4: null,
    quiz13: null,
    quiz21: null,
  });

  const [isModalNo, setIsModalNo] = useState(false);
  const [helpModal, setHelpModal] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isDisableButton, setIsDisableButton] = useState(false);
  const [isAccelerator, setIsAccelerator] = useState<boolean | null>(null);

  const handleChange = (id: number) => (value: string) => {
    setAnswers(prev => ({...prev, [id]: value}));
  };

  const onSave = async () => {
    if (
      Object.keys(answers).length !== questions?.length ||
      // !Object.values(routerAnswers).some(value => value === true) ||
      Object.values(answers).some(answer => (answer as string).trim() === '')
    ) {
      setIsError(true);
      return;
    }

    if (accelerator) {
      setIsDisableButton(true);
      await mutation.mutateAsync({
        accelerator: Number(accelerator.value),
        poll_answers: Object.entries(answers).map(([key, value]) => ({
          question: Number(key),
          answer: value as string,
        })),
      });

      await mutationChecklist.mutateAsync({project: pageId, router: 1, ...routerAnswers});

      navigate(`/draft/${pageId}`);
    }
  };

  const toggleButton = (field: keyof typeof routerAnswers, value: boolean) => {
    setRouterAnswers(prev => {
      const updatedAnswers: RouterAnswer = {quiz1: null, quiz4: null, quiz13: null, quiz21: null};

      let foundField = false;
      for (const key in prev) {
        const keyRouter = key as keyof RouterAnswer;
        if (key === field) {
          foundField = true;
          updatedAnswers[keyRouter] = value;
          break;
        }

        updatedAnswers[keyRouter] = foundField ? null : prev[keyRouter];
      }

      return updatedAnswers;
    });
  };

  return (
    <StyledContent>
      <StatusChecker
        errorsArray={[isErrorQuestion, isErrorAccelerator]}
        loadingsArray={[isLoading, isLoadingAccelerator]}>
        <ChoiceButtons
          onClickYes={() => setIsAccelerator(true)}
          onClickNo={() => setIsModalNo(true)}
          isActive={isAccelerator}
          title={'Данная заявка подаётся в рамках акселерационной программы?'}
        />

        {isAccelerator && (
          <SingleDropdown
            labels={acceleratorsLabels}
            value={accelerator}
            handleChange={setAccelerator}
            placeholder="Выберите акселератор"
          />
        )}

        {accelerator && (
          <>
            {questions?.map(({id, hint, text}) => {
              return (
                <AcceleratorAnswer
                  key={id}
                  question={text}
                  hint={hint}
                  value={answers[id]}
                  setValue={handleChange(id)}
                />
              );
            })}

            <ChoiceButtons
              title={titles['quiz1']}
              isActive={routerAnswers['quiz1']}
              onClickNo={() => toggleButton('quiz1', false)}
              onClickYes={() => toggleButton('quiz1', true)}
              tooltipText={tooltips['quiz1']}
            />

            {routerAnswers['quiz1'] === false && (
              <ChoiceButtons
                title={titles['quiz4']}
                isActive={routerAnswers['quiz4']}
                onClickNo={() => toggleButton('quiz4', false)}
                onClickYes={() => toggleButton('quiz4', true)}
                tooltipText={tooltips['quiz4']}
              />
            )}

            {routerAnswers['quiz1'] === false && routerAnswers['quiz4'] === false && (
              <ChoiceButtons
                title={titles['quiz13']}
                isActive={routerAnswers['quiz13']}
                onClickNo={() => toggleButton('quiz13', false)}
                onClickYes={() => toggleButton('quiz13', true)}
                tooltipText={tooltips['quiz13']}
              />
            )}

            {routerAnswers['quiz1'] === false &&
              routerAnswers['quiz4'] === false &&
              routerAnswers['quiz13'] === false && (
                <ChoiceButtons
                  title={titles['quiz21']}
                  isActive={routerAnswers['quiz21']}
                  onClickNo={() => toggleButton('quiz21', false)}
                  onClickYes={() => toggleButton('quiz21', true)}
                  tooltipText={tooltips['quiz21']}
                />
              )}
          </>
        )}
        {isError && <StyledError $color={Colors.red}>Вы ответили не на все вопросы</StyledError>}
        <SaveButtonWrapper>
          {!isDisableButton && <FilterButton action={onSave} isBlue={true} title="Готово" />}
        </SaveButtonWrapper>
        {isModalNo && <AcceptModal handleYes={setActive} setIsOpen={setIsModalNo} />}
      </StatusChecker>
      {helpModal && (
        <Overlay onClick={() => setHelpModal(false)}>
          <ModalWrapper
            onClick={e => {
              e.stopPropagation();
            }}>
            <CloseButton onClick={() => setHelpModal(false)}>
              <CrossIcon />
            </CloseButton>

            <TextParagraph color={Colors.textDark}>
              <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <p>
                  В первом разделе чек-листа &#34;Акселератор&#34; Вам необходимо выбрать
                  &#34;Да&#34;, если Ваша заявка подаётся в рамках акселерационной программы, после
                  чего Вам потребуется заполнить подробную информацию, или &#34;Нет&#34; в обратном
                  случае, после чего Вам необходимо будет заполнить ответы на вопросы по разделам
                  маршрутизации.
                </p>
                <p>
                  Подробнее о действующих программах акселерации Вы можете узнать в разделе &#34;Об
                  ИнноХаб&#34; в главном меню платформы.
                </p>
                <p>
                  После изучения Вы можете подать заявку в соответствующий акселератор через кнопку
                  &#34;Да&#34; в данном разделе.
                </p>
              </div>
            </TextParagraph>
          </ModalWrapper>
        </Overlay>
      )}
    </StyledContent>
  );
};

export default Accelerator;
