import styled from 'styled-components';

import React from 'react';

import {
  DocumentIcon,
  EnvelopeIcon,
  LabelIcon,
  LastInIcon,
  PhoneCallIcon,
  ShareIcon,
} from '@Common/Images';

import {formatDate} from '@utils/formatDate';

import {IUser} from 'types/User';

import ProfileInfoContact from './Contact';

const ContentInfoContactsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  align-items: start;
  text-align: left;

  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  border-radius: 10px;
`;

const checkLink = (link: string | null, title: string) => {
  return link ? title + link : '';
};

const ProfileInfoContacts = ({data}: {data: IUser}) => {
  return (
    <ContentInfoContactsStyled>
      <ProfileInfoContact data={[data.email1, data.email2]} title="Email" icon={<EnvelopeIcon />} />
      <ProfileInfoContact
        data={[data.phone1, data.phone2, data.phone3]}
        title="Телефон(ы)"
        icon={<PhoneCallIcon />}
      />
      <ProfileInfoContact
        data={[
          checkLink(data.social_telegram, 'Telegram: '),
          checkLink(data.social_linkedin, 'LinkedIn: '),
          checkLink(data.social_vk, 'VK: '),
          checkLink(data.social_google, 'Google: '),
          checkLink(data.social_skype, 'Skype: '),
        ]}
        title="Соц сети"
        icon={<ShareIcon />}
      />
      <ProfileInfoContact data={data.competencies} title="Компетенции" icon={<DocumentIcon />} />
      <ProfileInfoContact data={data.tags} title="Теги" icon={<LabelIcon />} />
      <ProfileInfoContact data={[data.source]} title="Источник" icon={<DocumentIcon />} />
      <ProfileInfoContact data={[data.extra]} title="Дополнительно" icon={<DocumentIcon />} />
      <ProfileInfoContact
        data={[formatDate(data.last_login)]}
        title="Последний вход"
        icon={<LastInIcon />}
      />
    </ContentInfoContactsStyled>
  );
};

export default ProfileInfoContacts;
