import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {workflowsService} from '@services/workflows';

import {transformDropdownData} from '@utils/TransformDropdownData';

const UseWorkflows = (acceleratorId?: string) => {
  const {
    data: workflowsList,
    isLoading: isLoadingWorkflows,
    isError: isErrorWorkflows,
  } = useQuery({
    queryKey: ['workflows', acceleratorId],
    queryFn: () => (acceleratorId ? workflowsService.geByAccelerator(acceleratorId) : []),
  });

  const workflowsLabels = useMemo(
    () => transformDropdownData(workflowsList || []),
    [workflowsList],
  );

  return {workflowsList, isLoadingWorkflows, isErrorWorkflows, workflowsLabels};
};

export default UseWorkflows;
