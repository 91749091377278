import styled from 'styled-components';

import React from 'react';

interface IPictureProps {
  src: string;
  variant: string;
  alt: string;
}

const StyledPicture = styled.img<{$variant: string}>`
  border-radius: 100%;
  width: ${props => (props.$variant === 'sm' ? '64px' : '100px')};
  height: ${props => (props.$variant === 'sm' ? '64px' : '100px')};
  object-fit: cover;
`;

const Picture = ({src, variant = 'big', alt}: IPictureProps) => {
  return <StyledPicture src={src} $variant={variant} alt={alt} />;
};

export default Picture;
