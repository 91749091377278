import React, {useRef, useState} from 'react';

import {ContentBoardMenu, PaginationMenu} from '@components/Menu';

import {ContentBoardMenuButton, ContentBoardViewButton} from '@Common/Buttons';
import {FilterGIcon, PlusImageComponent} from '@Common/Images';

import AcceleratorsTable from '@Tables/Accelerators';

import {UseNavigationList} from '@hooks/UseNavigationList';

const Accelerators = () => {
  const pageTitle = useRef('Акселераторы');
  const mockFunction = () => console.log('Accelerators/mock');
  const [isFiltersShown, setIsFilterShown] = useState(false);

  const navigationList = useRef([
    {path: '/accelerators', title: pageTitle.current},
    {path: '/accelerators', title: 'Список'},
  ]);

  UseNavigationList(navigationList.current);
  return (
    <>
      <ContentBoardMenu pageTitle={pageTitle.current}>
        <ContentBoardMenuButton
          title={'Добавить'}
          action={mockFunction}
          image={PlusImageComponent}
        />

        <ContentBoardViewButton />

        <ContentBoardMenuButton
          isActive={isFiltersShown}
          action={() => {
            setIsFilterShown(prev => !prev);
          }}
          image={<FilterGIcon />}
        />
      </ContentBoardMenu>

      <PaginationMenu isFilterButtonsShown={isFiltersShown} />

      <AcceleratorsTable editAction={mockFunction} />
    </>
  );
};

export default Accelerators;
