import {useQuery} from '@tanstack/react-query';

import {tagsService} from '@services/tags';

export function UseTag(tagId: string) {
  const {
    data: tagData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['tag'],
    queryFn: () => tagsService.getOne(tagId),
  });

  return {tagData, isLoading, isError};
}
