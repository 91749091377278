import NewsletterTemplatesTable from 'components/Tables/NewsletterTemplates';
import React, {FC, useRef, useState} from 'react';

import {ContentBoardMenu, PaginationMenu} from '@components/Menu';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {FilterGIcon, PlusImageComponent} from '@Common/Images';

import useAppSelector from '@hooks/UseAppSelector';
import {UseNavigationList} from '@hooks/UseNavigationList';

const NewsletterTemplates: FC = () => {
  const mockFunction = () => console.log('Recommendations/mock');
  const [isFiltersShown, setIsFilterShown] = useState(false);

  const newsletterTemplatesList = useAppSelector(state => state.newsletterTemplates.value);
  const navigationList = useRef([
    {path: '/recommendations', title: 'Рекомендации'},
    {path: '/recommendations', title: 'Список'},
  ]);

  UseNavigationList(navigationList.current);

  return (
    <>
      <ContentBoardMenu pageTitle={'Шаблоны рассылок'}>
        <ContentBoardMenuButton
          title={'Создать шаблон'}
          action={mockFunction}
          image={PlusImageComponent}
        />

        <ContentBoardMenuButton
          action={() => {
            setIsFilterShown(prev => !prev);
          }}
          image={<FilterGIcon />}
        />
      </ContentBoardMenu>
      <PaginationMenu objectList={newsletterTemplatesList} isFilterButtonsShown={isFiltersShown} />

      <NewsletterTemplatesTable />
    </>
  );
};

export default NewsletterTemplates;
