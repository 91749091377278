import UserPicture from 'assets/images/portrait-smiley-man-outdoor 1.png';

import {AlertsState} from 'types/Alert';

const initialState: AlertsState = {
  value: [
    {
      isRead: false,
      src: UserPicture,
      message: {text: 'Ваш профиль', problem: 'Укажите ФИО', dateString: '22222'},
    },
    {
      isRead: false,
      message: {
        text: 'Ваш профиль',
        problem: 'Загрузите аватар',
        dateString: 'string',
      },
    },
  ],
};

export default initialState;
