import {createSlice} from '@reduxjs/toolkit';

import initialState from './state';

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    markAllAsRead: state => {
      state.value = state.value.map(item => ({...item, isRead: true}));
    },
  },
});

export const {markAllAsRead} = alertsSlice.actions;

export default alertsSlice.reducer;
