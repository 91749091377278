import {ContentBoardMenuEnum} from 'constants/enum/ContentBoardMenu';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import initialState from 'store/projects/state';
import {sortArray} from 'utils';

import {IFilters} from 'types/filters';
import {IProjectItem} from 'types/project';

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    sort: (state, action: PayloadAction<{sortableField: ContentBoardMenuEnum}>) => {
      const payloadAction: {sortableField: keyof IProjectItem; sortDirection: string} = {
        sortableField: 'title',
        sortDirection: 'up',
      };
      switch (action.payload.sortableField) {
        case ContentBoardMenuEnum.nameUp:
          payloadAction.sortableField = 'title';
          payloadAction.sortDirection = 'up';
          break;
        case ContentBoardMenuEnum.nameDown:
          payloadAction.sortableField = 'title';
          payloadAction.sortDirection = 'down';
          break;
      }

      sortArray(state.value, payloadAction);
    },

    setViewTitle: (state, action: PayloadAction<{title: ContentBoardMenuEnum}>) => {
      state.views.viewsTitle = action.payload.title;
    },
    setValue: (state, action: PayloadAction<{objectsArray: IProjectItem[]}>) => {
      state.value = action.payload.objectsArray;
    },
    setFilteredValue: (state, action: PayloadAction<{objectsArray: IProjectItem[]}>) => {
      state.filteredValue = action.payload.objectsArray;
    },
    addFilters: (
      state,
      action: PayloadAction<{key: keyof IFilters; value: string | boolean | null}>,
    ) => {
      if (typeof action.payload.value === 'string' || action.payload.value === null)
        state.filterKeys[action.payload.key] = action.payload.value;
    },
  },
});

export const {sort, setViewTitle, setFilteredValue, addFilters} = projectsSlice.actions;

export default projectsSlice.reducer;
