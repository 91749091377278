export enum ContentBoardMenuEnum {
  accelerator = 'Акселератор',
  status = 'Статус',
  tags = 'Теги',
  noGroup = 'Без группировки',

  dateCreateUp = 'Дата создания',
  dateCreateDown = 'Дата создания (по убыванию)',
  nameUp = 'Название',
  nameDown = 'Название по убыванию',
  dateUpdateUp = 'Дата обновления',
  dateUpdateDown = 'Дата обновления (по убыванию)',

  table = 'Таблица',
  cards = 'Карточки',
}
