import {configureStore} from '@reduxjs/toolkit';
import navigationReducer from 'store/navigation/slice';

import {alertsReducer} from './alerts';
import {companyReducer} from './company';
import {draftReducer} from './draft';
import {draftsReducer} from './drafts';
import filtersReducer from './filters/slice';
import {legalEntitiesReducer} from './legalEntities';
import {NewsletterTemplatesReducer} from './newsletterTemplates';
import {profilesReducer} from './profiles';
import {projectAreaReducer} from './projectarea';
import {projectsReducer} from './projects';
import {recommendationsReducer} from './recommendations';
import {ReportsReducer} from './reports';
import {ReservationReducer} from './reservation';
import {supportReducer} from './support';
import {userReducer} from './user';
import {viewReducer} from './view';
import {workflowsReducer} from './workflows';

const store = configureStore({
  reducer: {
    alerts: alertsReducer,
    draft: draftReducer,

    drafts: draftsReducer,
    legalEntities: legalEntitiesReducer,
    navigation: navigationReducer,
    newsletterTemplates: NewsletterTemplatesReducer,
    profiles: profilesReducer,
    workflows: workflowsReducer,
    projects: projectsReducer,
    recommendations: recommendationsReducer,
    reports: ReportsReducer,
    reservation: ReservationReducer,
    support: supportReducer,
    filters: filtersReducer,
    projectarea: projectAreaReducer,
    company: companyReducer,

    view: viewReducer,
    user: userReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
