export const stepList = [
  {description: 'Создание гипотезы', stage: 4},
  {description: 'Разработка концепции', stage: 3},
  {description: 'Инвестиционный проект', stage: 2},
  {description: 'Масштабирование', stage: 1},
];

export const stepListLabels = [
  {label: 'Создание гипотезы', value: '4'},
  {label: 'Разработка концепции', value: '3'},
  {label: 'Инвестиционный проект', value: '2'},
  {label: 'Масштабирование', value: '1'},
];
