import {styled} from 'styled-components';

import {useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {useState} from 'react';
import {createPortal} from 'react-dom';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {ExpertiseService} from '@services/expertise';

import {FilterButton} from '@Common/Buttons';
import DeleteButton from '@Common/Buttons/Delete';
import AcceleratorCommentModal from '@Common/Modal/AcceleratorComment';
import AcceptModal from '@Common/Modal/Accept';
import {TextParagraph, TextSmall} from '@Common/Text';

import {Colors} from '@constants/enum';

import {formatDateWithoutTime} from '@utils/formatDate';

import {ICommentStep} from 'types/state/ProjectStepList';

import ExpertiseComment from './ExpertiseComment';
import {StyledExpertButtons} from './styles';
import {Props} from './types';

export const StyledContentRow = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};

  display: grid;
  grid-template-columns: auto 13% 17% 12% 12% 11% 5%;
  align-items: center;
  gap: 15px;

  padding: 10px;
  width: 100%;
`;

const DraftsExpertiseItem = ({data, user}: Props) => {
  const queryClient = useQueryClient();
  const {is_superuser, id: userId} = user;
  const pageId = Number(useParams().id);
  const [shownResult, setShownResult] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [isShownAccept, setIsShownAccept] = useState(false);

  const expert = data.project_expert.profile;
  const isFinished = data.finished_at;

  const deleteMutation = useMutation({
    mutationFn: () => ExpertiseService.delete(data.id),
    onSuccess: () => {
      void queryClient.invalidateQueries(['expertise', pageId]);
    },
  });

  const completeMutation = useMutation({
    mutationFn: (comment: ICommentStep) =>
      ExpertiseService.completion(data.id, comment.text, comment.attachments),
    onSuccess: () => {
      void queryClient.invalidateQueries(['expertise', pageId]);
    },
  });

  const toggleComment = () => {
    isFinished ? setShownResult(prev => !prev) : setShowModal(true);
  };

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  const handleCompletion = (comment: ICommentStep) => {
    completeMutation.mutate(comment);
  };

  return (
    <>
      <StyledContentRow>
        <TextParagraph color={Colors.accentBlue}>{expert.fio || expert.email1}</TextParagraph>
        <TextSmall>{formatDateWithoutTime(data.created_at)}</TextSmall>
        <TextParagraph color={Colors.textDark}>
          {data.project_step
            ? `${data.project_step?.accelerator.name} / ${data.project_step?.step.name}`
            : '-'}
        </TextParagraph>
        <TextSmall>{formatDateWithoutTime(data.deadline)}</TextSmall>
        <TextParagraph color={Colors.accentBlue}>
          {isFinished ? 'Завершена' : 'Запрошена'}
        </TextParagraph>
        <StyledExpertButtons>
          {(isFinished || is_superuser || userId === expert.id) && (
            <FilterButton
              isBlue={false}
              title={isFinished ? 'Результат' : 'Дать экспертизу'}
              action={toggleComment}
            />
          )}
          {!isFinished && userId === expert.id && (
            <FilterButton
              isBlue
              title={'Комментарий'}
              action={() => {
                setShownResult(prev => !prev);
              }}
            />
          )}
        </StyledExpertButtons>

        {(is_superuser || userId === data.created_by_id) && !isFinished && (
          <DeleteButton action={() => setIsShownAccept(true)} />
        )}
      </StyledContentRow>
      {shownResult && <ExpertiseComment comments={data.comments} />}
      {showModal &&
        createPortal(
          <AcceleratorCommentModal onSave={handleCompletion} onClose={() => setShowModal(false)} />,
          document.body,
        )}

      {isShownAccept &&
        createPortal(
          <AcceptModal handleYes={handleDelete} setIsOpen={setIsShownAccept} />,
          document.body,
        )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(DraftsExpertiseItem);
