import styled from 'styled-components';

import React from 'react';

import {checkRoles} from '@components/ProfileItem/Info/Card';

import {Colors, TextParagraph} from '@Common/Text';

import UseAppSelector from '@hooks/UseAppSelector';

const StyledSpan = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #0f1120;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

type UserNameProps = {
  text: string;
};

const UserName = ({text}: UserNameProps) => {
  const user = UseAppSelector(state => state.user.data);
  return (
    <StyledSpan>
      {text}

      <TextParagraph align="center" color={Colors.grey}>
        {checkRoles(user)}
      </TextParagraph>
    </StyledSpan>
  );
};

export default UserName;
