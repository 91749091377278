import styled from 'styled-components';

import React from 'react';

import {IHeaderMedium} from './types';

export const StyledHeaderMedium = styled.h2<{$align?: string; $isLink?: boolean}>`
  display: flex;
  margin: 0;
  word-break: break-word;
  text-align: ${({$align}) => $align || 'left'};
  justify-content: ${({$align}) => $align || 'left'};
  color: ${({theme, $isLink}) => ($isLink ? theme.colors.accentBlue : theme.colors.textDark)};
  font-weight: ${({theme}) => theme.fontWeight.bald};
  font-size: ${({theme}) => theme.fontSize.medium};
`;

const TextHeaderMedium = ({children, align, isLink}: IHeaderMedium) => {
  return (
    <StyledHeaderMedium $isLink={isLink} $align={align}>
      {children}
    </StyledHeaderMedium>
  );
};

export default TextHeaderMedium;
