import {axiosDocument, instance} from 'api';

import {IPostRequestProjectStep} from 'types/patchRequest';
import {IApiResponse} from 'types/services';
import {IPatchProjectStep, IProjectStepListItem} from 'types/state/ProjectStepList';
import {IStepItem} from 'types/state/Step';

export const ProjectStepListService = {
  async getAll() {
    return (await instance.get<IApiResponse<IProjectStepListItem>>('/project-step/')).data.results;
  },

  async getStepsByWorkflow(workflow_id: number) {
    return (await instance.get<IApiResponse<IStepItem>>(`/step/?workflow_id=${workflow_id}`)).data
      .results;
  },
  async getById(id: number) {
    return (await instance.get<IProjectStepListItem>(`/project-step/${id}/`)).data;
  },

  async getActive(projectId: number) {
    return (
      await instance.get<IApiResponse<IProjectStepListItem>>(
        `/project-step/?project=${projectId}&is_active=true`,
      )
    ).data.results;
  },

  async nextStep(projectId: number, text: string, attachments?: File[]) {
    const formData = new FormData();
    attachments?.forEach(file => {
      formData.append('attachments', file);
    });
    formData.append('text', text);
    return await axiosDocument.post<{ordering: number}>(
      `/project/${projectId}/next-step/`,
      formData,
    );
  },

  async prevStep(projectId: number) {
    return await instance.post(`/project/${projectId}/prev-step/`);
  },

  async createComment(projectId: number, text: string, attachments?: File[]) {
    const formData = new FormData();
    attachments?.forEach(file => {
      formData.append('attachments', file);
    });
    formData.append('text', text);
    return await axiosDocument.post(`/project/${projectId}/comment-step/`, formData);
  },

  async getByProject(projectId: number) {
    return (
      await instance.get<IApiResponse<IProjectStepListItem>>(`/project-step/?project=${projectId}`)
    ).data.results;
  },

  async add(data: IPostRequestProjectStep) {
    return instance.post<IProjectStepListItem>('/project-step/', data);
  },

  async update(id: number, data: IPatchProjectStep) {
    return instance.patch<IPatchProjectStep>(`/project-step/${id}/`, data);
  },
};
