export const renameStatus = (status: string) => {
  if (status === 'draft') return 'На проверке';
  if (status === 'negotiation') return 'Согласование';
  if (status === 'open') return 'Открыт';
  if (status === 'hired') return 'Выбран исполнитель';
  if (status === 'in_work') return 'В работе';
  if (status === 'declined') return 'Отклонён на корректировку';
  if (status === 'finished') return 'Завершён';
};

export const renameStatusResponse = (status: string) => {
  if (status === 'declined') return 'Отклонён';
  if (status === 'active') return 'Активный';
  if (status === 'selected') return 'Согласован';
};
