import {ThemeProvider} from 'styled-components';

import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import React from 'react';
import {CookiesProvider} from 'react-cookie';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import Router from 'routes/Router';

import store from './store';
import {GlobalStyles, theme} from './styles';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider defaultSetOptions={{path: '/'}}>
          <Provider store={store}>
            <Router />
            <GlobalStyles />
          </Provider>
        </CookiesProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
