import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {CfoService} from '@services/cfo';

import {transformDropdownData} from '@utils/TransformDropdownData';

const UseCfo = () => {
  const fetchCfoData = async () => {
    return (await CfoService.getAll()).filter(item => item.name !== '');
  };

  const {
    data: cfoList,
    isLoading: isLoadingCfo,
    isError: isErrorCfo,
  } = useQuery({
    queryKey: ['cfo'],
    queryFn: fetchCfoData,
  });

  const cfoLabels = useMemo(() => transformDropdownData(cfoList || []), [cfoList]);

  return {
    cfoList: cfoList || [],
    isLoadingCfo,
    isErrorCfo,
    cfoLabels,
    nameCfo: cfoList?.map(({name}) => name) || [],
  };
};

export default UseCfo;
