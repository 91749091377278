import {styled} from 'styled-components';

import React from 'react';

import {InfoIcon} from '@Common/Images';
import {TextParagraph} from '@Common/Text';
import TooltipCustom from '@Common/Tooltip';

import {Colors} from '@constants/enum';

const StyledMenuButton = styled.button`
  border-radius: ${({theme}) => theme.borderRadius.onlyTop};
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  border-bottom: ${({theme}) => `1px solid ${theme.colors.white}`};
  background-color: #fff;
  display: flex;
  padding: 10px;

  cursor: default;
`;

interface IMenuButton {
  title: string;
  tooltip?: string;
}

const MenuButton = ({title, tooltip}: IMenuButton) => {
  return (
    <StyledMenuButton>
      <TextParagraph color={Colors.textDark}>{title}</TextParagraph>
      {tooltip && (
        <TooltipCustom content={tooltip}>
          <InfoIcon />
        </TooltipCustom>
      )}
    </StyledMenuButton>
  );
};

export default MenuButton;
