import CaseIcon from 'assets/images/caseIcon.png';
import React, {useState} from 'react';

import {DraftsItemWrapper} from '@components/Wrappers/DraftsItem';

import {PanelHeader} from '@Common/DraftsItem/Panel';

import UseAppSelector from '@hooks/UseAppSelector';

import DraftsCustomersContent from './Content';

const DraftsItemCustomer = () => {
  const {is_customer} = UseAppSelector(state => state.user.data);
  const [isShownContent, setIsShownContent] = useState(false);
  const handleShow = () => {
    setIsShownContent(prev => !prev);
  };

  return (
    <DraftsItemWrapper isRed={is_customer}>
      <PanelHeader
        isShownContent={isShownContent}
        icon={<img src={CaseIcon} height={24} />}
        title={'Бизнес-заказчик'}
        action={handleShow}
      />
      {isShownContent && <DraftsCustomersContent />}
    </DraftsItemWrapper>
  );
};

export default DraftsItemCustomer;
