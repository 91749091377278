import styled from 'styled-components';

import {Link} from 'react-router-dom';

import React, {FormEvent, useState} from 'react';

import {AuthService} from '@services/auth';

import AuthContainer from '@Common/AuthContainer';
import {LoginFormButton} from '@Common/Buttons';
import {LoginForm, LoginInput} from '@Common/Forms/Login';
import Loader from '@Common/Loader';
import {TextHeader, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

const AppLink = styled(Link)`
  text-decoration: none;
  color: rgb(23, 80, 203);
  align-items: center;
  display: flex;
  gap: 8px;

  &:hover {
    color: #1a225f;
  }

  & path {
    fill: #1750cb;
  }
`;

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isHelpShown, setIsHelpShown] = useState(false);

  const onSubmit = async (event: FormEvent) => {
    setIsLoading(true);
    event.preventDefault();

    await AuthService.resetPassword({email})
      .then(() => {
        setIsHelpShown(true);
        setIsErrorShown(false);
      })
      .catch(() => setIsErrorShown(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <AuthContainer>
      <TextHeader align="center" color="white">
        ИННОХАБ РОСАТОМА
        <br /> ПЛАТФОРМА МАРШРУТИЗАЦИИ ПРОЕКТОВ
      </TextHeader>
      <LoginForm onSubmit={(e: FormEvent) => onSubmit(e)}>
        <TextParagraph color={Colors.textDark}>Сброс пароля</TextParagraph>
        <LoginInput
          type={'email'}
          value={email}
          autoComplete={'on'}
          onChange={e => setEmail(e.target.value)}
          placeholder={'Email'}
        />

        <LoginFormButton type="submit">Сбросить пароль</LoginFormButton>
        {isLoading && <Loader />}
        {isErrorShown && (
          <TextParagraph color={Colors.textDark}>Проверьте введенные данные</TextParagraph>
        )}
        <AppLink to={'/auth'}>Войти</AppLink>
        <AppLink to={'/registration'}>Регистрация</AppLink>
      </LoginForm>

      {isHelpShown && (
        <TextParagraph color={Colors.white}>
          На указанную почту было выслано письмо с ссылкой для восстановления
        </TextParagraph>
      )}
    </AuthContainer>
  );
};

export default ResetPasswordPage;
