import {useMutation} from '@tanstack/react-query';
import React from 'react';

import {tagsService} from '@services/tags';

import Loader from '@Common/Loader';

import StyledForm from './styled';
import {EditTagsProps} from './types';

const EditTagsForm = ({children, id, tagName}: EditTagsProps) => {
  const mutation = useMutation({
    mutationFn: () => {
      return tagsService.edit({name: tagName}, id);
    },
  });
  if (mutation.isLoading) return <Loader />;

  if (mutation.isError) return <div>Error</div>;

  return (
    <StyledForm onSubmit={e => e.preventDefault()}>
      <label>
        <b>Название</b>
        {children}
      </label>
      <input type={'submit'} value={'Сохранить'} onClick={() => mutation.mutate()} />
    </StyledForm>
  );
};

export default EditTagsForm;
