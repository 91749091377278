export const commentsChoices = [
  {
    value: 'comment',
    display_name: 'Комментарий',
  },
  {
    value: 'profiles_import',
    display_name: 'Профили - Импортирование',
  },
  {
    value: 'profile_create',
    display_name: 'Профиль - Создание',
  },
  {
    value: 'profile_change',
    display_name: 'Профиль - Изменение',
  },
  {
    value: 'accelerator_create',
    display_name: 'Акселератор - Создание',
  },
  {
    value: 'accelerator_change',
    display_name: 'Акселератор - Изменение',
  },
  {
    value: 'workflow_create',
    display_name: 'Workflow - Создание',
  },
  {
    value: 'projects_import',
    display_name: 'Проекты - Импортирование',
  },
  {
    value: 'project_create',
    display_name: 'Проект - Создание',
  },
  {
    value: 'project_change_data',
    display_name: 'Проект - Изменение данных',
  },
  {
    value: 'project_public',
    display_name: 'Проект - Публикация',
  },
  {
    value: 'project_change_accelerator_founder',
    display_name: 'Проект - Выбор акселератора',
  },
  {
    value: 'project_change_accelerator_tracker',
    display_name: 'Проект - Смена акселератора',
  },
  {
    value: 'project_in_work',
    display_name: 'Проект - В работе',
  },
  {
    value: 'project_next_step',
    display_name: 'Проект - Смена этапа',
  },
  {
    value: 'project_router_next_step',
    display_name: 'Проект - Маршрутизатор - Смена этапа',
  },
  {
    value: 'project_previous_step',
    display_name: 'Проект - Смена этапа назад',
  },
  {
    value: 'project_router_previous_step',
    display_name: 'Проект - Маршрутизатор - Смена этапа назад',
  },
  {
    value: 'project_router_checklist_update',
    display_name: 'Проект - Маршрутизатор - Обновлен чек-лист',
  },
  {
    value: 'project_expertise_create',
    display_name: 'Проект - Экспертиза - Создание',
  },
  {
    value: 'project_expertise_finish',
    display_name: 'Проект - Экспертиза - Завершение',
  },
  {
    value: 'project_archive',
    display_name: 'Проект - Архивация',
  },
  {
    value: 'project_task_create',
    display_name: 'Проект - Задача - Создание',
  },
  {
    value: 'project_task_done',
    display_name: 'Проект - Задача - Завершение',
  },
  {
    value: 'project_task_remove',
    display_name: 'Проект - Задача - Удаление',
  },
  {
    value: 'project_request_create',
    display_name: 'Запрос - Создание',
  },
  {
    value: 'project_request_change_data',
    display_name: 'Запрос - Изменение данных',
  },
  {
    value: 'project_request_attachments_create',
    display_name: 'Запрос - Добавление вложений',
  },
  {
    value: 'project_request_draft',
    display_name: 'Запрос - Смена статуса на Черновик',
  },
  {
    value: 'project_request_negotiation',
    display_name: 'Запрос - Смена статуса на Согласование',
  },
  {
    value: 'project_request_open',
    display_name: 'Запрос - Смена статуса на Открыт',
  },
  {
    value: 'project_request_hired',
    display_name: 'Запрос - Смена статуса на Выбран исполнитель',
  },
  {
    value: 'project_request_in_work',
    display_name: 'Запрос - Смена статуса на В работе',
  },
  {
    value: 'project_request_declined',
    display_name: 'Запрос - Смена статуса на Отклонен',
  },
  {
    value: 'project_request_finished',
    display_name: 'Запрос - Смена статуса на Завершен',
  },
  {
    value: 'project_request_archive',
    display_name: 'Запрос - Архивация',
  },
  {
    value: 'project_request_response_create',
    display_name: 'Отклик на запрос - Создание',
  },
  {
    value: 'project_request_response_decline',
    display_name: 'Отклик на запрос - Отклонен',
  },
  {
    value: 'project_request_response_expertise_create',
    display_name: 'Отклик на запрос - Экспертиза - Создание',
  },
  {
    value: 'project_request_response_expertise_finish',
    display_name: 'Отклик на запрос - Экспертиза - Завершение',
  },
  {
    value: 'newsletter_create',
    display_name: 'Рассылка - Создание',
  },
];
