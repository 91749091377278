import styled from 'styled-components';

const StyledSupport = styled.a<{$isHidden: boolean}>`
  border-width: 0;
  display: grid;
  grid-template-columns: ${({$isHidden}) => ($isHidden ? '30px' : '24px 100px 20px')};
  padding: 10px 24px;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background: unset;
  cursor: pointer;
  text-decoration: none;
  width: ${props => (props.$isHidden ? '' : '240px')};
  transition: all 0.2s ease-out;

  &:hover {
    background-color: #2b2e3a;
  }

  & span {
    color: #9a9cab;
  }
  & g path {
    fill: #9a9cab;
  }

  &.active {
    background: var(--white, #fff);
  }

  &.active span {
    color: #0a0a0a;
  }

  &.active g path {
    fill: #0a0a0a;
  }
`;

export default StyledSupport;
