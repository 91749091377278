import React from 'react';

import ColumnNames from './ColumnNames';

const WorkflowsTable = () => {
  return (
    <>
      <ColumnNames />
    </>
  );
};

export default WorkflowsTable;
