import React from 'react';

import PaginationMenuWrapper from '@components/Wrappers/PaginationMenu';

import {Counter} from '@Common/Counter';
import {SortFormInput} from '@Common/Input';

import {IFilters} from 'types/filters';

interface PaginationMenuProps<T extends object> {
  isFilterButtonsShown?: boolean;
  lengthList?: number;
  handleFilter?: (value: string | null, key: keyof IFilters) => void;
  objectList?: T[];
  filterKeys?: IFilters;
}
const PaginationMenu = <T extends object>({lengthList}: PaginationMenuProps<T>) => {
  return (
    <PaginationMenuWrapper>
      <SortFormInput />

      {lengthList && <Counter count={lengthList} />}
    </PaginationMenuWrapper>
  );
};

export default PaginationMenu;
export {default as PaginationButtons} from './PaginationButtons';
