import {useEffect} from 'react';

import {setNavigationList} from '@store/navigation/slice';

import {NavigationList} from 'types/components';

import UseAppDispatch from './UseAppDispatch';

type UseNavigationListType = (navigationList: NavigationList) => void;

export const UseNavigationList: UseNavigationListType = navigationList => {
  const dispatch = UseAppDispatch();
  useEffect(() => {
    dispatch(setNavigationList({navigationList}));
  }, [dispatch, navigationList]);
};
