import {useMutation, useQueryClient} from '@tanstack/react-query';

import {TechRequestService} from '@services/techRequest';

import {IPatchRequestResponse} from 'types/patchRequest';

const UseMutationTechRequestResponse = (responseId: number, techRequestId: number) => {
  const queryClient = useQueryClient();

  const fetchFn = (data: IPatchRequestResponse) =>
    TechRequestService.updateResponse(responseId, data);
  return useMutation({
    mutationFn: fetchFn,
    onSuccess: () => {
      void queryClient.invalidateQueries(['techRequestsResponses', techRequestId]);
    },
  });
};
export default UseMutationTechRequestResponse;
