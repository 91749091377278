import styled from 'styled-components';

import React, {memo} from 'react';

import {FlexButton} from '@Common/Utils/FlexButton';

import {FilterButtonProps} from 'types/Filter';

const StyledButton = styled(FlexButton)<{$isBlue: boolean; $width?: string}>`
  ${({$width}) =>
    $width
      ? `
  width: ${$width};
`
      : ''}
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  font-size: 16px;

  background: ${props => (props.$isBlue ? '#BEE4FF' : '#fff')};
  border: ${props => (props.$isBlue ? 'none' : '1px solid #003274')};
  color: ${props => (props.$isBlue ? 'black' : '#003274')};
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({theme, $isBlue}) => ($isBlue ? theme.colors.oceanBlue : theme.colors.darkBlue)};

    border: ${({theme, $isBlue}) => ($isBlue ? 'none' : `1px solid ${theme.colors.black}`)};
    color: ${({theme}) => theme.colors.white};
  }
`;

const FilterButton = ({isBlue, title, action, disabled, width}: FilterButtonProps) => {
  return (
    <StyledButton $isBlue={!!isBlue} $width={width} onClick={action} disabled={disabled}>
      {title}
    </StyledButton>
  );
};

export default memo(FilterButton);
