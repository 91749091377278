import styled from 'styled-components';

import React, {FC} from 'react';

import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

const StyledTitleHeader = styled.div`
  display: flex;
  gap: 8px;

  & path {
    fill: ${({theme}) => theme.colors.black};
  }
`;

interface ITitleHeader {
  icon: React.ReactNode;
  title: string;
  action?: () => void;
}

const TitlePanelHeader: FC<ITitleHeader> = ({icon, title}) => {
  return (
    <StyledTitleHeader>
      {icon}
      <TextParagraph color={Colors.textDark}>{title}</TextParagraph>
    </StyledTitleHeader>
  );
};

export default TitlePanelHeader;
