import {INewsletterTemplatesState} from 'types/NewsletterTemplates';

const initialState: INewsletterTemplatesState = {
  value: [
    {
      id: 1,
      title: 'Спам 1',
      theme: 'Уникальное предложение',
    },
    {
      id: 2,
      title: 'Спам 2',
      theme: 'Уникальное предложение',
    },
    {
      id: 3,
      title: 'Спам 3',
      theme: 'Уникальное предложение',
    },
    {
      id: 4,
      title: 'Спам 4',
      theme: 'Уникальное предложение',
    },
  ],
};

export default initialState;
