import styled from 'styled-components';

import React from 'react';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {InfoIcon, MinusIcon, PlusIcon} from '@Common/Images';
import TooltipCustom from '@Common/Tooltip';

import {TitlePanelHeader} from './Title';

const StyledPanelHeader = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;

  gap: 16px;
  border-radius: ${({theme}) => theme.borderRadius.full};
  background: ${({theme}) => theme.colors.white};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 5px;
`;

const DopWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

type PanelHeader = {
  icon: React.ReactNode;
  title: string;
  action: () => void;
  isShownContent: boolean;
  tooltip?: string;
  dopButton?: React.ReactNode;
};

const PanelHeader = ({icon, title, action, isShownContent, tooltip, dopButton}: PanelHeader) => {
  return (
    <StyledPanelHeader>
      <HeaderWrapper>
        <TitlePanelHeader icon={icon} title={title} />
        {tooltip && (
          <TooltipCustom content={tooltip}>
            <InfoIcon />
          </TooltipCustom>
        )}
      </HeaderWrapper>
      <DopWrapper>
        {dopButton}
        <ContentBoardMenuButton
          action={action}
          image={isShownContent ? <MinusIcon /> : <PlusIcon />}
        />
      </DopWrapper>
    </StyledPanelHeader>
  );
};

export default PanelHeader;
