import styled from 'styled-components';

import React from 'react';

import {setPage} from '@store/filters/slice';

import PaginationDivider from '@components/Pagination/Divider';

import {PaginationButton} from '@Common/Buttons';

import UseAppDispatch from '@hooks/UseAppDispatch';
import UseAppSelector from '@hooks/UseAppSelector';

const PaginationButtonsWrapper = styled.div<{$margin?: number}>`
  display: flex;
  margin-left: auto;
  height: 40px;
  margin-top: ${({$margin}) => ($margin ? $margin : 0)}px;
`;

interface IPaginationButtonsProps {
  count?: number;
  margin?: number;
}

const PaginationButtons = ({count, margin}: IPaginationButtonsProps) => {
  const {page} = UseAppSelector(state => state.filters);

  const dispatch = UseAppDispatch();
  const handlePrev = () => {
    if (page > 1) dispatch(setPage(page - 1));
  };
  const handleNext = () => {
    if (page < Math.ceil((count || 0) / 50)) dispatch(setPage(page + 1));
  };
  return (
    <PaginationButtonsWrapper $margin={margin}>
      <PaginationButton isActive={page > 1} onClick={handlePrev} isLeft={true} text={'Пред.'} />
      <PaginationDivider text={`${page} ${count ? `из ${Math.ceil(count / 50)}` : ''}`} />

      <PaginationButton
        isActive={page < Math.ceil((count || 0) / 50)}
        onClick={handleNext}
        isLeft={false}
        text={'След.'}
      />
    </PaginationButtonsWrapper>
  );
};

export default PaginationButtons;
