import styled from 'styled-components';

import React, {PropsWithChildren} from 'react';

const StyledNavbarLinksMenu = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  gap: 33px;
`;

const NavBarLinksMenu = ({children}: PropsWithChildren) => (
  <StyledNavbarLinksMenu>{children}</StyledNavbarLinksMenu>
);

export default NavBarLinksMenu;
