import {createSlice} from '@reduxjs/toolkit';
import initialState from 'store/company/state';

import {getAllCompany} from '../actions';

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllCompany.fulfilled, (state, action) => {
      return {...state, ...action.payload, isLoading: false};
    });
  },
});
