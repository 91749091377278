import {instance} from 'api';

import {IProjectArea, IProjectAreaItem} from 'types/Projectarea';

export const ProjectAreaService = {
  async getAll() {
    const response = await instance.get<IProjectArea>('/project-area/');

    return response.data.results;
  },
  async getById(id: number) {
    const response = await instance.get<IProjectAreaItem>(`/project-area/${id}/`);

    return response.data;
  },
};
