import styled from 'styled-components';

import React from 'react';

import {TextParagraph} from '@Common/Text';
import TooltipCustom from '@Common/Tooltip';

import {Colors} from '@constants/enum';

import {StyledButton, StyledButtonsWrapper} from './styles';

const StyledButtonsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

interface IChoiceButtons {
  isActive: boolean | null;
  onClickYes: () => void;
  onClickNo: () => void;
  title: string;
  tooltipText?: string;
}

const ChoiceButtons = ({title, isActive, onClickYes, onClickNo, tooltipText}: IChoiceButtons) => {
  return (
    <StyledButtonsContent>
      <TooltipCustom content={tooltipText}>
        <TextParagraph color={Colors.textDark}>{title}</TextParagraph>
      </TooltipCustom>
      <StyledButtonsWrapper>
        <StyledButton $isActive={isActive || false} onClick={onClickYes}>
          Да
        </StyledButton>
        <StyledButton $isActive={isActive === null ? false : !isActive} onClick={onClickNo}>
          Нет
        </StyledButton>
      </StyledButtonsWrapper>
    </StyledButtonsContent>
  );
};

export default ChoiceButtons;
