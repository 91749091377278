import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import React, {useMemo} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {ScoringService} from '@services/scoring';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {TrashIcon} from '@Common/Images';
import {TextLight} from '@Common/Text';

import {formatDate} from '@utils/formatDate';

import {IScoring} from 'types/state/Scoring';
import {IUser} from 'types/User';

import {ContentItemFeedback} from './Feedback';

const StyledScoringsContentItem = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  justify-content: space-between;
  display: flex;

  padding: 24px 30px;
  width: 100%;
`;

interface IScoringItem {
  data: IScoring;
  user: IUser;
}

const ScoringsContentItem = ({data, user}: IScoringItem) => {
  const pageId = Number(useParams().id);

  const formattedDate = useMemo(() => formatDate(data?.created_at), [data.created_at]);
  return (
    <StyledScoringsContentItem>
      <ContentItemFeedback data={data} />

      <TextLight>{formattedDate}</TextLight>
      {(user.is_supervisor || user.is_superuser) && (
        <ContentBoardMenuButton
          title={'Удалить'}
          action={async () => {
            await ScoringService.deleteScoring(data.id);
            await ScoringService.getByProject(pageId);
          }}
          image={<TrashIcon />}
        />
      )}
    </StyledScoringsContentItem>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});
export default connect(mapStateToProps)(ScoringsContentItem);
