import styled from 'styled-components';

const StyledForm = styled.form`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;

  & label {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 14px;
  }

  & input[type='text'] {
    padding: 12px 12px;
    border: 1px rgba(24, 24, 29, 0.14) solid;
    border-radius: 8px;
    font-size: 16px;
    width: 640px;
  }

  & input[type='text']:focus {
    outline: 1px #00c4ff solid;
  }

  & input[type='submit'] {
    background: rgb(23, 80, 203);
    color: rgb(255, 255, 255);
    border: 0;
    border-radius: 8px;
    padding: 12px 20px;
    font-weight: 500;
    cursor: pointer;
  }
`;

export default StyledForm;
