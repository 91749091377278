import styled from 'styled-components';

import React, {FC, PropsWithChildren} from 'react';

interface NavBarButtonProps extends PropsWithChildren {
  action: () => void;
}

const StyledNavbarButton = styled.button`
  display: flex;
  border: 0;
  background: inherit;
  cursor: pointer;
  gap: 30px;
  align-items: center;
`;

const NavBarButton: FC<NavBarButtonProps> = ({action, children}) => {
  return <StyledNavbarButton onClick={action}>{children}</StyledNavbarButton>;
};

export default NavBarButton;
