import styled from 'styled-components';

import React from 'react';

import {TextParagraph} from '@Common/Text';

import {ContentBoardMenuEnum, Colors} from '@constants/enum';

import {ILabel} from 'types/components';

const Container = styled.div`
  background-color: ${({theme}) => theme.colors.grayBackground};
  border-radius: ${({theme}) => theme.borderRadius.full};
  left: 0;
  overflow: hidden;
  padding: 10px 0;
  position: absolute;
  top: 170%;
  width: 100%;
  z-index: 1;
`;

const DropdownSmallItem = styled.li<{$isSelected: boolean}>`
  display: flex;
  align-items: center;

  padding: 4px 21px 4px 16px;

  cursor: pointer;

  font-size: ${({theme}) => theme.fontSize.medium};
  font-weight: ${({theme}) => theme.fontWeight.normal};

  color: ${({$isSelected, theme}) => ($isSelected ? theme.colors.white : theme.colors.grey)};

  background-color: ${({$isSelected, theme}) =>
    $isSelected ? theme.colors.darkBlue : 'transparent'};

  transition: all 0.2s ease-in-out;

  & svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  & g path {
    fill: ${({$isSelected, theme}) => ($isSelected ? theme.colors.white : theme.colors.grey)};
  }

  &:hover {
    color: ${({theme}) => theme.colors.grey};
    background-color: ${({theme}) => theme.colors.buttonBackground};
  }
`;

interface DropdownListProps {
  labels: ILabel[];
  selected: string;
  handleSelect: (arg0: ContentBoardMenuEnum) => void;
}

const DropdownList: React.FC<DropdownListProps> = ({labels, selected, handleSelect}) => {
  return (
    <Container>
      {labels.map(item => {
        return (
          <DropdownSmallItem
            key={item.label}
            $isSelected={selected === item.label}
            onClick={() => handleSelect(item.label)}>
            {item.icon && React.createElement(React.memo(item.icon))}
            <TextParagraph color={Colors.grey} isActive={selected === item.label}>
              {item.label}
            </TextParagraph>
          </DropdownSmallItem>
        );
      })}
    </Container>
  );
};

export default DropdownList;
