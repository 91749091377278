import styled from 'styled-components';

import React, {FC, useMemo, useState} from 'react';

import DropdownWrapper from '@components/Wrappers/Dropdown';

import {SortFormButton} from '@Common/Buttons';
import {TextInput} from '@Common/Input';

import DropdownScrollbarList from './DropdownScrollbarList';

interface DropdownProps {
  labels?: string[]; // Список выпадающих названий
  title: string; //Стартовое название
  children?: React.ReactNode;
  width?: number; //Ширина
  setSelected: (text: string) => void; //Функция для установи выбранного значения
  selected: string | null; //Выбранное значение
}

const DropdownListContainer = styled.div<{$width?: number}>`
  position: absolute;
  top: 105%;
  left: 0;
  z-index: 1;
  filter: drop-shadow(0px 15px 25px rgba(172, 172, 172, 0.25));
  width: ${({$width}) => ($width ? $width : 340)}px;
  max-height: 155px;
`;

const DropdownWithScrollBar: FC<DropdownProps> = ({
  title,
  labels,
  width,
  setSelected,
  selected,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');

  const filteredLabels = useMemo(
    () =>
      labels ? labels.filter(label => label.toLowerCase().includes(inputValue.toLowerCase())) : [],
    [labels, inputValue],
  );
  const handleSelect = (label: string) => {
    setSelected(label);
    setIsOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const toggleDropdown = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
    setInputValue('');
  };

  const deleteChoice = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    setSelected('-');
  };
  return (
    <DropdownWrapper>
      <SortFormButton
        width={width}
        isOpen={isOpen}
        baseTitle={title}
        title={selected ? selected : title}
        action={e => toggleDropdown(e)}
        actionDelete={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => deleteChoice(e)}
      />

      {isOpen && (
        <DropdownListContainer $width={width}>
          <TextInput value={inputValue} onChange={handleInputChange} />
          <DropdownScrollbarList
            width={width}
            sidebar={false}
            labels={filteredLabels ? filteredLabels : []}
            selected={selected}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleSelect={handleSelect}
          />
        </DropdownListContainer>
      )}
    </DropdownWrapper>
  );
};
export default DropdownWithScrollBar;
