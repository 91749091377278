import {Outlet} from 'react-router-dom';

import AuthApp from 'AuthApp';
import React, {memo, useState} from 'react';

import {MenuNavbarUser, NavigationMenu} from '@components/Menu';
import SideBar from '@components/SideBar';
import BoardWrapper from '@components/Wrappers/Board';
import ContentWrapper from '@components/Wrappers/Content';

const Root = () => {
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const sidebarSwitcher = () => setIsSidebarMinimized(!isSidebarMinimized);

  return (
    <AuthApp>
      <main>
        <SideBar minimized={isSidebarMinimized} />
        <BoardWrapper>
          <MenuNavbarUser menuToggle={sidebarSwitcher} />
          <ContentWrapper>
            <NavigationMenu />
            <Outlet />
          </ContentWrapper>
        </BoardWrapper>
      </main>
    </AuthApp>
  );
};

export default memo(Root);
