import {useParams} from 'react-router-dom';

import React, {useState} from 'react';

import {selectUser} from '@store/user/selectors';

import {PanelContentWrapper} from '@components/Wrappers/DraftsItem';

import {FilterButton} from '@Common/Buttons';
import CustomerActionModal from '@Common/Modal/CustomerAction';
import CustomerConsideration from '@Common/Modal/CustomerConsideration';
import StatusChecker from '@Common/StatusChecker';
import {TextHeaderMedium} from '@Common/Text';

import UseAppSelector from '@hooks/UseAppSelector';
import UseCustomers from '@hooks/UseCustomers';

import {StyledContentRow} from './Item';
import DraftsCustomerItem from './Item';

const titles = ['Бизнес-заказчик', 'Дата', 'Этап/Тех. запрос', 'Срок', 'Статус'];

const DraftsCustomersContent = () => {
  const pageId = Number(useParams().id);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  const {is_customer, is_supervisor} = UseAppSelector(selectUser);
  const {customersAnswersList, isErrorCustomersAnswers, isLoadingCustomersAnswers} =
    UseCustomers(pageId);

  return (
    <StatusChecker
      loadingsArray={[isLoadingCustomersAnswers]}
      errorsArray={[isErrorCustomersAnswers]}>
      <PanelContentWrapper>
        <StyledContentRow>
          {titles.map(title => (
            <TextHeaderMedium key={title}>{title}</TextHeaderMedium>
          ))}
          {is_supervisor && (
            <FilterButton
              isBlue={true}
              action={() => setModal2(true)}
              title={'Отправить на рассмотрение БЗ '}
            />
          )}
          {is_customer && (
            <FilterButton
              isBlue={false}
              title={'Рассмотреть проект'}
              action={() => setModal(true)}
            />
          )}
        </StyledContentRow>
        {customersAnswersList.map(item => (
          <DraftsCustomerItem key={item.id} data={item} />
        ))}
      </PanelContentWrapper>

      {modal && <CustomerActionModal setIsOpen={() => setModal(false)} />}
      {modal2 && <CustomerConsideration setIsOpen={() => setModal2(false)} />}
    </StatusChecker>
  );
};

export default DraftsCustomersContent;
