import React, {useState} from 'react';

import {DraftsItemWrapper} from '@components/Wrappers/DraftsItem';

import {PanelHeader} from '@Common/DraftsItem/Panel';
import {FileCheckIcon} from '@Common/Images';

import DraftsStepsHistoryContent from './Content';

const DraftsItemStepsHistory = () => {
  const [isShownContent, setIsShownContent] = useState(false);
  const handleShow = () => {
    setIsShownContent(prev => !prev);
  };

  return (
    <DraftsItemWrapper>
      <PanelHeader
        isShownContent={isShownContent}
        icon={<FileCheckIcon />}
        title={'История изменения этапов'}
        action={handleShow}
      />
      {isShownContent && <DraftsStepsHistoryContent />}
    </DraftsItemWrapper>
  );
};

export default DraftsItemStepsHistory;
