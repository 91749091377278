import {instance} from '@api/instance';

import {DropdownSingleType} from '@Common/Dropdown/Single/types';

import {IApiResponse} from 'types/services';

export interface SupportMeasureItem {
  id: number;
  name: string;
  created_at: string;
  created_by: number;
  description: string;
  found: {
    id: number;
    name: string;
    created_at: string;
    created_by: number;
  };
  found_id: number;
  support_measures: string;
  url: string;
  category: {
    id: number;
    name: string;
    created_at: string;
    created_by: number;
  };
  category_id: number;
}

export type SupportMeasureEditData = SupportMeasureInputFields & SupportMeasureDropdownFields;

export type SupportMeasureInputFields = {
  name: string;
  description: string;
  support_measures: string;
  url: string;
};

export type SupportMeasureDropdownFields = {
  found_id: DropdownSingleType;
  category_id: DropdownSingleType;
};

export interface SupportMeasurePatchData {
  name?: string;
  description?: string;
  found_id?: number;
  support_measures?: string;
  url?: string;
  category_id?: number;
}

interface SupportMeasureFound {
  id: number;
  name: string;
  created_at: string;
  created_by: number;
}

interface SupportMeasureCategory {
  id: number;
  name: string;
  created_at: string;
  created_by: number;
}

export const SupportMeasureService = {
  async getAll({search}: {search: string}) {
    const response = await instance.get<IApiResponse<SupportMeasureItem>>(
      `/support_measure/?search=${search}`,
    );

    return response.data.results;
  },

  async getById(id: number) {
    const response = await instance.get<SupportMeasureItem>(`/support_measure/${id}/`);

    return response.data;
  },
  async getFounds() {
    const response = await instance.get<IApiResponse<SupportMeasureFound>>('/found/');

    return response.data.results;
  },

  async updateFoundById(id: number, name: string) {
    const response = await instance.patch<SupportMeasureCategory>(`/found/${id}/`, {name});

    return response.data;
  },

  async getCategories() {
    const response = await instance.get<IApiResponse<SupportMeasureCategory>>(
      '/support_measure_category/',
    );

    return response.data.results;
  },

  async updateCategoryById(id: number, name: string) {
    const response = await instance.patch<SupportMeasureCategory>(
      `/support_measure_category/${id}/`,
      {name},
    );

    return response.data;
  },

  async update(id: number, data: SupportMeasurePatchData) {
    const response = await instance.patch<SupportMeasureItem>(`/support_measure/${id}/`, data);

    return response.data;
  },
};
