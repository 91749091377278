import {instance} from 'api/instance';

import {ICompanyItem, ICompanyState} from 'types/state/company';

export const CompanyService = {
  async getAll() {
    const response = await instance.get<ICompanyState>('/company/');

    return response.data.results;
  },

  async getById(id: string) {
    const response = await instance.get<ICompanyItem>(`/company/${id}/`);

    return response.data;
  },
};
