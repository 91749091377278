import styled from 'styled-components';

import React from 'react';

import {FilterButton} from '@Common/Buttons';

import UseCheckProfile from '@hooks/UseCheckProfile';

const MenuStyled = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  justify-content: start;
  align-items: center;

  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};

  border-radius: 10px;
`;
interface IMenuProps {
  setActiveMenu: (active: number) => void;
  active: number;
}

const ProfileActivityMenu = ({setActiveMenu, active}: IMenuProps) => {
  const {isEditableProfile} = UseCheckProfile();
  return (
    <MenuStyled>
      <FilterButton
        isBlue={active === 1}
        action={() => setActiveMenu(1)}
        title="Участие в проектах"
      />
      {isEditableProfile && (
        <>
          <FilterButton isBlue={active === 2} action={() => setActiveMenu(2)} title="Активность" />
          <FilterButton isBlue={active === 3} action={() => setActiveMenu(3)} title="Отзывы" />{' '}
        </>
      )}
    </MenuStyled>
  );
};

export default ProfileActivityMenu;
