import {createSlice} from '@reduxjs/toolkit';
import initialState from 'store/projectarea/state';

import {getAllProjectArea} from '../actions';

export const projectAreaSlice = createSlice({
  name: 'projectArea',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllProjectArea.fulfilled, (state, action) => {
      return {...state, ...action.payload, isLoading: false};
    });
  },
});

export default projectAreaSlice.reducer;
