import {instance} from 'api';

import {IApiResponse} from 'types/services';

type TagApi = {
  id: number;
  name: string;
  slug: string;
};

type EditTag = Omit<TagApi, 'id'>;

export const tagsService = {
  async getAll() {
    const response = await instance.get<IApiResponse<TagApi>>(
      '/tag/?ordering=id&limit=10000&offset=0',
    );

    return response.data.results;
  },

  async getOne(id: string) {
    const response = await instance.get<TagApi>('/tag/' + id);

    return response.data;
  },
  async edit(data: Omit<EditTag, 'slug'>, id: string) {
    await instance.patch<EditTag>('/tag/' + id + '/', data);
  },
};
