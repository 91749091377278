import {useMutation, useQueryClient} from '@tanstack/react-query';

import {CustomersService} from '@services/customers';

import {IPostCustomerResponse} from 'types/state/Customer';

const UseMutationProjectCustomerStatus = (pageId: number) => {
  const queryClient = useQueryClient();

  const fetchFn = (data: IPostCustomerResponse) => CustomersService.create({...data});

  return useMutation({
    mutationFn: fetchFn,
    onSuccess: () => {
      void queryClient.invalidateQueries(['project-customer-response', pageId]);
    },
  });
};
export default UseMutationProjectCustomerStatus;
