import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {useState} from 'react';

import {ScoringService} from '@services/scoring';

import {FilterButton} from '@Common/Buttons';
import CommentEditor from '@Common/CommentEditor';
import SingleDropdown from '@Common/Dropdown/Single';
import {DropdownSingleType} from '@Common/Dropdown/Single/types';
import {CrossIcon} from '@Common/Images';
import {Rating} from '@Common/Rating';
import {TextHeader, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {IPostScoring} from 'types/state/Scoring';

import {IScoringData} from './types';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 10px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 50%;
`;

interface ModalProps {
  projectId: number;
  isOpen: boolean;
  onClose: () => void;
}

const statusLabels = [
  {
    value: 'accelerator_wait',
    label: 'Ожидайте акселератор',
  },
  {
    value: 'revision',
    label: 'На доработку',
  },
  {
    value: 'recommendations',
    label: 'Выданы рекомендации',
  },
];

const ScoringModal = ({isOpen, onClose, projectId}: ModalProps) => {
  const queryClient = useQueryClient();
  const pageId = Number(useParams().id);
  const [scoringData, setScoringData] = useState<IScoringData>({
    status: statusLabels[0],
    team: 0,
    technology: 0,
    market: 0,
    comment: '',
    scoring: '',
  });
  const [error, setError] = useState(false);

  const mutation = useMutation({
    mutationFn: (data: IPostScoring) => ScoringService.create(data),
    onSuccess: () => {
      void queryClient.invalidateQueries(['scoring', projectId]);
      void queryClient.invalidateQueries(['draft', pageId]);
    },
  });

  const createScoring = () => {
    if (
      scoringData.team &&
      scoringData.technology &&
      scoringData.market &&
      scoringData.comment &&
      scoringData.scoring
    ) {
      mutation.mutate({
        content_type: 13,
        object_id: projectId,
        ...scoringData,
        status: scoringData.status?.value || null,
      });
      onClose();
    } else {
      setError(true);
    }
  };

  const handleRatingChange =
    (field: keyof IScoringData) => (value: number | string | DropdownSingleType) => {
      setScoringData(prev => ({
        ...prev,
        [field]: value,
      }));
    };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Overlay>
        <ModalWrapper
          onClick={e => {
            e.stopPropagation();
          }}>
          <CloseButton onClick={onClose}>
            <CrossIcon />
          </CloseButton>
          <TextHeader>Добавление скоринга</TextHeader>
          <SingleDropdown
            isClearable={false}
            labels={statusLabels}
            value={scoringData.status}
            placeholder="Статус скоринга"
            handleChange={option => setScoringData(prev => ({...prev, status: option}))}
          />
          <TextParagraph color={Colors.grey}>Технология *</TextParagraph>
          <Rating rating={scoringData.technology} setRating={handleRatingChange('technology')} />
          <TextParagraph color={Colors.grey}>Команда *</TextParagraph>
          <Rating rating={scoringData.team} setRating={handleRatingChange('team')} />
          <TextParagraph color={Colors.grey}>Рынок *</TextParagraph>
          <Rating rating={scoringData.market} setRating={handleRatingChange('market')} />
          <TextParagraph color={Colors.grey}>Комментарий *</TextParagraph>
          <CommentEditor value={scoringData.comment} setValue={handleRatingChange('comment')} />
          <TextParagraph color={Colors.grey}>Официальный комментарий *</TextParagraph>
          <CommentEditor value={scoringData.scoring} setValue={handleRatingChange('scoring')} />

          {error && <TextParagraph color={Colors.red}>Заполнены не все поля</TextParagraph>}

          <ButtonWrapper>
            <FilterButton isBlue={false} title={'Отправить'} action={createScoring} />
          </ButtonWrapper>
        </ModalWrapper>
      </Overlay>
    </>
  );
};

export default ScoringModal;
