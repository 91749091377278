import {axiosDocument, instance} from 'api/instance';

import {IApiResponse} from 'types/services';
import {IAttachment, IPostAttachment} from 'types/state/Attachment';

export const AttachmentService = {
  async getAll() {
    const response = await instance.get<IApiResponse<IAttachment>>('/attachment/');

    return response.data.results;
  },

  async getByComment(commentId: number) {
    const response = await instance.get<IApiResponse<IAttachment>>(
      `/attachment/?object_id=${commentId}`,
    );

    const results = response.data.results;

    results.sort((a, b) => (b.created_at || '').localeCompare(a.created_at || ''));

    return results;
  },

  async getByChecklist() {
    const response = await instance.get<IApiResponse<IAttachment>>('attachment/?content_type=76');

    const results = response.data.results;
    results.sort((a, b) => (b.created_at || '').localeCompare(a.created_at || ''));

    return results;
  },

  async getByRouterChecklist(checklistId: number) {
    const response = await instance.get<IApiResponse<IAttachment>>('attachment/?content_type=76');

    const results = response.data.results.filter(({object_id}) => object_id === checklistId);

    results.sort((a, b) => (b.created_at || '').localeCompare(a.created_at || ''));

    return results;
  },

  async create(data: IPostAttachment) {
    try {
      return axiosDocument.post<IAttachment>('/attachment/', data);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },

  async delete(id: number) {
    try {
      return instance.delete<IAttachment>(`/attachment/${id}`);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  },
};
